import { useState } from 'react';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BookingHeader } from './bookingHeader';
import { Button, TextField } from '@mui/material';

import PhoneInput from 'react-phone-input-2';
import { sendPhone, clientLoginCode } from '../../../actions/';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from '../../../helpers/translate';
import { ROLES } from './../../../constants';

export const BookingAuth = () => {
  const { __ } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const [role, setRole] = useState(path === ROLES.ADMIN.PATH ? ROLES.ADMIN.ROLE : ROLES.USER.PATH);
  const [loginPhone, setPhone] = useState({
    phone_number: '+380'
  });
  const [loginCode, setCode] = useState({
    code: '212223'
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);

  const handleChange = (e) => {
    setPhone({
      ...loginPhone,
      phone_number: e.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5')
    });
  };

  const handleChangeCode = (e) => {
    setCode({
      code: e.target.value
    });
  };

  const submitPhone = async () => {
    setErrorMessages({});
    sendPhone(loginPhone)
      .then((res) => {
        setIsPhoneSubmitted(true);
      })
      .catch((error) => {
        const errorFields = Object.keys(error.response.data.errors);
        const errorMessage = errorFields
          .map((field) => error.response.data.errors[field][0])
          .join('<br>');

        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        setErrorMessages(error.response.data.errors);
      });
  };

  const submitData = async () => {
    setErrorMessages({});
    try {
      const res = await dispatch(clientLoginCode(loginPhone.phone_number, loginCode.code, role));
      if (res.data.success) {
        navigate('/booking');
      }
    } catch (error) {
      const errorFields = Object.keys(error.response.data.errors);
      const errorMessage = errorFields
        .map((field) => error.response.data.errors[field][0])
        .join('<br>');

      toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, {
        position: 'top-right',
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });

      setErrorMessages(error.response.data.errors);
    }
  };

  const changePhoneNumber = () => {
    setIsPhoneSubmitted(false);
  };

  // modal open

  return (
    <>
      <ToastContainer />
      <BookingHeader />
      <div className="center-block">
        <div className="inside">
          <div className="center-block-form">
            <div className="center-block-title">
              <p>
                {' '}
                {isPhoneSubmitted
                  ? `${__('booking.authorization.title1')}`
                  : `${__('booking.authorization.title2')}`}
              </p>
            </div>
            {!isPhoneSubmitted && (
              <PhoneInput
                className={`phone-input ${errorMessages.phone_number ? 'input-error' : ''}`}
                isValid={!!loginPhone.phone_number.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i)}
                fullWidth
                placeholder={'Телефон'}
                name="phone_number"
                id="number"
                type="number"
                country={'ua'}
                preferredCountries={['ua', 'ru', 'us']}
                masks={{ ua: '(..) ...-..-..' }}
                value={loginPhone.phone_number}
                onChange={handleChange}
                error={errorMessages.phone_number ? true : false}
              />
            )}
            {isPhoneSubmitted && (
              <>
                <TextField
                  fullWidth
                  label={__('booking.authorization.input-code')}
                  name="code"
                  value={loginCode.code}
                  onChange={handleChangeCode}
                  error={errorMessages.code ? true : false}
                  helperText={errorMessages.code ? errorMessages.code[0] : ''}
                  className={errorMessages.code ? 'input-error' : ''}
                />
                <div className="form-action">
                  <Button
                    className="link"
                    sx={{ p: 0, textTransform: 'none', fontSize: 16, lineHeight: 1 }}
                    onClick={changePhoneNumber}>
                    {__('booking.authorization.button-action1')}
                  </Button>
                  <Button
                    className="link"
                    sx={{ p: 0, textTransform: 'none', fontSize: 16, lineHeight: 1 }}
                    onClick={submitPhone}>
                    {__('booking.authorization.button-action2')}
                  </Button>
                </div>
              </>
            )}
            {isPhoneSubmitted ? (
              <Button
                variant="contained"
                type="submit"
                onClick={submitData}
                sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
                {__('booking.authorization.button1')}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                onClick={submitPhone}
                sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
                {__('booking.authorization.button2')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
