import { ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import styles from './../layout.module.scss';
import { s } from './styles';
import { useDispatch } from 'react-redux';
import { SYSTEM_MODES } from './../../../../constants';
import { useCheckComponentAvailableByCurrentUser } from 'src/configData/rolesConfig/hooks/useCheckComponentAvailableByCurrentUser';

export const SidebarLink = ({ title, link, icon, changeModelType = false, resource = false, providerProps = {}, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isActive = location.pathname === link;
  let availability = useCheckComponentAvailableByCurrentUser(resource);
  
  availability = resource ? availability : true;

  const clickHandler = () => {
    if (changeModelType) {
      dispatch({ type: SYSTEM_MODES.CHANGE_MODE, payload: changeModelType });
    }
  }

  return (
    <>
      {
        availability &&
        <Link
          className={`${styles['dashbord-nav__item-link']} ${isActive ? styles.activeLink : ''}`}
          to={link}
          onClick={ clickHandler }
          {...props}>
          <Tooltip title={title} placement="right" leaveDelay={50}>
            <ListItemButton sx={s.listItemButton}>
              <ListItemIcon sx={s.listItemIcon}>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </Tooltip>
        </Link>
      }
    </>
  );
};