export const stackBtnsGroupOptions = [
  {
    groupKey: 'top',
    relationKey: 'bottom',
    btns: [
      { title: 'Діапазон', key: 'range', default: true },
      { title: 'Річний', key: 'yearly' },
      { title: 'Квартальний', key: 'quarterly' },
      { title: 'Місячний', key: 'monthly' },
      { title: 'Тижневий', key: 'weekly' },
    ],
  },
  {
    slave: true,
    groupKey: 'bottom',
    relationKey: 'top',
    btns: [
      { title: 'День', key: 'day', blockingKeys: [], default: true },
      { title: 'Тиждень', key: 'week', blockingKeys: ['weekly'], },
      { title: 'Місяць', key: 'month', blockingKeys: ['weekly', 'monthly'], },
      { title: 'Квартал', key: 'quarter', blockingKeys: ['quarterly', 'weekly', 'monthly'] },
    ],
  }
];