import React from "react";
import ReactDOM from "react-dom/client";

import App from "./components";

import "./styles/index.scss";
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";
// import Native from './helpers/redux_native_demo';
// Native();
const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
  type: 'success'
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
);
