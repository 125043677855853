const horsesCategories = {
        uk: {
                "horsesCategories.page.title": "Категорії коней",
                "horsesCategories.table.title": "Назва",
                "horsesCategories.table.description": "Опис",
                "horsesCategories.table.is_active": "Активний/Неактивний",
                "horsesCategories.table.actions": "Дії",
                "horsesCategories.form.title-create": "Створити категорію коней",
                "horsesCategories.form.title-edit": "Редагувати категорію коней",
                "horsesCategories.form.button": "Зберегти",
                "horsesCategories.form.title.error": "*Поле є обов'язковим",
        },
        en: {
                "horsesCategories.page.title": "Categories of horses",
                "horsesCategories.table.title": "Name",
                "horsesCategories.table.description": "Description",
                "horsesCategories.table.is_active": "Active/Inactive",
                "horsesCategories.table.actions": "Actions",
                "horsesCategories.form.title-create": "Create a category of horses",
                "horsesCategories.form.title-edit": "Edit category of horses",
                "horsesCategories.form.button": "Save",
                "horsesCategories.form.title.error": "*The field is mandatory",
        },
        ru: {
                "horsesCategories.page.title": "Категории лошадей",
                "horsesCategories.table.title": "Название",
                "horsesCategories.table.description": "Описание",
                "horsesCategories.table.is_active": "Активный/Неактивный",
                "horsesCategories.table.actions": "Действия",
                "horsesCategories.form.title-create": "Создать категорию лошадей",
                "horsesCategories.form.title-edit": "Редактировать категорию лошадей",
                "horsesCategories.form.button": "Сохранить",
                "horsesCategories.form.title.error": "*Поле является обязательным",
        }
}


export default horsesCategories;