import TextField from '@mui/material/TextField';
import { Autocomplete, Box, debounce, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { searchClients } from './../../../actions/visit/visitRoutes';

export const SelectFirstName = ({ value, setValue, isValidation = true, disabled = false, direction, isFullWidth, isFirstRender }) => {
  const [options, setOptions] = useState([]);
  const fetch = useMemo(
    () =>
      debounce(async (input) => {
        // if (isFirstRender) {
        //   return;
        // }
        
        try {
          const response = await searchClients('', input, '');
          setOptions(
            response.data.data.map((item) => {
              item.phone = item.login.substring(5);
              return item;
            })
          );
        } catch(error) {
          console.warn(error)
        }
        
      }, 1000),
    []
  );

  return (
    <Autocomplete
      disabled={ disabled }
      fullWidth={ isFullWidth }
      getOptionLabel={({ first_name }) => first_name}
      options={options}
      value={value}
      disableClearable
      freeSolo
      onChange={(_, newValue) => {
        setValue({ ...value, ...newValue });
      }}
      onInputChange={(_, newInputValue) => {
        setValue({ ...value, first_name: newInputValue });
        if (newInputValue.length >= 3) {
          fetch(newInputValue);
        }
        if (!newInputValue) {
          setOptions([]);
        }
      }}
      isOptionEqualToValue={() => true}
      renderOption={(props, { id, first_name, last_name, login, email }) => (
        <React.Fragment key={id}>
          <Box key={id} {...props} sx={{ flexDirection: 'column' }}>
            <Typography>
              {first_name} {last_name}
            </Typography>
            <Typography>{login}</Typography>
            <Typography>{email}</Typography>
          </Box>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={ disabled }
          label="Введіть ім'я користувача"
          style={{ marginLeft: 0, marginRight: 0 }}
          required={ isValidation }
          InputLabelProps={{ shrink: true }}
          error={ isValidation ? !value.first_name : false }
          fullWidth
        />
      )}
    />
  );
};
