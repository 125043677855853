import axios from '../../helpers/public.axios';
import { configurateToken } from '../../helpers/private.axios';

import { AUTH, LOCALE, SYSTEM_MODES, SYSTEM_TYPES } from '../../constants';
import { getSystemMode, getSystemType } from './utils';

export const clientLoginCode = (phone, code, role) => (dispatch) => {
  dispatch({ type: AUTH.AUTH_REQUEST });

  console.log('clientLoginCode.js');

  return axios
    .post(`/auth/client-login`, {
      phone_number: phone,
      code
    })
    .then((res) => {
      const data = res.data.data;
      const locale = data?.locale;
      
      const user = res.data.data;
      console.log(user);
      const systemType = getSystemType(user.service_types);
      const systemMode = getSystemMode(user.service_types);

      console.log(systemType, systemMode);

      dispatch({
        type: SYSTEM_TYPES.CHANGE_SYSTEM_TYPE,
        payload: systemType
      });

      dispatch({
        type: SYSTEM_MODES.CHANGE_MODE,
        payload: systemMode
      });

      dispatch({
        type: AUTH.AUTH_RESPONSE,
        payload: {
          user: { ...res.data.data, role },
          token: res.headers.authorization
        }
      });

      dispatch({
        type: LOCALE.CHANGE_LOCALE,
        payload: locale,
      });

      configurateToken(res.headers.authorization);
      return res;
    });
};

