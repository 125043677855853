

export const getOrderTypes = (obj, returnType='STR', sep=',', translation = false) => {
  const orderTranslate = {
    visit: 'Візит',
    abonnement: 'Абонемент',
    certificate: 'Сертифікат',
  }

  const isVisit = obj?.visit?.length;
  const seasonTickets = obj.seasonTickets || [];
  const isSeasonTickets = seasonTickets?.length;

  let types = [];

  if (isVisit) {
    types.push(!translation ? 'visit' : orderTranslate.visit);
  }

  if (isSeasonTickets) {
    let seasonTicketTypes = [];

    if (!translation) {
      seasonTicketTypes = seasonTickets.map(seasonTicket => seasonTicket.type)
    } else {
      seasonTicketTypes = seasonTickets.map(seasonTicket => orderTranslate[seasonTicket.type])
    }
    
    const uniqTypes = Array.from(new Set(seasonTicketTypes));
    types = [...types, ...uniqTypes];

  }

  if (returnType === 'STR') {
    return types.join(sep);
  } else if (returnType === 'ARRAY') {
    return types;
  }
}