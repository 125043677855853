import { useSelector } from 'react-redux';
import s from './RoleInfo.module.scss';

export const RoleInfo = () => {
  const { user } = useSelector(state => state.auth);
  const { roleInfo, login, role } = s;
  const roles = user?.roles?.map(role => role.name).join('; ').toUpperCase();

  return (
    <div className={ roleInfo }>
      <span className={ login }>{ user.login }</span>
      <span className={ login }>{ user.first_name } { user.last_name }</span>
      <span className={ role }>{ roles }</span>
    </div>
  );
};