import { useEffect, useState } from 'react';

import { getInventoryItemTypes, getInventoryItemsAvailability } from './../../../../actions';
import { useTranslation } from './../../../../helpers/translate';
import { 
  TableMain, 
  TableAction, 
  TableBlock,
  TableHeader,
  TrBody,
  TableBody,
  TablePagination
} from './../../../common/TableComponent';
import { FilterSearch, FilterSelect, SelectMultyCheckbox } from './../../../common/FormsComponent';
import { getItemsAvailabilityRowCellsData } from './getItemsAvailabilityRowCellsData';

export const InventoryItemsAvailability = () => {
  const { __ } = useTranslation();
  const [{ loaded, data, inventoryItemTypes }, setData] = useState({
    loaded: false,
    data: [],
    inventoryItemTypes: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [searchTitle, setSearchTitle] = useState('');
  const [inventoryItemTypeFilter, setInventoryItemTypeFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setInventoryItemTypeFilter(value);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getInventoryItemsAvailability(
          sortOption,
          sortDirection,
          searchTitle,
          inventoryItemTypeFilter,
          currentPage,
          itemsPerPage,
          showArchive
        );
        const { data: inventoryItemTypesResponse } = await getInventoryItemTypes();

        setData({
          loaded: true,
          data: response.data,
          inventoryItemTypes: inventoryItemTypesResponse?.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [
    sortOption,
    sortDirection,
    searchTitle,
    inventoryItemTypeFilter,
    currentPage,
    itemsPerPage,
    showArchive,
  ]);

  return (
    <TableMain
      title={'Наявність ТМЦ'} 
      getRowCellsData={getItemsAvailabilityRowCellsData}
      sortOption={sortOption} 
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}
    >

      <TableAction className="table-action">
        <div className="table-action__block">
          <FilterSearch
            label={ __('inventoryItem.filter.title') }
            name="inventory-item-title-filter"
            value= { searchTitle }
            handler={ e => setSearchTitle(e.target.value) } 
          />
          <SelectMultyCheckbox
            sx={{ minWidth: '250px', maxWidth: '250px' }}
            label={ "Тип ТМЦ" }
            dataList={ inventoryItemTypes }
            dataChecked={ inventoryItemTypeFilter }

            value={inventoryItemTypeFilter}
            onChange={handleChange('horsCategories')}
          />
        </div>
      </TableAction>

      <TableBlock __class="table--inventory-items-availability">
        <TableHeader />
        <TableBody loaded={loaded}>
          { 
            data.map((item) => (
              <TrBody key={item.id} data={item} />
            )) 
          }
        </TableBody>
      </TableBlock>
      
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} 
      />
      
    </TableMain>
  );
};
