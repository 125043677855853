import { ButtonAction } from './../../../common/TableComponent';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const fieldStyle = {
  m: 1
}

export const StocktakingCreateForm = ({ formHandler, cancelHandler }) => {
  const [formData, setFormData] = useState({
    subdivision: '',
    name: '',
    description: '',
  });

  const sumbitHandler = (e) => {
    e.preventDefault();

    if (validate(formData, ['subdivision'])) {
      formHandler(formData);
    }
  }

  return (
    <>
      <Box component="form">
        <FormControl sx={{ ...fieldStyle }} fullWidth disabled>
          <InputLabel id="stocktacking-select-label">Підрозділ</InputLabel>
          <Select
            labelId="stocktacking-select-label"
            id="stocktacking-select-select"
            value={formData.subdivision}
            label="Підрозділ"
            onChange={setFormData}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          </Select>
          <FormHelperText>Поле не має бути пустим</FormHelperText>
        </FormControl>
        <TextField
          error={!!!formData.name}
          sx={{ ...fieldStyle }}
          id="stocktacking-name"
          label="Назва"
          helperText="Поле не повинно бути пустим"
          fullWidth
          value={formData.name}
          onChange={(e) => setFormData({...formData, name: e.target.value})}
        />
        <TextField
          error={!!!formData.description}
          sx={{ ...fieldStyle }}
          id="stocktacking-description"
          label="Опис"
          helperText="Поле не повинно бути пустим"
          multiline
          maxRows={4}
          fullWidth
          value={formData.description}
          onChange={(e) => setFormData({...formData, description: e.target.value})}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonAction text="Створити" sx={{ mr: 2 }} onClick={ e => sumbitHandler(e) }/>
          <ButtonAction text="Відмовитись" color="error" onClick={ () => cancelHandler(false) } />
        </Box>
      </Box>
    </>
  );
}


function validate(obj, excludeArr) {
  for (let key in obj) {
    if (excludeArr.includes(key)) {
      continue;
    }

    const field = obj[key].trim();
    if (!field) {
      return false;
    }
  }

  return true;
}