import { useEffect, useState } from 'react';

import { getServicesId, updateServices } from '../../../actions/';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { ServicesForm } from './ServicesForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { getDataCloneForSend } from './utils';
import { getFormData } from 'src/components/library/';

export const ServicesEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [loaded, setLoaded] = useState(false);

  const toggleOpened = () => {
    navigate('/services');
  };

  const [services, setServices] = useState({
    is_active: true,
    available_for_online: true,
    service_category_id: '',
    amount: '',
    locale: {
      uk: {
        title: '',
        description: ''
      },
      ru: {
        title: '',
        description: ''
      },
      en: {
        title: '',
        description: ''
      }
    }
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' }
    },
    service_category_id: { isValid: true, errorMessage: '' },
    amount: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    Object.keys(services.locale).forEach((locale) => {
      const title = services.locale[locale].title;
      const isTitleValid = title.trim() !== '';

      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        [locale]: {
          ...prevValidationStatus[locale],
          title: {
            isValid: isTitleValid,
            errorMessage: isTitleValid ? '' : `${__('services.form.title.error')}`
          }
        }
      }));

      if (!isTitleValid) {
        isValid = false;
      }
    });

    const isServiceCategoryValid = services.service_category_id !== '';
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      service_category_id: {
        isValid: isServiceCategoryValid,
        errorMessage: isServiceCategoryValid ? '' : `${__('services.form.service_category.error')}`
      }
    }));
    if (!isServiceCategoryValid) {
      isValid = false;
    }

    const isAmountValid = services.amount.length > 0;
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      amount: {
        isValid: isAmountValid,
        errorMessage: isAmountValid ? '' : `${__('services.form.amount.error')}`
      }
    }));
    if (!isAmountValid) {
      isValid = false;
    }

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('services.form.title.error'),
    setServices,
    setValidationStatus
  );

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active' || name === 'available_for_online') {
      value = event?.target.checked;
    } else if (name === 'image') {
      value = event;
    } else {
      value = name === 'amount' ? event.target.value.replace(/[^0-9.]/g, '') : event.target.value;
    }

    setServices((prevServices) => {
      let updatedServices = {
        ...prevServices,
        [name]: value
      };

      if (name === 'service_category_id') {
        updatedServices.service_category_id = value;
      } else if (name === 'type') {
        updatedServices.service_category.type = value;
      } 

      return updatedServices;
    });
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('services.form.amount.error')}`
      }
    }));
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      const servicesClone = getDataCloneForSend(services);

      updateServices(params.id, getFormData(servicesClone)).then((res) => {
        navigate('/services');
      });
    }
  };

  useEffect(() => {
    getServicesId(params.id).then((res) => {
      const servicesData = res.data.data;
      servicesData.serviceCategories = res.data.data.service_category_id;
      setServices(servicesData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('services.form.title-edit')}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <ServicesForm
        services={services}
        setServices={setServices}
        action={handleUpdate}
        handleChange={handleChange}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('services.form.submit-btn')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
