import { getValidationLocaleModel } from "./../../../common/FormsComponent/LocaleTabs";
// may be import from current component folder
// import { requiredLocaleValidationFields } from "./../../../common/FormsComponent/LocaleTabs";
import { requiredLocaleValidationFields } from "./requiredLocaleValidationFields";

export const validationModel = {
  ...getValidationLocaleModel(requiredLocaleValidationFields),
  inventory_item_type_id: { isValid: true, errorMessage: '' },
  unit_id: { isValid: true, errorMessage: '' },
  cost: { isValid: true, errorMessage: '' },
  reserve: { isValid: true, errorMessage: '' },
  sale_price: { isValid: true, errorMessage: '' }
}