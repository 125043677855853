const subscription = {
    uk: {
        "subscription.page.title": "Абонементи",
        "subscription.table.code": "Код",
        "subscription.table.title": "Назва",
        "subscription.table.description": "Опис",
        "subscription.table.category": "Категорія",
        "subscription.table.quantity_hours": "Кількість годин",
        "subscription.table.date_start": "Дата початку дії",
        "subscription.table.date_end": "Дата закінчення дії",
        "subscription.table.discount_amount": "Знижка сума",
        "subscription.table.discount_percent": "Знижка %",
        "subscription.table.person": "Клієнт",
        "subscription.table.is_active": "Активний/Неактивний",
        "subscription.table.actions": "Дії",
        "subscription.form.title-create": "Створити абонимент",
        "subscription.form.title-edit": "Редагувати абонимент",
        "subscription.form.button": "Зберегти",
        "subscription.input.date_start": "Дата початку",
        "subscription.input.date_end": "Дата закінчення",
        "subscription.input.subscriptionCategories": "Категорія абонимент",
    },
    en: {
        "subscription.page.title": "Subscription",
        "subscription.table.code": "Code",
        "subscription.table.title": "Title",
        "subscription.table.description": "Description",
        "subscription.table.category": "Category",
        "subscription.table.quantity_hours": "Quantity (hours)",
        "subscription.table.date_start": "Start Date",
        "subscription.table.date_end": "End Date",
        "subscription.table.discount_amount": "Discount Amount",
        "subscription.table.discount_percent": "Discount %",
        "subscription.table.person": "Customer",
        "subscription.table.is_active": "Active/Inactive",
        "subscription.table.actions": "Actions",
        "subscription.form.title-create": "Create Subscription",
        "subscription.form.title-edit": "Edit Subscription",
        "subscription.form.button": "Save",
        "subscription.input.date_start": "Start Date",
        "subscription.input.date_end": "End Date",
        "subscription.input.subscriptionCategories": "Subscription Category",
    },
    ru: {
        "subscription.page.title": "Абонименты",
        "subscription.table.code": "Код",
        "subscription.table.title": "Название",
        "subscription.table.description": "Описание",
        "subscription.table.category": "Категория",
        "subscription.table.quantity_hours": "Количество часов",
        "subscription.table.date_start": "Дата начала",
        "subscription.table.date_end": "Дата окончания",
        "subscription.table.discount_amount": "Скидки сумма",
        "subscription.table.discount_percent": "Скидка %",
        "subscription.table.person": "Клиент",
        "subscription.table.is_active": "Активный/Неактивный",
        "subscription.table.actions": "Действия",
        "subscription.form.title-create": "Создать абонимент",
        "subscription.form.title-edit": "Редактировать абонимент",
        "subscription.form.button": "Сохранить",
        "subscription.input.date_start": "Дата начала",
        "subscription.input.date_end": "Дата окончания",
        "subscription.input.subscriptionCategories": "Категория абонимент",
    }
}

export default subscription;