export const getfilledAllLocaleFromUserData = (localeData, userData) => {
  if (!userData) {
    return localeData;
  }

  const newLocaleData = {...localeData};
  const locales = ['uk', 'en', 'ru'];

  locales.forEach(locale => {
    if (!newLocaleData[locale]) {
      newLocaleData[locale] = {};
    }

    newLocaleData[locale].first_name = userData?.first_name;
    newLocaleData[locale].last_name = userData?.last_name;
  });

  return newLocaleData;
}