import React from 'react';
import { ToggleButtonsGroup } from './ToggleButtonsGroup';
import { Stack } from '@mui/material';
import { stackBtnsGroupOptions } from './groupsData';

export const StackToggleBtnsGroup = ({
  groupValue,
  setGroupValue,
  orientation,
}) => {
  return (
    <Stack spacing={1} alignItems="left" direction={ orientation === 'horizontal' ? 'column' : 'row' }>
      {
        stackBtnsGroupOptions.map(options => {
          return (
            <ToggleButtonsGroup
              key={ options.groupKey } 
              options={ options }
              groupValue={ groupValue }
              setGroupValue={ setGroupValue }
              orientation={ orientation }
            />
          )
        })
      }
    </Stack>
  );
};