import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AddInventoryItems, ButtonSend } from './../../common/FormsComponent';

import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Box
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';

import { getOrderContractors, getOrderStores } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import dayjs from 'dayjs';
import { ToggleActiveStatus } from './../../common/FormsComponent';
import { useLocation } from 'react-router-dom';

export const OrderIncomingsForm = ({
  orderIncomings,
  handleChange,
  actionUpdate,
  actionApply,
  actionCreate,
  ctaButtonText,
  validationStatus,
  setValidationStatus,
  updateItemСount,
  formType,
  itemsPush,
  isChanged,
}) => {
  const { __ } = useTranslation();
  const { pathname } = useLocation();
  // const disabledEdit = pathname.includes('edit') ? true : false;
  const disabledEdit = false;

  const [{ loaded, contractors, stores }, setData] = useState({
    loaded: false,
    contractors: [],
    stores: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: orderContractorsResponse } = await getOrderContractors();
        const { data: orderStoresResponse } = await getOrderStores();
        setData({
          loaded: true,
          contractors: orderContractorsResponse,
          stores: orderStoresResponse
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <TextField
        fullWidth
        value={orderIncomings?.order_num}
        onChange={handleChange('order_num')}
        name="order_num"
        label={__('orderIncomings.input.order_num')}
        error={!validationStatus.order_num.isValid}
        helperText={validationStatus.order_num.errorMessage}
      />
      <FormControl className={validationStatus.contractor_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('orderIncomings.input.contractor_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('orderIncomings.input.contractor_id')}
          name="contractor_id"
          value={orderIncomings?.contractor_id}
          onChange={handleChange('contractor_id')}
          error={!validationStatus.contractor_id.isValid}
          helperText={validationStatus.contractor_id.errorMessage}>
          {contractors.data &&
            contractors.data.map((contractors) => (
              <MenuItem key={contractors.id} value={contractors.id}>
                {contractors.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.contractor_id.isValid && (
          <FormHelperText>{validationStatus.contractor_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('orderIncomings.input.date_order')}
            value={dayjs(orderIncomings.date_order)}
            onChange={handleChange('date_order')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.date_order.isValid}
            helperText={validationStatus.date_order.errorMessage}
            className={validationStatus.date_order.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>
      <FormControl className={validationStatus.store_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('orderIncomings.input.store_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('orderIncomings.input.store_id')}
          name="store_id"
          value={orderIncomings?.store_id}
          onChange={handleChange('store_id')}
          error={!validationStatus.store_id.isValid}
          helperText={validationStatus.store_id.errorMessage}>
          {stores.data &&
            stores.data.map((stores) => (
              <MenuItem key={stores.id} value={stores.id}>
                {stores.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.store_id.isValid && (
          <FormHelperText>{validationStatus.store_id.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <div className={validationStatus.inventoryItems.isValid ? '' : 'error'}>
        <AddInventoryItems disabled={ disabledEdit } type="incomings" updateItemСount={updateItemСount} itemsPush={itemsPush} />
      </div>

      {
        formType === 'edit' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ !isChanged } onClick={ actionUpdate }text="Зафіксувати зміни" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
      {
        formType === 'create' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ isChanged } onClick={ actionCreate }text="Створити" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ !isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
    </>
  );
};
