import axios from '../../helpers/private.axios';

export const createUser = (user) => {
  return axios.post(`/users`, user)
  .then((res) => {
    return res;
  })
  .catch((error) => {
    return error;
  });
};
