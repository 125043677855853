import { flatArrToObj } from "./flatArrToObj";

export const getFormData = (dataObj, method = 'PUT', checkIsArr = false) => {
  const formData = new FormData();

  for (let key in dataObj) {
    let data = dataObj[key];
    if (!Array.isArray(data)) {
      formData.append(key, data);
      continue;
    }

    if (checkIsArr) {
      const obj = flatArrToObj(data, key);
      for (let objKey in obj) {
        formData.append(objKey, obj[objKey]);
      }
    }
    
  }

  if (method === 'PUT') {
    formData.append('_method', 'PUT');
  }
  
  return formData;
}