import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { HorseTimeSlots } from './HorseTimeSlots';
import { equalDates, getDayEmptySlots, getEmptySlots } from '../Utils';
import { getModelTimeSlots } from './../../../../../../actions';
import { useSelector } from 'react-redux';

export const TimeAndDate = ({
  visitDate,
  setVisitDate,
  selectedHorse,
  originalSlotId,
  setTimeSlot,
  visitId,
  modelType,
}) => {
  const { locale } = useSelector((state) => state.localization);
  const [{ loaded, data, calendarData }, setData] = useState({
    loaded: false,
    data: [],
    calendarData: []
  });

  const specialDays = calendarData ? calendarData.map((item) => item.date) : [];

  useEffect(() => {
    if (!selectedHorse?.id) {
      return;
    }

    const fetchData = async () => {
      try {
        const res = await getModelTimeSlots(selectedHorse?.id, modelType, visitDate, 'decade');
        const slots = res.data.data;

        setData({
          loaded: true,
          data: getDayEmptySlots(slots, visitDate, originalSlotId),
          calendarData: getEmptySlots(slots, originalSlotId)
        });
      } catch (error) {
        // Обробка помилки
      }
    };

    fetchData();
  }, [selectedHorse, visitDate]);

  return (
    <>
      {
        selectedHorse &&
        <>
          <Box sx={{ width: '560px', maxWidth: '100%' }}>
            <Typography variant="h6">Дата візиту</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale || 'uk'}>
              <DatePicker
                slots={{
                  day: (date) => {
                    const isSpacialDay = !!specialDays.find((day) => equalDates(day, date.day));
                    const specialStyles = isSpacialDay
                      ? {
                          border: '2px solid silver',
                          background: '#bbdefb'
                        }
                      : {};

                    return (
                      <PickersDay {...date} showDaysOutsideCurrentMonth={true} sx={specialStyles} />
                    );
                  }
                }}
                sx={{ mt: 1, mb: 4, width: '100%' }}
                onChange={(value) => setVisitDate(value)}
                value={visitDate ? dayjs(visitDate) : dayjs()}
                disablePast
                disabled={!visitId}
              />
            </LocalizationProvider>
          </Box>

          { visitId && (
            <Box sx={{ width: '560px', maxWidth: '100%' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Час візиту
              </Typography>
              <HorseTimeSlots
                loaded={loaded}
                data={data}
                originalSlotId={originalSlotId}
                setTimeSlot={setTimeSlot}
              />
            </Box>
          )}
        
        </>
      }
      
    </>
  );
};
