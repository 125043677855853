import { Link } from "react-router-dom";

export const Td = ({ children, value, th, link, __class, __classTd,  ...props }) => {

  if (link) {
    value = <Link to={ link.url } state={link.state}>{ value }</Link>
  }

  return (
    <div className={ `td${__class && ' ' + __class}${__classTd && ' ' + __classTd}`} {...props}>
      <div className="td-hidden-name">{ th }</div>
      <div className="td-content">
        { 
          children
            ? children
            : value 
        }
      </div>
    </div>
  );
};

Td.defaultProps = {
  __class: '',
  __classTd: '',
}