import { getDateValidOrder } from "./../../../../library";
import dayjs from "dayjs";

export const getRowCellsData = (dataItem, __) => {

  const clientId = dataItem?.user?.client_id;

  let dateDiff = '';
  const now = dayjs();
  const dateStart = dataItem && 
    dataItem.date_start ? dayjs(dataItem.date_start).format('YYYY-MM-DD') : '';
  
  const dateEnd = dataItem && 
  dataItem.date_end ? dayjs(dataItem.date_end).format('YYYY-MM-DD') : '';

  if ( dateStart && dateEnd) {
    dateDiff = dayjs(dateEnd).diff(dayjs(now), 'day');
    if (dateDiff < 0) {
      dateDiff = 0;
    }
  }

  const validOrder = getDateValidOrder(dayjs(dateEnd)) && dataItem?.quantity_hours_left;
  const validOrderText = validOrder
    ? __('certificates.table.valid')
    : __('certificates.table.notvalid');

  const allowedDays = dataItem?.allowed_days ? dataItem?.allowed_days?.map(day => day.title).join(',') : '';

  return {
    title: {
      th: __('certificates.table.number'),
      td: dataItem && dataItem.title,
      sort: null,
    },
    description: {
      th: __('certificates.table.title'),
      td: dataItem && dataItem.description,
      sort: null,
    },
    category: {
      th: __('certificates.table.category'),
      td: dataItem && dataItem.category?.title,
      sort: null,
    },
    services: {
      th: __('certificates.table.service'),
      td: dataItem && dataItem.service?.title,
      sort: null,
    },
    quantityHours: {
      th: __('certificates.table.quantity_hours'),
      td: dataItem && dataItem.quantity_hours,
      sort: null,
    },
    quantityHoursLast: {
      th: __('certificates.table.quantity_hours_last'),
      td: dataItem && dataItem.quantity_hours_left,
      sort: null,
    },
    dateStart: {
      th: __('certificates.table.date_start'),
      td: dataItem && dataItem.date_start,
      sort: null,
    },
    dateEnd: {
      th: __('certificates.table.date_end'),
      td: dataItem && dataItem.date_end,
      sort: null,
    },
    dayCount: {
      th: __('certificates.table.day_last'),
      td: dataItem && dateDiff,
      sort: null,
    },

    // discountAmount: {
    //   th: __('certificates.table.discount_amount'),
    //   td: dataItem && dataItem.discount_amount,
    //   sort: null,
    // },
    // discountPercent: {
    //   th: __('certificates.table.discount_percent'),
    //   td: dataItem && dataItem.discount_percent,
    //   sort: null,
    // },
    client: {
      th: __('certificates.table.person'),
      td: dataItem &&  
                  `${dataItem.user.first_name || ''}
                  ${dataItem.user.last_name || ''}`,
      link: clientId && {
        url: `/clients/${clientId}`,
        state: { id: clientId, backUrl: '/clients/loyality/certificates' }
      },
      sort: null,
    },
    allowedDays: {
      th: 'Доступні дні',
      td: dataItem && allowedDays,
      sort: null,
    },
    isValidOrder: {
      th: __('certificates.table.is_valid'),
      td: dataItem && validOrderText,
      sort: null,
    },
    isActive: {
      th: __('certificates.table.is_active'),
      td: dataItem && 
            dataItem.is_active
              ? __('certificates.table.status_active')
              : __('certificates.table.status_inactive'),
      sort: null,
    },
    actions: {
      th: __('certificates.table.actions'),
      td: null,
      sort: null,
      __class: 'td--right',
    },
  }
}