import { Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import VisitForm from './../components/VisitForm';
import { AddServiceForm } from './../components/AddServiceForm';
import { getVisit } from '../../../../actions/visit/visitRoutes';
import dayjs from 'dayjs';
import { OpenDialog } from './../../Popups';
import { useLocation } from 'react-router-dom';

export default function VisitDetails({ clickedDate, extendedProps }) {
  const state = useLocation();
  console.log(state);
  const [value, setValue] = useState({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    phoneCode: '+380 ',
    email: '',
    another_name: '',
    length: 1,
    visit_services: [],
    count: 1,
    status_id: 0,
    time: ''
  });

  const [validVisit, setValidVisit] = useState({
    first_name: true,
    last_name: true,
    email: true,
    phone: true,
    visit_services: true,
    time: true
  });

  const [status, setStatus] = useState(0);
  const visitId = extendedProps.visit?.id;
  const [update, setUpdate] = useState(false);
  const [load, setLoad] = useState(!visitId ? true : false);
  const [visitError, setVisitError] = useState(null); 
  const [errorPopupData, setErrorPopupData] = useState(false);

  const mainTitle = visitId ? 'Редагування візиту' : 'Створення візиту';

  useEffect(() => {
    if (!visitId) {
      return;
    }

    (async () => {

      try {

        const res = await getVisit(visitId)
        const data = res?.data?.data;

        if (!data) {
          const error = res?.response?.data;
          const errorInfo = {
            message: error.message,
            error: error.exception.error,
            title: error.exception.file,
            line: error.exception.line,
          }

          const popupData = {
            title: 'Виникла помилка, зверніться до розробника',
            description: Object.values(errorInfo).join('\n'),
          };

          setErrorPopupData(popupData);
          setVisitError(errorInfo);
          setLoad(false);
          return;
        }

        const date = dayjs(data?.timeslot.date).$d;
    
        setLoad(true);
        setValue({
          ...data,
          phoneCode: '+380 ',
          phone: data?.phone.substring(5),
          editTime: date
        });

      } catch (error) {
        console.warn('ERROR: getVisits', error);
      }
      

    })()

  }, [update]);

  return (
    <>
      {
        errorPopupData && <OpenDialog open={errorPopupData} setOpen={setErrorPopupData} data={errorPopupData} redirect="/" type="error" />
      }
      <>
        <Typography
          variant="h4"
          sx={{
            mb: 3,
            '@media screen and (max-width: 920px)': {
              fontSize: '28px'
            }
          }}>
          {mainTitle}
        </Typography>
        <VisitForm
          value={value}
          setValue={setValue}
          status={status}
          setStatus={setStatus}
          validVisit={validVisit}
        />

        <Divider variant="middle" sx={{ mb: 4 }} />

        {load && (
          <AddServiceForm
            clickedDate={clickedDate}
            setValue={setValue}
            value={value}
            status={status}
            validVisit={validVisit}
            setValidVisit={setValidVisit}
            extendedProps={extendedProps}
            setUpdate={setUpdate}
          />
        )}
      </>
    </>
    
  );
}
