import { useEffect, useState } from 'react';

import { getSeasonTicketsId, updateSeasonTickets } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import moment from 'moment';

import { useNavigate, useParams } from 'react-router-dom';

import { SeasonTicketsForm } from './SeasonTicketsForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange, localeModel } from './../../common/FormsComponent/LocaleTabs';
import { getDataCloneForSend } from './utils';
import { getFormData } from 'src/components/library/';

export const SeasonTicketsEdit = () => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/season-tickets');
  };

  const [loaded, setLoaded] = useState(false);

  const [seasonTickets, setSeasonTickets] = useState({
    allowed_days: [],
    is_active: true,
    category_id: '',
    service_id: '',
    type: '',
    amount: '',
    quantity_hours: '',
    discount_amount: '',
    discount_percent: '',
    validity_period: '',
    date_start: '',
    date_end: '',
    locale: { ...localeModel }
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    },
    type: { isValid: true, errorMessage: '' },
    category_id: { isValid: true, errorMessage: '' },
    service_id: { isValid: true, errorMessage: '' },
    amount: { isValid: true, errorMessage: '' },
    quantity_hours: { isValid: true, errorMessage: '' },
    // discount_amount: { isValid: true, errorMessage: '' },
    // discount_percent: { isValid: true, errorMessage: '' },
    validity_period: { isValid: true, errorMessage: '' },
    date_start: { isValid: true, errorMessage: '' },
    date_end: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    const isValidNumber = (value) => /^\d+(\.\d{1,2})?$/.test(value);

    const isValidDate = (value) => {
      return moment(value, 'YYYY-MM-DD', true).isValid();
    };

    const startDate = moment(seasonTickets.date_start, 'YYYY-MM-DD');
    const endDate = moment(seasonTickets.date_end, 'YYYY-MM-DD');

    ['uk', 'ru', 'en'].forEach((locale) => {
      const titleValue = seasonTickets?.locale[locale]?.title || '';
      const descriptionValue = seasonTickets?.locale[locale]?.description || '';

      if (titleValue.length === 0) {
        isValid = false;
        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            title: {
              isValid: false,
              errorMessage: `${__('seasonTickets.form.title.error')}`
            }
          }
        }));
      }

      if (descriptionValue.length === 0) {
        isValid = false;
        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            description: {
              isValid: false,
              errorMessage: `${__('seasonTickets.form.description.error')}`
            }
          }
        }));
      }
    });

    if (!seasonTickets.type) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        type: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.type.error')}`
        }
      }));
    }

    if (!seasonTickets.category_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        category_id: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.category_id.error')}`
        }
      }));
    }

    if (!seasonTickets.service_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        service_id: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.service_id.error')}`
        }
      }));
    }

    if (!isValidNumber(seasonTickets.amount)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        amount: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.amount.error')}`
        }
      }));
    }

    if (
      !seasonTickets.quantity_hours &&
      !seasonTickets.discount_amount &&
      !seasonTickets.discount_percent
    ) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        quantity_hours: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.quantity_hours.error')}`
        }
      }));
    }
    if (
      !seasonTickets.discount_amount &&
      !seasonTickets.quantity_hours &&
      !seasonTickets.discount_percent
    ) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        discount_amount: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.discount_amount.error')}`
        }
      }));
    }
    if (
      !seasonTickets.discount_percent &&
      !seasonTickets.quantity_hours &&
      !seasonTickets.discount_amount
    ) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        discount_percent: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.discount_percent.error')}`
        }
      }));
    }

    if (!seasonTickets.validity_period) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        validity_period: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.validity_period.error')}`
        }
      }));
    }

    if (!isValidDate(seasonTickets.date_start)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        date_start: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.date_start.error')}`
        }
      }));
    }

    if (!isValidDate(seasonTickets.date_end)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        date_end: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.date_end.error')}`
        }
      }));
    }

    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        date_start: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.date_start.error')}`
        },
        date_end: {
          isValid: false,
          errorMessage: `${__('seasonTickets.form.date_end.error')}`
        }
      }));
    }

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('seasonTickets.form.title.error'),
    setSeasonTickets,
    setValidationStatus
  );

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = event?.target.checked;
    } else if (name === 'validity_period') {
      value = event.target.value.replace(/[^1-9.]/g, '');
    } else if (name === 'date_start') {
      value = moment(event).format('YYYY-MM-DD');
    } else if (name === 'date_end') {
      value = moment(event).format('YYYY-MM-DD');
    } else if (name === 'image') {
      value = event;
    } else {
      if (['amount', 'quantity_hours', 'discount_amount', 'discount_percent'].includes(name)) {
        value = event.target.value.replace(/[^0-9.]/g, '');
      } else {
        value = event.target.value;
      }
    }

    const isQuantityHoursField = ['quantity_hours', 'discount_amount', 'discount_percent'].includes(
      name
    );

    setSeasonTickets((prevSeasonTickets) => {
      let updatedSeasonTickets = {
        ...prevSeasonTickets,
        [name]: value
      };

      if (name === 'category_id') {
        updatedSeasonTickets.category_id = value;
      } else if (name === 'service_id') {
        updatedSeasonTickets.service_id = value;
      } else if (name === 'type') {
        updatedSeasonTickets.type = value;
      }

      if (isQuantityHoursField) {
        if (value.length > 0) {
          const otherFields = ['quantity_hours', 'discount_amount', 'discount_percent'].filter(
            (fieldName) => fieldName !== name
          );
          otherFields.forEach((fieldName) => {
            updatedSeasonTickets[fieldName] = '';
            setValidationStatus((prevValidationStatus) => ({
              ...prevValidationStatus,
              [fieldName]: {
                isValid: true,
                errorMessage: ''
              }
            }));
          });
        }
      }

      return updatedSeasonTickets;
    });
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isQuantityHoursField && !isNaN(value)),
        errorMessage:
          value.length > 0 || (!isQuantityHoursField && !isNaN(value))
            ? ''
            : `${__('seasonTickets.form.null.error')}`
      }
    }));
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      const seasonTicketsClone = getDataCloneForSend(seasonTickets);

      updateSeasonTickets(params.id, getFormData(seasonTicketsClone)).then((res) => {
        navigate('/season-tickets');
      });
    }
  };

  useEffect(() => {
    getSeasonTicketsId(params.id).then((res) => {
      const seasonTicketsData = res.data.data;
      seasonTicketsData.category_id = res.data.data.category.category_id;
      seasonTicketsData.service_id = res.data.data.service.service_id;
      seasonTicketsData.type = res.data.data.type.type;

      // !!! Disabled discount
      seasonTicketsData.discount_amount = '';
      seasonTicketsData.discount_percent = '';

      setSeasonTickets(seasonTicketsData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('seasonTickets.form.title-edit')}
      open={true}
      onClose={toggleOpened}
      loaded={loaded}
      closeBtn
    >
      <SeasonTicketsForm
        seasonTickets={seasonTickets}
        setSeasonTickets={setSeasonTickets}
        action={handleUpdate}
        handleLocalizationChange={handleLocalizationChange}
        handleChange={handleChange}
        ctaButtonText={__('seasonTickets.form.button')}
        validationStatus={validationStatus}
        setValidationStatus={setValidationStatus}
      />
    </ModalForm>
  );
};
