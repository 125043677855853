export const handleRoleChangeCreator = (data, handleData) => ( name ) => ( event ) => {
  let value;
  value = event?.target.value;

  handleData({
    ...data,
    user: [
      ...data.user,
      {
          [name]: value
      }
    ]
  })
}