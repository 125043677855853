export const UserDataInfo = ({ data, __ }) => {
  return (
    <>
      <div className="booking-form__user-data">
        <p>
          <span>{__('booking.form.date.first_name')}:</span>
          <span>{data.first_name}</span>
        </p>
        <p>
          <span>{__('booking.form.date.last_name')}:</span>
          <span>{data.last_name}</span>
        </p>
        <p>
          <span>{__('booking.form.date.phone')}:</span>
          <span>{data.phone}</span>
        </p>
      </div>
    </>
  );
};
