import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';

import { Link } from 'react-router-dom';
import { CircularProgress, Button, IconButton } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getPages, deletePage } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

import {
  TableAction,
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
} from './../../common/TableComponent';
import { ButtonIcon, ButtonLink, ButtonLinkIcon, ToggleShowActive } from './../../common/FormsComponent';
import { getRowCellsData } from './getRowCellsData';
import { rolesComponentConfig } from './rolesComponentConfig';

export const PagesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showArchive, setShowArchive] = useState(false);

  const handlePageDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deletePage(id).then(async () => {
        const { data: response } = await getPages({}, showArchive);
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getPages({}, showArchive);
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [showArchive]);

  return (
    <>
      <TableMain
        title={__('pages.page.title')}
        getRowCellsData={getRowCellsData}
        __={__}
      >
        <TableAction className="table-action">
          <ButtonLink
            text={ __('create.button.title') }
            route="create"
            state={ { action: 'create', backRoute: '/info-pages'} }
            checkRoles={ rolesComponentConfig }
            permission={ PERMISSIONS.ACTIONS.CREATE }
          />

          <ToggleShowActive value={showArchive} onChange={() => setShowArchive(prev => !prev)} />
        </TableAction>

        <TableBlock __class="table--pages">
          <TableHeader />
          <TableBody loaded={loaded}>
            {data.map((page) => (
              <TrBody key={page.id} data={page}>
                <ButtonLinkIcon 
                  icon={ <EditIcon /> }
                  route={ `/info-pages/${page.id}/edit` }
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.UPDATE}
                />
                <ButtonIcon 
                  icon={ <DeleteIcon /> } 
                  onClick={ handlePageDelete(page.id) }
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.DELETE}
                />
              </TrBody>
            ))}
          </TableBody>
        </TableBlock>

        <TablePagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </TableMain>
    </>
  );
};
