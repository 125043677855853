const clients = {
    uk: {
        "clients.page.title": "Клієнтська база",
        // table
        "clients.table.userName": "Кліент",
        "clients.table.userPhone": "Телефон",
        "clients.table.userEmal": "Email",
        "clients.table.status": "Статус",
        "clients.table.group": "Група",
        "clients.table.profit": "Дохід",
        "clients.table.discount": "Знижка %",
        "clients.table.visitsCount": "Візити",
        "clients.table.virtualWallet": "Віртуальний рахунок",
        "clients.table.firstVisit": "Перший візит",
        "clients.table.lastVisit": "Останній візит",
        "clients.table.actions": "Дії",
        // table end

        //filter
        "clients.filter.search": "Клієнт",
        "clients.filter.status": "Статус",
        "clients.filter.group": "Група",
        //filter end

        //form
        //form error
        "clients.form.client_status_id.error": "Це поле обов'язкове",
        "clients.form.client_group_id.error": "Це поле обов'язкове",
        "clients.form.discount_percent.error": "Це поле обов'язкове",
        //form end
    },
    ru: {
        "clients.page.title": "Клиентская база",
        // table
        "clients.table.userName": "Клиент",
        "clients.table.userPhone": "Телефон",
        "clients.table.userEmal": "Email",
        "clients.table.status": "Статус",
        "clients.table.group": "Группа",
        "clients.table.profit": "Доход",
        "clients.table.discount": "Скидка %",
        "clients.table.visitsCount": "Визиты",
        "clients.table.virtualWallet": "Виртуальный счет",
        "clients.table.firstVisit": "Первый визит",
        "clients.table.lastVisit": "Последний визит",
        "clients.table.actions": "Действия",
        // table end

        // filter
        "clients.filter.search": "Клиент",
        "clients.filter.status": "Статус",
        "clients.filter.group": "Группа",
        // filter end

        //form
        //form error
        "clients.form.client_status_id.error": "Это обязательное поле",
        "clients.form.client_group_id.error": "Это обязательное поле",
        "clients.form.discount_percent.error": "Это обязательное поле",
        //form end
    },
    en: {
        "clients.page.title": "Client Database",
        // table
        "clients.table.userName": "Client",
        "clients.table.userPhone": "Phone",
        "clients.table.userEmal": "Email",
        "clients.table.status": "Status",
        "clients.table.group": "Group",
        "clients.table.profit": "Profit",
        "clients.table.discount": "Discount %",
        "clients.table.visitsCount": "Visits",
        "clients.table.virtualWallet": "Virtual Wallet",
        "clients.table.firstVisit": "First Visit",
        "clients.table.lastVisit": "Last Visit",
        "clients.table.actions": "Actions",
        // table end

        // filter
        "clients.filter.search": "Client",
        "clients.filter.status": "Status",
        "clients.filter.group": "Group",

        //form
        //form error
        "clients.form.client_status_id.error": "This field is required",
        "clients.form.client_group_id.error": "This field is required",
        "clients.form.discount_percent.error": "This field is required",
        //form end
    }

};

export default clients;
