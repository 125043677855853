import { useEffect, useState } from 'react';

import { getInventoryItemsId, updateInventoryItems } from '../../../actions/inventoryItems';
import { useTranslation } from '../../../helpers/translate';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { InventoryItemsForm } from './InventoryItemsForm';
import { getFormData, floatLocateData } from './../../library';
import { validationModel } from './validation';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { ModalForm, serverErrorHandler } from './../../common/FormsComponent';

export const InventoryItemsEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const { state: locationState } = useLocation();
  const backRoute = locationState?.backRoute;
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/inventory-items');
  };

  const [selectedImage, setSelectedImage] = useState('');

  const [loaded, setLoaded] = useState(false);

  let [inventoryItem, setInventoryItem] = useState({
    is_active: true,
    inventory_item_type_id: '',
    unit_id: '',
    cost: '',
    reserve: '',
    sale_price: '',
    image: '',
    locale: {
      uk: { title: '', description: '' },
      ru: { title: '', description: '' },
      en: { title: '', description: '' }
    }
  });

  const [validationStatus, setValidationStatus] = useState(validationModel);
  const [errorMessages, setErrorMessages] = useState([]);

  const validateForm = () => {
    let isValid = true;

    ['uk', 'ru', 'en'].forEach((locale) => {
      const titleValue = inventoryItem?.locale[locale]?.title || '';
      const descriptionValue = inventoryItem?.locale[locale]?.description || '';

      if (titleValue.length === 0) {
        isValid = false;

        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            title: {
              isValid: false,
              errorMessage: `${__('inventoryItem.form.title.error')}`
            }
          }
        }));
      }

      if (descriptionValue.length === 0) {
        isValid = false;

        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            description: {
              isValid: false,
              errorMessage: `${__('inventoryItem.form.description.error')}`
            }
          }
        }));
      }
    });

    if (!inventoryItem.inventory_item_type_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        inventory_item_type_id: {
          isValid: false,
          errorMessage: `${__('inventoryItem.form.inventory_item_type_id.error')}`
        }
      }));
    }

    if (!inventoryItem.unit_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        unit_id: {
          isValid: false,
          errorMessage: `${__('inventoryItem.form.unit_id.error')}`
        }
      }));
    }

    const isValidNumber = (value) => /^\d+(\.\d{1,2})?$/.test(value);

    if (!isValidNumber(inventoryItem.cost)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        cost: {
          isValid: false,
          errorMessage: `${__('inventoryItem.form.cost.error')}`
        }
      }));
    }

    // if (!isValidNumber(inventoryItem.reserve)) {
    //   isValid = false;
    //   setValidationStatus((prevValidationStatus) => ({
    //     ...prevValidationStatus,
    //     reserve: {
    //       isValid: false,
    //       errorMessage: `${__('inventoryItem.form.reserve.error')}`
    //     }
    //   }));
    // }

    if (!isValidNumber(inventoryItem.sale_price)) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        sale_price: {
          isValid: false,
          errorMessage: `${__('inventoryItem.form.sale_price.error')}`
        }
      }));
    }

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('general.form.required_field.error'),
    setInventoryItem,
    setValidationStatus
  );

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = Number(event.target.checked);
    } else if (name === 'image') {
      value = event;
    } else {
      value =
        name === 'cost' || name === 'reserve' || name === 'sale_price'
          ? event.target.value.replace(/[^0-9.]/g, '')
          : event.target.value;
    }

    setInventoryItem({
      ...inventoryItem,
      [name]: value
    });

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('inventoryItem.form.numbers.error')}`
      }
    }));
  };

  const handleUpdate = () => {
    const isValid = validateForm();

    if (!inventoryItem.image) {
      delete inventoryItem.image;
    }

    if (inventoryItem.image && typeof inventoryItem.image === 'string') {
      delete inventoryItem.image;
    }

    if (isValid) {
      // float locale title (for send as formdata metod post)
      let sendData = { ...inventoryItem };
      const locale = sendData.locale;

      sendData = {
        ...sendData,
        ...floatLocateData(locale, 'title'),
        ...floatLocateData(locale, 'description')
      };
      delete sendData.locale;

      sendData = getFormData(sendData);
      updateInventoryItems(params.id, sendData).then((res) => {
        if (res?.data?.success) {
          navigate('/inventory-items');
        }

        serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
      });
    }
  };

  useEffect(() => {
    getInventoryItemsId(params.id).then((res) => {
      const { inventory_item_type, unit, ...rest } = res.data.data;

      setInventoryItem({
        ...rest,
        inventory_item_type_id: inventory_item_type?.inventory_item_type_id || '',
        unit_id: unit?.unit_id || ''
      });

      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('inventoryItem.form.title-edit')}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      backRoute={backRoute}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <InventoryItemsForm
        inventoryItem={inventoryItem}
        setInventoryItem={setInventoryItem}
        action={handleUpdate}
        handleLocalizationChange={handleLocalizationChange}
        handleChange={handleChange}
        ctaButtonText={__('inventoryItem.form.button')}
        validationStatus={validationStatus}
        setValidationStatus={setValidationStatus}
        setSelectedImage={setSelectedImage}
      />
    </ModalForm>
  );
};
