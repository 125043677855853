import dayjs from "dayjs"

export const getStocktakingRowCellsData = (dataItem, __) => {
  const status = dataItem?.status &&
    dataItem.status === 1 ? 'Закрита' : 'Відкрита';
     
  return {
    serial: {
      th: 'Назва',
      td: dataItem && dataItem.serial,
      sort: null,
    },
    desription: {
      th: 'Опис',
      td: dataItem && dataItem.description,
      sort: null,
    },
    created_at: {
      th: 'Дата (створення)',
      td: dataItem && dayjs(dataItem.created_at).format('YYYY-MM-DD'),
      sort: null,
    },
    created_by: {
      th: 'Співробітник (створив)',
      td: dataItem && dataItem.created_by?.name,
      sort: null,
    },
    updated_at: {
      th: 'Дата (зміни)',
      td: dataItem && dayjs(dataItem.updated_at).format('YYYY-MM-DD'),
      sort: null,
    },
    updated_by: {
      th: 'Співробітник (змінив)',
      td: dataItem && dataItem.updated_by?.name,
      sort: null,
    },
    status: {
      th: 'Статус',
      td: dataItem && status,
      sort: null,
    },

    actions: {
      th: 'Дії',
      td: null,
      sort: null,
      __class: 'td--right',
    }
  }
}


// link: dataItem && {
//   url: `/certificate-categories/${dataItem.category?.category_id}/edit`,
//   state: { id: dataItem.category?.category_id }
// },