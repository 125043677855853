import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from './../../../helpers/translate';

export const NotifyBySms = ({ title, value, handler, field }) => {
  const { __ } = useTranslation();

  return (
    <Box sx={{ mt: 3 }}>
      {title && <b style={{ marginBottom: '12px', display: 'block' }}>{title}</b>}

      <FormControl fullWidth>
        <InputLabel>{__('booking.input.notifyBySms')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('booking.input.notifyBySms')}
          name={field}
          value={value || ''}
          onChange={handler(field)}>
          <MenuItem value={0}>{__('booking.input.notifyBySms.time0')}</MenuItem>
          <MenuItem value={1}>{__('booking.input.notifyBySms.time1')}</MenuItem>
          <MenuItem value={2}>{__('booking.input.notifyBySms.time2')}</MenuItem>
          <MenuItem value={3}>{__('booking.input.notifyBySms.time3')}</MenuItem>
          <MenuItem value={4}>{__('booking.input.notifyBySms.time4')}</MenuItem>
          <MenuItem value={5}>{__('booking.input.notifyBySms.time5')}</MenuItem>
          <MenuItem value={6}>{__('booking.input.notifyBySms.time6')}</MenuItem>
          <MenuItem value={9}>{__('booking.input.notifyBySms.time9')}</MenuItem>
          <MenuItem value={12}>{__('booking.input.notifyBySms.time12')}</MenuItem>
          <MenuItem value={15}>{__('booking.input.notifyBySms.time15')}</MenuItem>
          <MenuItem value={18}>{__('booking.input.notifyBySms.time18')}</MenuItem>
          <MenuItem value={21}>{__('booking.input.notifyBySms.time21')}</MenuItem>
          <MenuItem value={24}>{__('booking.input.notifyBySms.time24')}</MenuItem>
          <MenuItem value={48}>{__('booking.input.notifyBySms.time48')}</MenuItem>
          <MenuItem value={72}>{__('booking.input.notifyBySms.time72')}</MenuItem>
          <MenuItem value={120}>{__('booking.input.notifyBySms.time120')}</MenuItem>
          <MenuItem value={168}>{__('booking.input.notifyBySms.time168')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
