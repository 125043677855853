import { useContext } from 'react';
import { TableContext, handleSortButtonClick } from './..';

export const Th = ({ 
  title, __class, 
  isSort, field
}) => {
  return (
    <div className={ `td${__class && ' ' + __class}` }>
      {
        !isSort
          ? <div className="td-name">
              <p>{ title }</p>
            </div>
          : <SortButton
              field={ field }
              title={ title }
              __class={__class}
            />
      }
    </div> 
  );
};

Th.defaultProps = {
  __class: '',
  isSort: false,
}

const SortButton = ({field, title}) => {
  const { 
    sortOption,
    setSortOption,
    sortDirection,
    setSortDirection,
   } = useContext(TableContext);
  return (
    <button
      onClick={() => handleSortButtonClick(
        field,
        sortOption,
        setSortOption,
        sortDirection,
        setSortDirection,
      )}
      className="table-button-short">
      <span className="td-name">
        <span>{title}</span>
      </span>
      <span className="table-button-short__arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          className={
            sortOption === field ? `${sortDirection === 1 ? 'active' : ''}` : ''
          }>
          <path
            d="M6 0L0 6.47773L1.41 8L6 3.05533L10.59 8L12 6.47773L6 0Z"
            fill="black"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          className={
            sortOption === field ? `${sortDirection === 1 ? '' : 'active'}` : ''
          }>
          <path
            d="M6 8L12 1.52227L10.59 3.71833e-08L6 4.94467L1.41 3.71833e-08L0 1.52227L6 8Z"
            fill="black"
          />
        </svg>
      </span>
    </button>
  )
}