import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '../../../../helpers/translate';

import {
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  TablePagination,
  ButtonIcon
} from '../../../common/TableComponent';
import { getRowCellsData } from './getRowCellsData';

import { s } from '../styles';
import { getCollapseRowCellsData } from './getCollapseRowCellsData';
import { getPaymentsInfo } from './../../../../actions';
import {
  FilterSearch,
  FilterSelect,
  PhoneInputBlock,
  ToggleShowActive
} from './../../../common/FormsComponent';
import { statuses } from './statuses';

export const FinancialAnalytics = () => {
  const { __ } = useTranslation();
  const { state: routeState } = useLocation();
  const userId = routeState?.user?.user_id;

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {
      payments: [],
      meta: []
    }
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchPhone, setSearchPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [filterStatus, setFilterStatus] = useState('');

  const handleSearchPhone = (name) => (value) => {
    const phone = value.substring(3);
    const phoneLen = phone.length;
    const code = value.substring(0, 3);

    if (!phone) {
      setPhoneNumber('');
      setSearchPhone('');
      return;
    }

    if (phone.length < 2) {
      return;
    }

    let formatPhone = '';

    if (phoneLen <= 5) {
      formatPhone = phone.replace(/(\d{2})/, '$1 ');
    } else if (phoneLen <= 7) {
      formatPhone = phone.replace(/(\d{2})(\d{3})/, '$1 $2-');
    } else if (phoneLen <= 9) {
      formatPhone = phone.replace(/(\d{2})(\d{3})(\d{2})/, '$1 $2-$3-');
    }

    setSearchPhone(`${code} ${formatPhone}`);
  };

  useEffect(() => {
    if (searchLastName && searchLastName.length < 3) {
      return;
    }

    const fetchData = async () => {
      try {
        const { data: payments } = await getPaymentsInfo(
          sortOption,
          sortDirection,
          currentPage,
          userId,
          searchPhone,
          searchLastName,
          filterStatus
        );
        const meta = payments.meta;

        setData({
          loaded: true,
          data: {
            payments: payments.data,
            meta
          }
        });
      } catch (error) {
        console.warn('PersonalOrders fetch error: ', error);
      }
    };
    fetchData();
  }, [sortOption, sortDirection, currentPage, searchPhone, searchLastName, filterStatus]);

  return (
    <TableMain
      title={'Фінансова аналітика'}
      getRowCellsData={getRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      collapsible={true}
      collapsibleTableTitle={[]}
      getCollapseRowCellsData={getCollapseRowCellsData}
      __={__}>
      <TableBlock __class="table--clients-financial-analytics">
        <TableAction className="table-action">
          <div className="table-action__block">
            {!userId && (
              <>
                <PhoneInputBlock
                  isError={false}
                  value={phoneNumber}
                  setValue={handleSearchPhone('phone')}
                  noMarginBottom
                  noValidate={true}
                  countryCodeEditable={false}
                />
                <FilterSearch
                  label="Прізвище"
                  name="filter_search_last_name"
                  value={searchLastName}
                  handler={(e) => setSearchLastName(e.target.value.trim())}
                />
              </>
            )}

            <FilterSelect
              label="Статус оплати"
              name="filter_select_status"
              value={filterStatus}
              onChange={setFilterStatus}
              dataSelect={statuses}
            />
          </div>
        </TableAction>
        <TableHeader />
        <TableBody loaded={loaded}>
          {data?.payments?.map((payment) => {
            const isPaid = payment.status !== 0 ? true : false;
            const paidStyle = isPaid ? s.paid : s.notpaid;

            return <TrBody key={payment.id} data={payment} style={paidStyle} />;
          })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
