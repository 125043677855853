import { useState } from 'react';

import { useTranslation } from '../../../../../helpers/translate';

import { useNavigate } from 'react-router-dom';

import { ModalForm } from '../../../../common/FormsComponent';
import { LoyalityForm } from './LoyalityForm';

export const LoaylityCreate = ({ title, action, getSeasonTicketsAction, baseRoute, type }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const basePageUrl = `/clients/loyality/${baseRoute}`;

  const toggleOpened = () => {
    navigate(basePageUrl);
  };

  const [certificates, setCertificates] = useState({
    season_ticket: '',
    user_id: '',
    is_active: 1
  });

  const handleChange = (name) => (event) => {
    let value = event?.target ? event.target.value : event;
    if (name === 'is_active') {
      value = Number(event?.target.checked);
    }

    setCertificates((prevCertificates) => {
      let updatedCertificates = {
        ...prevCertificates,
        [name]: value
      };

      switch (name) {
        case 'season_ticket':
          updatedCertificates.season_ticket = value;
          return updatedCertificates;
        case 'user':
          updatedCertificates.user_id = value.user?.id;
          delete updatedCertificates?.user;
          return updatedCertificates;
        default:
          return updatedCertificates;
      }
    });
  };

  const handleCreate = () => {
    const dataClone = { ...certificates };
    action(dataClone).then((res) => {
      navigate(basePageUrl);
    });
  };

  return (
    <ModalForm
      title={title}
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <LoyalityForm
        certificates={certificates}
        getSeasonTicketsAction={getSeasonTicketsAction}
        action={handleCreate}
        handleChange={handleChange}
        ctaButtonText={__('certificates.form.button')}
        type={type}
      />
    </ModalForm>
  );
};
