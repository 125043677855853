import axios from '../../helpers/private.axios';
import { AUTH } from "../../constants"

export const logout = () => (dispatch) => {
  axios
    .post(`/auth/logout`)
    .then((res) => {
      dispatch({ type: AUTH.AUTH_LOGOUT });
      return res;
    })
    .catch(error => {
      return error;
    });

}
