import dayjs from "dayjs";

export const addZeroToHourFromDate = (date) => {
  return new Date(date).getHours() < 10
    ? '0' + new Date(date).getHours().toString()
    : new Date(date).getHours();
};

export const addZeroToMinuteFromDate = (date) => {
  return new Date(date).getMinutes() < 10
    ? '0' + new Date(date).getMinutes().toString()
    : new Date(date).getMinutes();
};

export const addZeroToTimeUnit = (unit) => {
  if (unit.toString().length === 1) {
    return '0' + unit;
  } else {
    return unit;
  }
};

export const getCorrectDateFormat = (dateVisit, date) => {
  return dateVisit
    ? dateVisit
    : date.getFullYear() +
        '-' +
        addZeroToTimeUnit(date.getMonth() + 1) +
        '-' +
        addZeroToTimeUnit(date.getDate());
};

export const getCorrectTimeFormat = (timeFrom, date) => {
  return timeFrom
    ? timeFrom
    : addZeroToHourFromDate(date) + ':' + addZeroToMinuteFromDate(date.getDate());
};

export const phoneNumberFormat = (phoneNumberStr, formatStr) => {
  const digitsOnly = phoneNumberStr.replace(/\D/g, '');
  const formatted = digitsOnly.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, formatStr); // '$1 $2-$3-$4'

  return formatted;
}

export const phoneNumberWithCodeFormat = (phoneNumberStr, formatStr) => {
  const digitsOnly = phoneNumberStr.replace(/\D/g, '');
  let formatted = digitsOnly.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/, formatStr); // '$1 $2 $3-$4-$5'
  formatted = '+' + formatted;

  return formatted;
}

export const equalDates = (date1, date2, dateOnly = true) => {
  if (!dateOnly) {
    return dayjs(date1).isSame(dayjs(date2));
  }

  return dayjs(date1).isSame(dayjs(date2), 'day');
}

export function getEmptySlots(slots, currentSlotId) {
  let filteredSlots = [];
  if (!currentSlotId) {
    filteredSlots = 
      slots
        .filter(slot => !slot.visit.id)
        .filter(slot => {
          const diff = dayjs(slot.date).diff(dayjs(), 'minute');
          return diff > 0;
        });
    return filteredSlots;
  }

  filteredSlots = 
    slots
      .filter(slot => !slot.visit.id || slot.id === currentSlotId)
      .filter(slot => {
        const diff = dayjs(slot.date).diff(dayjs(), 'minute');
        return diff > 0;
      });
  return filteredSlots;
}

export function getDayEmptySlots(slots, date, currentSlotId) {
  let filteredSlots = [];
  const daySlots = slots.filter(slot => equalDates(slot.date, date));

  if (!currentSlotId) {
    filteredSlots = 
      daySlots
        .filter(slot => !slot.visit.id)
        .filter(slot => {
          const diff = dayjs(slot.date).diff(dayjs(), 'minute');
          return diff > 0;
        });
    return filteredSlots;
  }

  filteredSlots = 
    daySlots
      .filter(slot => !slot.visit.id || slot.id === currentSlotId)
      .filter(slot => {
        const diff = dayjs(slot.date).diff(dayjs(), 'minute');
        return diff > 0;
      });
  return filteredSlots;
}

export function timeDateHasPassed(date, unit = 'minutes') {
  if (!date) {
    return true;
  }

  const dateDiff = dayjs(date).diff(dayjs(), unit)
  return dateDiff > 0 ? false : true;
}