import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  Pagination,
  FormControlLabel,
  Switch,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { FilterSelect } from './../../common/TableComponent';

import { getSeasonTickets, deleteSeasonTickets } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { types } from './data/types';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

export const SeasonTicketsList = ({ type }) => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);
  const [filterType, setFilterType] = useState('');

  const handleSeasonTicketsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteSeasonTickets(id).then(async () => {
        const { data: response } = await getSeasonTickets(filterType, currentPage, itemsPerPage, showArchive);

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getSeasonTickets(filterType, currentPage, itemsPerPage, showArchive, type);
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [filterType, currentPage, itemsPerPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('seasonTickets.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">

        <div className="input-wrap">
          <FilterSelect 
            inputLabel={'Тип'}
            selectLabel={'Тип'}
            selectName="season-tickets-select-filter"
            selectValue={filterType}
            selectHandler={(e) => setFilterType(e.target.value)}
            menuItemDefaultText="Всі типи"
            sx={{ width: 300 }}
          >
            {
              types &&
              types.map((type) => (
                <MenuItem key={type.name} value={type.name}>
                  {type.title}
                </MenuItem>
              ))
            }
          </FilterSelect>
        </div>
          
        </div>
        <div className="table-action__block">
          <FormControlLabel
            label={
              showArchive
                ? `${__('inventoryItem.filter.archiveHide')}`
                : `${__('inventoryItem.filter.archiveShow')}`
            }
            control={
              <Switch checked={showArchive} onChange={(e) => setShowArchive(e.target.checked)} />
            }
          />
           <Link to="/season-tickets/create">
            <Button
              variant="contained"
              type="submit"
              sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
              {__('create.button.title')}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-block">
        <div className="table table--season-tickets">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.image')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.type')}</p>
                </div>
              </div>
              
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.category')}</p>
                </div>
              </div>
              
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.service')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.quantity_hours')}</p>
                </div>
              </div>

              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.validity_period')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.date_start')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.date_end')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.allowed_days')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('seasonTickets.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((seasonTickets) => (
              <div className="tr" key={seasonTickets.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.title')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.description')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.description}</div>
                </div>

                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.image')}</p>
                  </div>
                  <div className="td-content">
                    <img src={ seasonTickets.image || noImage } alt="" />
                  </div>
                </div>

                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.type')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.type?.title}</div>
                </div>
                
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.category')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.category.title}</div>
                </div>

                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.service')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.service.title}</div>
                </div>
                {/* <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.amount')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.amount}</div>
                </div> */}

                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.quantity_hours')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.quantity_hours}</div>
                </div>

                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.validity_period')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.validity_period}</div>
                </div>

                {/* <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.discount_amount')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.discount_amount}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.discount_percent')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.discount_percent}</div>
                </div> */}
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.date_start')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.date_start}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.date_end')}</p>
                  </div>
                  <div className="td-content">{seasonTickets.date_end}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.allowed_days')}</p>
                  </div>
                  <div className="td-content">
                    { seasonTickets.allowed_days 
                      ? seasonTickets.allowed_days.map(day => day.title).join(',')
                      : ''
                    }
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {
                      seasonTickets.is_active
                        ? __('general.table.active')
                        : __('general.table.inactive')
                    }
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('seasonTickets.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/season-tickets/${seasonTickets.id}/edit`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleSeasonTicketsDelete(seasonTickets.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

SeasonTicketsList.defaultProps = {
  type: ''
}