import { useTranslation } from './../../../../helpers/translate';
import { getVisit } from './../../../../actions/visit/visitRoutes';
import { getVisitPaymentType, visitPayment } from './../../../../actions';
import { ButtonSend, ModalForm, serverErrorHandler } from './../../FormsComponent';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { TableBlock, TableBody, TableHeader, TableMain, TrBody } from './../../TableComponent';
import { getServiceRowCellsData } from './getServiceRowCellsData';
import { SelectingPaymentSystem } from './../../Payment';
import { validPaidParams } from './utils';

export const VisitPayment = ({ extendedProps }) => {
  const { __ } = useTranslation();

  const visit = extendedProps?.visit;
  const visitId = visit?.id;
  const alreadyPaid = !!visit?.billing_client_service_id;
  const [isPaid, setIsPaid] = useState();
  const [paidErrorPopup, setPaidErrorPopup] = useState([]);

  let mainTitle = alreadyPaid || isPaid ? 'Візит оплачений' : 'Візит неоплачений';
  let mainTitleStyle = alreadyPaid || isPaid ? { color: 'green' } : { color: 'red' };

  const [errorMessages, setErrorMessages] = useState();
  const [{ loading, visitInfo, visitService }, setData] = useState({
    loading: false,
    visitInfo: {},
    visitService: {}
  });

  const [openPaymentSelectingPopup, setOpenPaymentSelectingPopup] = useState(false);
  const [paymentAccessibility, setPaymentAccessibility] = useState({});
  const [virtualWalletBalance, setVirtulaWalletBalance] = useState(null);
  const [update, setUpdate] = useState(false);

  const handleOpenPopup = async () => {
    const visitDate = visitInfo?.timeslot.date;
    const paidProps = { 
      visitDate: visitDate,
     };

    const paidErrorsArr = validPaidParams(paidProps);

    setPaidErrorPopup(paidErrorsArr);
    if (paidErrorsArr.length) {
      return;
    }

    const resPaymentTypes = await getVisitPaymentType(visitId);
    const errorsPaymentTypes = resPaymentTypes?.response?.data?.errors;
    if (errorsPaymentTypes) {
      serverErrorHandler(errorsPaymentTypes, setErrorMessages);
      return;
    }

    const paymentTypes = resPaymentTypes?.data?.data;
    const paymentAccessibility = {};
    const virtualWalletBalance = paymentTypes?.virtual_wallet;
    const certificate = paymentTypes?.certificate;
    const abonement = paymentTypes?.abonement;
    if (certificate) {
      paymentAccessibility.certificate = certificate;
    }

    if (abonement) {
      paymentAccessibility.abonnement = abonement;
    }

    setVirtulaWalletBalance(virtualWalletBalance);
    setPaymentAccessibility(paymentAccessibility);
    setOpenPaymentSelectingPopup(true);
  };

  const handlePaid = async (selectedPaymentMethod) => {
    let paymentMethod = '';
    if (selectedPaymentMethod === 'abonnement') {
      paymentMethod = 'abonement';
    } else if (selectedPaymentMethod === 'certificate') {
      paymentMethod = 'certificate';
    } else {
      paymentMethod = 'cash_payment';
    }

    let paymentParams = null;
    if (paymentMethod) {
      paymentParams = {
        method: paymentMethod,
        data: paymentMethod === 'cash_payment' ? 1 : paymentAccessibility[selectedPaymentMethod]
      };
    }

    console.log({ [paymentParams.method]: paymentParams.data });

    try {
      const res = await visitPayment(visitId, { [paymentParams.method]: paymentParams.data });
      console.log(res);
      const status = res?.response?.status;

      if (status && status !== 200) {
        console.log(res?.response?.data?.message);
        serverErrorHandler(res?.response?.data?.message, setErrorMessages);
        return;
      }

      setOpenPaymentSelectingPopup(false);
      setIsPaid(true);
      setUpdate((prev) => !prev);
    } catch (error) {
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const resVisit = await getVisit(visitId);
        const errorsVisit = resVisit?.response?.data?.errors;
        if (errorsVisit) {
          serverErrorHandler(errorsVisit, setErrorMessages);
          return;
        }

        const visit = resVisit.data.data;
        setData({
          loading: true,
          visitInfo: visit,
          visitService: visit?.visit_services[0],
        });
        setIsPaid(!!visit?.billing_client_service_id);
      } catch (error) {
        console.warn(error);
      }
    })();
  }, []);

  return (
    <>
      <ToastContainer />
      <SelectingPaymentSystem
        open={openPaymentSelectingPopup}
        onClose={() => setOpenPaymentSelectingPopup(false)}
        handlePaid={handlePaid}
        accessibility={Object.keys(paymentAccessibility)}
        virtualWalletBalance={virtualWalletBalance}
        paymentData={paymentAccessibility}
        isAdmin
      />

      <Typography
        variant="h4"
        sx={{
          mb: 3,
          '@media screen and (max-width: 920px)': {
            fontSize: '28px'
          },
          ...mainTitleStyle
        }}>
        {mainTitle}
      </Typography>

      <TableMain title={'Інформація про послугу'} getRowCellsData={getServiceRowCellsData} __={__}>
        <TableBlock __class="table--visit-payment">
          <TableHeader />

          <TableBody loaded={loading}>
            <TrBody key={visitService.id} data={visitService} />
          </TableBody>
        </TableBlock>
      </TableMain>

      {!isPaid && <ButtonSend text="Сплатити" onClick={handleOpenPopup} sx={{ p: 4 }} />}

      <ModalForm
        title={ paidErrorPopup.join('\n') }
        open={ !!paidErrorPopup.length }
        onClose={ () => setPaidErrorPopup([]) }
      >

        <ButtonSend text="ok" onClick={ () => setPaidErrorPopup([]) }/>

      </ModalForm>
    </>
  );
};