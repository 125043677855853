import { SYSTEM_MODES } from "./../../../../../../constants";
import { getModelName, modelActionsByService } from "../../../../../../configData/modelConfig";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export const SelectHorse = ({ 
  title,
  selectedHorse,
  setSelectedHorse,
  setTimeSlot,
  isAnyHorseSelected,
  setAnyHorseSelected,
  visitServices,
  validVisit,
  visitId,
  modelType
 }) => {

  const [horses, setHorses] = useState([]);
  const fieldLabel = modelType === SYSTEM_MODES.HORSE ? 'Виберіть коня' : 'Виберіть тренера';

  useEffect(() => {
    const serviceId = visitServices?.id;
    if (!serviceId) {
      return;
    }

    if (!visitId) {
      return;
    }

    (async () => {
      const res = await modelActionsByService[modelType](serviceId);
      const horses = res?.data?.data;

      if (!horses.length) {
        setSelectedHorse(null);
        setTimeSlot(null);
        return;
      }

      setSelectedHorse(selectedHorse ? selectedHorse : horses[0]);
      setHorses(horses);
    })();
  }, [visitServices]);

  return (
    <Autocomplete
      disabled={ !visitId }
      disableClearable
      id="horses"
      sx={{ width: '100%', mt: 3, mb: 2, mr: 2 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={ 
        !visitId 
          ? selectedHorse
          : horses.length ? selectedHorse : '' }
      onChange={(event, newValue) => {
        setSelectedHorse(newValue);
        setTimeSlot(null);
      }}
      getOptionLabel={(data) => getModelName(data, modelType, horses) || ''}
      options={selectedHorse ? horses : []}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ fieldLabel }
          required
          InputLabelProps={{ shrink: true }}
          error={ !selectedHorse }
        />
      )}
    />
  );
};