import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useCheckAvailableCurrentUserPermission } from "src/configData/rolesConfig";

export const ButtonLink = ({text, route, state, sx, fullWidth, checkRoles = false, permission, ...props}) => {
  const componentAvailable = useCheckAvailableCurrentUserPermission(checkRoles?.resource, permission);

  return (
    <>
      {
        componentAvailable && 
        <Link to={ route } state={state} >
          <Button
            variant="contained"
            type="submit"
            sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
            { ...props }
          >
            { text }
          </Button>
        </Link>
      }
    </>
    
  );
};

ButtonLink.defaultProps = {
  sx: {},
  state: {},
}