const clientCard = {
    uk: {
        "clientsCard.page.title": "Карточка кліента",
        "clientsCard.nav.clientsCard": "Карточка кліента",
        "clientsCard.nav.historyVisits": "Історія відвідувань",
        "clientsCard.nav.loyalty": "Лояльність",
        "clientsCard.nav.analytics": "Аналітика",

        "clientsCardUserInfo.table.userName": "Користувач",
        "clientsCardUserInfo.table.userPhone": "Телефон",
        "clientsCardUserInfo.table.userEmail": "Email",


        "clientsCard.table.created_at": "Дата створення",
        "clientsCard.table.profit": "Профіт",
        "clientsCard.table.visits_count": "Кількість візитів",
        "clientsCard.table.virtual_wallet": "Віртуальний рахунок",
        "clientsCard.table.discount_percent": "Знижка",
        "clientsCard.table.first_visit": "Перший візит",
        "clientsCard.table.last_visit": "Останній візит",
        "clientsCard.table.status_title": "Статус",
        "clientsCard.table.group_title": "Група",

        "clientsVisitsHistory.page.title": "Історія відвідувань",
        "clientsVisitsHistory.table.date": "Дата",
        "clientsVisitsHistory.table.time": "Час",
        "clientsVisitsHistory.table.horse": "Кінь",
        "clientsVisitsHistory.table.service": "Послуга",
        "clientsVisitsHistory.table.status": "Статус",
        "clientsVisitsHistory.table.summ": "Сумма",
        "clientsVisitsHistory.table.payStatus": "Оплата",
        "clientsVisitsHistory.table.payStatus_yse": "Сплачено",
        "clientsVisitsHistory.table.payStatus_no": "Не сплачено",



        "clientsLoyality.page.title": "Лояльність",
        "clientsLoyality.tab.certificates": "Сертифікати",
        "clientsLoyality.tab.seasonTickets": "Абонементи",
        "clientsLoyality.table.title": "Номер",
        "clientsLoyality.table.description": "Назва",
        "clientsLoyality.table.category": "Категорія",
        "clientsLoyality.table.service": "Послуга",
        "clientsLoyality.table.qtyHours": "Кількість годин (всього)",
        "clientsLoyality.table.lastHours": "Кількість годин (залишилось)",
        "clientsLoyality.table.dateOfStart": "Дата початку",
        "clientsLoyality.table.dateOfEnd": "Дата кінця",
        "clientsLoyality.table.dayCount": "Термін дії (днів до закінчення)",
        "clientsLoyality.table.isValidOrder": "Діючий/ Недіючий",
        "clientsLoyality.table.isActive": "Активний/ Неактивний",


        "clientsLoyality.table_abonements.name": "Назва",
        "clientsLoyality.table_abonements.description": "Опис",
        "clientsLoyality.table_abonements.category": "Категорія",
        "clientsLoyality.table_abonements.service": "Послуга",
        "clientsLoyality.table_abonements.visitsCount": "Кількість годин (всього)",
        "clientsLoyality.table_abonements.visitsLeft": "Кількість годин (залишилось)",
        "clientsLoyality.table_abonements.dateOfStart": "Дата початку",
        "clientsLoyality.table_abonements.dateOfEnd": "Дата кінця",
        "clientsLoyality.table_abonements.dayCount": "Термін дії (днів до закінчення)",
        "clientsLoyality.table_abonements.isValidOrder": "Діючий/ Недіючий",
    },
    ru: {
        "clientsCard.page.title": "Карточка клиента",
        "clientsCard.nav.clientsCard": "Карточка клиента",
        "clientsCard.nav.historyVisits": "История посещений",
        "clientsCard.nav.loyalty": "Лояльность",
        "clientsCard.nav.analytics": "Аналитика",

        "clientsCardUserInfo.table.userName": "Пользователь",
        "clientsCardUserInfo.table.userPhone": "Телефон",
        "clientsCardUserInfo.table.userEmail": "Email",

        "clientsCard.table.created_at": "Дата создания",
        "clientsCard.table.profit": "Профит",
        "clientsCard.table.visits_count": "Количество визитов",
        "clientsCard.table.virtual_wallet": "Виртуальный счет",
        "clientsCard.table.discount_percent": "Скидка",
        "clientsCard.table.first_visit": "Первый визит",
        "clientsCard.table.last_visit": "Последний визит",
        "clientsCard.table.status_title": "Статус",
        "clientsCard.table.group_title": "Группа",

        "clientsVisitsHistory.page.title": "История посещений",
        "clientsVisitsHistory.table.date": "Дата",
        "clientsVisitsHistory.table.time": "Время",
        "clientsVisitsHistory.table.horse": "Лошадь",
        "clientsVisitsHistory.table.service": "Услуга",
        "clientsVisitsHistory.table.status": "Статус",
        "clientsVisitsHistory.table.summ": "Сумма",
        "clientsVisitsHistory.table.payStatus": "Оплата",
        "clientsVisitsHistory.table.payStatus_yse": "Оплачено",
        "clientsVisitsHistory.table.payStatus_no": "Не оплачено",

        "clientsLoyality.page.title": "Лояльность",
        "clientsLoyality.tab.certificates": "Сертификаты",
        "clientsLoyality.tab.seasonTickets": "Абонементы",
        "clientsLoyality.table.title": "Номер",
        "clientsLoyality.table.description": "Название",
        "clientsLoyality.table.category": "Категория",
        "clientsLoyality.table.service": "Услуга",
        "clientsLoyality.table.qtyHours": "Количество часов (всего)",
        "clientsLoyality.table.lastHours": "Количество часов (осталось)",
        "clientsLoyality.table.dateOfStart": "Дата начала",
        "clientsLoyality.table.dateOfEnd": "Дата окончания",
        "clientsLoyality.table.dayCount": "Срок действия (дней до окончания)",
        "clientsLoyality.table.isValidOrder": "Действующий/Недействующий",
        "clientsLoyality.table.isActive": "Активный/Неактивный",

        "clientsLoyality.table_abonements.name": "Название",
        "clientsLoyality.table_abonements.description": "Описание",
        "clientsLoyality.table_abonements.category": "Категория",
        "clientsLoyality.table_abonements.service": "Услуга",
        "clientsLoyality.table_abonements.visitsCount": "Количество часов (всего)",
        "clientsLoyality.table_abonements.visitsLeft": "Количество часов (осталось)",
        "clientsLoyality.table_abonements.dateOfStart": "Дата начала",
        "clientsLoyality.table_abonements.dateOfEnd": "Дата окончания",
        "clientsLoyality.table_abonements.dayCount": "Срок действия (дней до окончания)",
        "clientsLoyality.table_abonements.isValidOrder": "Действующий/Недействующий",
    },
    en: {
        "clientsCard.page.title": "Client Card",
        "clientsCard.nav.clientsCard": "Client Card",
        "clientsCard.nav.historyVisits": "Visit History",
        "clientsCard.nav.loyalty": "Loyalty",
        "clientsCard.nav.analytics": "Analytics",

        "clientsCardUserInfo.table.userName": "User",
        "clientsCardUserInfo.table.userPhone": "Phone",
        "clientsCardUserInfo.table.userEmail": "Email",

        "clientsCard.table.created_at": "Creation Date",
        "clientsCard.table.profit": "Profit",
        "clientsCard.table.visits_count": "Number of Visits",
        "clientsCard.table.virtual_wallet": "Virtual Wallet",
        "clientsCard.table.discount_percent": "Discount",
        "clientsCard.table.first_visit": "First Visit",
        "clientsCard.table.last_visit": "Last Visit",
        "clientsCard.table.status_title": "Status",
        "clientsCard.table.group_title": "Group",

        "clientsVisitsHistory.page.title": "Visit History",
        "clientsVisitsHistory.table.date": "Date",
        "clientsVisitsHistory.table.time": "Time",
        "clientsVisitsHistory.table.horse": "Horse",
        "clientsVisitsHistory.table.service": "Service",
        "clientsVisitsHistory.table.status": "Status",
        "clientsVisitsHistory.table.summ": "Amount",
        "clientsVisitsHistory.table.payStatus": "Payment",
        "clientsVisitsHistory.table.payStatus_yse": "Paid",
        "clientsVisitsHistory.table.payStatus_no": "Not Paid",

        "clientsLoyality.page.title": "Loyalty",
        "clientsLoyality.tab.certificates": "Certificates",
        "clientsLoyality.tab.seasonTickets": "Season Tickets",
        "clientsLoyality.table.title": "Number",
        "clientsLoyality.table.description": "Name",
        "clientsLoyality.table.category": "Category",
        "clientsLoyality.table.service": "Service",
        "clientsLoyality.table.qtyHours": "Number of Hours (Total)",
        "clientsLoyality.table.lastHours": "Number of Hours (Left)",
        "clientsLoyality.table.dateOfStart": "Start Date",
        "clientsLoyality.table.dateOfEnd": "End Date",
        "clientsLoyality.table.dayCount": "Validity Period (Days Remaining)",
        "clientsLoyality.table.isValidOrder": "Active/Inactive",
        "clientsLoyality.table.isActive": "Active/Inactive",

        "clientsLoyality.table_abonements.name": "Name",
        "clientsLoyality.table_abonements.description": "Description",
        "clientsLoyality.table_abonements.category": "Category",
        "clientsLoyality.table_abonements.service": "Service",
        "clientsLoyality.table_abonements.visitsCount": "Number of Hours (Total)",
        "clientsLoyality.table_abonements.visitsLeft": "Number of Hours (Left)",
        "clientsLoyality.table_abonements.dateOfStart": "Start Date",
        "clientsLoyality.table_abonements.dateOfEnd": "End Date",
        "clientsLoyality.table_abonements.dayCount": "Validity Period (Days Remaining)",
        "clientsLoyality.table_abonements.isValidOrder": "Active/Inactive",
    },

};

export default clientCard;
