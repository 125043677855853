import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  FormHelperText
} from '@mui/material';

import { getUnit, getInventoryItemTypes } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ImageUpload } from './../../library/ImageUpload';
import previewPicture from './../../library/ImageUpload/assets/img/no-image.png';
import { useLocation } from 'react-router-dom';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation';

export const InventoryItemsForm = ({
  inventoryItem,
  setInventoryItem,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  validationStatus,
  setValidationStatus,
}) => {
  const { __ } = useTranslation();

  const [{ loaded, unitTypes, inventoryItemTypes }, setData] = useState({
    loaded: false,
    unitTypes: [],
    inventoryItemTypes: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: unitResponse } = await getUnit();
        const { data: inventoryItemTypesResponse } = await getInventoryItemTypes();
        setData({
          loaded: true,
          unitTypes: unitResponse,
          inventoryItemTypes: inventoryItemTypesResponse
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <LocaleTabs 
        data={ inventoryItem }
        setData={ setInventoryItem }
        dataName={ 'inventoryItem' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />
      
      <FormControl className={validationStatus.unit_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('inventoryItem.input.unit')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('inventoryItem.input.unit')}
          name="unit_id"
          value={inventoryItem?.unit_id}
          onChange={handleChange('unit_id')}
          error={!validationStatus.unit_id.isValid}
          helperText={validationStatus.unit_id.errorMessage}>
          {unitTypes.data &&
            unitTypes.data.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.unit_id.isValid && (
          <FormHelperText>{validationStatus.unit_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControl className={validationStatus.inventory_item_type_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('inventoryItem.input.inventory_item_type')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('inventoryItem.input.inventory_item_type')}
          name="inventory_item_type_id"
          value={inventoryItem?.inventory_item_type_id}
          onChange={handleChange('inventory_item_type_id')}
          error={!validationStatus.inventory_item_type_id.isValid}
          helperText={validationStatus.inventory_item_type_id.errorMessage}>
          {inventoryItemTypes.data &&
            inventoryItemTypes.data.map((inventoryItemTypes) => (
              <MenuItem key={inventoryItemTypes.id} value={inventoryItemTypes.id}>
                {inventoryItemTypes.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.inventory_item_type_id.isValid && (
          <FormHelperText>{validationStatus.inventory_item_type_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <TextField
        fullWidth
        value={inventoryItem?.cost}
        onChange={handleChange('cost')}
        name="cost"
        label={__('inventoryItem.input.cost')}
        error={!validationStatus.cost.isValid}
        helperText={validationStatus.cost.errorMessage}
        className={validationStatus.cost.isValid ? '' : 'input-error'}
      />
      <TextField
        fullWidth
        value={inventoryItem?.reserve}
        onChange={handleChange('reserve')}
        name="reserve"
        label={__('inventoryItem.input.reserve')}
      />
      <TextField
        fullWidth
        value={inventoryItem?.sale_price}
        onChange={handleChange('sale_price')}
        name="sale_price"
        label={__('inventoryItem.input.sale_price')}
        error={!validationStatus.sale_price.isValid}
        helperText={validationStatus.sale_price.errorMessage}
        className={validationStatus.sale_price.isValid ? '' : 'input-error'}
      />
      <ImageUpload value={inventoryItem?.image} onChange={handleChange('image')} previewPicture={ previewPicture }/>
      <label className="checkbox">
        <Checkbox
          name="is_active"
          value={!!inventoryItem?.is_active}
          checked={inventoryItem?.is_active || false}
          onChange={handleChange('is_active')}
        />
        <p>{__('inventoryItem.form.is_active')}</p>
      </label>
      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
