export const handleServerErrors = (
  errors, 
  setFromValidation, 
  validationStatus, 
  setValidationStatus
) => {
  const fields = Object.keys(errors);

  fields.forEach(fields => {
    validationStatus[fields] = false;
  });
  
  setValidationStatus(validationStatus);
  setFromValidation(false);
}