import validator from 'validator';

export const validate = ( name, value, data = null ) => {
  let rules = [];

  if( name === 'login' ) {
    const phone = value.replace(/[+()\s-]/g, '')
    rules = [ validator.isLength( phone, { min: 12 })];
  }

  if( name === "password"){
    rules = [ validator.isLength( value, { min: 6 })];
  }

  if( name === "password_confirmation"){
    rules = [ value === data?.password ];
  }
  
  if( name === "first_name"){
    rules = [ validator.isLength( value, { min: 2 })];
  }

  if( name === "last_name"){
    rules = [ validator.isLength( value, { min: 2 })];
  }

  return rules.every( rule => rule === true );
}