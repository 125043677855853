import { useDispatch, useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import iconHorse from './../icons/horse.png';
import iconJockey from './../icons/jockey.png';
import s from './ModeToggle.module.scss';
import { SYSTEM_MODES } from 'src/constants';
import { useNavigate } from 'react-router-dom';

export const ModeToggle = ({ size, orientation = 'horizontal', sx = {}, isExternal = false }) => {
  const { mode } = useSelector(state => state.mode);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { HORSE, COACH } = SYSTEM_MODES;

  const { icon } = s;

  const handleChange = (event, newMode) => {
    if (newMode !== null) {
      dispatch({ type: SYSTEM_MODES.CHANGE_MODE, payload: newMode });
      !isExternal && navigate('/');
    }
  };

  const children = [
    <ToggleButton value={ HORSE } key={ HORSE }>
      <img className={ icon } src={iconHorse} />
    </ToggleButton>,
    <ToggleButton value={ COACH } key={ COACH }>
      <img className={ icon } src={iconJockey} />
    </ToggleButton>,
  ];

  const control = {
    value: mode,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Stack spacing={2} alignItems="center" sx={{ ...sx }} >
      <ToggleButtonGroup size={ size } orientation={ orientation } {...control}>
        {children}
      </ToggleButtonGroup>
    </Stack>
  );
}