import { Link } from 'react-router-dom';
import { BookingHeader } from '../Booking/bookingHeader';
import { useTranslation } from '../../../helpers/translate';

export const Home = () => {
  const { __ } = useTranslation();

  return (
    <>
      <BookingHeader />
      <div className="home-page">
        <div className="inside">
          <div className="home-page-title">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit corporis cupiditate
              repudiandae quas ad officia, quibusdam deserunt totam recusandae deleniti architecto
              laudantium reprehenderit necessitatibus enim voluptas, amet nesciunt modi veniam!
            </p>
          </div>
          <div className="home-page-title-text">
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit corporis cupiditate
              repudiandae quas ad officia, quibusdam deserunt totam recusandae deleniti architecto
              laudantium reprehenderit necessitatibus enim voluptas, amet nesciunt modi veniam!
            </p>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit corporis cupiditate
              repudiandae quas ad officia, quibusdam deserunt totam recusandae deleniti architecto
              laudantium reprehenderit necessitatibus enim voluptas, amet nesciunt modi veniam!
            </p>
          </div>
          <Link to="/booking" className="button">
            <p>Перейти до послуг</p>
          </Link>
        </div>
      </div>
    </>
  );
};
