import { timeFormat } from "../utils/timeFormat";
import { visitStatuses, visitInitiator } from "./../../../common/ClientCard/components/visitStatuses";
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';

export const BusySlot = ({styles, eventInfo, extendedClassName}) => {
  const { timeText, event: { title, extendedProps } } = eventInfo;
  const { visit, statusId } = extendedProps;
  const { first_name, last_name, phone, status, billing_client_service_id, controller_name} = visit;
  const isUser = first_name && last_name;

  const isPaid = !!billing_client_service_id;

  const { 
    slot, slot_busy,
    header, header__payment, header__payment_paid, header__payment_nopaid,  
    content, content__info,
  } = styles;

  return (
    <div className={ `${slot} ${slot_busy}${extendedClassName ? ' ' + extendedClassName : ' '}` }>
      <div className={ header } style={{ background: visitInitiator[controller_name] }}>
        <span>{ timeFormat(timeText) }</span>
        <span>{ visitStatuses[status].title }</span>
        <div className={ `${header__payment} ${ isPaid ? header__payment_paid : header__payment_nopaid }` }>
          <PaidRoundedIcon sx={{ color: isPaid ? 'green' : 'red' }}/>
        </div>
      </div>
      <div className={ content } style={{ background: visitStatuses[status].color }}>
        <div className={ content__info }>
          <span>{ phone }</span>
          {
            isUser &&
              <span>
                { last_name } { first_name }
              </span>
          }
        </div>
      </div>
    </div>
  );
};