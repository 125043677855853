export const getItemsAvailabilityRowCellsData = (dataItem, __) => {
  let diffClass = '';
  const itemDiff = dataItem && dataItem?.difference_count;

  if (itemDiff) {
    if (itemDiff < 0) {
      diffClass = 'td--minus';
    } else if (itemDiff > 0) {
      diffClass = 'td--plus';
    }
  }

  return {
    title: {
      th: 'Назва',
      td: dataItem && dataItem.title,
      sort: 'title',
      // link: dataItem && {
      //   url: `/inventory-items/${dataItem.id}/edit/`,
      //   state: { backRoute: '/inventory-items-availability' }
      // },
    },
    unit: {
      th: 'Од. виміру',
      td: dataItem && dataItem.unit?.title,
      sort: null,
    },
    price: {
      th: 'Ціна',
      td: dataItem && dataItem.cost,
      sort: null,
    },
    balanceOut: {
      th: 'Кількість',
      td: dataItem && dataItem?.turnover?.balance_out,
      sort: null,
    },
    type: {
      th: 'Тип',
      td: dataItem && dataItem?.inventory_item_type?.title,
      sort: null,
    },
    reserv: {
      th: 'Страховий запас',
      td: dataItem && dataItem?.reserve,
      sort: null,
    },
    differenceCount: {
      th: 'Відхилення',
      td: dataItem && dataItem?.difference_count,
      __classTd: diffClass && diffClass,
      sort: null,
    },
    cost: {
      th: 'Вартість',
      td: dataItem && (dataItem?.cost * dataItem?.turnover?.balance_out).toFixed(2),
      sort: null,
    },
  }
}