const orderOutcomings = {
    uk: {
        "orderOutcomings.page.title": "Розхідні ордери",
        "orderOutcomings.filter.title": "Номер ордеру",
        "orderOutcomings.filter.archiveHide": "Сховати архів",
        "orderOutcomings.filter.archiveShow": "Показати архів",
        "create.button.title": "Створити",
        "orderOutcomings.table.order_num": "Номер ордеру",
        "orderOutcomings.table.subdivision": "Підрозділ",
        "orderOutcomings.table.date_order": "Дата списання",
        "orderOutcomings.table.date_create": "Дата створення",
        "orderOutcomings.table.cost": "Вартість",
        "orderOutcomings.table.person": "Особа",
        "orderOutcomings.table.store": "Склад",
        "orderOutcomings.table.is_active": "Активний/Неактивний",
        "orderOutcomings.table.actions": "Дії",


        "orderOutcomings.form.title-create": "Створити ордер",
        "orderOutcomings.form.title-edit": "Редагувати ордер",
        "orderOutcomings.form.button": "Провести",

        "orderOutcomings.input.order_num": "Введіть номер ордера*",
        "orderOutcomings.input.subdivision_id": "Оберіть підрозділ*",
        "orderOutcomings.input.date_order": "Дата документа*",
        "orderOutcomings.input.store_id": "Оберіть склад*",
        "orderOutcomings.input.autoorder": "Автосписання",
        "orderOutcomings.input.autoorder.none": "Відсутнє",
        "orderOutcomings.input.autoorder.day": "День",
        "orderOutcomings.input.autoorder.week": "Тиждень",
        "orderOutcomings.input.autoorder.month": "Місяць",

        "orderOutcomings.form.subdivision_id.error": "*Поле є обов'язковим",
        "orderOutcomings.form.store_id.error": "*Поле є обов'язковим",
        "orderOutcomings.form.date_start.error": "*Поле є обов'язковим",
        "orderOutcomings.form.order_num.error": "*Поле обов'язкове і має бути унікальним",
        "orderOutcomings.form.numbers.error": "*Поле є обов'язковим",
        "orderOutcomings.form.items.error": "*ТМЦ є обов'язковими",


        "orderOutcomings.form.items.title": "Список ТМЦ",
        "orderOutcomings.input.inventoryItems": "Виберіть ТМЦ",
        "orderOutcomings.table.items.title": "Назва",
        "orderOutcomings.table.items.unit": "Одиниця виміру",
        "orderOutcomings.table.items.price": "Ціна",
        "orderOutcomings.table.items.numbers": "Кількість",
        "orderOutcomings.table.items.sale_price": "Вартість",
        "orderOutcomings.table.items.actions": "Дії",

        "orderOutcomingsView.page.title": "Розхідний ордер",
        "orderOutcomingsView.list.order_num": "Номер ордеру",
        "orderOutcomingsView.list.subdivision": "Підрозділ",
        "orderOutcomingsView.list.person": "Особа",
        "orderOutcomingsView.list.store": "Склад",
        "orderOutcomingsView.list.date_order": "Дата документа",
        "orderOutcomingsView.list.date_create": "Дата створення документа",
        "orderOutcomingsView.list.cost": "Вартість",
        "orderOutcomingsView.table.inventory_item-title": "Назва",
        "orderOutcomingsView.table.unit-title": "Одиниця виміру",
        "orderOutcomingsView.table.count": "Кількість",
        "orderOutcomingsView.table.price": "Ціна",
        "orderOutcomingsView.table.cost": "Вартість",

    },
    en: {
        "orderOutcomings.page.title": "Outgoing Orders",
        "orderOutcomings.filter.title": "Order Number",
        "orderOutcomings.filter.archiveHide": "Hide Archive",
        "orderOutcomings.filter.archiveShow": "Show Archive",
        "create.button.title": "Create",
        "orderOutcomings.table.order_num": "Order Number",
        "orderOutcomings.table.subdivision": "Subdivision",
        "orderOutcomings.table.date_order": "Date of Write-off",
        "orderOutcomings.table.date_create": "Date of created",
        "orderOutcomings.table.cost": "Cost",
        "orderOutcomings.table.person": "Person",
        "orderOutcomings.table.store": "Store",
        "orderOutcomings.table.is_active": "Active/Inactive",
        "orderOutcomings.table.actions": "Actions",

        "orderOutcomings.form.title-create": "Create Order",
        "orderOutcomings.form.title-edit": "Edit Order",
        "orderOutcomings.form.button": "Execute",

        "orderOutcomings.input.order_num": "Enter Order Number*",
        "orderOutcomings.input.subdivision_id": "Select Subdivision*",
        "orderOutcomings.input.date_order": "Document Date*",
        "orderOutcomings.input.store_id": "Select Store*",
        "orderOutcomings.input.autoorder": "Auto-write-off",
        "orderOutcomings.input.autoorder.none": "None",
        "orderOutcomings.input.autoorder.day": "Day",
        "orderOutcomings.input.autoorder.week": "Week",
        "orderOutcomings.input.autoorder.month": "Month",

        "orderOutcomings.form.subdivision_id.error": "*Field is required",
        "orderOutcomings.form.store_id.error": "*Field is required",
        "orderOutcomings.form.date_start.error": "*Field is required",
        "orderIncomings.form.order_num.error": "*This field is required and unique",
        "orderOutcomings.form.numbers.error": "*Field is required",
        "orderOutcomings.form.items.error": "*List of Inventory field is required",

        "orderOutcomings.form.items.title": "List of Inventory",
        "orderOutcomings.input.inventoryItems": "Select Inventory Items",
        "orderOutcomings.table.items.title": "Name",
        "orderOutcomings.table.items.unit": "Unit of Measure",
        "orderOutcomings.table.items.price": "Price",
        "orderOutcomings.table.items.numbers": "Quantity",
        "orderOutcomings.table.items.sale_price": "Cost",
        "orderOutcomings.table.items.actions": "Actions",

        "orderOutcomingsView.page.title": "Outgoing order",
        "orderOutcomingsView.list.order_num": "Order Number",
        "orderOutcomingsView.list.subdivision": "Subdivision",
        "orderOutcomingsView.list.person": "Person",
        "orderOutcomingsView.list.store": "Store",
        "orderOutcomingsView.list.date_order": "Document Date",
        "orderOutcomingsView.list.date_create": "Document creation Date",
        "orderOutcomingsView.list.cost": "Cost",
        "orderOutcomingsView.table.inventory_item-title": "Name",
        "orderOutcomingsView.table.unit-title": "Unit of Measure",
        "orderOutcomingsView.table.count": "Quantity",
        "orderOutcomingsView.table.price": "Price",
        "orderOutcomingsView.table.cost": "Cost",
    },
    ru: {
        "orderOutcomings.page.title": "Расходные ордера",
        "orderOutcomings.filter.title": "Номер ордера",
        "orderOutcomings.filter.archiveHide": "Спрятать архив",
        "orderOutcomings.filter.archiveShow": "Показать архив",
        "create.button.title": "Создать",
        "orderOutcomings.table.order_num": "Номер ордера",
        "orderOutcomings.table.subdivision": "Подразделение",
        "orderOutcomings.table.date_order": "Дата списания",
        "orderOutcomings.table.date_create": "Дата створення",
        "orderOutcomings.table.cost": "Стоимость",
        "orderOutcomings.table.person": "Лицо",
        "orderOutcomings.table.store": "Склад",
        "orderOutcomings.table.is_active": "Активный/Неактивный",
        "orderOutcomings.table.actions": "Действия",

        "orderOutcomings.form.title-create": "Создать ордер",
        "orderOutcomings.form.title-edit": "Редактировать ордер",
        "orderOutcomings.form.button": "Провести",

        "orderOutcomings.input.order_num": "Введите номер ордера*",
        "orderOutcomings.input.subdivision_id": "Выберите подразделение*",
        "orderOutcomings.input.date_order": "Дата документа*",
        "orderOutcomings.input.store_id": "Выберите склад*",
        "orderOutcomings.input.autoorder": "Автосписание",
        "orderOutcomings.input.autoorder.none": "Отсутствует",
        "orderOutcomings.input.autoorder.day": "День",
        "orderOutcomings.input.autoorder.week": "Неделя",
        "orderOutcomings.input.autoorder.month": "Месяц",

        "orderOutcomings.form.subdivision_id.error": "*Поле обязательно",
        "orderOutcomings.form.store_id.error": "*Поле обязательно",
        "orderOutcomings.form.date_start.error": "*Поле обязательно",
        "orderIncomings.form.order_num.error": "*Поле обязательно и должно быть уникальным",
        "orderOutcomings.form.numbers.error": "*Поле обязательно",
        "orderOutcomings.form.items.error": "*ТМЦ есть обязательными",


        "orderOutcomings.form.items.title": "Список ТМЦ",
        "orderOutcomings.input.inventoryItems": "Выберите ТМЦ",
        "orderOutcomings.table.items.title": "Название",
        "orderOutcomings.table.items.unit": "Единица измерения",
        "orderOutcomings.table.items.price": "Цена",
        "orderOutcomings.table.items.numbers": "Количество",
        "orderOutcomings.table.items.sale_price": "Стоимость",
        "orderOutcomings.table.items.actions": "Действия",

        "orderOutcomingsView.page.title": "Расходный ордер",
        "orderOutcomingsView.list.order_num": "Номер ордера",
        "orderOutcomingsView.list.subdivision": "Подразделение",
        "orderOutcomingsView.list.person": "Лицо",
        "orderOutcomingsView.list.store": "Склад",
        "orderOutcomingsView.list.date_order": "Дата документа",
        "orderOutcomingsView.list.date_create": "Дата создания документа",
        "orderOutcomingsView.list.cost": "Стоимость",
        "orderOutcomingsView.table.inventory_item-title": "Название",
        "orderOutcomingsView.table.unit-title": "Единица измерения",
        "orderOutcomingsView.table.count": "Количество",
        "orderOutcomingsView.table.price": "Цена",
        "orderOutcomingsView.table.cost": "Стоимость",
    }

}

export default orderOutcomings;