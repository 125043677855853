import { SelectModelForSchedule } from '../../common/ScheduleModelManage';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Grid  } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export function Schedule({ modelType }) {
  const classes = useStyles();

  return (
    <>
    <div className="title title--mb36">Налаштування розкладу</div>
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SelectModelForSchedule modelType={ modelType }></SelectModelForSchedule>
        </Grid>
      </Grid>
    </div>   
    </>
  );
}
