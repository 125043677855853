import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';

import { useTranslation } from '../../../../helpers/translate';

import { IconButton, MenuItem } from '@mui/material';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveUpIcon from '@mui/icons-material/MoveUp';

import { 
  TableBody,
  TrBody, 
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  FilterSelect,
  TablePagination,
  ButtonIcon,
  ButtonLinkIcon,
} from '../../../common/TableComponent';
import { getRowCellsData } from './getRowCellsData';

import { s } from '../styles';
import { SelectingPaymentSystem } from './../../../common/Payment';
import { createPaymentOrders } from './../../../../actions/booking';
import { setPersonalVisitCancel } from './../../../../actions/cabinet';
import { makePaymentQuery, openPaymentPlacePage } from './../../Booking/utils';
import { getDateValidOrder, getPossibilityVisitCancel } from './../../../library';
import { ToastContainer } from 'react-toastify';
import { fetchData, fetchPaymentType } from './API';
import dayjs from 'dayjs';
import { serverErrorHandler } from 'src/components/common/FormsComponent';

export const PersonalVisits = () => {
  const { __ } = useTranslation();
  const { visitId } = useParams();
  const navigate = useNavigate();

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {
      visits: [],
      statuses: [],
      meta: [],
    },
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [visitStatusFilter, setVisitStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const [openPaymentSelectingPopup, setOpenPaymentSelectingPopup] = useState(!!visitId);
  const [paymentAccessibility, setPaymentAccessibility] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [update, setUpdate] = useState(false);
  const [payDisabled, setPayDisabled] = useState(false);

  const handlePaid = async (selectedPaymentMethod) => {
    let paymentMethod = '';
    if ( selectedPaymentMethod === 'abonnement' ) {
      paymentMethod = 'abonement';
    } else if (selectedPaymentMethod === 'certificate') {
      paymentMethod = 'certificate';
    }

    let paymentParams = null;
    if (paymentMethod) {
      paymentParams = {
        method: paymentMethod,
        data: paymentAccessibility[selectedPaymentMethod]
      }
    }

    const { paymentPlacePageUrl, paymentType, errorMessages } = await makePaymentQuery(
      visitId,
      createPaymentOrders, 
      () => console.log('handler for open popup, not set'),
      setErrorMessages,
      { type: 'BOOKING', param: 'visit', paymentParams }
    );

    console.log(errorMessages);
    if (errorMessages) {
      serverErrorHandler(errorMessages, setErrorMessages);
    }

    if (paymentPlacePageUrl, paymentType === 'ONLINE') {
      openPaymentPlacePage(paymentPlacePageUrl);
    }

    setOpenPaymentSelectingPopup(false);
    navigate('/visits/');
  }

  const handleVisitMove = async (moveData) => {
    const userConfirm = window.confirm('Ви дійсно бажаєте перенести візит?');
    if (!userConfirm) {
      return;
    }

    const { visitId, serviceId, horseId } = moveData;
    const route = `/booking/services/${ serviceId }/horse/${horseId}`;
    navigate(route, { state: { visitId } });

  }

  const handleVisitCancel = async (visitId) => {
    const userConfirm = window.confirm('Ви дійсно бажаєте скасувати візит?');
    if (!userConfirm) {
      return;
    }

    const res = await setPersonalVisitCancel(visitId);
    const data = res?.data;
    if (!data) {
      console.warn(res);
    }

    setUpdate(prev => !prev);
  }

  useEffect(() => {

    (async () => {
      fetchData(sortOption, sortDirection, visitStatusFilter, currentPage, setData);

      if (visitId) {
        const res = await fetchPaymentType(visitId, setPaymentAccessibility);
        const resData = res?.response?.data;
        if (resData?.success !== undefined && !resData?.success) {
          setPayDisabled(true);
          serverErrorHandler(resData?.message, setErrorMessages);
        }
      }
    })()

  }, [sortOption, sortDirection, visitStatusFilter, currentPage, update]);

  return (
    <>
      <SelectingPaymentSystem 
        open={openPaymentSelectingPopup}
        onClose={() => {}}
        handlePaid={handlePaid}
        accessibility={Object.keys(paymentAccessibility)}
        paymentData={paymentAccessibility}
        payDisabled={ payDisabled }
      />
      <ToastContainer />
      <TableMain
        title={'Візити'} 
        getRowCellsData={getRowCellsData}
        sortOption={sortOption} 
        setSortOption={setSortOption}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        __={__}
      >
        <TableBlock __class="table--personal-visits">
          <TableAction className="table-action">
            <div className="table-action__block">
              <FilterSelect 
                  inputLabel={'Історія візитів'}
                  selectLabel={'Історія візитів'}
                  selectName="visits-history-select-filter"
                  selectValue={visitStatusFilter}
                  selectHandler={(e) => setVisitStatusFilter(e.target.value)}
                  menuItemDefaultText="Всі статуси"
                >
                  {
                    data &&
                    data.statuses.map((status, index) => (
                      <MenuItem key={status.id} value={status.id}>
                        {status.title}
                      </MenuItem>
                    ))
                  }
                </FilterSelect>
            </div>
          </TableAction>
          <TableHeader />
          <TableBody loaded={loaded}>
            { 
              data?.visits.map((personalVisit) =>{
                const visit = personalVisit.visit;
                const timeslot = visit.timeslot;
                const visitId = visit.id;
                const serviceId = visit.visit_services[0].service_id;
                const isPaid = visit.billing_client_service_id ? true : false;
                const validVisit = getDateValidOrder(visit.timeslot_date);
                const horseId = timeslot.model_id;
                const possibilityVisitCancel = getPossibilityVisitCancel(visit.timeslot_date, 24, false);

                let rowStyles = {};

                if (isPaid) {
                  rowStyles = s.paid;
                } else {
                  rowStyles = validVisit ? s.notpaid : { background: '#f2f2f2' }
                }

                return (
                  <TrBody key={ v4() } data={personalVisit} style={ rowStyles }>

                    {
                      (!isPaid && validVisit) &&
                        <ButtonLinkIcon icon={<AttachMoneyIcon />} route={`/visits/${ visit?.id }/payment`} title="сплатити візит"/>
                    }
                    
                    <ButtonLinkIcon icon={<ContentCopyIcon />} route={`/booking/services/${ serviceId }/horse/${horseId} `} title="Копіювати візит"/>
                    
                    {
                      possibilityVisitCancel &&
                        <>
                          <ButtonIcon 
                            onClick={ () => handleVisitMove( {visitId, serviceId, horseId} ) } 
                            icon={ <MoveUpIcon /> } 
                            title="перенести візит"
                          />
                          <ButtonIcon onClick={ () => handleVisitCancel(visitId) } icon={ <DeleteIcon /> } title="скасувати візит"/>
                        </>
                    }

                  </TrBody>
                )
              }) 
            }
          </TableBody>
        </TableBlock>
        
        <TablePagination
          totalPages={data?.meta?.last_page}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage} 
        />
        
      </TableMain>
    </>
  );
};