import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const StackedVerticalBarChart = ({ chartOptions }) => {
  const stackedOptions = {...chartOptions?.options}
  if (stackedOptions) {
    stackedOptions.scales = {
      ...stackedOptions.scales,
      ...{
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
    };
  }
  
  return (
    <>
      {
        chartOptions && 
          <Bar options={stackedOptions} data={chartOptions?.data}/>
      }
    </>
  );
}