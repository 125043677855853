import { useEffect, useState } from 'react';
import { AddInventoryItems, ButtonSend } from './../../common/FormsComponent';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Box
} from '@mui/material';

import { getOrderSubdivisions, getOrderStores } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useLocation } from 'react-router-dom';

export const WriteOffsForm = ({
  writeOffs,
  handleChange,
  actionUpdate,
  actionApply,
  actionCreate,
  ctaButtonText,
  validationStatus,
  setValidationStatus,
  updateItemСount,
  formType,
  itemsPush,
  isChanged,
}) => {
  const { __ } = useTranslation();
  const { pathname } = useLocation();
  const disabledEdit = false;

  const [{ loaded, subdivisions, stores }, setData] = useState({
    loaded: false,
    subdivisions: [],
    stores: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: orderSubdivisionsResponse } = await getOrderSubdivisions();
        const { data: orderStoresResponse } = await getOrderStores();
        setData({
          loaded: true,
          subdivisions: orderSubdivisionsResponse,
          stores: orderStoresResponse
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <TextField
        fullWidth
        multiline
        maxRows={8}
        value={writeOffs?.commentary}
        onChange={handleChange('commentary')}
        name="description"
        label={__('writeOffs.input.commentary')}
        error={!validationStatus.commentary}
        helperText={validationStatus.commentary.errorMessage}
        className={validationStatus.commentary.isValid ? '' : 'input-error'}
      />
      <FormControl className={validationStatus.subdivision_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('writeOffs.input.subdivision_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('writeOffs.input.subdivision_id')}
          name="subdivision_id"
          value={writeOffs?.subdivision_id}
          onChange={handleChange('subdivision_id')}
          error={!validationStatus.subdivision_id.isValid}
          helperText={validationStatus.subdivision_id.errorMessage}>
          {subdivisions.data &&
            subdivisions.data.map((subdivisions) => (
              <MenuItem key={subdivisions.id} value={subdivisions.id}>
                {subdivisions.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.subdivision_id.isValid && (
          <FormHelperText>{validationStatus.subdivision_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <InputLabel>{__('writeOffs.input.reason')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('writeOffs.input.reason')}
          name="reason"
          value={writeOffs?.reason}
          onChange={handleChange('reason')}>
          <MenuItem value={0}>{__('writeOffs.input.reason.name1')}</MenuItem>
          <MenuItem value={1}>{__('writeOffs.input.reason.name2')}</MenuItem>
          <MenuItem value={2}>{__('writeOffs.input.reason.name3')}</MenuItem>
        </Select>
      </FormControl>
      <div className={validationStatus.inventoryItems.isValid ? '' : 'error'}>
        <AddInventoryItems disabled={ disabledEdit } type="write-offs" updateItemСount={updateItemСount} itemsPush={itemsPush} />
      </div>

      {
        formType === 'edit' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ !isChanged } onClick={ actionUpdate }text="Зафіксувати зміни" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
      {
        formType === 'create' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ isChanged } onClick={ actionCreate }text="Створити" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ !isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
    </>
  );
};
