import axios from '../../helpers/private.axios';

export const getSeasonTickets = async (
    filterType,
    currentPage,
    itemsPerPage,
    showArchive,
) => {
    const params = {};

    if (showArchive) {
        params.status = 'archive';
    }

    if (filterType) {
        params.type = filterType;
    }

    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/season-tickets`, { params });
};