export const getCollapseRowCellsData = (dataItem, __) => {

  return {
    type: {
      th: 'Тип замовлення',
      td: dataItem && dataItem.type,
    },
    service: {
      th: 'Послуга',
      td: dataItem && dataItem.service,
    },
    horse: {
      th: 'Кінь',
      td: dataItem && dataItem.horse,
    },
    dateVisit: {
      th: 'Дата візиту',
      td: dataItem && dataItem.dateVisit,
    },
    seasonTicketTitle: {
      th: 'Абонемент/Сертифікат',
      td: dataItem && dataItem.seasonTicketTitle,
    },
    dateStart: {
      th: 'Початок дії (абонемент/сертифікат)',
      td: dataItem && dataItem.dateStart,
    },
    dateEnd: {
      th: 'Закінчення дії (абонемент/сертифікат)',
      td: dataItem && dataItem.dateEnd,
    },
    hoursQty: {
      th: 'Кількість годин',
      td: dataItem && dataItem.hoursQty,
    },
    validityPeriod: {
      th: 'Термін дії (міс)',
      td: dataItem && dataItem.validityPeriod,
    },
  }
}