import dayjs from "dayjs"

export const getVisitsHistoryRowCellsData = (dataItem, __) => {
  const visit = dataItem && dataItem.visit;
  const timeslot = dataItem && visit?.timeslot;
  const statuses = dataItem && dataItem.statuses;
  const services = visit && visit.services;
  const payStatus = visit && (visit.billing_client_service_id ? 'сплачено' : 'не сплачено')

  const statusTitle = 
    visit && statuses.find(status => status.id === visit.status_id)?.title;

  return {
    date: {
      th: 'Дата',
      td: dataItem && dayjs(timeslot?.date).format('YYYY-MM-DD'),
    },
    time: {
      th: 'Час',
      td: dataItem && dayjs(timeslot?.date).format('HH:mm'),
      sort: null,
    },
    horse: {
      th: 'Кінь/Тренер',
      td: dataItem && timeslot.model_title,
      sort: null,
    },
    service: {
      th: 'Послуга',
      td: services && services[0].service_title,
      sort: null,
    },
    status: {
      th: 'Статус',
      td: dataItem && statusTitle,
      sort: null,
    },
    summ: {
      th: 'Сумма',
      td: visit && visit.sum,
      sort: null,
    },
    payStatus: {
      th: 'Оплата',
      td: visit && payStatus,
      sort: null,
    }
  }
}