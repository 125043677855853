import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { CircularProgress, Button, IconButton, FormControlLabel, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getInventoryItemTypes, deleteInventoryItemTypes } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const InventoryItemTypesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleInventoryItemTypesDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteInventoryItemTypes(id).then(async () => {
        const { data: response } = await getInventoryItemTypes(
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getInventoryItemTypes(
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    showArchive
  ]);

  return (
    <>
      <div className="title title--mb36">{__('inventoryItemTypes.page.title')}</div>
      <div className="table-action">
        <Link to="/inventory-item-types/create">
          <Button
            variant="contained"
            type="submit"
            sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
            {__('create.button.title')}
          </Button>
        </Link>
        <div className="table-action__block">
          <FormControlLabel
            label={
              showArchive
                ? `${__('inventoryItem.filter.archiveHide')}`
                : `${__('inventoryItem.filter.archiveShow')}`
            }
            control={
              <Switch checked={showArchive} onChange={(e) => setShowArchive(e.target.checked)} />
            }
          />
        </div>
      </div>

      <div className="table-block">
        <div className="table table--inventory-item-types">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('inventoryItemTypes.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('inventoryItemTypes.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('inventoryItemTypes.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('inventoryItemTypes.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((inventoryItemTypes) => (
              <div className="tr" key={inventoryItemTypes.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('inventoryItemTypes.table.title')}</p>
                  </div>
                  <div className="td-content">{inventoryItemTypes.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('inventoryItemTypes.table.description')}</p>
                  </div>
                  <div className="td-content">{inventoryItemTypes.description}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('inventoryItemTypes.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {inventoryItemTypes.is_active
                      ? __('inventoryItem.form.active')
                      : __('inventoryItem.form.inactive')}
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('inventoryItemTypes.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link
                        to={`/inventory-item-types/${inventoryItemTypes.id}/edit`}
                        sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleInventoryItemTypesDelete(inventoryItemTypes.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
