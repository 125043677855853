import axios from '../../helpers/private.axios';

export const getServicesNew = async (
    sortOption,
    sortDirection,
    searchTitle,
    categoriesFilter,
    showArchive,
    currentPage,
    itemsPerPage,
) => {
    const params = {};

    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (searchTitle) {
        params['search-title'] = searchTitle;
    }

    if (categoriesFilter) {
        params['category'] = categoriesFilter;
    }

    if (showArchive) {
        params.status = 'archive';
    }

    // params.page = currentPage;
    // params.limit = itemsPerPage;

    return axios.get(`/services`, { params });
};

