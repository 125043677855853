import { Button, Checkbox } from '@mui/material';
import { useTranslation } from '../../../helpers/translate';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

export const InventoryItemTypesForm = ({
  inventoryItemTypes,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  validationStatus,
  setInventoryItemTypes
}) => {
  const { __ } = useTranslation();

  return (
    <>
      <LocaleTabs 
        data={ inventoryItemTypes }
        setData={ setInventoryItemTypes }
        dataName={ 'inventoryItemTypes' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />

      <label className="checkbox">
        <Checkbox
          name="is_active"
          value={inventoryItemTypes?.is_active}
          checked={inventoryItemTypes?.is_active || false}
          onChange={handleChange('is_active')}
        />
        <p>{__('inventoryItemTypes.form.is_active')}</p>
      </label>
      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
