import { validate } from ".";

export const validEditFormUploadData = (data) => {
  const passport_fields = ['code', 'number', 'date', 'organ' ];
  const validateStatus = {};
  
  for (let name in data) {
    let value;
    let isValid = false;

    if (name !== 'passport_data') {
      value = data[name];
      if (!value) {
        validateStatus[name] = false;
        continue;
      }

      isValid = validate( name, value );
      validateStatus[name] = isValid;
      continue;
    }

    passport_fields.forEach(name => {
      const value = data.passport_data[name];
      if (!value) {
        validateStatus[name] = false;
      } else {
        isValid = validate( name, value );
        validateStatus[name] = isValid;
      }
    });

  }

  return [validateStatus, Object.keys( validateStatus ).every( key => validateStatus[key] === true )];
}