export const checkValidSingleLocale = (validateStatusSingleLocale) => {
  if (!validateStatusSingleLocale) {
    return true;
  }
  const validateDataArr = Object.values(validateStatusSingleLocale);
  if (!validateDataArr.length) {
    return true;
  }

  const isValid = validateDataArr.every(field => field.isValid);
  return isValid;
}