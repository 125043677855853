import { getRequiredFields } from "../../../common/FormsComponent/LocaleTabs/utils";
import { getValidationLocaleModel } from "../../../common/FormsComponent/LocaleTabs";
// may be import from current component folder
// import { requiredLocaleValidationFields } from "./../../../common/FormsComponent/LocaleTabs";
import { localeFieldsModel } from "../localeValidation";

const requiredLocaleValidationFields = getRequiredFields(localeFieldsModel);
export const validationData = {
  ...getValidationLocaleModel(requiredLocaleValidationFields),
  categories: { isValid: true, errorMessage: '' },
  experience: { isValid: true, errorMessage: '' },
  job_start: { isValid: true, errorMessage: '' },
  services: { isValid: true, errorMessage: '' },
  status: { isValid: true, errorMessage: '' },
  user_id: { isValid: true, errorMessage: '' },
}