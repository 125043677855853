import { useEffect, useState } from 'react';

import { createCoach, editCoach, updateCoach } from '../../../actions/';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { DataForm } from './DataForm';
import { ModalForm, serverErrorHandler } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';

import { getFormData } from './../../library';

import { getDataCloneForSend, getHandleChange } from './utils';
import { dataModel } from './dataModels';
import { validateForm, validationModel, validationData } from './validation';
import dayjs from 'dayjs';

export const EditCoach = ({ type }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/coaches');
  };

  const [loaded, setLoaded] = useState(false);
  const [coaches, setCoaches] = useState(dataModel);

  const [validationStatus, setValidationStatus] = useState(validationData);
  const [errorMessages, setErrorMessages] = useState([]);

  const formTitle = type === 'create' ? __('coaches.form.title-create') : __('coaches.form.title-edit')

  const handleLocalizationChange = getHandleLocalizationChange(
    __('coaches.form.name.error'),
    setCoaches,
    setValidationStatus
  );
  const handleChange = getHandleChange(coaches, setCoaches, setValidationStatus);

  const actionHandle = () => {
    const isValid = validateForm(
      coaches,
      'coaches',
      validationModel,
      setValidationStatus,
      __,
      true,
    );

    if (isValid) {
      const dataClone = getDataCloneForSend(coaches);
      let method = 'POST';
      let action = createCoach.bind(null, getFormData(dataClone, method, true));

      if (type === 'edit') {
        if (dataClone.image && typeof dataClone.image === 'string') {
          delete dataClone.image;
        }

        method = 'PUT';
        action = updateCoach.bind(null, params.id, getFormData(dataClone, method, true));
      }

      try {
        action().then((res) => {
          if (res?.data?.success) {
            navigate('/coaches');
          }

          serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
        });
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    if (type === 'create') {
      setLoaded(true);
      return
    }

    editCoach(params.id).then((res) => {
      const fetchData = res.data.data;

      // fetchData.category = fetchData?.options?.coach_category?.map((category) => category.id);
      fetchData.services = fetchData?.services?.map((service) => service.id);
      setCoaches(fetchData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={formTitle}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      closeBtn
    >
      <DataForm
        coaches={coaches}
        setCoaches={setCoaches}
        action={actionHandle}
        handleChange={handleChange}
        // selectedCategories={coaches.categories}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('coaches.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
