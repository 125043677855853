import { useState } from 'react';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BookingHeader } from '../Booking/bookingHeader';

import { Button, TextField } from '@mui/material';

import { sendPhone, clientLoginCode } from '../../../actions';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from '../../../helpers/translate';
import { ROLES } from '../../../constants';

import { PhoneInputBlock } from './uiComponents';
import { phoneChangeHandler, serverErrorHandler } from './utils';

export const AuthCode = () => {
  const { state } = useLocation();
  const userIsAuth = state?.userIsAuth;
  const authUserPhone = state?.authUserPhone;

  const { __ } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const [role, setRole] = useState(path === ROLES.ADMIN.PATH ? ROLES.ADMIN.ROLE : ROLES.USER.PATH);

  const [loginPhone, setPhone] = useState({
    // phone_number: authUserPhone || '+380 67 765-43-55'
    phone_number: authUserPhone || ''
  });
  const [loginCode, setCode] = useState({
    code: '', // 212223
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);

  const handleChange = (e) => phoneChangeHandler(e, loginPhone, setPhone, setErrorMessages);

  const handleChangeCode = (e) => {
    setCode({
      code: e.target.value
    });
  };

  const submitPhone = async () => {
    setErrorMessages({});
    sendPhone(loginPhone)
      .then((res) => {
        setIsPhoneSubmitted(true);
      })
      .catch((error) => console.log('1', error) || serverErrorHandler(error.response.data.errors, setErrorMessages));
  };

  const submitData = async () => {
    setErrorMessages({});
    try {
      const res = await dispatch(clientLoginCode(loginPhone.phone_number, loginCode.code, role));
      if (res.data.success) {
        navigate('/');
        return;
      }
    } catch (error) {
      error && serverErrorHandler(error?.response?.data?.errors, setErrorMessages);
    }
  };

  const changePhoneNumber = () => {
    setIsPhoneSubmitted(false);
  };

  return (
    <>
      <ToastContainer />
      <BookingHeader />
      <div className="center-block">
        <div className="inside">
          <div className="center-block-form">
            <div className="center-block-title">
              <p>
                {' '}
                {(userIsAuth || isPhoneSubmitted)
                  ? `${__('booking.authorization.title1')}`
                  : `${__('booking.authorization.title2')}`}
              </p>
            </div>
            {(!userIsAuth && !isPhoneSubmitted) && (
              <PhoneInputBlock
                isError={ errorMessages.phone_number }
                value={ loginPhone.phone_number }
                setValue={ handleChange }
                errorText={ __('general.authorization.phone-error') }
              />
            )}
            {(userIsAuth || isPhoneSubmitted) && (
              <>
                <TextField
                  fullWidth
                  label={__('booking.authorization.input-code')}
                  name="code"
                  value={loginCode.code}
                  onChange={handleChangeCode}
                  error={errorMessages.code ? true : false}
                  helperText={errorMessages.code ? errorMessages.code[0] : ''}
                  className={errorMessages.code ? 'input-error' : ''}
                />
                <div className="form-action">
                  <Button
                    className="link"
                    sx={{ p: 0, textTransform: 'none', fontSize: 16, lineHeight: 1 }}
                    onClick={changePhoneNumber}>
                    {__('booking.authorization.button-action1')}
                  </Button>
                  <Button
                    className="link"
                    sx={{ p: 0, textTransform: 'none', fontSize: 16, lineHeight: 1 }}
                    onClick={submitPhone}>
                    {__('booking.authorization.button-action2')}
                  </Button>
                </div>
              </>
            )}
            {(userIsAuth || isPhoneSubmitted) ? (
              <Button
                variant="contained"
                type="submit"
                onClick={submitData}
                sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
                {__('booking.authorization.button1')}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                onClick={submitPhone}
                sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
                {__('booking.authorization.button2')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
