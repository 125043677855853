import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';

import { useTranslation } from '../../../../helpers/translate';

import { IconButton, MenuItem } from '@mui/material';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { 
  TableBody,
  TrBody, 
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  FilterSelect,
  TablePagination,
} from '../../../common/TableComponent';
import { getVisitsHistoryRowCellsData } from '../TablesData';

import { getPersonalVisitsStatuses } from './../../../../actions';
import { getClientVisitsHistory } from './../../../../actions';

export const ClientVisitsHistory = ({ clientId = null }) => {
  const location = useLocation();

  if (!clientId) {
    clientId = location?.state.id;
  }
  
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {
      visits: [],
      statuses: [],
      meta: [],
    },
  });

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!clientId) {
      setData({
        loaded: true,
        data: null,
      });
      return;
    }
    const fetchData = async () => {
      try {
        const { data: visits } = await getClientVisitsHistory(
                                            clientId,
                                            currentPage,
                                        );
        const { data: statuses } = await getPersonalVisitsStatuses();
        const meta = visits.meta;

        setData({
          loaded: true,
          data: {
            visits: visits.data.map(visit => ({visit, statuses: statuses.data})),
            statuses: statuses.data,
            meta,
          }
        });
      }
      catch (error) {
        console.warn('PersonalVisits fetch error: ', error);
      }
    }
    fetchData();
  }, [
    currentPage,
  ]);

  return (
    <TableMain
      title={'Історія відвідувань'} 
      getRowCellsData={getVisitsHistoryRowCellsData}
      __={__}
    >

      <TableBlock __class="table--client-visits-history">
        <TableHeader />
        <TableBody loaded={loaded}>
          { 
            data?.visits.map((personalVisit) =>{
              return (
                <TrBody key={ v4() } data={personalVisit} />
              )
            }) 
          }
        </TableBody>
      </TableBlock>
      
      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} 
      />
      
    </TableMain>
  );
};