import { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';

import {
  applyOrderIncomings,
  getOrderIncomingsId,
  updateOrderIncomings
} from '../../../actions/orderIncomings';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { OrderIncomingsForm } from './orderIncomingsForm';

import dayjs from 'dayjs';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 960,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const OrderIncomingsEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/order-incomings');
  };

  const [loaded, setLoaded] = useState(false);

  const [orderIncomings, setOrderIncomings] = useState({
    order_num: '',
    person_id: '',
    contractor_id: '',
    store_id: '',
    reserve: '',
    date_order: '',
    items: [],
    is_active: ''
  });

  const [items, setItems] = useState([]);
  const [itemsPush, setItemsPush] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const updateItemСount = (newItems) => {
    setItems(newItems);

    const itemsArray = Object.entries(newItems).map(([inventory_item_id, itemData]) => ({
      inventory_item_id: parseInt(inventory_item_id),
      count: itemData.count,
      price: itemData.price,
      id: itemData.id
    }));

    setOrderIncomings((prevOrderIncomings) => ({
      ...prevOrderIncomings,
      items: itemsArray
    }));

    setIsChanged(true);
  };

  const [validationStatus, setValidationStatus] = useState({
    contractor_id: { isValid: true, errorMessage: '' },
    store_id: { isValid: true, errorMessage: '' },
    date_order: { isValid: true, errorMessage: '' },
    order_num: { isValid: true, errorMessage: '' },
    inventoryItems: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    if (!orderIncomings.contractor_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        contractor_id: {
          isValid: false,
          errorMessage: `${__('orderIncomings.form.contractor_id.error')}`
        }
      }));
    }

    if (!orderIncomings.store_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        store_id: {
          isValid: false,
          errorMessage: `${__('orderIncomings.form.store_id.error')}`
        }
      }));
    }

    if (!orderIncomings.date_order) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        date_order: {
          isValid: false,
          errorMessage: `${__('orderIncomings.form.date_start.error')}`
        }
      }));
    }

    if (!orderIncomings.order_num) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        order_num: {
          isValid: false,
          errorMessage: `${__('orderIncomings.form.order_num.error')}`
        }
      }));
    }

    if (!orderIncomings.items.length > 0) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        inventoryItems: {
          isValid: false
        }
      }));
    } else {
      orderIncomings.items.forEach((item) => {
        if (
          !item.price ||
          parseFloat(item.price) <= 0 ||
          !item.count ||
          parseFloat(item.count) <= 0
        ) {
          isValid = false;
          setValidationStatus((prevValidationStatus) => ({
            ...prevValidationStatus,
            inventoryItems: {
              isValid: false
            }
          }));
        }
      });
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = event.target.checked;
    } else if (name === 'date_order') {
      value = dayjs(event).format('YYYY-MM-DD');
    } else {
      value = event.target.value;
    }

    setOrderIncomings({
      ...orderIncomings,
      [name]: value
    });

    setIsChanged(true);
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      updateOrderIncomings(params.id, orderIncomings).then((res) => {
        setIsChanged(false);
      });
    }
  };

  const handleApply = () => {
    const isValid = validateForm();
    if (isValid) {
      const sendData = new FormData();
      sendData.append('id', params.id);
      applyOrderIncomings(sendData).then((res) => {
        navigate('/order-incomings');
      });
    }
  };

  const convertItemsToItemCounts = (items) => {
    const itemCounts = {};

    items.forEach((item) => {
      const inventoryItemId = item.inventory_item.id;

      if (!itemCounts[inventoryItemId]) {
        itemCounts[inventoryItemId] = {
          count: parseFloat(item.count),
          price: parseFloat(item.price),
          id: parseFloat(item.id)
        };
      } else {
        itemCounts[inventoryItemId].count += parseFloat(item.count);
        itemCounts[inventoryItemId].price += parseFloat(item.price);
        itemCounts[inventoryItemId].id += parseFloat(item.id);
      }
    });

    return itemCounts;
  };

  useEffect(() => {
    getOrderIncomingsId(params.id).then((res) => {
      const { ...rest } = res.data.data;
      const savedUserData = JSON.parse(localStorage.getItem('ride_state'));
      setOrderIncomings({
        ...rest,
        person_id: savedUserData?.auth?.user?.id || ''
      });
      setItemsPush(convertItemsToItemCounts(rest.items));
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <Modal
        open={true}
        onClose={toggleOpened}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('orderIncomings.form.title-edit')}</h3>
              </div>
              <OrderIncomingsForm
                orderIncomings={orderIncomings}
                setOrderIncomings={setOrderIncomings}
                actionUpdate={handleUpdate}
                actionApply={handleApply}
                handleChange={handleChange}
                ctaButtonText={__('orderIncomings.form.button')}
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                updateItemСount={updateItemСount}
                itemsPush={itemsPush}
                isChanged={!!isChanged}
                formType="edit"
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
