import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createHorses } from '../../../actions/horses';
import { useTranslation } from '../../../helpers/translate';

import { ModalForm, serverErrorHandler } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

import { getFormData } from './../../library';

import { HorsesForm } from './HorsesForm';
import { getDataCloneForSend, getHandleChange } from './utils';
import { horsesModel, horsesValidationModel } from './dataModels';

import { validateForm, validationModel } from './validation';

export const HorsesCreate = () => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/horses');
  };

  const [horses, setHorses] = useState(horsesModel);

  const [validationStatus, setValidationStatus] = useState(validationModel);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleLocalizationChange = getHandleLocalizationChange(
    __('horses.form.name.error'),
    setHorses,
    setValidationStatus
  );
  const handleChange = getHandleChange(horses, setHorses, setValidationStatus);

  const handleCreate = () => {
    const isValid = validateForm(
      horses,
      horsesValidationModel,
      setValidationStatus,
      'horses',
      __,
      true,
      requiredLocaleValidationFields
    );
    if (isValid) {
      const horsesClone = getDataCloneForSend(horses);

      // in create data mast be categories, in edit horsCategories  !!! ???
      // HARDCODE
      horsesClone.categories = horsesClone.horsCategories;
      delete horsesClone.horsCategories;
      // ----------------------------------------------------------------

      try {
        createHorses(getFormData(horsesClone, 'POST', true)).then((res) => {
          if (res?.data?.success) {
            navigate('/horses');
          }
          serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
        });
      } catch (error) {
      }
    }
  };

  return (
    <ModalForm title={__('horses.form.title-create')} open={true} onClose={toggleOpened}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <HorsesForm
        horses={horses}
        setHorses={setHorses}
        action={handleCreate}
        handleChange={handleChange}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('horses.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
