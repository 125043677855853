import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { BookingHeader } from './bookingHeader';
import { OpenDialog } from './../../common/Popups';
import {
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Modal,
  Box
} from '@mui/material';
import dayjs from 'dayjs';
import { getBookingDetailVisit, getPersonalData, createPaymentOrders, getSitePage } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useDispatch } from 'react-redux';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { autoLoginIntoCabinet, makePaymentQuery, openPaymentPlacePage, redirectToLoginForm, visitStoreQuery } from './utils';
import { ButtonSend, NotifyBySms, serverErrorHandler } from './../../common/FormsComponent';
import { UserDataBlock } from './components';
import { SelectingPaymentSystem } from './../../common/Payment';
import { configurateToken } from './../../../helpers/private.axios';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const BookingForm = () => {
  const { user } = useSelector((state) => state.auth);
  const [role, setRole] = useState('USER');
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState('');

  const { __ } = useTranslation();
  const navigate = useNavigate();
  const { selectedServiceId, selectedTimeId } = useParams();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [confirmationData, setConfirmationData] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [openPaymentSelectingPopup, setOpenPaymentSelectingPopup] = useState(false);
  const [paymentAccessibility, setPaymentAccessibility] = useState({});
  const [paymentId, setPaymentId] = useState(null);

  const [agreement, setAgreement] = useState(false);
  const [agreementData, setAgreementData] = useState({
    title: '',
    body: ''
  });

  const queryParams = new URLSearchParams(location.search);

  const selectedHorseId = queryParams.get('horse_id');

  const isBookingActive = true;

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {}
  });
  const [{ userData }, setUserData] = useState({
    userData: []
  });

  const selectedDate = data?.timeslot?.date ? dayjs(data.timeslot.date).format('YYYY-MM-DD') : '';
  const selectedTime = data?.timeslot?.date ? dayjs(data.timeslot.date).format('HH:mm:ss') : '';

  const formattedDate = selectedDate && selectedTime ? `${selectedDate} ${selectedTime}` : '';

  const [booking, setBooking] = useState({
    timeslot_id: selectedTimeId,
    service_id: selectedServiceId,
    hors_id: selectedHorseId,
    date: formattedDate,
    user_id: '',
    last_name: '',
    first_name: '',
    phone: '',
    email: '',
    description: '',
    notifyBySms: ''
  });

  const [validationStatus, setValidationStatus] = useState({
    last_name: { isValid: true, errorMessage: '' },
    first_name: { isValid: true, errorMessage: '' },
    phone: { isValid: true, errorMessage: '' },
    description: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    if (!booking.first_name) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        first_name: {
          isValid: false,
          errorMessage: `${__('booking.form.first_name.error')}`
        }
      }));
    }

    if (!booking.last_name) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        last_name: {
          isValid: false,
          errorMessage: `${__('booking.form.last_name.error')}`
        }
      }));
    }

    if (!booking.description) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        description: {
          isValid: false,
          errorMessage: `${__('booking.form.description.error')}`
        }
      }));
    }

    const isValidPhoneNumber = /\+\d{3} \d{2} \d{3}-\d{2}-\d{2}/.test(booking.phone);
    if (!isValidPhoneNumber) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        phone: {
          isValid: false,
          errorMessage: `${__('booking.form.invalid_phone.error')}`
        }
      }));
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;

    if (name === 'phone') {
      value = event.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
    } else {
      value = event?.target.value;
    }

    setBooking({
      ...booking,
      [name]: value,
      date: formattedDate
    });

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || !isNaN(value),
        errorMessage: value.length > 0 || !isNaN(value) ? '' : `${__('booking.form.null.error')}`
      }
    }));
  };

  const handlePaid = async (selectedPaymentMethod) => {
    let paymentMethod = '';
    if ( selectedPaymentMethod === 'abonnement' ) {
      paymentMethod = 'abonement';
    } else if (selectedPaymentMethod === 'certificate') {
      paymentMethod = 'certificate';
    }

    let paymentParams = null;
    if (paymentMethod) {
      paymentParams = {
        method: paymentMethod,
        data: paymentAccessibility[selectedPaymentMethod]
      }
    }

    console.log(paymentParams);

    const { paymentPlacePageUrl, paymentType } = await makePaymentQuery(
      paymentId,
      createPaymentOrders, 
      setOpen,
      setErrorMessages,
      { type: 'BOOKING', param: 'visit', paymentParams }
    );

    if (paymentPlacePageUrl, paymentType === 'ONLINE') {
      openPaymentPlacePage(paymentPlacePageUrl);
    }

    if (user) {
      setOpen(true);
      return;
    } 

    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/history');
    setOpenPaymentSelectingPopup(false);
  }

  const handlePaidLater = async () => {
    if (user) {
      setOpen(true);
      return;
    } 

    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/visits');
    setErrorMessages([]);
    setOpenPaymentSelectingPopup(false);
  }

  const agreementHandler = async () => {
    setErrorMessages({});
    if (!validateForm()) {
      return;
    }

    const res = await getSitePage('tehnika-bezpeki');
    const { title, body } = res.data.data;
    setAgreementData({title, body});
    setAgreement(true);
  }

  const handleCreate = async () => {
    setAgreement(false);

    const { token, paymentId, paymentAccessibility } = 
      await visitStoreQuery(booking, role, setErrorMessages, setOpen2, setConfirmationData);

    const userAlreadyRegistered = !user && !token;

    setPaymentAccessibility(paymentAccessibility);
    setAuthToken(token);
    setPaymentId(paymentId);
    
    if ( userAlreadyRegistered ) {
      redirectToLoginForm(booking?.phone, setErrorMessages, navigate);
      return;
    }
    
    if (!user && token) {
      configurateToken(token);
    }

    setOpenPaymentSelectingPopup(true);
  }

  const handleMoreButtonClick = () => {
    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/booking/services');
  };

  const handleCabinetButtonClick = () => {
    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/visits');
  };

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setBooking((prevBooking) => ({
        ...prevBooking,
        last_name: userData.last_name,
        first_name: userData.first_name,
        phone: userData.phone
      }));
    }
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        return;
      }
      
      try {
        const { data: response } = await getPersonalData();
        setUserData({
          userData: response.data
        });
      } catch (error) {
        console.error(error.message);
      }
    };
    user && fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingDetailVisit(
          selectedServiceId,
          selectedHorseId,
          selectedTimeId
        );
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.errors) {
          serverErrorHandler(error.response.data.errors, setErrorMessages);
        } else {
          console.error(error.message);
        }
      }
    };

    fetchData();
  }, [selectedServiceId, selectedHorseId, selectedTimeId]);

  return (
    <>
      <SelectingPaymentSystem 
        open={openPaymentSelectingPopup}
        onClose={() => {}}
        handlePaid={handlePaid}
        handlePaidLater={handlePaidLater}
        accessibility={Object.keys(paymentAccessibility)}
        paymentData={paymentAccessibility}
        authToken={authToken}
      />
      <ToastContainer />
      <BookingHeader />
      <main className="main-booking">
        <div className="booking-wrap">
          <div className="booking-form-wrap">
            <div className="booking-title">
              <Link
                className="booking-title__back"
                to={`/booking/services/${selectedServiceId}/horse/${selectedHorseId}/`}>
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <p>{__('booking.form.title')}</p>
            </div>
            <div className="booking-details">
              <div className="booking-details__title">
                <p>{__('booking.form.title.details')}</p>
              </div>
              <div className="booking-details__inside">
                <div className="booking-details__horse">
                  <div className="booking-details__horse-photo">
                    <img src={data?.service?.image ? data?.service?.image : noImage} alt="" />
                  </div>
                  <div className="booking-details__horse-info">
                    <div className="booking-details__horse-name">
                      <p>
                        {data?.hors?.name}
                      </p>
                    </div>
                    <div className="booking-details__horse-time">
                      <p>
                        {__('booking.form.info.time-start')} -{' '}
                        {dayjs(data?.timeslot?.date).format('HH:mm')}
                      </p>
                      <p>
                        {__('booking.form.info.date-start')} -{' '}
                        {dayjs(data?.timeslot?.date).format('DD-MM-YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="booking-details__servese">
                  <div className="booking-details__servese-price">
                    <p>
                      {__('booking.form.info.price')} {data?.service?.amount} ₴
                    </p>
                  </div>
                  <div className="booking-details__servese-name">
                    <p>
                      {data?.service?.title} <span>1 {__('booking.form.info.hour')}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-form">
              <div className="booking-form__title">
                <p>{__('booking.form.title.form')}</p>
              </div>
              <div className="booking-form__inside">
                {
                  <UserDataBlock
                    user={ user }
                    data={ booking }
                    setData={ setBooking }
                    onChange={ handleChange }
                    validationStatus={ validationStatus }
                    setValidationStatus={ setValidationStatus }
                    isOtherUser={ false }
                    __={ __ }
                  >
                    <TextField
                      fullWidth
                      value={booking.email}
                      name="email"
                      label={__('booking.input.email')}
                      onChange={handleChange('email')}
                    />
                    <TextField
                      fullWidth
                      value={booking.description}
                      name="description"
                      label={__('booking.input.description')}
                      onChange={handleChange('description')}
                      error={!validationStatus.description.isValid}
                      helperText={
                        validationStatus.description.isValid
                          ? `${__('booking.input.description.info')}`
                          : validationStatus.description.errorMessage
                      }
                      className={validationStatus.description.isValid ? '' : 'input-error'}
                    />
                    <NotifyBySms 
                      value={ booking?.notifyBySms || 3 }
                      handler={ handleChange }
                      field="notifyBySms"
                    />

                  </UserDataBlock> 
                }
                
              </div>
            </div>

            <div className={`booking-action ${isBookingActive ? 'active' : ''}`}>
              <div className="booking-action-inside">
                <div className="booking-action-checkbox">
                  <label>
                    <Checkbox sx={{ p: 0 }} defaultChecked disabled />
                    <p>
                      {__('booking.сheckbox.text')}
                      <Link to="/"> {__('booking.сheckbox.text2')}</Link>.
                    </p>
                  </label>
                </div>
                <button className="button button--width100p" onClick={ agreementHandler }>
                  <p>{__('booking.button.end')}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <OpenDialog
        open={agreement}
        setOpen={setAgreement}
        data={{
          title: agreementData?.title,
          description: agreementData?.body,
        }}>
        <ButtonSend
          text="Скасувати"
          sx={{ mr: 1, width: '50%' }}
          onClick={() => setAgreement(false)}
        />
        <ButtonSend
          variant="outlined"
          text="Підтвердити"
          sx={{ mr: 1, width: '50%' }}
          onClick={handleCreate}
        />
      </OpenDialog>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>

          <div className="booking-popup">
            <div className="booking-popup-title">
              <p>{__('booking.popup.title')}</p>
            </div>
            <div className="booking-popup-text">
              <p>{__('booking.popup.title.details')}</p>
            </div>
            {(confirmationData && Object.keys(confirmationData).length) && (
              <div className="booking-popup-details">
                <p>
                  {__('booking.popup.date')} {dayjs(confirmationData?.timeslot?.date).format('DD-MM-YYYY')}
                </p>
                <p>
                  {__('booking.popup.time')} {dayjs(confirmationData?.timeslot?.date).format('HH:mm')}
                </p>
                <p>
                {__('booking.popup.horse')}: {confirmationData?.timeslot?.model_title}
                </p>
                <p>
                  {__('booking.popup.sum')}: {confirmationData.visit_services?.[0].summa}
                </p>
                <p>{__('booking.popup.duration')}</p>
              </div>
            )}
            <div className="booking-popup-actions">
              <button className="button button--width100p button--center" onClick={handleMoreButtonClick}>
                <p>{__('booking.popup.button.more')}</p>
              </button>
              {
                user === null && 
                  <button className="button button--width100p" onClick={handleCabinetButtonClick}>
                    <p>{__('booking.popup.button.cabinet')}</p>
                  </button>
              }
            </div>
          </div>

        </Box>
      </Modal>
      <Modal
        open={open2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>

          <div className="booking-popup">
            <div className="booking-popup-title">
              <p>{__('booking.popup.error-api')}</p>
              <p>{__('booking.popup.error-api2')}</p>
            </div>
            <Link className="button button--width100p" to={`/booking/services`}>
              <p>{__('booking.popup.error-button')}</p>
            </Link>
          </div>
          
        </Box>
      </Modal>
    </>
  );
};
