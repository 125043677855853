import axios from '../../helpers/private.axios';

export const getPersonalOrders = (
  sortOption,
  sortDirection,
  currentPage
) => {
  const params = {};

  if (sortOption && sortDirection !== 0) {
    params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
  }

  params.page = currentPage;
  params.limit = 10;
  
  return axios.get(`/site/cabinet/orders`, { params });
};