import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

export function FilterWithSearchDropdown({
  id,
  label,
  action,
  optionItem,
  onSelected = () => {},
  disabled = false 
}) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const loading = open && options.length === 0;

  const {searchArgPos, f} = action;
  let {key, textKeyArr} = optionItem;
  
  key = !key ? 'title': key;
  textKeyArr = !textKeyArr || !textKeyArr.length ? ['title']: textKeyArr;

  useEffect(() => {

    let active = true;

    if (!loading) {
      return undefined;
    }

    if (!searchTitle) {
      return undefined;
    }

    (async () => {
      const searchItems = await f(
        ...getArgs(searchArgPos, searchTitle)
      );
      const data = searchItems?.data.data;
      setOpen(true);

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, searchTitle, action, f, searchArgPos]);

  return (
    <Autocomplete
      disabled={ disabled }
      value={selectedItem}
      onChange={(event, newValue) => {
        setSelectedItem(newValue);
        onSelected(newValue);
        setOpen(false);
        setOptions([]);
      }}
      onInputChange={(event) => {
        const len = event.target.value?.length;
        const searchStr = event.target.value;

        if (len >= 2) {
          setOpen(true);
          setSearchTitle(searchStr);
        }

        if (!len) {
          setOpen(false);
          setOptions([]);
          setSearchTitle('');
        }
        
      }}
      id={ id }
      sx={{ width: 300 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option[key] === value[key]}
      getOptionLabel={(option) => getOptionText(option, textKeyArr)}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={ label }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

function getOptionText(data, textKeyArr) {
  let optionText = textKeyArr
    .map(textKey => data[textKey])
    .join(' ');
  
  return optionText;
}

function getArgs(searchArgPos, searchArg, emptyArgValue = '') {
  const args = [];
  for (let i = 0; i < searchArgPos - 1; i++) {
    args.push(emptyArgValue);
  }
  args.push(searchArg);
  return args;
}