import { Accordion, AccordionDetails, List } from '@mui/material';
import { AccordionTooltip } from '.';
import { s } from './styles';
import React from 'react';
import { useCheckComponentAvailableByCurrentUser } from 'src/configData/rolesConfig/hooks/useCheckComponentAvailableByCurrentUser';

export const SidebarAccordion = ({children, title, icon, providing, resource = false}) => {
  const availability = useCheckComponentAvailableByCurrentUser(resource);
  // console.log(resource, availability);

  return (
    <>
      {
        providing
          ? availability &&
              <Accordion sx={s.accordion}>
                <AccordionTooltip title={ title } icon={ icon } />
                <AccordionDetails sx={s.accordionDetails}>
                  <List component="div" disablePadding>
                    {
                      React.Children.map(children, child => {
                        const childProps = {providerProps: {

                        }};

                        return React.cloneElement(child, childProps)
                      })
                    }
                  </List>
                </AccordionDetails>
              </Accordion>
          :
            <Accordion sx={s.accordion}>
              <AccordionTooltip title={ title } icon={ icon } />
              <AccordionDetails sx={s.accordionDetails}>
                <List component="div" disablePadding>
                  { children }
                </List>
              </AccordionDetails>
            </Accordion>
      }
    </>
    
  );
};