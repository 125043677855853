import axios from '../../helpers/private.axios';
import { path } from './constants';

export const createModelsTimeslotWeek = (scheduleList, modelType) => {
  scheduleList.modelType = modelType;
  scheduleList.modelId = scheduleList.hors_id;

  return axios.post(`/${path}/week`, scheduleList).then((res) => {
    if (res.success) {
      return res.success;
    }
  });
};
