import axios from '../../helpers/private.axios';

export const createServices = (services) => {
    return axios.post(`/services/`, services, { 
        headers: { 'Content-Type': 'mulipart/form-data' },
      })
    .then((res) => {
        if (res.success) {
            return res.success;
        }
    });
};
