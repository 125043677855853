import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useGetUser } from 'src/hooks';
import { SYSTEM_MODES } from 'src/constants';

import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';

// import { EventDialog } from '../../common';

import styles from './dashboard.module.scss';
import './fullCalendar.scss';
import { getModelTimeslotsSchedule } from '../../../actions';
import dayjs from 'dayjs';

import { BreakSlot, BusySlot, EmptySlot } from './UI';
import { timeSlotStatuses } from './timeSlotStatuses';
import { Navigate } from 'react-router-dom';
import { getModelName, modelActions, modelDefaultAvatar } from '../../../configData/modelConfig';
import { rolesComponentConfig } from './rolesComponentConfig';
import { useIsReadOnlyResourceByCurrentUser } from 'src/configData/rolesConfig';
import EmptyDashboardInfo from './UI/EmptyDashboardInfo';

export function Dashboard() {
  const resource = rolesComponentConfig.resource;
  const {userId, isReadOnly} = useIsReadOnlyResourceByCurrentUser(resource);

  const firstDaty = 1;
  const calendar = useSelector((state) => state.calendar);
  const calendarDate = calendar.date;
  const calendarRef = useRef();

  const modelType = useSelector((state) => state.mode);

  const [open, setOpen] = useState(false);
  const [models, setModels] = useState([]);
  const [events, setEvents] = useState([]);
  const [minMaxEventsTime, setMinMaxEventsTime] = useState({ minTime: '00:00:00', maxTime: '23:00:00' });

  const [clickedDate, setClickedDate] = useState({});
  const [extendedProps, setExtendedProps] = useState({ id: '', horse_id: '' });

  const { locale } = useSelector((state) => state.localization);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     calendarRef.current.getApi().gotoDate(calendarDate.$d);
  //   }, 0);
    
  //   return clearTimeout(timeout);

  // }, [calendarDate]);

  useEffect(() => {
    calendarRef.current.getApi().gotoDate(calendarDate.$d);
  }, [calendarDate]);

  useEffect(() => {
    (async () => {
      const {
        data: { data: allModels }
      } = await modelActions[modelType.mode]();

      setModels(allModels);

      const dateStr = calendarDate.format('YYYY-MM-DD');

      getModelTimeslotsSchedule(dateStr, 'day', modelType.mode)
          .then(({ data: { data } }) => {
            const visitEvents = data.map(({ id, model, date, status, sub_status, visit }) => {
              const { id: modelId, type: modelType, title: modelTitle } = model;
              let timeSlotStatus = 'empty';
              
              let userIdByCoachModel = null;
              const coach = allModels.find(model => model.id === modelId);
              userIdByCoachModel = coach ? coach.user_id : null;
              
              if (sub_status.id === 0 && visit?.user_id) {
                timeSlotStatus = 'busy';
              }
      
              const event = {
                id,
                resourceId: modelId,
                start: date,
                end: new Date(date).setMilliseconds(1 * 60 * 60 * 1000),
                title: timeSlotStatuses[timeSlotStatus].title,
                overlap: false,
                color: 'transparent',
                extendedProps: {
                  date: dayjs(date).$d,
                  horseId: modelId,
                  timeSlotStatus,
                  timeSlotId: id,
                  visit: !visit ? null : { ...visit },
                  subStatusId: sub_status.id,
                  statusId: status.id
                }
              };
      
              if (modelType === SYSTEM_MODES.COACH) {
                event.extendedProps.userId = userIdByCoachModel;
              }
      
              return event;
            });
      
            setEvents(visitEvents);
            setMinMaxEventsTime(getMinMaxTimeFromSheduleTimeslot(visitEvents));
      });

    })();
    
  }, [calendarDate, modelType]);

  const renderEventContent = (eventInfo) => {
    const {
      event: { extendedProps }
    } = eventInfo;
    const { timeSlotStatus } = extendedProps;

    const userIdFromSlot = extendedProps.userId;
    const isCurrentCoachSlot = userIdFromSlot === userId;
    let extendedClassName = 'slot_unactive';

    if (isReadOnly && isCurrentCoachSlot) {
      extendedClassName = 'slot_active';
    }

    return (
      <>
        {timeSlotStatus === 'empty' && <EmptySlot styles={styles} eventInfo={eventInfo} extendedClassName={extendedClassName}/>}
        {timeSlotStatus === 'busy' && <BusySlot styles={styles} eventInfo={eventInfo} extendedClassName={extendedClassName}/>}
        {timeSlotStatus === 'break' && <BreakSlot styles={styles} eventInfo={eventInfo} extendedClassName={extendedClassName}/>}
      </>
    );
  };

  const renderResourceContent = ({ resource: { _resource: data } }) => {
    const src = data.extendedProps.img;
    const { title } = data;
    return (
      <span className={`${styles.resourceWrapper}`}>
        <span className={styles.img}>
          <img src={src} alt=""/>
        </span>
        <p>{ title }</p>
      </span>
    );
  };

  const handleDateClick = (clickedDate) => {
    setClickedDate(clickedDate);
    // setOpen(true);
  };

  const handleEventClick = ({ event: { extendedProps }, ...event }) => {
    const { timeSlotStatus, date } = extendedProps;
    const isOpen = timeSlotStatus !== 'break';

    const userIdFromSlot = extendedProps.userId;
    const isCurrentCoachSlot = userIdFromSlot === userId;

    console.log(isReadOnly, userId, userIdFromSlot);

    if (isReadOnly && !isCurrentCoachSlot) {
      return;
    }

    setExtendedProps(extendedProps);
    setClickedDate(date);
    setOpen(isOpen);
  };

  console.log(minMaxEventsTime.minTime, minMaxEventsTime.maxTime);
  console.log(events);

  return (
    <>
      <FullCalendar
        slotMinTime={ minMaxEventsTime.minTime }
        slotMaxTime={ minMaxEventsTime.maxTime }
        dateClick={handleDateClick}
        allDaySlot={false}
        ref={calendarRef}
        eventContent={renderEventContent}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        initialView="resourceTimeGridDay"
        firstDay={firstDaty}
        locale={locale}
        headerToolbar={{
          // right: 'today prev,next',
          right: '',
          center: '',
          left: 'title'
        }}
        weekends={true}
        themeSystem="Simplex"
        plugins={[resourceTimeGridPlugin, interactionPlugin]}
        resources={models?.map((model) => ({
          id: model.id,
          title: getModelName(model, modelType.mode),
          img: model.image || modelDefaultAvatar[modelType.mode]
        }))}
        resourceLabelContent={renderResourceContent}
        events={events}
        eventClick={handleEventClick}
      />
      {
        open && (
          <Navigate to="/visit" state={ {visitData: JSON.stringify({ open, clickedDate, extendedProps })} } />
        )
        // <EventDialog
        //   open={open}
        //   clickedDate={clickedDate}
        //   extendedProps={extendedProps}
        //   setClickedDate={setClickedDate}
        // />
      }

      {
        !events.length && <EmptyDashboardInfo />
      }
    </>
  );
}


function getMinMaxTimeFromSheduleTimeslot(eventsLists, srcDateFormat = 'YYYY-MM-DD HH:mm:ss', reverse = false) {
  let minTime = '00:00:00';
  let maxTime = '00:00:00';

  const minMaxTimeFromSheduleTimeslot = { minTime, maxTime }

  if (!eventsLists || !eventsLists.length) {
    return minMaxTimeFromSheduleTimeslot;
  }

  const sortedEventsList = eventsLists.sort((a, b) => {
    const dateA = dayjs(a.start, srcDateFormat);
    const dateB = dayjs(b.start, srcDateFormat);

    const diffDate = !reverse ? dateA.diff(dateB, 'hour') :  dateB.diff(dateA, 'hour');
    return diffDate;
  });

  if (!sortedEventsList || !sortedEventsList.length) {
    return minMaxTimeFromSheduleTimeslot;
  }

  minTime =  dayjs(sortedEventsList[0].start).format('HH:mm:ss');
  maxTime = dayjs(sortedEventsList[sortedEventsList.length - 1].start).hour() === 23 
    ? '24:00:00' 
    : dayjs(sortedEventsList[sortedEventsList.length - 1].start).format('HH:mm:ss')

  minMaxTimeFromSheduleTimeslot.minTime = minTime;
  minMaxTimeFromSheduleTimeslot.maxTime = maxTime;

  return minMaxTimeFromSheduleTimeslot;
}