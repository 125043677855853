import { serverErrorHandler } from './../../../common/FormsComponent';
import { createBooking } from '../../../../actions';

export const visitStoreQuery = async (paymentData, role, setErrorMessages, setOpenPopup, setConfirmationData) => {
  const res = await createBooking(paymentData, role);
  const errors = res?.response?.data?.errors;
  const data = res?.data?.data;
  const paymentId = data?.id;
  const token = res?.headers?.authorization;

  const certificate = data?.certificate;
  const abonement = data?.abonement;
  const paymentAccessibility = {};

  if (errors) {
    const requiredFields = ['hors_timeslot_id'];
    const errorFields = Object.keys(errors);
    const hasRequiredErrors = requiredFields.every((field) => errorFields.includes(field));

      if (hasRequiredErrors) {
        setOpenPopup(true);
      } else {
        serverErrorHandler(errors, setErrorMessages);
        return;
      }
  }

  setConfirmationData(data || {});

  if (certificate) {
    paymentAccessibility.certificate = certificate;
  }

  if (abonement) {
    paymentAccessibility.abonnement = abonement;
  }

  return { token, paymentId, paymentAccessibility };
}