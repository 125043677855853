import axios from '../../helpers/private.axios';

export const getPages = ( searchParams, showArchive ) => {
    const params = { ...searchParams };
  
    if (showArchive) {
      params.status = 'archive';
    } 
  
    return axios.get('/pages/', { params } );
  };
