import { localeFieldsModel } from './../localeValidation';
import { getLocaleModel } from './../../../common/FormsComponent/LocaleTabs/utils';

export const dataModel = {
  experience: '',
  image: '',
  job_start: '',
  categories: [1],
  services: [],
  status: '',
  is_active: true,
  user_id: '',
  locale: {...getLocaleModel(localeFieldsModel)},
}

