import config from "src/config";
import { getSystemType } from "./getSystemType";
import { SYSTEM_MODES, SYSTEM_TYPES } from "src/constants";

export const getSystemMode = (typesObj) => {
  if (!typesObj) {
    return config.system_mode_default;
  }

  const typesArr = Object.values(typesObj);
  const systemType = getSystemType(typesObj);
  let systemMode = config.system_mode_default;

  if (systemType === SYSTEM_TYPES.SINGLE) {
    systemMode = typesArr[0].includes('hors') ? SYSTEM_MODES.HORSE : SYSTEM_MODES.COACH;
  }

  return systemMode;
}