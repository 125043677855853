import { useEffect, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';

import { getCoachStatuses, getCoachesServices, getUserId } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ButtonSend, InputField, SelectField, SelectMultyCheckbox, ToggleActiveStatus } from '../../common/FormsComponent';
import { ImageUpload } from '../../library/ImageUpload';
import previewPicture from './../../library/ImageUpload/assets/img/no-image.png';
import dayjs from 'dayjs';
import { LocaleTabs } from '../../common/FormsComponent/LocaleTabs';

import { localeFieldsModel } from './localeValidation';
import { UserSelectComponent } from './../../common';
import { Separator } from './../../common/FormsComponent/UI';
import { getfilledAllLocaleFromUserData } from './utils/getfilledAllLocaleFromUserData';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { getRequiredFields } from './../../common/FormsComponent/LocaleTabs/utils';

export const DataForm = ({
  coaches,
  setCoaches,
  action,
  selectedCategories,
  handleChange,
  handleLocalizationChange,
  ctaButtonText,
  validationStatus,
}) => {
  const { __ } = useTranslation();

  const [{ loaded, services, statuses }, setData] = useState({
    loaded: false,
    services: [],
    statuses: {},
  });

  const [userData, setUserData] = useState({
    user: {
      user_id: '',
      first_name: '',
      last_name: '',
      phone: '+380' + ''
    }
  });

  const requiredLocaleValidationFields = getRequiredFields(localeFieldsModel);

  const userDataChangeHandler = (userData) => {
    if (userData.user) {
      setUserData(userData.user);
    }

    if (userData?.user?.id) {
      const newLocaleData = getfilledAllLocaleFromUserData(coaches?.locale, userData);
      setCoaches(prev => ({ ...prev, locale: newLocaleData, user_id: userData?.user.id ? userData?.user.id : coaches.user_id }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: servicesResponse } = await getCoachesServices();
        const { data: statusesResponse } = await getCoachStatuses();
        const { data: userResponse } = await getUserId(coaches.user_id);
        const person = userResponse?.data?.person;

        setData({
          loaded: true,
          services: servicesResponse?.data,
          statuses: statusesResponse,
        });


        setUserData({
          user: {
            user_id: userResponse?.data?.id,
            first_name: person?.first_name,
            last_name: person?.last_name,
            phone: person?.phone
          }
        });

      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <UserSelectComponent 
        data={ userData } 
        dataChangeHandler={ userDataChangeHandler }
      />

      <Separator />

      <LocaleTabs 
        data={ coaches }
        setData={ setCoaches }
        dataName={ 'coaches' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        fillCheckbox
        fieldsModel={ localeFieldsModel }
        displayControl
      />

      <InputField
        type="number"
        value={coaches?.experience}
        field="experience"
        label={__('coaches.form.experience')}
        onChange={ handleChange }
        validationStatus={ validationStatus } 
      />

      <SelectMultyCheckbox 
        fieldClassNames={ validationStatus.services.isValid ? '' : 'input-error' }
        label={ __('coaches.form.services') }
        dataList={ services }
        dataChecked={ coaches?.services }
        error={ !validationStatus.services.isValid }
        errorMessage={ validationStatus.services.errorMessage }
        value={coaches?.services}
        onChange={handleChange('services')}
      />

      <FormControl className={validationStatus.status.isValid ? '' : 'input-error'}>
        <InputLabel>{__('coaches.form.status')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          value={coaches?.status || ''}
          label={__('coaches.form.status')}
          name="status"
          onChange={handleChange('status')}
          error={!validationStatus.status.isValid}
          helperText={validationStatus.status.errorMessage}
          className={validationStatus.status.isValid ? '' : 'input-error'}>
          {statuses && Object.entries(statuses).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {!validationStatus.status.isValid && (
          <FormHelperText>{validationStatus.status.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('coaches.form.job_start')}
            value={dayjs(coaches.job_start)}
            onChange={handleChange('job_start')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.job_start.isValid}
            helperText={validationStatus.job_start.errorMessage}
            className={validationStatus.job_start.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>

      <ImageUpload 
        value={coaches?.image} 
        onChange={handleChange('image')} 
        fileParams={{ width: 1500, height: 1500, size: 500 }}
        previewPicture={ previewPicture }
      />

      <ToggleActiveStatus 
        name="is_active"
        value={!!coaches?.is_active}
        checked={!!coaches?.is_active || false}
        onChange={handleChange('is_active')}
      />

      <ButtonSend onClick={action} text={ctaButtonText} />
    </>
  );
};