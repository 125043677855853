import dayjs from "dayjs";

export const datePickerBlockData = [
  {
    pickerKey: 'start',
    title: 'Початкова дата:',
    maxDate: dayjs(),
  },
  {
    pickerKey: 'end',
    title: 'Кінцева дата:',
    maxDate: dayjs(),
    blockingKeysOfReportType: ['yearly', 'quarterly', 'weekly', 'monthly'],
  },
];