import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  FormHelperText,
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';

import { getSeasonTicketsCategoriesAll, getCertificateCategories, getServicesAll } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import dayjs from 'dayjs';
import { InputField, SelectMultyCheckbox, ToggleActiveStatus } from './../../common/FormsComponent';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';
import { days } from './data/days';
import { convertAllowedDays, useStyles } from './utils';
import { ImageUpload } from './../../library/ImageUpload';
import previewPicture from './../../library/ImageUpload/assets/img/no-image.png';


export const SeasonTicketsForm = ({
  seasonTickets,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  setSeasonTickets,
  validationStatus,
  setValidationStatus
}) => {
  const { __ } = useTranslation();
  const [{ loaded, seasonTicketsCategories, services }, setData] = useState({
    loaded: false,
    seasonTicketsCategories: [],
    services: []
  });
  let [type, setType] = useState(seasonTickets.type || 'abonnement');
  const changeTypeHandler = (e) => {
    handleChange('type').call(null, e);
    setType(e.target.value);
    setSeasonTickets((prev) =>({...prev, category_id: ''}));
  }

  const menuClasses = useStyles();

  console.log(seasonTickets);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let servicesResponse = services;
        if (!servicesResponse.length) {
          servicesResponse = await getServicesAll();
          servicesResponse = servicesResponse?.data.data;
        }

        const { data: seasonTicketsCategoriesResponse } = type === 'abonnement'
          ? await getSeasonTicketsCategoriesAll()
          : await getSeasonTicketsCategoriesAll()

        setData({
          loaded: true,
          services: servicesResponse,
          seasonTicketsCategories: seasonTicketsCategoriesResponse.data,
        });

      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [type]);

  return (
    <>
      <LocaleTabs 
        data={ seasonTickets }
        setData={ setSeasonTickets }
        dataName={ 'seasonTickets' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />

      <FormControl className={validationStatus.type.isValid ? '' : 'input-error'}>
        <InputLabel>{__('seasonTickets.input.type')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('seasonTickets.input.type')}
          name="type"
          value={seasonTickets?.type || 'abonnement'}
          onChange={(e) => changeTypeHandler(e)}>
          <MenuItem value={'abonnement'}>абонемент</MenuItem>
          <MenuItem value={'certificate'}>сертифікат</MenuItem>
        </Select>
        {!validationStatus.type.isValid && (
          <FormHelperText>{validationStatus.type.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <FormControl className={validationStatus.category_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('seasonTickets.input.category_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('seasonTickets.input.category_id')}
          name="category_id"
          value={ seasonTicketsCategories.length ? seasonTickets?.category_id : '' }
          onChange={handleChange('category_id')}>
          {seasonTicketsCategories &&
            seasonTicketsCategories?.map((seasonTicketsCategories) => (
              <MenuItem key={seasonTicketsCategories.id} value={seasonTicketsCategories.id}>
                {seasonTicketsCategories.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.category_id.isValid && (
          <FormHelperText>{validationStatus.category_id.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <FormControl className={validationStatus.service_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('seasonTickets.input.service_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('seasonTickets.input.service_id')}
          name="service_id"
          value={ console.log(services.length) || services.length ? seasonTickets?.service_id : '' }
          onChange={handleChange('service_id')}>
          {services &&
            services.map((services) => (
              <MenuItem key={services.id} value={services.id}>
                {services.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.service_id.isValid && (
          <FormHelperText>{validationStatus.service_id.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <InputField
        value={ seasonTickets?.amount }
        field="amount"
        label={__('seasonTickets.input.amount')}
        onChange={ handleChange }
        validationStatus={validationStatus} 
      />

      <InputField
        value={ seasonTickets?.quantity_hours }
        field="quantity_hours"
        label={__('seasonTickets.input.quantity_hours')}
        onChange={ handleChange }
        validationStatus={validationStatus} 
      />

      <InputField
        disabled
        value={ seasonTickets?.discount_amount }
        field="discount_amount"
        label={ __('seasonTickets.input.discount_amount') }
        onChange={ handleChange }
        validationStatus={validationStatus} 
      />

      <InputField
        disabled
        value={ seasonTickets?.discount_percent }
        field="discount_percent"
        label={ __('seasonTickets.input.discount_percent') }
        onChange={ handleChange }
        validationStatus={validationStatus} 
      />

      <InputField
        value={ seasonTickets?.validity_period }
        field="validity_period"
        label={ __('seasonTickets.input.validity_period') }
        onChange={ handleChange }
        validationStatus={validationStatus} 
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('seasonTickets.input.date_start')}
            value={dayjs(seasonTickets.date_start)}
            onChange={handleChange('date_start')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.date_start.isValid}
            helperText={validationStatus.date_start.errorMessage}
            className={validationStatus.date_start.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('seasonTickets.input.date_end')}
            value={dayjs(seasonTickets.date_end)}
            onChange={handleChange('date_end')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.date_end.isValid}
            helperText={validationStatus.date_end.errorMessage}
            className={validationStatus.date_end.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>

      <SelectMultyCheckbox 
        fieldClassNames={ true ? '' : 'input-error' }
        label={ __('seasonTickets.input.allowed_days') }
        dataList={ days }
        dataChecked={ convertAllowedDays(seasonTickets?.allowed_days) }
        error={ false }
        errorMessage={ '' }
        value={convertAllowedDays(seasonTickets?.allowed_days) || []}
        onChange={handleChange('allowed_days')}
        MenuProps={{
          classes: {
            list: menuClasses.list,
          },
        }}
      />

      <ImageUpload 
        value={seasonTickets?.image} 
        onChange={handleChange('image')} 
        fileParams={{ width: 1500, height: 1500, size: 500 }}
        previewPicture={ previewPicture }
      />

      <ToggleActiveStatus 
        name="is_active"
        value={seasonTickets?.is_active}
        checked={!!seasonTickets?.is_active || false}
        onChange={handleChange('is_active')}
      />

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};

