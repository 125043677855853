import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  IconButton,
  MenuItem
} from '@mui/material';

import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getUsers, deleteUser, getUserCategories, getUserRoles } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import moment from 'moment';
import { FilterSelect, TablePagination } from './../../common/TableComponent';
import { FilterSearch, ToggleShowActive } from './../../../components/common';
import { PhoneInputBlock } from './../../../components/common/FormsComponent';

export const UsersList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: [],
    meta: []
  });

  const [role, setRole] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);
  const [searchFullName, setSearchFullName] = useState('');
  const [categories, setCategories] = useState([]);
  const [roles, setRoles] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState('');

  const [searchPhone, setSearchPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSearchPhone = (name) => (value) => {
    const phone = value.substring(3);
    const phoneLen = phone.length;
    const code = value.substring(0, 3);

    if (!phone) {
      setPhoneNumber('');
      setSearchPhone('');
      return;
    }

    if (phone.length < 2) {
      return;
    }

    let formatPhone = '';

    if (phoneLen <= 5) {
      formatPhone = phone.replace(/(\d{2})/, '$1 ');
    } else if (phoneLen <= 7) {
      formatPhone = phone.replace(/(\d{2})(\d{3})/, '$1 $2-');
    } else if (phoneLen <= 9) {
      formatPhone = phone.replace(/(\d{2})(\d{3})(\d{2})/, '$1 $2-$3-');
    }

    setSearchPhone(`${code} ${formatPhone}`);
  };

  const handleSearchFullName = (e) => {
    setSearchFullName(e.target.value);
  };

  const handleUserDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteUser(id).then(async () => {
        const { data: response } = await getUsers(
          searchPhone,
          searchFullName,
          role,
          categoriesFilter,
          showArchive,
          currentPage,
          itemsPerPage
        );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getUsers(
          searchPhone,
          searchFullName,
          role,
          categoriesFilter,
          showArchive,
          currentPage,
          itemsPerPage
        );

        const categoriesRes = await getUserCategories();
        const categoriesData = categoriesRes.data.data;

        const rolesRes = await getUserRoles();
        const rolesData = rolesRes.data.data;

        setRoles(rolesData);
        setCategories(categoriesData);

        setData({
          loaded: true,
          data: response.data,
          meta: response.meta
        });

        setTotalPages(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [searchPhone, searchFullName, role, categoriesFilter, showArchive, currentPage, itemsPerPage]);

  return (
    <>
      <div className="title title--mb36">Користувачі</div>

      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <PhoneInputBlock
              isError={false}
              value={phoneNumber}
              setValue={handleSearchPhone('phone')}
              noMarginBottom
              noValidate={true}
              countryCodeEditable={false}
            />
          </div>
          <div className="input-wrap">
            <FilterSearch
              name="user-title-filter"
              label={__('general.form.filter.last_name')}
              value={searchFullName}
              onChange={handleSearchFullName}
            />
          </div>
          <div className="input-wrap">
            <FilterSelect
              inputLabel={'Роль'}
              selectLabel={'Роль'}
              selectName="roles-select-filter"
              selectValue={role}
              selectHandler={(e) => setRole(e.target.value)}
              menuItemDefaultText="Всі ролі"
              sx={{ width: '100%' }}>
              {roles &&
                roles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
            </FilterSelect>
          </div>
          <div className="input-wrap">
            <FilterSelect
              inputLabel={'Категорія'}
              selectLabel={'Категорія'}
              selectName="categories-select-filter"
              selectValue={categoriesFilter}
              selectHandler={(e) => setCategoriesFilter(e.target.value)}
              menuItemDefaultText="Всі категорії"
              sx={{ width: '100%' }}>
              {categories &&
                categories?.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.title}
                  </MenuItem>
                ))}
            </FilterSelect>
          </div>
        </div>

        <div className="table-action__block">
          <ToggleShowActive value={showArchive} onChange={() => setShowArchive((prev) => !prev)} />

          <Link to="/users/create">
            <Button
              variant="outlined"
              type="submit"
              sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
              Додати
            </Button>
          </Link>
        </div>
      </div>

      <div className="table-block">
        <div className="table table--users">
          <div className="table-header">
            <div className="tr">
              <div className="td">ID</div>
              <div className="td">
                {' '}
                <div className="td-name">
                  <p>{__('users.table.name')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.roles')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.login')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.registered_at')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.categories')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.is_active')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('users.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((user) => (
              <div className="tr">
                <div className="td">
                  <div className="td-hidden-name">
                    <p>ID</p>
                  </div>
                  <div className="td-content">{user.id}.</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.name')}</p>
                  </div>
                  <div className="td-content">
                    <Link to={`/persons/${user.person?.id}/edit`}>
                      {user.person?.first_name} {user.person?.last_name}
                    </Link>
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.roles')}</p>
                  </div>
                  <div className="td-content">{user.roles.map((role) => role.name).join(', ')}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.login')}</p>
                  </div>
                  <div className="td-content">{user.login}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.registered_at')}</p>
                  </div>
                  <div className="td-content">
                    {moment(new Date(user.registered_at * 1000)).format('DD.MM.YYYY HH.mm.ss')}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.categories')}</p>
                  </div>
                  <div className="td-content">
                    {user.categories.map((category) => category.title)}{' '}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {user.is_active ? __('general.table.active') : __('general.table.inactive')}{' '}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('users.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/users/${user.id}/edit`}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      {/* <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={handleUserDelete(user.id)}
                      variant="contained">
                      <DeleteIcon />
                    </IconButton> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
