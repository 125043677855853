import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';

export function YourData({ visit, setVisit, validVisit }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', pt: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 4
        }}>
        <b>Ім'я клієнта</b>
        <TextField
          value={visit.first_name}
          onChange={(e) => setVisit({ ...visit, first_name: e.target.value })}
          label="Введіть ім'я клієнта"
          required
          InputLabelProps={{ shrink: true }}
          error={!validVisit.first_name}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
        <b>Прізвище клієнта</b>
        <TextField
          value={visit.last_name}
          onChange={(e) => setVisit({ ...visit, last_name: e.target.value })}
          label="Введіть прізвище клієнта"
          required
          InputLabelProps={{ shrink: true }}
          error={!validVisit.last_name}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, justifyContent: 'space-between' }}>
        <b>Телефон</b>
        <Box sx={{ display: 'flex' }}>
          <PhoneInput
            isValid={validVisit.phone}
            fullWidth
            placeholder={'Телефон'}
            name="login"
            id="number"
            type="number"
            country={'ua'}
            preferredCountries={['ua', 'ru', 'us']}
            masks={{ ua: '(..) ...-..-..' }}
            value={visit.phone}
            onChange={(e) =>
              setVisit({
                ...visit,
                phone: e.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5')
              })
            }
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 4 }}>
        <b>Email</b>
        <TextField
          value={visit.email}
          onChange={(e) => setVisit({ ...visit, email: e.target.value })}
          label="Введіть email клієнта"
          required
          InputLabelProps={{ shrink: true }}
          error={!validVisit.email}
        />
      </Box>
    </Box>
  );
}
