import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { getClient } from '../../../../actions';
import styles from './clientCard.module.scss';

import { useLocation } from 'react-router-dom';
import { useTranslation } from './../../../../helpers/translate';
import { TableVerticalSimple } from './../../../common/TableComponent';
import { getClientCardVerticalTableData } from '../TablesData';

export const ClientCard = () => {
  const { __ } = useTranslation();
  const location = useLocation();
  const clientId = location?.state?.id;
  const { form } = styles;

  const [ loaded, setLoaded ] = useState(false);

  const [ client, setClient ] = useState({});

  useEffect(() => {
    (async () => {
      const res = await getClient( clientId )

      setClient( res.data.data );
      setLoaded( true );
    })()
    
  }, [])

  if( !loaded ){
    return(
      <div className={ form }>
        <CircularProgress />
      </div>
    );
  }

  return(

    <div className={ form }>
            
      <div className="title">
        <h3>Карточка кліента</h3>
      </div>

      <TableVerticalSimple
        data={client}
        getVerticalTableData={ getClientCardVerticalTableData } 
        __={__}
      />
    </div>
  );

}
