export const flatingLocaleData = (data = {}) => {
  const localeData = data.locale;
  if (!localeData) {
    return {}
  }

  const flatLocaleData = {};

  for (let locale in localeData) {
    const localeValue = localeData[locale];
    if (!localeValue) {
      continue;
    }

    for (let field in localeValue) {
      const fieldValue = localeValue[field];
      if (!fieldValue) {
        continue;
      }

      flatLocaleData[`locale[${ locale }][${ field }]`] = fieldValue;
    }
  }

  return flatLocaleData;
}