import axios from '../../helpers/private.axios';

export const getClientCertificates = (
  id,
  currentPage,
  itemsPerPage,
) => {
  const params = {};

  params.page = currentPage;
  params.limit = itemsPerPage;

  return axios.get(`/clients/certificates/${id}`, {params});
};
