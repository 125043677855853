import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';
import {
  CircularProgress,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Switch,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';

import { getOrderIncomings, deleteOrderIncomings, getContractors } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ButtonIcon, ButtonLink, ButtonLinkIcon, ToggleShowActive } from './../../common/FormsComponent';
import { rolesComponentConfig } from './rolesComponentConfig';
import dayjs from 'dayjs';

export const OrderIncomingsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data, contractors }, setData] = useState({
    loaded: false,
    data: [],
    contractors: []
  });

  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);
  //const [contractorFilter, setContractorFilter] = useState();

  const handleOrderIncomingsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteOrderIncomings(id).then(async () => {
        const { data: response } = await getOrderIncomings(
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getOrderIncomings(
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );
        const { data: responseContractors } = await getContractors();

        setData({
          loaded: true,
          data: response.data,
          contractors: responseContractors.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [searchTitle, currentPage, itemsPerPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('orderIncomings.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { pr: '40px' },
                '.MuiOutlinedInput-root': { width: '100%' }
              }}
              label={__('orderIncomings.filter.title')}
              name="order-incomings-title-filter"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />

            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
        </div>

        <div className="table-action__block">

          <ButtonLink
            text={ __('create.button.title') }
            route="create"
            checkRoles={ rolesComponentConfig }
            permission={ PERMISSIONS.ACTIONS.CREATE }
          />

        </div>
      </div>
      <div className="table-block">
        <div className="table table--order-incomings">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.order_num')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.date_create')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.date_writeoff')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.contractor')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.date_order')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.cost')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.person')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.store')}</p>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <p>{__('orderIncomings.table.is_active')}</p>
                </div>
              </div> */}
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('orderIncomings.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((orderIncomings) => {
              const isEditable = !!(orderIncomings.type === 0 && !orderIncomings.turnover_completed);
              return (
              <div className="tr" key={orderIncomings.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.order_num')}</p>
                  </div>
                  <div className="td-content">{orderIncomings.order_num}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.date_create')}</p>
                  </div>
                  <div className="td-content">
                    {dayjs(orderIncomings.created_at).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.date_writeoff')}</p>
                  </div>
                  <div className="td-content">
                    {dayjs(orderIncomings.updated_at).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.contractor')}</p>
                  </div>
                  <div className="td-content">{orderIncomings.contractor.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.date_order')}</p>
                  </div>
                  <div className="td-content">{orderIncomings.date_order}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.cost')}</p>
                  </div>
                  <div className="td-content">{orderIncomings.cost}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.person')}</p>
                  </div>
                  <div className="td-content">
                    <p>
                      {orderIncomings.person.last_name} {orderIncomings.person.first_name}
                    </p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.store')}</p>
                  </div>
                  <div className="td-content">{orderIncomings?.store?.title}</div>
                </div>
                {/*   <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {orderIncomings.is_active
                      ? __('general.table.active')
                      : __('general.table.inactive')}
                  </div>
                </div> */}
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('orderIncomings.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">

                      <ButtonLinkIcon 
                        icon={ <PageviewIcon /> }
                        route={ `/order-incomings/${orderIncomings.id}` }
                        checkRoles={ rolesComponentConfig }
                        permission={PERMISSIONS.ACTIONS.READ}
                      />

                      {
                        isEditable && 
                          <>
                            <ButtonLinkIcon 
                              icon={ <EditIcon /> }
                              route={ `/order-incomings/${orderIncomings.id}/edit` }
                              checkRoles={ rolesComponentConfig }
                              permission={PERMISSIONS.ACTIONS.UPDATE}
                            />
                            <ButtonIcon 
                              icon={ <DeleteIcon /> } 
                              onClick={ handleOrderIncomingsDelete(orderIncomings.id) }
                              checkRoles={ rolesComponentConfig }
                              permission={PERMISSIONS.ACTIONS.DELETE}
                            />
                          </>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
