import { getDateValidOrder } from "./../../../library";
import dayjs from "dayjs"

export const getRowCellsData = (dataItem, __) => {

  let dateDiff = 0;
  const now = dayjs();
  const dateStart = dataItem && 
    dataItem.date_start ? dayjs(dataItem.date_start).format('YYYY-MM-DD') : '';
  
  const dateEnd = dataItem && 
  dataItem.date_end ? dayjs(dataItem.date_end).format('YYYY-MM-DD') : '';

  if ( dateStart && dateEnd) {
    dateDiff = dayjs(dateEnd).diff(dayjs(now), 'days');
    if (dateDiff < 0) {
      dateDiff = 0;
    }
  }

  const validOrder = getDateValidOrder(dayjs(dateEnd));
  const validOrderText = (validOrder && dataItem?.quantity_hours_left)
    ? __('certificates.table.valid')
    : __('certificates.table.notvalid')

  const allowedDays = dataItem?.allowed_days ? dataItem?.allowed_days?.map(day => day.title).join(',') : '';
  
  return {
    number: {
      th: 'Номер',
      td: dataItem && dataItem.title,
      sort: null,
    },
    title: {
      th: 'Назва',
      td: dataItem && dataItem.description,
      sort: null,
    },
    Category: {
      th: 'Категорія',
      td: dataItem && dataItem.category?.title,
      sort: null,
    },
    service: {
      th: 'Послуга',
      td: dataItem && dataItem.service.title,
      sort: null,
    },
    qtyHours: {
      th: 'Кількість годин (всього)',
      td: dataItem && dataItem.quantity_hours,
      sort: null,
    },
    lastHours: {
      th: 'Кількість годин (залишилось)',
      td: dataItem && dataItem.quantity_hours_left,
      sort: null,
    },
    dateOfStart: {
      th: 'Дата початку',
      td: dataItem && dateStart,
      sort: null,
    },
    dateOfEnd: {
      th: 'Дата кінця',
      td: dataItem && dateEnd,
      sort: null,
    },
    dayCount: {
      th: 'Термін дії (днів до закінчення)',
      td: dataItem && dateDiff,
      sort: null,
    },
    allowedDays: {
      th: 'Доступні дні',
      td: dataItem && allowedDays,
      sort: null,
    },
    isValidOrder: {
      th: 'Діючий/Недіючий',
      td: dataItem && validOrderText,
      sort: null,
    },

    // discountAmount: {
    //   th: 'Знижка к-ть',
    //   td: dataItem && dataItem.discount_amount,
    //   sort: null,
    // },

    // discountPercent: {
    //   th: 'Знижка %',
    //   td: dataItem && dataItem.discount_percent,
    //   sort: null,
    // },

    // action: {
    //   th: 'Перезамовити',
    //   td: null,
    //   sort: null,
    //   __class: 'td--right',
    //   link: dataItem && {
    //     url: `/order-${orderType}/${docOrderId}`,
    //     state: null
    //   },
    // },
  }
}