import { getVisit } from './../../../../actions/visit/visitRoutes';
import { ClientVisitsHistory } from './../../../pages';
import { useEffect, useState } from 'react';

export default function VisitHistory({ extendedProps }) {
  const [visit, setVisit] = useState({});
  const [load, setLoad] = useState(false);

  const visitId = extendedProps.visit?.id;

  useEffect(() => {
    if (!visitId) {
      return;
    }

    (async () => {
      const res = await getVisit(visitId);
      const data = res?.data?.data;
      setLoad(true);
      setVisit(data);
    })()

  }, []);

  return (
    load && <ClientVisitsHistory clientId={ visit?.client_id }/>
  );
}
