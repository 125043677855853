import { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';

import {
  getOrderOutcomingsId,
  copyOrderOutcomings,
  applyOrderOutcomings
} from '../../../actions/orderOutcomings';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { OrderOutcomingsForm } from './orderOutcomingsForm';

import dayjs from 'dayjs';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 960,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const OrderOutcomingsCopy = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/order-outcomings');
  };

  const [loaded, setLoaded] = useState(false);

  const [orderOutcomings, setOrderOutcomings] = useState({
    order_num: '',
    person_id: '',
    subdivision_id: '',
    store_id: '',
    reserve: '',
    date_order: '',
    autoorder: '',
    items: []
  });

  const [items, setItems] = useState({});
  const [itemsPush, setItemsPush] = useState([]);

  const updateItemСount = (newItems) => {
    setItems(newItems);

    const itemsArray = Object.entries(newItems).map(([inventory_item_id, itemData]) => ({
      inventory_item_id: parseInt(inventory_item_id),
      count: itemData.count,
      price: itemData.price,
      id: itemData.id
    }));

    setOrderOutcomings((prevOrderOutcomings) => ({
      ...prevOrderOutcomings,
      items: itemsArray
    }));

    // !!! ONLY FOR COPY ORDER
    return itemsArray;
  };

  const [validationStatus, setValidationStatus] = useState({
    subdivision_id: { isValid: true, errorMessage: '' },
    store_id: { isValid: true, errorMessage: '' },
    date_order: { isValid: true, errorMessage: '' },
    order_num: { isValid: true, errorMessage: '' },
    inventoryItems: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    if (!orderOutcomings.subdivision_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        subdivision_id: {
          isValid: false,
          errorMessage: `${__('orderOutcomings.form.subdivision_id.error')}`
        }
      }));
    }

    if (!orderOutcomings.store_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        store_id: {
          isValid: false,
          errorMessage: `${__('orderOutcomings.form.store_id.error')}`
        }
      }));
    }

    if (!orderOutcomings.date_order) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        date_order: {
          isValid: false,
          errorMessage: `${__('orderOutcomings.form.date_start.error')}`
        }
      }));
    }

    if (!orderOutcomings.items.length > 0) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        inventoryItems: {
          isValid: false
        }
      }));
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = event.target.checked;
    } else if (name === 'date_order') {
      value = dayjs(event).format('YYYY-MM-DD');
    } else {
      value = event.target.value;
    }

    setOrderOutcomings({
      ...orderOutcomings,
      [name]: value
    });

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('orderOutcomings.form.numbers.error')}`
      }
    }));
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      const orderOutcomingsClone = { ...orderOutcomings };

      // FOR COPY IF NOT ADD NEW TMC
      if (!Object.keys(items).length) {
        orderOutcomingsClone.items = updateItemСount(itemsPush);
      }

      copyOrderOutcomings(orderOutcomingsClone).then((res) => {
        navigate('/order-outcomings');
      });
    }
  };

  const convertItemsToItemCounts = (items) => {
    const itemCounts = {};

    items.forEach((item) => {
      const inventoryItemId = item.inventory_item.id;

      if (!itemCounts[inventoryItemId]) {
        itemCounts[inventoryItemId] = {
          count: parseFloat(item.count),
          price: parseFloat(item.price),
          id: parseFloat(item.id)
        };
      } else {
        itemCounts[inventoryItemId].count += parseFloat(item.count);
        itemCounts[inventoryItemId].price += parseFloat(item.price);
        itemCounts[inventoryItemId].id += parseFloat(item.id);
      }
    });

    return itemCounts;
  };

  useEffect(() => {
    getOrderOutcomingsId(params.id).then((res) => {
      const { ...rest } = res.data.data;
      const savedUserData = JSON.parse(localStorage.getItem('ride_state'));
      setOrderOutcomings({
        ...rest,
        person_id: savedUserData?.auth?.user?.id || '',
        subdivision_id: rest.subdivision?.id || '',
        store_id: rest.store?.id || '',
        order_num: ''
      });
      setItemsPush(convertItemsToItemCounts(rest.items));
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <Modal
        open={true}
        onClose={toggleOpened}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('orderOutcomings.form.title-edit')}</h3>
              </div>
              <OrderOutcomingsForm
                orderOutcomings={orderOutcomings}
                setOrderOutcomings={setOrderOutcomings}
                actionUpdate={handleUpdate}
                handleChange={handleChange}
                ctaButtonText={__('orderOutcomings.form.button')}
                validationStatus={validationStatus}
                updateItemСount={updateItemСount}
                setValidationStatus={setValidationStatus}
                itemsPush={itemsPush}
                formType="copy"
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
