import { Button } from "@mui/material";

export const ButtonAction = ({ text, sx, ...props }) => {
  return (
    <Button
      variant="contained"
      type="submit"
      sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
      {...props}
    >
      { text }
    </Button>
  );
};

ButtonAction.defaultProps = {
  sx: {},
}