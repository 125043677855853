import { useEffect, useState } from 'react';
import { PERMISSIONS } from './../../../constants';
import { Link, Outlet } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import { getHorses, getHorsesStatuses, deleteHorses, getHorsesCategories, getHorsesServices } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ButtonIcon, ButtonLink, ButtonLinkIcon, ToggleShowActive } from './../../common/FormsComponent';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';
import { rolesComponentConfig } from './rolesComponentConfig';

export const HorsesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data, statuses, categories, services }, setData] = useState({
    loaded: false,
    data: [],
    statuses: {},
    categories: {},
    services: [],
  });

  const [searchName, setSearchName] = useState('');
  const [selectedCategory, setSelectedСategory] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [showArchive, setShowArchive] = useState(false);

  const handleSearchTitleChange = (event) => {
    setSearchName(event.target.value);
  };

  const handleСategoryChange = (event) => {
    setSelectedСategory(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getHorses(showArchive);
        const { data: statusesResponse } = await getHorsesStatuses();
        const { data: categoriesResponse } = await getHorsesCategories();
        const { data: servicesResponse } = await getHorsesServices();

        setData({
          loaded: true,
          data: response.data,
          statuses: statusesResponse,
          categories: categoriesResponse,
          services: servicesResponse,
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [showArchive]);

  const handleHorsesDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteHorses(id).then(async () => {
        const { data: response } = await getHorses(showArchive);
        const { data: statusesResponse } = await getHorsesStatuses();
        const { data: categoriesResponse } = await getHorsesCategories();
        const { data: servicesResponse } = await getHorsesServices();
        setData((prevState) => ({
          ...prevState,
          loaded: true,
          data: response.data,
          statuses: statusesResponse,
          categories: categoriesResponse,
          services: servicesResponse
        }));
      });
    }
  };

  const filteredData = data.filter((horses) => {
    const nameMatch = horses.name?.toLowerCase().includes(searchName.toLowerCase());
    const categoryMatch =
      selectedCategory.length === 0 ||
      horses.horsCategories.some((category) => selectedCategory.includes(category.title));
    const statusMatch = selectedStatus === '' || horses.status === selectedStatus;

    return nameMatch && categoryMatch && statusMatch;
  });

  const getStatusLabel = (statusKey, statuses) => {
    for (const [key, value] of Object.entries(statuses)) {
      if (key === statusKey) {
        return value;
      }
    }
    return '';
  };

  return (
    <>
      <div className="title title--mb36">{__('horses.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { pr: '40px' },
                '.MuiOutlinedInput-root': { width: '100%' }
              }}
              label={__('horses.search.name')}
              value={searchName}
              onChange={handleSearchTitleChange}
            />
            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
          <div className="input-wrap">
            <FormControl
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { boxSizing: 'border-box' }
              }}>
              <InputLabel>{__('horses.filter.select.categories')}</InputLabel>
              <Select
                sx={{ width: '100%', display: 'block' }}
                label={__('horses.filter.select.categories')}
                multiple
                value={selectedCategory}
                onChange={handleСategoryChange}
                renderValue={(selected) => selected.join(', ')}>
                {categories.data &&
                  categories.data.map((category) => (
                    <MenuItem key={category.id} value={category.title}>
                      <Checkbox checked={selectedCategory.includes(category.title)} />
                      <ListItemText primary={category.title} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div className="input-wrap">
            <FormControl
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { boxSizing: 'border-box' }
              }}>
              <InputLabel>{__('horses.filter.select.status')}</InputLabel>
              <Select
                sx={{ width: '100%', display: 'block' }}
                label={__('horses.filter.select.status')}
                value={selectedStatus}
                onChange={handleStatusChange}>
                <MenuItem value="">{__('horses.filter.select.status.all')}</MenuItem>
                {Object.entries(statuses).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="table-action__block">
          <div className="input-wrap">
            <ToggleShowActive value={showArchive} onChange={() => setShowArchive(prev => !prev)} />
          </div>
          <ButtonLink
            text={__('create.button.title')}
            route="create"
            checkRoles={ rolesComponentConfig }
            permission={PERMISSIONS.ACTIONS.CREATE}
          />
        </div>
      </div>
      <div className="table-block">
        <div className="table table--horses">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.name')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.image')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.passport')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.сategories')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.services')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.date_of_birth')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.status')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('horses.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('horses.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {filteredData.map((horses) => (
              <div className="tr" key={horses.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.name')}</p>
                  </div>
                  <div className="td-content">{horses.name}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.image')}</p>
                  </div>
                  <div className="td-content">
                    <img src={ horses.image || noImage } alt="" />
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.passport')}</p>
                  </div>
                  <div className="td-content">{horses.passport}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.сategories')}</p>
                  </div>
                  <div className="td-content td-content-">
                    <p>{horses.horsCategories.map((category) => category.title).join(', ')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.services')}</p>
                  </div>
                  <div className="td-content td-content-">
                    <p>{horses.services.map((service) => service.title).join(', ')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.date_of_birth')}</p>
                  </div>
                  <div className="td-content">{horses.date_of_birth}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.status')}</p>
                  </div>
                  <div className="td-content">{getStatusLabel(horses.status, statuses)}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {' '}
                    <div className="td-content">
                      {horses.is_active ? __('general.table.active') : __('general.table.inactive')}
                    </div>
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('horses.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">

                      <ButtonLinkIcon 
                        icon={ <EditIcon /> }
                        route={ `/horses/${horses.id}/edit` }
                        checkRoles={ rolesComponentConfig }
                        permission={PERMISSIONS.ACTIONS.UPDATE}
                      />
                      <ButtonIcon 
                        icon={ <DeleteIcon /> } 
                        onClick={ handleHorsesDelete(horses.id) }
                        checkRoles={ rolesComponentConfig }
                        permission={PERMISSIONS.ACTIONS.DELETE}
                      />
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
