import axios from '../../helpers/private.axios';

export const createPage = (data) => {
    return axios.post(`/pages`,data, 
    { 
      headers: { 'Content-Type': 'mulipart/form-data' },
    },
   )
    .then((res) => res)
    .catch(error => error);
};
