const seasonTicketsCategories = {
    uk: {
        "seasonTicketsCategories.page.title": "Категорії абонементів",
        "seasonTicketsCategories.table.title": "Назва",
        "seasonTicketsCategories.table.description": "Опис",
        "seasonTicketsCategories.table.is_active": "Активний/Неактивний",
        "seasonTicketsCategories.table.actions": "Дії",
        "seasonTicketsCategories.form.title-create": "Створити категорію абонементів",
        "seasonTicketsCategories.form.title-edit": "Редагувати категорію абонементів",
        "seasonTicketsCategories.form.button": "Зберегти",
        "seasonTicketsCategories.form.is_active": "Активний/Неактивний",

        "seasonTicketsCategories.form.title.error": "Поле обов'язкове до заповнення",
        "seasonTicketsCategories.form.description.error": "Поле обов'язкове до заповнення",
    },
    en: {
        "seasonTicketsCategories.page.title": "Season Ticket Categories",
        "seasonTicketsCategories.table.title": "Name",
        "seasonTicketsCategories.table.description": "Description",
        "seasonTicketsCategories.table.is_active": "Active/Inactive",
        "seasonTicketsCategories.table.actions": "Actions",
        "seasonTicketsCategories.form.title-create": "Create Season Ticket Category",
        "seasonTicketsCategories.form.title-edit": "Edit Season Ticket Category",
        "seasonTicketsCategories.form.button": "Save",
        "seasonTicketsCategories.form.is_active": "Active/Inactive",

        "seasonTicketsCategories.form.title.error": "Requiered field",
        "seasonTicketsCategories.form.description.error": "Requiered field",
    },
    ru: {
        "seasonTicketsCategories.page.title": "Категории абонементов",
        "seasonTicketsCategories.table.title": "Название",
        "seasonTicketsCategories.table.description": "Описание",
        "seasonTicketsCategories.table.is_active": "Активный/Неактивный",
        "seasonTicketsCategories.table.actions": "Действия",
        "seasonTicketsCategories.form.title-create": "Создать категорию абонементов",
        "seasonTicketsCategories.form.title-edit": "Редактировать категорию абонементов",
        "seasonTicketsCategories.form.button": "Сохранить",
        "seasonTicketsCategories.form.is_active": "Активный/Неактивный",

        "seasonTicketsCategories.form.title.error": "Поле обязательное для заполнения",
        "seasonTicketsCategories.form.description.error": "Поле обязательное для заполнения",
    }

}

export default seasonTicketsCategories;