import { SYSTEM_MODES } from '../../constants';
import { getHorse, getHorses, getHorsesByService } from '../../actions/horses';
import { getCoach, getCoachByService, getCoaches } from '../../actions/coaches';

const ABS_PATH = window.location.origin;

export const singleModelActions = {
  [SYSTEM_MODES.HORSE]: getHorse,
  [SYSTEM_MODES.COACH]: getCoach,
}

export const modelActions = {
  [SYSTEM_MODES.HORSE]: getHorses,
  [SYSTEM_MODES.COACH]: getCoaches,
}

export const modelActionsByService = {
  [SYSTEM_MODES.HORSE]: getHorsesByService,
  [SYSTEM_MODES.COACH]: getCoachByService,
}

export const modelSelect = {
  [SYSTEM_MODES.HORSE]: 'Кінь',
  [SYSTEM_MODES.COACH]: 'Тренер',
}

export const modelTitle = {
  [SYSTEM_MODES.HORSE]: {
    selectModel: 'Вибрати коня',
    noModel: 'Коней поки немає',
    anyModel: 'booking.horse.any-horse',
  },
  [SYSTEM_MODES.COACH]: {
    selectModel: 'Вибрати тренера',
    noModel: 'Тренерів поки немає',
    anyModel: 'booking.coach.any-coach',
  }
}

export const modelDefaultAvatar = {
  [SYSTEM_MODES.HORSE]: ABS_PATH + '/images/avatar/horse.png',
  [SYSTEM_MODES.COACH]: ABS_PATH + '/images/avatar/jockey.png',
}

export const getModelName = (model, modelType, horses) => {
  const selectedCoach = horses?.find(coach => coach.id === model.id);

  const coachFirstName = model.first_name ? model.first_name : selectedCoach?.first_name;
  const coachLastName = model.last_name ? model.last_name : selectedCoach?.last_name;

  const modelName = modelType === SYSTEM_MODES.HORSE ? model.name || '' : `${coachFirstName || ''} ${coachLastName || ''}`;

  return modelName;
}