import { formatDataForHistoryCollaps } from "../utils";
import { getPaymentInfoId } from "./../../../../actions";
import { statuses } from './statuses';

export const getRowCellsData = (dataItem, __) => {
  const externalUser = dataItem?.external_first_name
    ? `${dataItem?.external_first_name} ${dataItem?.external_last_name} ( ${dataItem?.external_phone} )`
    : '';

  const paidStatus = dataItem?.status !== undefined 
    ? statuses.find(status => status.id === dataItem.status).title
    : ''

  return {
    firstName: {
      th: "Ім'я",
      td: dataItem && dataItem.first_name,
      sort: null,
    },
    lastName: {
      th: 'Прізвище',
      td: dataItem && dataItem.last_name,
      sort: null,
    },
    phone: {
      th: 'Телефон',
      td: dataItem && dataItem.phone,
      sort: null,
    },
    amount: {
      th: 'Сума',
      td: dataItem && dataItem.amount_paid,
      sort: null,
    },
    externalUser: {
      th: 'Замовлення на іншу особу',
      td: dataItem && externalUser,
      sort: null,
    },
    paidDate: {
      th: 'Дата оплати',
      td: dataItem && dataItem.date_paid,
      sort: null,
    },
    status: {
      th: 'Статус оплати',
      td: dataItem && paidStatus,
      sort: null,
    },
    
    
    __GET_COLLAPS_DATA_ACTION: dataItem && getPaymentInfoId.bind(null, dataItem.id),
    __FORMAT_COLLAPS_DATA_CALLBACK: dataItem && formatDataForHistoryCollaps
  }
}