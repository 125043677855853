import { LOCALE } from '../../constants';
import config from '../../config'

const initState = {
  locale: config.default_locale
};

const localeReducer = (state = initState, action) => {
  switch (action.type) {
    case LOCALE.CHANGE_LOCALE:
      return {
        ...state,
        locale: action.payload
      };

    default:
      return state;
  }
};

export default localeReducer;
