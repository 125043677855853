import { Checkbox } from "@mui/material";
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

export const getRowCellsData = (dataItem, __) => {
  const fullName = dataItem && `${dataItem.first_name} ${dataItem.last_name}`;
  const services = dataItem && dataItem.services.map(service => service.title).join(';');

  return {
    fullName: {
      th: 'Тренер',
      td: dataItem && fullName,
      sort: null,
    },
    photo: {
      th: 'Фото',
      td: dataItem && <img src={ dataItem.image || noImage } alt="" />,
      sort: null,
    },
    experience: {
      th: 'Досвід',
      td: dataItem && dataItem.experience,
      sort: null,
    },
    jobStart: {
      th: 'Дата працевлаштування',
      td: dataItem && dataItem.job_start,
      sort: null,
    },
    services: {
      th: 'Послуги',
      td: dataItem && services,
      sort: null,
    },
    services: {
      th: 'Статус',
      td: dataItem && dataItem.status,
      sort: null,
    },
    status: {
      th: 'Активний / Неактивний',
      td: dataItem && dataItem.is_active ? 'активний' : 'неактивний',
      sort: null,
    },
  }
}