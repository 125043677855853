import {
  ruleClientGroup,
  ruleClientStatus,
  ruleDiscountPercent, 
} from "../validation/validationRules";

export const clientModel = {
  client_status_id: '',
  client_group_id: '',
  discount_percent: 0,
}

export const clientValidationModel = {
  client_status_id: ruleClientStatus,
  client_group_id: ruleClientGroup,
  discount_percent: ruleDiscountPercent,
}