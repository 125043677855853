import { Button } from '@mui/material';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

export const HorsesCategoriesForm = ({
  horsesCategories,
  setHorsesCategories,
  handleLocalizationChange,
  action,
  ctaButtonText,
  validationStatus
}) => {

  return (
    <>
      <LocaleTabs 
        data={ horsesCategories }
        setData={ setHorsesCategories }
        dataName={ 'horsesCategories' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />
      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
