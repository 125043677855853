import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  createClient,
  getClientEdit,
  getClientsGroups,
  getClientsStatuses,
  updateClient
} from './../../../../actions';
import { useTranslation } from './../../../../helpers/translate';

import { ClientForm } from './ClientForm';
import { ModalForm } from './../../../common/FormsComponent';

import { getDataCloneForSend, getHandleChange, handleActionResponse } from './utils';
import { clientModel, clientValidationModel } from './dataModels';
import { validateForm, validationModel } from './validation';

export const ClientEdit = () => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { state } = useLocation();
  const { action: formType, backRoutes } = state;
  const formTitle = formType === 'create' ? 'Створити клієнта' : 'Редагувати клієнта';

  const toggleOpened = () => {
    navigate('/clients');
  };

  const [loaded, setLoaded] = useState(false);
  const [client, setClient] = useState(clientModel);
  const [statuses, setStatuses] = useState([]);
  const [groups, setGroups] = useState([]);

  const [validationStatus, setValidationStatus] = useState(validationModel);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleChange = getHandleChange(client, setClient, setValidationStatus);

  const handleAction = () => {
    const isValid = validateForm(client, clientValidationModel, setValidationStatus, 'clients', __);
    if (!isValid) {
      return;
    }

    const clientClone = getDataCloneForSend(client);

    try {
      if (formType === 'edit') {
        updateClient(params.id, clientClone).then((res) => {
          handleActionResponse(res, navigate, '/clients', setErrorMessages);
        });
      } else if (formType === 'create') {
        clientClone.first_name = clientClone.user?.user?.first_name;
        clientClone.last_name = clientClone.user?.user?.last_name;
        clientClone.phone = clientClone.user?.user?.phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2-$3-$4");
        clientClone.phone = clientClone.user?.user?.phoneCode + ' ' + clientClone.phone;

        createClient(clientClone).then((res) => {
          handleActionResponse(res, navigate, '/clients', setErrorMessages);
        });
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    (async () => {
      const { data: statusesResponse } = await getClientsStatuses();
      const { data: groupsResponse } = await getClientsGroups();

      if (formType !== 'edit') {
        setLoaded(true);
        setClient(clientModel);
        setStatuses(statusesResponse.data);
        setGroups(groupsResponse.data);
        return;
      }

      const { data: clientResponse } = await getClientEdit(params.id);
      const clientData = clientResponse?.data;
      setLoaded(true);
      setClient({
        description: '',
        person: { ...clientData?.person, gender: 'male' },
        discount_percent: clientData.discount_percent,
        client_group_id: clientData?.group?.client_group_id,
        client_status_id: clientData?.status?.client_status_id
      });
      setStatuses(statusesResponse.data);
      setGroups(groupsResponse.data);
    })();
  }, [formType, params.id]);

  return (
    <ModalForm title={formTitle} open={true} loaded={loaded} onClose={toggleOpened} closeBtn>
      <ClientForm
        client={client}
        statuses={statuses}
        groups={groups}
        action={handleAction}
        handleChange={handleChange}
        ctaButtonText="Зберегти"
        validationStatus={validationStatus}
        formType={formType}
      />
    </ModalForm>
  );
};
