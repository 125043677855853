import { Box, Typography } from "@mui/material";
import { flexBoxCenter } from './../assets/styles';

export const BoxHeader = ({ fileParams }) => {
  const { width, height, size } = fileParams;
  return (
    <Box sx={{mb: 1, ...flexBoxCenter }}>
      <Typography sx={{ mr: 3 }} variant="body2">Max size: <b>{ size }Kb</b></Typography>
      <Typography variant="body2">Max resolution: <b>{ width } x { height }</b></Typography>
    </Box>
  );
};