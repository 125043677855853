import { useEffect, useState } from 'react';

import { getHorsesId, updateHorses } from '../../../actions/';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { HorsesForm } from './HorsesForm';
import { ModalForm, serverErrorHandler } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

import { getFormData } from './../../library';

import { getDataCloneForSend, getHandleChange } from './utils';
import { horsesModel, horsesValidationModel } from './dataModels';
import { validateForm, validationModel } from './validation';

export const HorsesEdit = () => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/horses');
  };

  const [loaded, setLoaded] = useState(false);
  const [horses, setHorses] = useState(horsesModel);

  const [validationStatus, setValidationStatus] = useState(validationModel);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleLocalizationChange = getHandleLocalizationChange(
    __('horses.form.name.error'),
    setHorses,
    setValidationStatus
  );
  const handleChange = getHandleChange(horses, setHorses, setValidationStatus);

  const handleUpdate = () => {
    const isValid = validateForm(
      horses,
      horsesValidationModel,
      setValidationStatus,
      'horses',
      __,
      true,
      requiredLocaleValidationFields
    );
    if (isValid) {
      const horsesClone = getDataCloneForSend(horses);

      try {
        updateHorses(params.id, getFormData(horsesClone, 'PUT', true)).then((res) => {
          if (res?.data?.success) {
            navigate('/horses');
          }

          serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
        });
      } catch (error) {
      }
    }
  };

  useEffect(() => {
    getHorsesId(params.id).then((res) => {
      const horseData = res.data.data;
      horseData.horsCategories = res.data.data.horsCategories?.map((horse) => horse.id);
      horseData.services = res.data.data.services?.map((horse) => horse.id);
      setHorses(horseData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('horses.form.title-edit')}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      closeBtn
    >
      <HorsesForm
        horses={horses}
        setHorses={setHorses}
        action={handleUpdate}
        handleChange={handleChange}
        selectedCategories={horses.categories}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('horses.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
