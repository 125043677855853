import { useEffect, useState } from 'react';

import { getCertificatesId, updateCertificates } from '../../../../../actions';
import { useTranslation } from '../../../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { CertificatesForm } from './CertificatesForm';
import { ModalForm } from '../../../../common/FormsComponent';
import dayjs from 'dayjs';

export const CertificatesEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const basePageUrl = '/clients/loyality/certificates';

  const toggleOpened = () => {
    navigate(basePageUrl);
  };

  const [loaded, setLoaded] = useState(false);

  const [certificates, setCertificates] = useState({
    title: '',
    description: '',
    category_id: '',
    quantity_hours: '',
    discount_amount: '',
    discount_percent: '',
    date_start: '',
    date_end: '',
    is_active: true
  });

  const handleChange = (name) => (event) => {
    let value = event?.target ? event.target.value : event;
    if (name === 'is_active') {
      value = event?.target.checked;
    }

    setCertificates((prevCertificates) => {
      let updatedCertificates = {
        ...prevCertificates,
        [name]: value
      };

      switch (name) {
        case 'quantity_hours':
          return {
            ...updatedCertificates,
            discount_amount: '',
            discount_percent: ''
          };
        case 'discount_amount':
          return {
            ...updatedCertificates,
            quantity_hours: '',
            discount_percent: ''
          };
        case 'discount_percent':
          return {
            ...updatedCertificates,
            quantity_hours: '',
            discount_amount: ''
          };
        case 'date_start':
          updatedCertificates.date_start = dayjs(value).format('YYYY-MM-DD');
          return updatedCertificates;
        case 'date_end':
          updatedCertificates.date_end = dayjs(value).format('YYYY-MM-DD');
          return updatedCertificates;
        case 'category_id':
          updatedCertificates.category_id = value;
          return updatedCertificates;
        case 'service_id':
          updatedCertificates.service_id = value;
          return updatedCertificates;
        case 'user':
          updatedCertificates.user_id = value.user.id;
          updatedCertificates.user = value.user;
          return updatedCertificates;
        default:
          return updatedCertificates;
      }
    });
  };

  const handleUpdate = () => {
    const certificatesClone = { ...certificates };
    certificatesClone.date_start = dayjs(certificatesClone.date_start).format('YYYY-MM-DD');
    certificatesClone.date_end = dayjs(certificatesClone.date_end).format('YYYY-MM-DD');
    updateCertificates(params.id, certificatesClone).then((res) => {
      navigate(basePageUrl);
    });
  };

  useEffect(() => {
    getCertificatesId(params.id).then((res) => {
      const data = res.data.data;
      const certificatesData = res.data.data;

      certificatesData.category_id = data.category.category_id;
      certificatesData.certificatesCategories = data.certificatesData?.map(
        (certificates) => certificates.id
      );
      certificatesData.service_id = data.service.service_id;
      certificatesData.user_id = data.user_id;
      setCertificates(certificatesData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      loaded={loaded}
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="inside">
        <div className="create-edit-form">
          <div className="title">
            <h3>{__('certificates.form.title-edit')}</h3>
          </div>
          <CertificatesForm
            userEditDisabled={true}
            certificates={certificates}
            action={handleUpdate}
            handleChange={handleChange}
            ctaButtonText={__('certificates.form.button')}
          />
        </div>
      </div>
    </ModalForm>
  );
};
