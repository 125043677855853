import { serverErrorHandler } from "../../../common/FormsComponent";

export const makePaymentQuery = async (
  data,
  paymentAction, 
  setOpen,
  setErrorMessages,
  queryType = { type: 'SEASONTICKETS', param: '', paymentParams: null }
) => {
  try {
    let sendData = data;
    let paymentType = 'ONLINE';

    if (queryType.type === 'BOOKING') {
      sendData = new FormData();
      sendData.append(queryType.param, data);

      if (queryType.paymentParams) {
        const { method, data } = queryType.paymentParams;
        const { id, allowedDay } = data;
        sendData.append(method, id);
        paymentType = 'SEASONTICKETS';
      }
    }

    const res = await paymentAction(sendData);
    const errors = res?.response?.data?.errors;
    const success = res?.data.success;
    const token = res?.headers?.authorization;
    let paymentUrl = '';

    console.log(success);

    if (!success) {
      return { 
        paymentPlacePageUrl:null, 
        paymentType: null,
        token: null,
        errorMessages: res?.data.message,
      };
    }

    if (errors) {
      
      return { 
        paymentPlacePageUrl:null, 
        paymentType: null,
        token: null,
        errorMessages: errors,
      };
    }

    if (res?.data?.data) {

      if (paymentType === 'ONLINE') {
        paymentUrl = res?.data?.data?.url;
        paymentUrl = decodeURIComponent(paymentUrl);
      }

      setOpen(true);
      localStorage.removeItem('selectedBookingItems');
    }

    return { 
      paymentPlacePageUrl: paymentUrl, 
      paymentType, 
      token, 
    };
  }
  catch(error) {
  }
}