import { getValidationLocaleModel } from "./getValidationLocaleModel";
import { validateLocale } from "./validateLocale";

export const validateAllLocales = (data, dataName, setValidationStatus, requiredLocaleValidationFields, __) => {
  let isValid = true;
  const validationLocaleModel = getValidationLocaleModel(requiredLocaleValidationFields);

  for (let locale in validationLocaleModel) {
    let localeValid = true;
    const localeData = validationLocaleModel[locale];
    localeValid = validateLocale(locale, localeData, data, dataName, setValidationStatus, __)
    if (!localeValid) {
      isValid = false;
    }
  }

  return isValid;
}