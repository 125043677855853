import axios from '../../helpers/private.axios';

export const getClientVisitsHistory = (
  id,
  currentPage,
) => {
  const params = {};

  params.page = currentPage;

  return axios.get(`/clients/visits/${id}/`, {params});
};
