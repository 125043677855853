import { getFormatedPhone } from './../../components/library';
import axios from '../../helpers/private.axios';

export const getPersons = (
  searchLastName,
  loginFilter,
  currentPage,
  itemsPerPage
) => {

  const formattedPhoneNumber = getFormatedPhone(loginFilter)
  const params = {};
  
  params.status = 'all';

  if (searchLastName) {
      params['last_name'] = searchLastName;
  }

  if (loginFilter) {
      params['login'] = formattedPhoneNumber;
  }

  params.page = currentPage;
  params.limit = itemsPerPage;

  return axios.get(`/persons`, { params });
};

