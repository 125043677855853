import axios from '../../helpers/private.axios';

export const searchClients = (phone, firstName, lastName) => {
  const params = {}

  if (phone) {
    params.phone = '+380 ' + phone;
  }
  if (firstName && firstName.length >= 3 ) {
    params.first_name = firstName;
  }
  if (lastName && lastName.length >= 3 ) {
    params.last_name = lastName;
  }

  return axios
    .get(`/users/live-search`, { params })
    .then(res => res)
    .catch((error => error))
};

export const createVisit = (visit) => {
  return axios.post(`/visits/`, visit)
    .then(res => res)
    .catch(error => error);
};

export const editVisit = (id, visit) => {
  return axios.post(`/visits/${id}`, { ...visit, _method: 'PUT' })
    .then(res => res)
    .catch(error => error);
};

export const getVisits = () => {
  return axios.get(`/visits/`);
};

// !!!Dmitry
export const getVisitsShedule = (date, period) => {
  return axios.get(`/visits/schedule?date=${date}&period=${period}`);
}

export const getVisit = (id) => {
  return axios.get(`/visits/${id}`)
    .then(res => res)
    .catch(error => error)
};
