import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visitStatuses } from '../visitStatuses';

export default function Statuses({ status, setStatus, value, setValue }) {
  const handleChange = (event, newValue) => {
    value.created_at ? setValue((prev) => ({ ...prev, status_id: newValue })) : setStatus(newValue);
  };
  const editStatus = value.created_at ? value.status_id : status;

  const tabStyles = {
    '@media screen and (max-width: 920px)': {
      width: '50%',
      justifyContent: 'flex-start'
    },
    '@media screen and (max-width: 520px)': {
      width: '100%'
    }
  };

  return (
    <Tabs
      value={editStatus}
      onChange={handleChange}
      sx={{
        '.MuiTabs-indicator': {
          backgroundColor: visitStatuses[editStatus].color,
          '@media screen and (max-width: 920px)': {
            display: 'none'
          }
        },
        '.MuiTabs-flexContainer': {
          '@media screen and (max-width: 920px)': {
            flexWrap: 'wrap'
          }
        }
      }}>
      {' '}
      <Tab
        icon={<AccessTimeIcon />}
        iconPosition="start"
        label="Очікування клієнта"
        sx={{
          '&.Mui-selected': { color: editStatus === 0 ? visitStatuses[editStatus].color : '' },
          ...tabStyles
        }}
        /* style={{ color: editStatus === 0 ? 'white' : '' }} */
      />
      <Tab
        icon={<AddCircleOutlineIcon />}
        iconPosition="start"
        label="Клієнт прийшов"
        sx={{
          '&.Mui-selected': { color: editStatus === 1 ? visitStatuses[editStatus].color : '' },
          ...tabStyles
        }}
        /* style={{ color: editStatus === 1 ? 'white' : '' }} */
      />
      <Tab
        icon={<RemoveCircleIcon />}
        iconPosition="start"
        label="Клієнт не прийшов"
        sx={{
          '&.Mui-selected': { color: editStatus === 2 ? visitStatuses[editStatus].color : '' },
          ...tabStyles
        }}
        /*  style={{ color: editStatus === 2 ? 'white' : '' }} */
      />
      <Tab
        icon={<CheckCircleIcon />}
        iconPosition="start"
        label="Клієнт підтвердив"
        sx={{
          '&.Mui-selected': { color: editStatus === 3 ? visitStatuses[editStatus].color : '' },
          ...tabStyles
        }}
        /*  style={{ color: editStatus === 3 ? 'white' : '' }} */
      />
    </Tabs>
  );
}
