import axios from '../../helpers/private.axios';
import { path } from './constants';

export const getModelTimeslotsSchedule = (date, period, modelType) => {
  const params = {
    date: date,
    span: period,
    modelType,
  }
  return axios.get(`/${path}`, { params});
};
