import axios from '../../helpers/private.axios';

export const getCertificates = (
    archive,
    userSearch,
    titleSearch,
    currentPage,
) => {
    const params = {};

    if (archive) {
        params.status = 'archive';
    }

    if (userSearch) {
        params.user = userSearch;
    }

    if (titleSearch) {
        params['search-title'] = titleSearch;
    }

    params.page = currentPage;

    return axios.get(`/certificates/`, { params });
};
