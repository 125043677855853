const expenditureNorms = {
        uk: {
                "expenditureNorms.page.title": "Норми витрати",
                // table
                "expenditureNorms.table.name": "Назва",
                "expenditureNorms.table.created_at": "Дата створення",
                "expenditureNorms.table.total": "Вартість",
                "expenditureNorms.table.horse": "Об'єкт норми",
                "expenditureNorms.table.period": "Період дії",
                "expenditureNorms.table.status": "Активний/Неактивний",
                "expenditureNorms.table.actions": "Дії",
                // table end

                //filter
                "expenditureNorms.filter.title": "Назва",
                "expenditureNorms.filter.archiveShow": "Показати неактивні",
                "expenditureNorms.filter.archiveHide": "Показати активні",
                //filterend

                //form
                "expenditureNorms.form.title-create": "Створити норму витрати",
                "expenditureNorms.form.title-edit": "Редагувати норму витрати",
                "expenditureNorms.form.button": "Зберегти",

                "expenditureNorms.input.name": "*Назва",
                "expenditureNorms.input.horses_id": "*Об'єкт норми",
                "expenditureNorms.input.period": "Період дії",
                "expenditureNorms.input.period.day": "День",
                "expenditureNorms.input.period.week": "Тиждень",
                "expenditureNorms.input.period.month": "Місяць",
                "expenditureNorms.input.period.quarter": "Квартал",
                "expenditureNorms.input.period.year": "Рік",
                "expenditureNorms.input.status": "Активний/Неактивний",

                //form error
                "expenditureNorms.form.name.error": "*Поле є обов'язковим",
                "expenditureNorms.form.horse_id.error": "*Поле є обов'язковим",

                //View list
                "expenditureNormsView.page.title": "Норма витрати",
                "expenditureNormsView.list.name": "Назва",
                "expenditureNormsView.list.created_at": "Дата створення",
                "expenditureNormsView.list.total": "Вартість",
                "expenditureNormsView.list.horse": "Об'єкт норми",
                "expenditureNormsView.list.period": "Період дії",
                "expenditureNormsView.list.status": "Активний/Неактивний",
                "expenditureNormsView.list.actions": "Дії",

                //inventory item list
                "expenditureNormsView.table.inventory_item-title": "Назва",
                "expenditureNormsView.table.quantity": "Кількість",
                "expenditureNormsView.table.unit-title": "Одиниця виміру",
                "expenditureNormsView.table.cost": "Собівартість",
                "expenditureNormsView.table.total": "Вартість позиції",

        },
        en: {
                "expenditureNorms.page.title": "Expenditure Norms",
                "expenditureNorms.table.name": "Name",
                "expenditureNorms.table.created_at": "Created at",
                "expenditureNorms.table.total": "Cost",
                "expenditureNorms.table.horse": "Norm Object",
                "expenditureNorms.table.period": "Duration",
                "expenditureNorms.table.status": "Active/Inactive",
                "expenditureNorms.table.actions": "Actions",
                "expenditureNorms.filter.title": "Name",
                "expenditureNorms.filter.archiveShow": "Show Inactive",
                "expenditureNorms.filter.archiveHide": "Show Active",
                "expenditureNorms.form.title-create": "Create Expenditure Norm",
                "expenditureNorms.form.title-edit": "Edit Expenditure Norm",
                "expenditureNorms.form.button": "Save",
                "expenditureNorms.input.name": "*Name",
                "expenditureNorms.input.horses_id": "*Norm Object",
                "expenditureNorms.input.period": "Duration",
                "expenditureNorms.input.period.day": "Day",
                "expenditureNorms.input.period.week": "Week",
                "expenditureNorms.input.period.month": "Month",
                "expenditureNorms.input.period.quarter": "Quarter",
                "expenditureNorms.input.period.year": "Year",
                "expenditureNorms.input.status": "Active/Inactive",
                "expenditureNorms.form.name.error": "*This field is required",
                "expenditureNorms.form.horse_id.error": "*This field is required",
                "expenditureNormsView.page.title": "Expenditure Norms",
                "expenditureNormsView.list.name": "Name",
                "expenditureNormsView.list.created_at": "Created at",
                "expenditureNormsView.list.total": "Cost",
                "expenditureNormsView.list.horse": "Norm Object",
                "expenditureNormsView.list.period": "Duration",
                "expenditureNormsView.list.status": "Active/Inactive",
                "expenditureNormsView.list.actions": "Actions",
                "expenditureNormsView.table.inventory_item-title": "Name",
                "expenditureNormsView.table.quantity": "Quantity",
                "expenditureNormsView.table.unit-title": "Unit of Measure",
                "expenditureNormsView.table.cost": "Cost Price",
                "expenditureNormsView.table.total": "Position Cost",
        },
        ru: {
                "expenditureNorms.page.title": "Нормы расхода",
                "expenditureNorms.table.name": "Название",
                "expenditureNorms.table.created_at": "Дата создания",
                "expenditureNorms.table.total": "Стоимость",
                "expenditureNorms.table.horse": "Объект нормы",
                "expenditureNorms.table.period": "Период действия",
                "expenditureNorms.table.status": "Активный/Неактивный",
                "expenditureNorms.table.actions": "Действия",
                "expenditureNorms.filter.title": "Название",
                "expenditureNorms.filter.archiveShow": "Показать неактивные",
                "expenditureNorms.filter.archiveHide": "Показать активные",
                "expenditureNorms.form.title-create": "Создать норму расхода",
                "expenditureNorms.form.title-edit": "Редактировать норму расхода",
                "expenditureNorms.form.button": "Сохранить",
                "expenditureNorms.input.name": "*Название",
                "expenditureNorms.input.horses_id": "*Объект нормы",
                "expenditureNorms.input.period": "Период действия",
                "expenditureNorms.input.period.day": "День",
                "expenditureNorms.input.period.week": "Неделя",
                "expenditureNorms.input.period.month": "Месяц",
                "expenditureNorms.input.period.quarter": "Квартал",
                "expenditureNorms.input.period.year": "Год",
                "expenditureNorms.input.status": "Активный/Неактивный",
                "expenditureNorms.form.name.error": "*Поле является обязательным",
                "expenditureNorms.form.horse_id.error": "*Поле является обязательным",
                "expenditureNormsView.page.title": "Норма расхода",
                "expenditureNormsView.list.name": "Название",
                "expenditureNormsView.list.created_at": "Дата создания",
                "expenditureNormsView.list.total": "Стоимость",
                "expenditureNormsView.list.horse": "Объект нормы",
                "expenditureNormsView.list.period": "Период действия",
                "expenditureNormsView.list.status": "Активный/Неактивный",
                "expenditureNormsView.list.actions": "Действия",
                "expenditureNormsView.table.inventory_item-title": "Название",
                "expenditureNormsView.table.quantity": "Количество",
                "expenditureNormsView.table.unit-title": "Единица измерения",
                "expenditureNormsView.table.cost": "Себестоимость",
                "expenditureNormsView.table.total": "Стоимость позиции",
        },
}


export default expenditureNorms;