import { useGetUser } from "src/hooks";
import { getPermissionsByResource, permissionsIsReadOnly } from "../utils";

export const useIsReadOnlyResourceByCurrentUser = (resource) => {
  const { id, roles } = useGetUser();
  const userId = id;
  // const userId = 300; // !!! HARDCODE
  const permissions = getPermissionsByResource(roles, resource);
  const isReadOnly = permissionsIsReadOnly(permissions);

  return {userId, isReadOnly};
}