import { SYSTEM_MODES, SYSTEM_TYPES } from "./constants";

const config = {
  supported_locales: ["en", "ru", "uk"],
  default_locale: "uk",

  supported_system_types: [SYSTEM_TYPES.SINGLE, SYSTEM_TYPES.DUAL],
  system_type_default: SYSTEM_TYPES.DUAL,

  supported_system_modes: [SYSTEM_MODES.HORSE, SYSTEM_MODES.COACH],
  system_mode_default: SYSTEM_MODES.HORSE,
}

export default config;