import { Button, Checkbox } from '@mui/material';
import { useTranslation } from '../../../helpers/translate';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

export const SeasonTicketsCategoriesForm = ({
  seasonTicketsCategories,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  validationStatus,
  setSeasonTicketsCategories
}) => {
  const { __ } = useTranslation();

  return (
    <>
      <LocaleTabs 
        data={ seasonTicketsCategories }
        setData={ setSeasonTicketsCategories }
        dataName={ 'seasonTicketsCategories' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />
      <label className="checkbox">
        <Checkbox
          name="is_active"
          value={seasonTicketsCategories?.is_active}
          checked={seasonTicketsCategories?.is_active || false}
          onChange={handleChange('is_active')}
        />
        <p>{__('seasonTicketsCategories.form.is_active')}</p>
      </label>
      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
