import { IconButton } from "@mui/material";
import { useCheckAvailableCurrentUserPermission } from "src/configData/rolesConfig";

export const ButtonIcon = ({ icon, sx, checkRoles = false, permission, ...props }) => {
  const componentAvailable = useCheckAvailableCurrentUserPermission(checkRoles?.resource, permission);

  return (
    <>
      {
        componentAvailable &&
          <IconButton
            variant="contained"
            type="submit"
            sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
            {...props}
          >
            <>{ icon }</>
          </IconButton>
      }
    </>
  );
};

ButtonIcon.defaultProps = {
  sx: {},
}