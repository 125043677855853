export const getCollapseRowCellsData = (dataItem, __) => {
    return {
        type: {
            th: 'Тип замовлення віфвф',
            td: dataItem && dataItem.type,
        },
        service: {
            th: 'Послуга',
            td: dataItem && dataItem.service,
        },
        dateVisit: {
            th: 'Дата візиту',
            td: dataItem && dataItem.dateVisit,
        },
        dateStart: {
            th: 'Початок дії (абонемент/сертифікат)',
            td: dataItem && dataItem.dateStart,
        },
        dateEnd: {
            th: 'Закінчення дії (абонемент/сертифікат)',
            td: dataItem && dataItem.dateEnd,
        },
        hoursQty: {
            th: 'Кількість годин',
            td: dataItem && dataItem.hoursQty,
        },
    }
}