import { useEffect, useState } from 'react';
import { useTranslation } from './../../../helpers/translate';
import { ButtonIcon, ButtonLinkIcon, TableAction, TableBlock, TableBody, TableHeader, TableMain, TablePagination, TrBody } from './../../common/TableComponent';

import { getLogs } from './../../../actions';
import { getCollapseRowCellsData, getRowCellsData } from './tableData';
import PageviewIcon from '@mui/icons-material/Pageview';

export const Logs = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {
      logs: [],
      meta: [],
    },
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: logs } = await getLogs(
                                            sortOption,
                                            sortDirection,
                                            currentPage,
                                        );
        const meta = logs.meta;

        setData({
          loaded: true,
          data: {
            logs: logs.data,
            meta,
          }
        });
      }
      catch (error) {
        console.warn('Logs fetch error: ', error);
      }
    }
    fetchData();
  }, [
    sortOption,
    sortDirection,
    currentPage,
  ]);

  return (
    <TableMain
      title={'Логи'} 
      getRowCellsData={getRowCellsData}
      sortOption={sortOption} 
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}
    >
      <TableBlock __class="table--logs">
        <TableAction className="table-action">
          <div className="table-action__block"></div>
        </TableAction>

        <TableHeader />

        <TableBody loaded={loaded}>
          { 
            data?.logs.map((log) =>{
              return (
                <TrBody key={ log.id } data={log} >
                  <ButtonLinkIcon route={`${log.id}`} icon={ <PageviewIcon /> }/>
                </TrBody>
              )
            }) 
          }
        </TableBody>
      </TableBlock>
      
      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} 
      />
      
    </TableMain>
  );
};