import { dispatchToken } from "./dispatchToken";

export const autoLoginIntoCabinet = async (dispatch, navigate, role, authToken, cabinetPageUrl) => {
  if (authToken) {
    await dispatchToken(dispatch, role, authToken);
    navigate(cabinetPageUrl);
    return;
  }
  
  navigate(cabinetPageUrl);
}