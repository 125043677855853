import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getHorses } from 'src/actions/horses';
import { getCoaches } from 'src/actions/coaches';

const types = {
  horses: {
    selectTitle: 'Коні',
    action: getHorses,
    getName(item) {
      return item.name;
    },
  },
  coaches: {
    selectTitle: 'Тренери',
    action: getCoaches,
    getName(item) {
      return item.first_name + ' ' + item.last_name;
    },
  },
}

export const DifferenceBlock = ({ type, setDataDifference }) => {
  const [value, setValue] = useState(0);

  const [data, setData] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;

    setValue(value);
    setDataDifference(value);
  };

  const selectTitle = types[type].selectTitle;
  const selecAction = types[type].action;

  useEffect(() => {

    (async () => {
      const res = await selecAction();
      const data = res.data.data;
      
      setData(data);
      setIsLoading(true);
    })();

  }, []);

  return (
    <Box sx={{ minWidth: 120, maxWidth: 300, mb: 3 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{ selectTitle }</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={ value }
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={0}>Загальна статистика</MenuItem>
          { data && 
              data?.map( item => {
                const itemName = types[type].getName(item);
                return (
                  <MenuItem key={ item.id } value={ item.id }>{ itemName }</MenuItem>
                )
              }) 
          }
        </Select>
      </FormControl>
    </Box>
  );
};