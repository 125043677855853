import axios from '../../helpers/private.axios';

export const getServices = () => {
  return axios.get(`/services/`);
};

export const getServicesAll = () => {
  return axios.get(`/services/?status=all`);
};

export const getServicesOnline = () => {
  return axios.get(`/services?available-for-online=1`);
};

export const getOneService = (id) => {
  return axios.get(`/services/${id}`);
};

export const getNewService = () => {
  return axios.get(`/services/create`);
};

export const createService = (editedService) => {
  const { amount, locale } = editedService;
  return axios.post(`/services/`, {
    ...editedService,
    locale: JSON.stringify(locale),
    amount: amount.includes('.') ? amount : amount + '.00'
  });
};

export const copyService = (editedService) => {
  const { amount, locale } = editedService;
  return axios.post(`/services/`, {
    ...editedService,
    locale: JSON.stringify(locale),
    amount: amount.includes('.') ? amount : amount + '.00'
  });
};

export const getEditService = (id) => {
  return axios.get(`/services/${id}/edit`);
};

export const editService = (editedService) => {
  const { amount, locale } = editedService;
  return axios.post(`/services/${editedService.id}`, {
    ...editedService,
    locale: JSON.stringify(locale),
    amount: amount.includes('.') ? amount : amount + '.00',
    _method: 'PUT'
  });
};

export const deleteService = (id) => {
  return axios.delete(`/services/${id}`);
};
