import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress, Button, IconButton } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getUnit, deleteUnit } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const UnitsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const handleUnitDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteUnit(id).then(async () => {
        const { data: response } = await getUnit();
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getUnit();
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="title title--mb36">{__('units.page.title')}</div>
      <div className="table-action">
        <Link to="/units/create">
          <Button
            variant="contained"
            type="submit"
            sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
            {__('create.button.title')}
          </Button>
        </Link>
      </div>
      <div className="table-block">
        <div className="table table--units">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('units.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('units.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('units.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('units.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((units) => (
              <div className="tr" key={units.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('units.table.title')}</p>
                  </div>
                  <div className="td-content">{units.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('units.table.description')}</p>
                  </div>
                  <div className="td-content">{units.description}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('units.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {units.is_active
                      ? __('inventoryItem.form.active')
                      : __('inventoryItem.form.inactive')}
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('units.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/units/${units.id}/edit`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleUnitDelete(units.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
