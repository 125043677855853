export const flatingObjData = (data = {}, fieldName) => {
  if (!data) {
    return {}
  }

  console.log(data);

  const flatData = {};

  for (let key in data) {
    const value = data[key];
    if (!value) {
      continue;
    }

    flatData[`${fieldName}[${ key }]`] = value;
  }

  return flatData;
}