import dayjs from "dayjs"

export const getRowCellsData = (dataItem, __) => {
  const visit = dataItem && dataItem.visit;
  const timeslot = dataItem && visit.timeslot;
  const statuses = dataItem && dataItem.statuses;
  const services = visit && visit.visit_services; 

  const statusTitle = 
    visit && statuses.find(status => status.id === visit.status_id)?.title;

  const payStatus = visit?.billing_client_service_id ? 'Сплачено' : 'Не сплачено';

  return {
    date: {
      th: 'Дата',
      td: dataItem && dayjs(visit.timeslot_date).format('YYYY-MM-DD'),
      sort: 'coach_timeslots.date',
    },
    time: {
      th: 'Час',
      td: dataItem && dayjs(visit.timeslot_date).format('HH:mm'),
      sort: null,
    },
    horse: {
      th: 'Кінь / Тренер',
      td: dataItem && timeslot.model_title,
      sort: null,
    },
    service: {
      th: 'Послуга',
      td: dataItem && services[0].title,
      sort: null,
    },
    status: {
      th: 'Статус',
      td: dataItem && statusTitle,
      sort: null,
    },
    summ: {
      th: 'Сума',
      td: dataItem && visit.visit_services[0].summa,
      sort: null,
    },

    PayStatus: {
      th: 'Оплата',
      td: dataItem && payStatus,
      sort: null,
    },
    
    action: {
      th: 'Дії',
      td: null,
      sort: null,
      __class: 'td--right'
    },
  }
}