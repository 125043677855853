import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { createStocktaking, deleteStocktaking, getStocktakings } from './../../../actions';
import { useTranslation } from './../../../helpers/translate';
import {
  TableAction,
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
} from './../../common/TableComponent';

import { getStocktakingRowCellsData } from './TablesData';
import { serverErrorHandler } from './../../common';
import { ButtonIcon, ButtonLinkIcon, ButtonSend, ModalForm } from './../../common/FormsComponent';
import { StocktakingCreateForm } from './FormsComponent';
import { rolesComponentConfig } from './rolesComponentConfig';

export const Stocktaking = () => {
  const navigate = useNavigate();
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [errorMessages, setErrorMessages] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [formData, setFormdata] = useState({});

  const handleStocktakingDelete = async (id) => {
    const action = window.confirm('Delete stocktacking');

    if (action) {
      await deleteStocktaking(id);
      navigate(0);
    }
  };

  const handleStocktakingCreate = async (data) => {
    setShowModal(false);
    setFormdata(data);

    const res = await createStocktaking({
      description: data.description,
      serial: data.name
    });

    const {success, message, data: stocktacking} = res?.data;

    if (!success) {
      serverErrorHandler(message, setErrorMessages);
      return;
    }

    navigate('items', { state: { id: stocktacking.id } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getStocktakings(currentPage, itemsPerPage);
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response?.meta.last_page);
      } catch (error) {
        console.warn('Clients fetch error: ', error);
      }
    };
    fetchData();
  }, [currentPage, itemsPerPage, formData]);

  return (
    <>
      <TableMain title={'Інвентаризація'} getRowCellsData={getStocktakingRowCellsData} __={__}>
        <TableAction className="table-action">
          <div className="table-action__block">
            <ButtonSend 
              text="Створити" 
              onClick={() => setShowModal(true)} 
              checkRoles={ rolesComponentConfig }
              permission={ PERMISSIONS.ACTIONS.CREATE }
            />
          </div>
        </TableAction>

        <TableBlock __class="table--stocktacking">
          <TableHeader />
          <TableBody loaded={loaded}>
            {data.map((stocktaking) => (
              <TrBody key={stocktaking.id} data={stocktaking}>
                <Box sx={{ display: 'flex' }}>

                  <ButtonLinkIcon 
                    icon={ !stocktaking.status ? <EditIcon /> : <VisibilityIcon /> }
                    route="/stocktaking/items"
                    state={ { id: stocktaking.id } }
                    checkRoles={ rolesComponentConfig }
                    permission={ !stocktaking.status ? PERMISSIONS.ACTIONS.UPDATE : PERMISSIONS.ACTIONS.READ }
                  />
                  <ButtonIcon 
                    icon={ <DeleteIcon /> } 
                    onClick={ () => handleStocktakingDelete(stocktaking.id) }
                    checkRoles={ rolesComponentConfig }
                    permission={PERMISSIONS.ACTIONS.DELETE}
                  />
                  
                </Box>
              </TrBody>
            ))}
          </TableBody>
        </TableBlock>

        <TablePagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </TableMain>

      <ModalForm
        open={showModal}
        onClose={setShowModal.bind(null, false)}
        title="Створити інвентаризацію">
        <div className="close-modal" onClick={() => setShowModal(false)}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <StocktakingCreateForm formHandler={handleStocktakingCreate} cancelHandler={setShowModal} />
      </ModalForm>
    </>
  );
};
