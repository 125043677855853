import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from '@mui/material';

import { useTranslation } from '../../../helpers/translate';
import { ExtendForm } from './ExtendForm';
import { PhoneInputBlock, SelectMultyCheckbox } from './../../common/FormsComponent';
import { validation } from './validation';

export const UserFormEdit = ({
  user,
  categories,
  roles,
  handleChange,
  action,
  ctaButtonText,
  extendedFormType,
  validationStatus,
}) => {
  const { __ } = useTranslation();

  const isEdit = extendedFormType === 'edit';
  let excludeArr = user?.roles.includes(1) ? [] : ['password', 'password_confirmation'];

  if (isEdit) {
    excludeArr = [...excludeArr, 'login', 'first_name', 'last_name']
  }

  const isValid = validation(validationStatus, excludeArr);


  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="interface-select-label">Мова інтерфейсу</InputLabel>
        <Select
          labelId="interface-select-label"
          id="interface-select"
          value={user.locale || ''}
          name="locale"
          label="Мова інтерфейсу"
          onChange={handleChange('locale')}
          error={ !validationStatus.locale }
        >
          <MenuItem selected disabled value={''}>
            {' '}
            Оберіть мову інтерфейсу{' '}
          </MenuItem>
          <MenuItem value="en">En</MenuItem>
          <MenuItem value="ru">Ru</MenuItem>
          <MenuItem value="uk">Uk</MenuItem>
        </Select>
      </FormControl>

      <PhoneInputBlock
        disabled={ extendedFormType === "edit" }
        isError={ isEdit ? false : !validationStatus.login }
        value={ user.login }
        setValue={ handleChange('login') }
        countryCodeEditable={ false }
        errorText="Некорректний номер телефону"
      />

      <ExtendForm
        user={user}
        handleChange={handleChange}
        validationStatus={validationStatus}
        extendedFormType={ extendedFormType }
      />

      <SelectMultyCheckbox 
        fieldClassNames={ user?.roles.length ? '' : 'input-error' }
        label="Роль"
        dataList={ roles }
        dataListFields={{ key: 'id', value: 'id', text: 'name' }}
        dataChecked={ user?.roles }
        error={ !validationStatus.roles }
        errorMessage={ 'This field is required' }
        value={ user?.roles }
        onChange={ handleChange('roles') }
      />

      <FormControl fullWidth>
        <InputLabel id="categories-select-label">Категорія</InputLabel>
        <Select
          labelId="categories-select-label"
          id="categories-select"
          name="categories"
          value={user?.categories ? user?.categories[0] : ''}
          label="Категорія"
          onChange={handleChange("categories")}
          error={ !validationStatus.categories }
        >
          <MenuItem selected disabled value={""}> Оберіть категорію </MenuItem>
          {
            categories?.map(category => (
              <MenuItem key={ category.id } value={ category.id }>{ category.title }</MenuItem>
            ))
          }
        </Select>
      </FormControl>

      <label className="checkbox">
        <Checkbox
          name="is_active"
          checked={user?.is_active}
          onChange={handleChange('is_active')}
        />
        <p>{__('general.form.is_active')}</p>
      </label>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
        disabled={!isValid}
      >
        {ctaButtonText}
      </Button>
    </>
  );
};