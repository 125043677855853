import { LocaleSwitch } from '../../common/Layout/LocaleSwitch';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../helpers/translate';

export const BookingHeader = () => {
  const { __ } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      {user === null ? (
        <header>
          <div className="inside">
            <div className="header">
              <div className="logo">
                <Link to="/">
                  <img src="/images/logo.png" alt="" />
                </Link>
              </div>
              <div className="header-right">
                <LocaleSwitch />
                <Link to="/sign-up" className="button">
                  <p>{__('booking.header.sign-up')}</p>
                </Link>
                <Link to="/sign-in" className="button">
                  <p>{__('booking.header.sign-in')}</p>
                </Link>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <></>
      )}
    </>
  );
};
