// import { ToastContainer } from "react-toastify";
import { TableContext } from ".";

export const TableMain = ({
  children,
  title, 
  ...context
}) => {
  return (
    <>
      {/* <ToastContainer limit={10}/> */}
      <TableContext.Provider value={{...context}}>
        { title && <div className="title title--mb36">{ title }</div> }
        { children }
      </TableContext.Provider>
    </>
  );
};
