import { flatingLocaleData, flatingObjData } from "./../../../library";
import { convertAllowedDays } from "./convertAllowedDays";
import moment from 'moment';

export const getDataCloneForSend = (data) => {
  const amountWithTwoDecimalPlaces = parseFloat(data.amount).toFixed(2);
  let dataClone = { ...data, amount: amountWithTwoDecimalPlaces };
  const flatLocaleData = flatingLocaleData(dataClone);
  const flatCategory = flatingObjData(dataClone.category, 'category');
  const flatService = flatingObjData(dataClone.service, 'service');

  dataClone.date_start = moment(dataClone.date_start).format('YYYY-MM-DD');
  dataClone.date_end = moment(dataClone.date_end).format('YYYY-MM-DD');

  if (typeof dataClone.type === "object") {
    dataClone.type = dataClone.type.type;
  }
  
  

  dataClone.allowed_days = convertAllowedDays(dataClone.allowed_days);
  const allowedDays = flatingObjData(dataClone.allowed_days, 'allowed_days');


  delete dataClone.allowed_days;
  delete dataClone.category;
  delete dataClone.service;
  delete dataClone.locale;
  
  
  dataClone = {...dataClone, ...allowedDays};
  dataClone = {...dataClone, ...flatLocaleData};
  dataClone = {...dataClone, ...flatCategory};
  dataClone = {...dataClone, ...flatService};

  if (typeof dataClone.image === 'string') {
    dataClone.image = '';
  }
    
  return dataClone;
}