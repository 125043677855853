import { v4 as uuidv4 } from 'uuid';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { TableSimpleContext } from './../tableSimpleContext';
import { useContext } from 'react';
import { Cells } from '.';
import { CircularProgress } from '@mui/material';

export const TableBodySimple = ({ data = [] }) => {
  const { getRowCellsData, __ } = useContext(TableSimpleContext);

  return (
    <>
      {
        !data 
          ? <CircularProgress />
          : <TableBody>
              {
                data.map(row => {
                  const dataRow = getRowCellsData(row, __);
                  return (
                    <TableRow key={uuidv4()}>
                      <Cells cellsData={ dataRow } />
                    </TableRow>
                  )
                })
              }
            </TableBody>
      }
    </>
  );
};


