const writeOffs = {
    uk: {
        "writeOffs.page.title": "Акти на списання",
        // table
        "writeOffs.table.serial_number": "№ Акту",
        "writeOffs.table.created_at": "Дата списання",
        "writeOffs.table.person": "Автор акту",
        "writeOffs.table.subdivision": "Підрозділ",
        "writeOffs.table.reason": "Причина",
        "writeOffs.table.commentary": "Коментар",
        "writeOffs.table.summary": "Сума акту",
        "writeOffs.table.status": "Активний/Неактивний",
        "writeOffs.table.actions": "Дії",
        // table end

        //filter
        "writeOffs.filter.title": "Назва",
        "writeOffs.filter.archiveShow": "Показати неактивні",
        "writeOffs.filter.archiveHide": "Показати активні",
        //filterend

        //form
        "writeOffs.form.title-create": "Створити акт списання",
        "writeOffs.form.title-edit": "Редагувати акт списання",
        "writeOffs.form.button": "Провести",

        "writeOffs.input.commentary": "*Коментар",
        "writeOffs.input.reason": "*Причина",
        "writeOffs.input.subdivision_id": "*Підрозділ",
        "writeOffs.input.reason.name1": "Причина1",
        "writeOffs.input.reason.name2": "Причина2",
        "writeOffs.input.reason.name3": "Причина3",

        //form error
        "writeOffs.form.commentary.error": "*Поле є обов'язковим",
        "writeOffs.form.items.error": "*ТМЦ є обов'язковими",
        "writeOffs.form.subdivision_id.error": "*Поле є обов'язковим",

        //View list
        "writeOffsView.page.title": "Акт списання",
        "writeOffsView.list.serial_number": "№ Акту",
        "writeOffsView.list.created_at": "Дата списання",
        "writeOffsView.list.person": "Автор акту",
        "writeOffsView.list.reason": "Причина",
        "writeOffsView.list.commentary": "Коментар",
        "writeOffsView.list.summary": "Сума акту",
        "writeOffsView.list.subdivision": "Підрозділ",
        "writeOffsView.list.status": "Активний/Неактивний",
        "writeOffsView.list.actions": "Дії",

        //inventory item list
        "writeOffsView.table.inventory_item-title": "Назва",
        "writeOffsView.table.quantity": "Кількість",
        "writeOffsView.table.unit-title": "Одиниця виміру",
        "writeOffsView.table.count": "Кількість",
        "writeOffsView.table.price": "Ціна",
        "writeOffsView.table.cost": "Вартість",


        "writeOffs.form.items.title": "Список ТМЦ",
        "writeOffs.input.inventoryItems": "Виберіть ТМЦ",
        "writeOffs.table.items.title": "Назва",
        "writeOffs.table.items.unit": "Одиниця виміру",
        "writeOffs.table.items.price": "Ціна",
        "writeOffs.table.items.numbers": "Кількість",
        "writeOffs.table.items.cost": "Вартість",
        "writeOffs.table.items.actions": "Дії",

    },
    en: {
        "writeOffs.page.title": "Write-Offs",
        // table
        "writeOffs.table.serial_number": "Write-Off Number",
        "writeOffs.table.created_at": "Write-Off Date",
        "writeOffs.table.person": "Author",
        "writeOffs.table.reason": "Reason",
        "writeOffs.table.commentary": "Comment",
        "writeOffs.table.subdivision": "Subdivision",
        "writeOffs.table.summary": "Total Amount",
        "writeOffs.table.status": "Active/Inactive",
        "writeOffs.table.actions": "Actions",
        // table end

        // filter
        "writeOffs.filter.title": "Title",
        "writeOffs.filter.archiveShow": "Show Inactive",
        "writeOffs.filter.archiveHide": "Show Active",
        // filter end

        // form
        "writeOffs.form.title-create": "Create Write-Off",
        "writeOffs.form.title-edit": "Edit Write-Off",
        "writeOffs.form.button": "Conduct",

        "writeOffs.input.commentary": "*Comment",
        "writeOffs.input.reason": "*Reason",
        "writeOffs.input.subdivision_id": "*Subdivision",
        "writeOffs.input.reason.name1": "Reason 1",
        "writeOffs.input.reason.name2": "Reason 2",
        "writeOffs.input.reason.name3": "Reason 3",

        // form error
        "writeOffs.form.commentary.error": "*This field is required",
        "writeOffs.form.items.error": "*List of Inventory field is required",
        "writeOffs.form.subdivision_id.error": "*This field is required",

        // View list
        "writeOffsView.page.title": "Write-Off",
        "writeOffsView.list.serial_number": "Write-Off Number",
        "writeOffsView.list.created_at": "Write-Off Date",
        "writeOffsView.list.person": "Author",
        "writeOffsView.list.reason": "Reason",
        "writeOffsView.list.commentary": "Comment",
        "writeOffsView.list.subdivision": "Subdivision",
        "writeOffsView.list.summary": "Total Amount",
        "writeOffsView.list.status": "Active/Inactive",
        "writeOffsView.list.actions": "Actions",

        // inventory item list
        "writeOffsView.table.inventory_item-title": "Name",
        "writeOffsView.table.quantity": "Quantity",
        "writeOffsView.table.unit-title": "Unit of Measure",
        "writeOffsView.table.count": "Count",
        "writeOffsView.table.price": "Price",
        "writeOffsView.table.cost": "Cost",

        "writeOffs.form.items.title": "List of Goods",
        "writeOffs.input.inventoryItems": "Select Goods",
        "writeOffs.table.items.title": "Name",
        "writeOffs.table.items.unit": "Unit of Measure",
        "writeOffs.table.items.price": "Price",
        "writeOffs.table.items.numbers": "Quantity",
        "writeOffs.table.items.cost": "Cost",
        "writeOffs.table.items.actions": "Actions",

    },
    ru: {
        "writeOffs.page.title": "Акты списания",
        // table
        "writeOffs.table.serial_number": "Номер акта списания",
        "writeOffs.table.created_at": "Дата списания",
        "writeOffs.table.person": "Автор",
        "writeOffs.table.reason": "Причина",
        "writeOffs.table.subdivision": "Подразделение",
        "writeOffs.table.commentary": "Комментарий",
        "writeOffs.table.summary": "Общая сумма",
        "writeOffs.table.status": "Активный/Неактивный",
        "writeOffs.table.actions": "Действия",
        // table end
        // filter
        "writeOffs.filter.title": "Название",
        "writeOffs.filter.archiveShow": "Показать неактивные",
        "writeOffs.filter.archiveHide": "Показать активные",
        // filter end

        // form
        "writeOffs.form.title-create": "Создать акт списания",
        "writeOffs.form.title-edit": "Редактировать акт списания",
        "writeOffs.form.button": "Сохранить",

        "writeOffs.input.commentary": "*Комментарий",
        "writeOffs.input.reason": "*Причина",
        "writeOffs.input.subdivision_id": "*Подразделение",
        "writeOffs.input.reason.name1": "Причина 1",
        "writeOffs.input.reason.name2": "Причина 2",
        "writeOffs.input.reason.name3": "Причина 3",

        // form error
        "writeOffs.form.commentary.error": "*Это поле обязательно",
        "writeOffs.form.items.error": "*ТМЦ есть обязательными",
        "writeOffs.form.subdivision_id.error": "*Это поле обязательно",
        // View list
        "writeOffsView.page.title": "Акт списания",
        "writeOffsView.list.serial_number": "Номер акта списания",
        "writeOffsView.list.created_at": "Дата списания",
        "writeOffsView.list.person": "Автор",
        "writeOffsView.list.reason": "Причина",
        "writeOffsView.list.subdivision": "Подразделение",
        "writeOffsView.list.commentary": "Комментарий",
        "writeOffsView.list.summary": "Общая сумма",
        "writeOffsView.list.status": "Активный/Неактивный",
        "writeOffsView.list.actions": "Действия",

        // inventory item list
        "writeOffsView.table.inventory_item-title": "Название",
        "writeOffsView.table.quantity": "Количество",
        "writeOffsView.table.unit-title": "Единица измерения",
        "writeOffsView.table.count": "Колличество",
        "writeOffsView.table.price": "Цена",
        "writeOffsView.table.cost": "Стоимость",

        "writeOffs.form.items.title": "Список товаров",
        "writeOffs.input.inventoryItems": "Выберите товары",
        "writeOffs.table.items.title": "Название",
        "writeOffs.table.items.unit": "Единица измерения",
        "writeOffs.table.items.price": "Цена",
        "writeOffs.table.items.numbers": "Количество",
        "writeOffs.table.items.cost": "Себестоимость",
        "writeOffs.table.items.actions": "Действия",

    },
}


export default writeOffs;