import config from "src/config";
import { SYSTEM_TYPES } from "src/constants";

export const getSystemType = (typesObj) => {
  if (!typesObj) {
    return config.system_type_default;
  }

  const typesArr = Object.values(typesObj);
  const systemType = typesArr.length > 1 ? SYSTEM_TYPES.DUAL : SYSTEM_TYPES.SINGLE;

  return systemType;
}