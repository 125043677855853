import { Box, Typography } from "@mui/material";

const EmptyDashboardInfo = () => {
  return (
    <Box sx={{ minHeight: '100px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="h5">Розклад на цю дату відсутній</Typography>
    </Box>
  );
};

export default EmptyDashboardInfo;