import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Switch,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getOrderOutcomings, deleteOrderOutcomings } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { rolesComponentConfig } from './rolesComponentConfig';
import dayjs from 'dayjs';
import { ButtonIcon, ButtonLink, ButtonLinkIcon } from './../../common/FormsComponent';

export const OrderOutcomingsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleOrderOutcomingsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteOrderOutcomings(id).then(async () => {
        const { data: response } = await getOrderOutcomings(
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getOrderOutcomings(
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [searchTitle, currentPage, itemsPerPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('orderOutcomings.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { pr: '40px' },
                '.MuiOutlinedInput-root': { width: '100%' }
              }}
              label={__('orderOutcomings.filter.title')}
              name="order-outcomings-title-filter"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />

            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
        </div>
        <div className="table-action__block">
          {/*  <FormControlLabel
            label={
              showArchive
                ? `${__('orderOutcomings.filter.archiveHide')}`
                : `${__('orderOutcomings.filter.archiveShow')}`
            }
            control={
              <Switch checked={showArchive} onChange={(e) => setShowArchive(e.target.checked)} />
            }
          /> */}

          <ButtonLink
            text={ __('create.button.title') }
            route="create"
            state={ { action: 'create', backRoute: '/clients'} }
            checkRoles={ rolesComponentConfig }
            permission={ PERMISSIONS.ACTIONS.CREATE }
          />

        </div>
      </div>
      <div className="table-block">
        <div className="table table--order-outcomings">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.order_num')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.date_create')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.date_order')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.subdivision')}</p>
                </div>
              </div>
              
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.cost')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.person')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.store')}</p>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.is_active')}</p>
                </div>
              </div> */}
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('orderOutcomings.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((orderOutcomings) => {
              const isCopying = !!(orderOutcomings.type === 0 && orderOutcomings.turnover_completed);
              const isEditable = !!(orderOutcomings.type === 0 && !orderOutcomings.turnover_completed);
              return (
              <div className="tr" key={orderOutcomings.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.order_num')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.order_num}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.date_create')}</p>
                  </div>
                  <div className="td-content">{dayjs(orderOutcomings.created_at).format('YYYY:DD:MM')}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.date_order')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.date_order}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.subdivision')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.subdivision.title}</div>
                </div>
                
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.cost')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.cost}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.person')}</p>
                  </div>
                  <div className="td-content">
                    {orderOutcomings.person.last_name} {orderOutcomings.person.first_name}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.store')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.store.title}</div>
                </div>
                {/*  <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.is_active')}</p>
                  </div>
                  <div className="td-content">{orderOutcomings.is_active}</div>
                </div> */}
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('orderOutcomings.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">

                      <ButtonLinkIcon 
                        icon={ <PageviewIcon /> }
                        route={`/order-outcomings/${orderOutcomings.id}`}
                        checkRoles={ rolesComponentConfig }
                        permission={PERMISSIONS.ACTIONS.READ}
                      />

                      {
                        isCopying &&
                          <ButtonLinkIcon 
                            icon={ <ContentCopyIcon /> }
                            route={ `/order-outcomings/${orderOutcomings.id}/copy` }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.CREATE}
                          />
                      }

                      {
                        isEditable &&
                        <>
                          <ButtonLinkIcon 
                            icon={ <ContentCopyIcon /> }
                            route={ `/order-outcomings/${orderOutcomings.id}/copy` }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.CREATE}
                          />
                          <ButtonLinkIcon 
                            icon={ <EditIcon /> }
                            route={ `/order-outcomings/${orderOutcomings.id}/edit` }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.UPDATE}
                          />
                          <ButtonIcon 
                            icon={ <DeleteIcon /> } 
                            onClick={ handleOrderOutcomingsDelete(orderOutcomings.id) }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.DELETE}
                          />
                        </>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
