const UserCategories = {
        uk: {
                "userCategories.page.title": "Категорії користувачів",
                "userCategories.table.title": "Назва",
                "userCategories.table.description": "Опис",
                "userCategories.table.is_active": "Активний/Неактивний",
                "userCategories.table.actions": "Дії",
                "userCategories.form.title-create": "Створити категорію користувача",
                "userCategories.form.title-edit": "Редагувати категорію користувача",
                "userCategories.form.button": "Зберегти",
                "userCategories.form.is_active": "Активний/Неактивний",
                "userCategories.form.title.error": "*Поле є обов'язковим",
                "userCategories.form.description.error": "*Поле є обов'язковим",
        },
        en: {
                "userCategories.page.title": "Categories of users",
                "userCategories.table.title": "Title",
                "userCategories.table.description": "Description",
                "userCategories.table.is_active": "Active/Inactive",
                "userCategories.table.actions": "Actions",
                "userCategories.form.title-create": "Create user category",
                "userCategories.form.title-edit": "Edit user category",
                "userCategories.form.button": "Save",
                "userCategories.form.is_active": "Active/Inactive",
                "userCategories.form.title.error": "*This field is required",
                "userCategories.form.description.error": "*This field is required",
        },

        ru: {
                "userCategories.page.title": "Типы категории пользователей",
                "userCategories.table.title": "Название",
                "userCategories.table.description": "Описание",
                "userCategories.table.is_active": "Активный/Неактивный",
                "userCategories.table.actions": "Действия",
                "userCategories.form.title-create": "Создать категорию пользователя",
                "userCategories.form.title-edit": "Редактировать категорию пользователя",
                "userCategories.form.button": "Сохранить",
                "userCategories.form.is_active": "Активный/Неактивный",
                "userCategories.form.title.error": "*Это поле обязательно",
                "userCategories.form.description.error": "*Это поле обязательно",
        }
}


export default UserCategories;