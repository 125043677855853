import { Box, Collapse } from "@mui/material";

export const CollapsibleContent = ({ children, open }) => {
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1 }}>
        
        { children }

      </Box>
    </Collapse>
  );
};
