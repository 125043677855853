import { List } from '@mui/material';

import { SidebarAccordion, SidebarLink } from '../MenuItems';

import {
  PeopleIcon,
  PersonIcon,
  GroupsIcon,
  CalendarMonthIcon,
  WarehouseIcon,
  LibraryBooksIcon,
  AttachMoneyIcon,
  CreditCardIcon,
  SquareFootIcon,
  BedroomBabyRoundedIcon,
  CardGiftcardIcon
} from './../icons';

import styles from './../layout.module.scss';

const ExternalAccount = () => {
  return (
    <List
      className={styles['dashbord-nav']}
      component="nav"
      aria-labelledby="nested-list-subheader">

      {/* history */}
      <SidebarLink title="Історія платежів" link="/history" icon={<AttachMoneyIcon />} />

      {/* buy */}
      <SidebarAccordion title="Придбати" icon={<CreditCardIcon />}>
        <SidebarLink title="Послуги" link="/booking" icon={<BedroomBabyRoundedIcon />} />
        <SidebarLink title="Абонементи" link="/booking/season-tickets" icon={<CalendarMonthIcon />} />
        <SidebarLink title="Сертифікати" link="/booking/certificates" icon={<LibraryBooksIcon />} />
        <SidebarLink title="Кошик" link="/booking/booking-basket" icon={<AttachMoneyIcon />} />
      </SidebarAccordion>

      {/* presonal data */}
      <SidebarLink title="Персональні данні" link="/personal-data" icon={<WarehouseIcon />} />

      {/* visits */}
      <SidebarLink title="Мої візити" link="/visits" icon={<CalendarMonthIcon />} />

      {/* season-tickets */}
      <SidebarLink title="Абонементи" link="/season-tickets" icon={<CardGiftcardIcon />} />

      {/* sertificates */}
      <SidebarLink title="Сертифікати" link="/sertificates" icon={<LibraryBooksIcon />} />
    </List>
  );
};

export default ExternalAccount;
