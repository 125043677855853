import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const RadioBtnsGroup = ({ direction = 'column', label, name, radioDataProp=[], value, onChange }) => {
  const firstChild = radioDataProp[0].value || '';

  return (
    <FormControl>
      {
        label && 
          <FormLabel id="radio-buttons-group-label" sx={{ textAlign: 'left', fontSize: '1.2rem', mb: 1 }}>
            { label }
          </FormLabel>
      }
      <RadioGroup
        row={ direction.toLowerCase() === 'row' && true }
        aria-labelledby="radio-buttons-group"
        name={ name }
        value={ value || firstChild }
        onChange={ onChange }
      >
        {
          radioDataProp.map(radio => {
            return (
              <FormControlLabel 
                key={ radio.value }
                value={ radio.value } 
                control={ <Radio /> } 
                label={ radio.label }
                disabled={ radio.disabled } 
              />
            )
          })
        }
      </RadioGroup>
    </FormControl>
  );
}



// example
/*

const radioDataProp = [
  { value: 'online', label: 'Онлайн оплата', disabled: 'false' },
  { value: 'cash', label: 'Готівка', disabled: 'false' },
  { value: 'seasontiket', label: 'Абонемент/Сертифікат', disabled: 'false' },
];

*/