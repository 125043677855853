const financialAnalytics = {
    uk: {
        "financialAnalytics.page.title": "Фінансова аналітика",
        "financialAnalytics.filter.lastName": "Прізвище",
        "financialAnalytics.filter.payStatus": "Статус оплати",
        "financialAnalytics.table.firstName": "Ім'я",
        "financialAnalytics.table.lastName": "Прізвище",
        "financialAnalytics.table.phone": "Телефон",
        "financialAnalytics.table.amount": "Сума",
        "financialAnalytics.table.externalUser": "Замовлення на іншу особу",
        "financialAnalytics.table.paidDate": "Дата оплати",
        "financialAnalytics.table.status": "Статус оплати",


        "financialAnalytics.table_more.type": "Тип замовлення",
        "financialAnalytics.table_more.service": "Послуга",
        "financialAnalytics.table_more.horse": "Кінь",
        "financialAnalytics.table_more.dateVisit": "Дата візиту",
        "financialAnalytics.table_more.seasonTicketTitle": "Абонемент/ Сертифікат",
        "financialAnalytics.table_more.dateStart": "Початок дії (абонемент/сертифікат)",
        "financialAnalytics.table_more.dateEnd": "Закінчення дії (абонемент/  сертифікат)",
        "financialAnalytics.table_more.hoursQty": "Кількість годин",
        "financialAnalytics.table_more.validityPeriod": "Термін дії (міс)",
    },
    ru: {
        "financialAnalytics.page.title": "Финансовая аналитика",
        "financialAnalytics.filter.lastName": "Фамилия",
        "financialAnalytics.filter.payStatus": "Статус оплаты",
        "financialAnalytics.table.firstName": "Имя",
        "financialAnalytics.table.lastName": "Фамилия",
        "financialAnalytics.table.phone": "Телефон",
        "financialAnalytics.table.amount": "Сумма",
        "financialAnalytics.table.externalUser": "Заказ на другого пользователя",
        "financialAnalytics.table.paidDate": "Дата оплаты",
        "financialAnalytics.table.status": "Статус оплаты",

        "financialAnalytics.table_more.type": "Тип заказа",
        "financialAnalytics.table_more.service": "Услуга",
        "financialAnalytics.table_more.horse": "Лошадь",
        "financialAnalytics.table_more.dateVisit": "Дата визита",
        "financialAnalytics.table_more.seasonTicketTitle": "Абонемент/Сертификат",
        "financialAnalytics.table_more.dateStart": "Начало действия (абонемент/сертификат)",
        "financialAnalytics.table_more.dateEnd": "Окончание действия (абонемент/сертификат)",
        "financialAnalytics.table_more.hoursQty": "Количество часов",
        "financialAnalytics.table_more.validityPeriod": "Срок действия (месяцев)",
    },
    en: {
        "financialAnalytics.page.title": "Financial Analytics",
        "financialAnalytics.filter.lastName": "Last Name",
        "financialAnalytics.filter.payStatus": "Payment Status",
        "financialAnalytics.table.firstName": "First Name",
        "financialAnalytics.table.lastName": "Last Name",
        "financialAnalytics.table.phone": "phone",
        "financialAnalytics.table.amount": "Amount",
        "financialAnalytics.table.externalUser": "Order for Another User",
        "financialAnalytics.table.paidDate": "Payment Date",
        "financialAnalytics.table.status": "Payment Status",

        "financialAnalytics.table_more.type": "Order Type",
        "financialAnalytics.table_more.service": "Service",
        "financialAnalytics.table_more.horse": "Horse",
        "financialAnalytics.table_more.dateVisit": "Visit Date",
        "financialAnalytics.table_more.seasonTicketTitle": "Season Ticket/Certificate",
        "financialAnalytics.table_more.dateStart": "Start Date (Season Ticket/Certificate)",
        "financialAnalytics.table_more.dateEnd": "End Date (Season Ticket/Certificate)",
        "financialAnalytics.table_more.hoursQty": "Number of Hours",
        "financialAnalytics.table_more.validityPeriod": "Validity Period (months)",
    },


};

export default financialAnalytics;
