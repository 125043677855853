import { createContext } from "react";
export const TableContext = createContext({
  getRowCellsData: () => {},
  getCollapseRowCellsData: () => {},
  sortOption: '', 
  setSortOption: () => {},
  sortDirection: 1,
  setSortDirection: () => {},
  __: () => {},
  collapsible: false,
  collapsibleTableTitle: [],
});