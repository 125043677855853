import { configurateToken } from '../../../../helpers/private.axios';
import { AUTH } from "./../../../../constants";

export const dispatchToken = async (dispatch, role, authToken) => {
  dispatch({ type: AUTH.AUTH_REQUEST });
  await dispatch({
    type: AUTH.AUTH_RESPONSE,
    payload: {
      user: { role },
      token: authToken
    }
  });

  configurateToken(authToken);
}