import { useState } from 'react';
import { Box, Modal } from '@mui/material';

import { createUnit } from '../../../actions/units';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate } from 'react-router-dom';

import { UnitsForm } from './UnitsForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';

export const UnitsCreate = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/units');
  };

  const [unit, setUnit] = useState({
    is_active: true,
    locale: {
      en: {
        title: '',
        description: ''
      },
      ru: {
        title: '',
        description: ''
      },
      uk: {
        title: '',
        description: ''
      }
    }
  });
  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' }
    }
  });

  const validateForm = () => {
    let isValid = true;

    Object.keys(unit.locale).forEach((locale) => {
      const title = unit.locale[locale].title;
      const isTitleValid = title ? title.trim() !== '' : false;

      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        [locale]: {
          ...prevValidationStatus[locale],
          title: {
            isValid: isTitleValid,
            errorMessage: isTitleValid ? '' : `${__('units.form.title.error')}`
          }
        }
      }));

      if (!isTitleValid) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('units.form.title.error'),
    setUnit,
    setValidationStatus
  );

  const handleCreate = () => {
    const isValid = validateForm();
    const sendingData = {...unit};
    if (isValid) {
      sendingData.locale = JSON.stringify(sendingData.locale);
      createUnit(sendingData).then((res) => {
        navigate('/units');
      });
    }
  };

  return (
    <ModalForm 
      title={__('units.form.title-create')} 
      open={true} onClose={toggleOpened} 
      closeBtn
    >
      <UnitsForm
        unit={unit}
        setUnit={setUnit}
        action={handleCreate}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('units.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
