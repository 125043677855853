import { useEffect, useState, useMemo } from 'react';
import { PERMISSIONS } from 'src/constants';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';

import { getCoaches, deleteCoach } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { TableAction, TableBlock, TableHeader, TableMain, TableBody, TrBody, TablePagination } from '../../common/TableComponent';
import { ButtonIcon, ButtonLink, ButtonLinkIcon, FilterSearch, ToggleShowActive } from '../../common/FormsComponent';
import { getRowCellsData } from './getRowCellsData';
import { Link } from 'react-router-dom';
import { IconButton, TextField } from '@mui/material';
import { rolesComponentConfig } from './rolesComponentConfig';

export const CoachesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [ searchParams, setSerachParams ] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showArchive, setShowArchive] = useState(false);

  const handleCoachDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteCoach(id).then(async () => {
        const { data: response } = await getCoaches( searchParams );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const {data: response} = await getCoaches( searchParams, showArchive );
        setData({
          loaded: true,
          data: response.data
        });
      } 
      catch (error) {
        console.log(error);
      }
    })()
  }, [searchParams, showArchive ]);


  const handleSearch = ( e ) => {
    const { name, value } = e.target;
    setSerachParams({
      ...searchParams,
      [name]: value
    });
  }

  return (
    <TableMain title="Тренери" getRowCellsData={getRowCellsData} __={__}>
      
      <TableAction className="table-action">
        <div className="table-action__block">
          <FilterSearch
            label="Прізвище"
            name="fullname"
            value= { searchParams.fullname }
            handler={ handleSearch } 
          />
        </div>
        <div className="table-action__block">
          <ToggleShowActive value={showArchive} onChange={() => setShowArchive(prev => !prev)} />
          <ButtonLink text={__('create.button.title')} route="create" checkRoles={ rolesComponentConfig } permission={PERMISSIONS.ACTIONS.CREATE}/>
        </div>
      </TableAction>

      <TableBlock __class="table--coaches">
        <TableHeader />
        <TableBody loaded={loaded}>

          {
            data && data.map((coach) => (
              <TrBody key={coach.id} data={coach}>
                
                <ButtonLinkIcon 
                  icon={ <EditIcon /> }
                  route={`/coaches/${coach.id}/edit`}
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.UPDATE}
                />
                <ButtonIcon 
                  icon={ <DeleteIcon /> } 
                  onClick={ handleCoachDelete(coach.id) }
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.DELETE}
                />
                
              </TrBody>
            ))
          }

        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
