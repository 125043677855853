import Common from "./common";
import Create from "./common";
import Coaches from "./coaches";

import config from "../config";
import Services from "./services";
import InfoPages from "./infoPages";
import Units from "./units";
import ServiceCategories from "./serviceCategories";
import Horses from "./horses";
import HorsesCategories from "./horsesCategories";
import Abonnements from "./abonnements";
import Certificates from "./certificates";
import CertificateCategories from "./certificateCategories";
import InventoryItems from "./inventoryItems";
import InventoryItemTypes from "./inventoryItemsTypes";
import Subscription from "./subscription";
import SeasonTickets from "./seasonTickets";
import SeasonTicketsCategories from "./seasonTicketsCategories";
import Booking from "./booking";
import OrderIncomings from "./orderIncomings";
import OrderOutcomings from "./orderOutcomings";
import Persons from "./persons";
import General from "./general";
import ExpenditureNorms from "./expenditureNorms";
import UserCategories from "./userCategories";
import WriteOffs from "./writeOffs";
import Clients from "./clients";
import Users from "./users";
import ClientCard from "./clientCard";
import FinancialAnalytics from "./financialAnalytics";







/*
  Для того, щоб додати новий блок перекладу - створюєте новий файл на модуль,
  а далі підключаєте його у TranslationsArray нижче.
*/

const TranslationsArray = [
    Common,
    Coaches,
    Services,
    Create,
    InfoPages,
    Units,
    ServiceCategories,
    Horses,
    HorsesCategories,
    Abonnements,
    Certificates,
    CertificateCategories,
    InventoryItems,
    InventoryItemTypes,
    Subscription,
    SeasonTickets,
    SeasonTicketsCategories,
    Booking,
    OrderIncomings,
    OrderOutcomings,
    Persons,
    General,
    ExpenditureNorms,
    UserCategories,
    WriteOffs,
    Clients,
    Users,
    ClientCard,
    FinancialAnalytics
];

const finalTranslations = {};

const supportedLangs = config.supported_locales;

supportedLangs.map((locale) => {
    finalTranslations[locale] = {};
});

TranslationsArray.map((translationBlock) => {
    supportedLangs.forEach((lang) => {
        finalTranslations[lang] = {
            ...finalTranslations[lang],
            ...translationBlock[lang]
        };
    });
});

export default finalTranslations;
