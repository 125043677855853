import { Th } from '.';
import { useContext } from 'react';
import { TableContext } from '..';

export const TableHeader = ({ data }) => {
  const { getRowCellsData, __, collapsible } = useContext(TableContext);
  const rowCellsData = getRowCellsData(data, __);

  return (
    <div className="table-header">
      <div className="tr">
        <Cells cellsData={ rowCellsData } collapsible={ collapsible }/>
      </div>
    </div>
  )
};

const Cells = ({ cellsData, collapsible }) => {
  const rowCells = [];

  collapsible && rowCells.push(<Th key="collapseBtn" __class="collapse-empty-cell"/>);

  for (let key in cellsData) {
    if (key.startsWith('__')) {
      continue;
    }

    const sortField = cellsData[key].sort;
    const title = cellsData[key].th;
    const __class = cellsData[key].__class;

    if (sortField) {
      rowCells.push(
        <Th
          key={ key }
          title={ title }
          isSort={ true }
          field={ sortField }
          __class={__class}
        />
      );
      continue;
    }

    rowCells.push(
      <Th 
        key={key} 
        title={title} 
        __class={__class}
      />
    );
      
  }

  return (
    <>
      { rowCells }
    </>
  )
}
