import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  TextField,
} from '@mui/material';

import { useTranslation } from './../../../../helpers/translate';
import { PhoneInputBlock } from './../../../common/FormsComponent';
import { Link } from 'react-router-dom';

export const Form = ({
  user,
  handleChange,
  action,
  ctaButtonText,
  validationStatus,
  serverErrorMessages,
  isExtendForm,
}) => {
  const { __ } = useTranslation();

  const isValid = customCheckIsValid(user);

  return (
    <>
      <PhoneInputBlock
        isError={ serverErrorMessages?.login }
        value={ user.login }
        setValue={ handleChange('login') }
        errorText={ __('general.authorization.phone-error') }
      />

      <TextField
        fullWidth 
        name="first_name" 
        value={user.first_name} 
        onChange={handleChange("first_name")} 
        label="Вкажіть ім'я*" 
        error={!validationStatus?.first_name}
      />

      <TextField 
        fullWidth 
        name="last_name" 
        value={user.last_name} 
        onChange={handleChange("last_name")} 
        label="Вкажіть прізвище*"
        error={!validationStatus?.last_name} 
      />

      <TextField 
        fullWidth 
        name="email" 
        value={user.email} 
        onChange={handleChange("email")} 
        label="Вкажіть Email"
      />

      <label className="checkbox">
        <Checkbox
          name="personal_data_agreement"
          checked={user?.personal_data_agreement}
          onChange={handleChange('personal_data_agreement')}
        />
        <p>
          {__('booking.сheckbox.text')}
          <Link to="/"> {__('booking.сheckbox.text2')}</Link>.
        </p>
      </label>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
        disabled={!isValid}
      >
        {ctaButtonText}
      </Button>
    </>
  );
};

function customCheckIsValid(user) {
  const phoneValid = user.login.replace(/[+()\s-]/g, '').length === 12;
  const allFieldValid = Object.values(user).every(value => value);
  return (
    allFieldValid &&
    phoneValid
  )
}

Form.defaultProps = {
  isExtendForm: false,
  validationStatus: {},
  serverErrorMessages: [],
}