export const localeFieldsModel = [
  { fieldName: 'title', required: true, displayControl: true },
  {
    fieldName: 'body',
    required: true,
    fieldProps: { multiline: true, maxRows: 8 },
    options: {
      value: {
        maxLength: 4096
      }
    },
  }
];