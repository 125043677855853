import axios from '../../helpers/private.axios';

export const getStocktakingItems = (
  id,
  currentPage,
  itemsPerPage,
) => {
  const params = {};
    params.status = 'all';

    params.page = currentPage;
    params.limit = itemsPerPage;

    params.stocktaking_id = id;

  return axios.get(`/stocktaking/stock-items`, { params });
};