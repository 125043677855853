import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from './../../../../helpers/translate';
import { TableVerticalSimple } from './../../../common/TableComponent';
import { getClientCardVerticalTableData } from './getClientCardVerticalTableData';
import { getVisit } from './../../../../actions/visit/visitRoutes';
import dayjs from 'dayjs';

export const VisitStatus = ({ clickedDate, extendedProps }) => {
  const { __ } = useTranslation();
  const [visit, setVisit] = useState();
  const [load, setLoad] = useState(false);

  const visitId = extendedProps.visit?.id;

  useEffect(() => {
    if (visitId) {
      getVisit(visitId).then((res) => {
        const data = res?.data?.data;
        const date = dayjs(data?.timeslot.date).$d;

        setLoad(true);

        setVisit({
          ...data,
          phoneCode: '+380 ',
          phone: data?.phone.substring(5),
          editTime: date
        });
      });
    }
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
          '@media screen and (max-width: 920px)': {
            fontSize: '28px'
          }
        }}>
        Статус візиту
      </Typography>

      <TableVerticalSimple
        data={visit}
        getVerticalTableData={getClientCardVerticalTableData}
        __={__}
      />
    </>
  );
};
