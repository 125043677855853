import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import PieChartIcon from '@mui/icons-material/PieChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';

import { MultiAxisLineChart, PieChart, StackedVerticalBarChart, VerticalBarChart } from "./components";

export const chartsAssociatesObj = {
  bars: {
    component: <VerticalBarChart />,
    iconComponent: <BarChartIcon />,
    value: 'bars',
  },
  stackedbars: {
    component: <StackedVerticalBarChart />,
    iconComponent: <StackedBarChartIcon />,
    value: 'stackedbars',
  },
  lines: {
    component: <MultiAxisLineChart />,
    iconComponent: <InsightsIcon />,
    value: 'lines',
  },
  pie: {
    component: <PieChart />,
    iconComponent: <PieChartIcon />,
    value: 'pie',
  },
};