const inventoryItemTypes = {
        uk: {
                "inventoryItemTypes.page.title": "Типи ТМЦ",
                "inventoryItemTypes.table.title": "Назва",
                "inventoryItemTypes.table.description": "Опис",
                "inventoryItemTypes.table.is_active": "Активний/Неактивний",
                "inventoryItemTypes.table.actions": "Дії",
                "inventoryItemTypes.form.title-create": "Створити тип ТМЦ",
                "inventoryItemTypes.form.title-edit": "Редагувати тип ТМЦ",
                "inventoryItemTypes.form.button": "Зберегти",
                "inventoryItemTypes.form.is_active": "Активний/Неактивний",
                "inventoryItemTypes.form.title.error": "*Поле є обов'язковим",
                "inventoryItemTypes.form.description.error": "*Поле є обов'язковим",
        },
        en: {
                "inventoryItemTypes.page.title": "Inventory Item Types",
                "inventoryItemTypes.table.title": "Title",
                "inventoryItemTypes.table.description": "Description",
                "inventoryItemTypes.table.is_active": "Active/Inactive",
                "inventoryItemTypes.table.actions": "Actions",
                "inventoryItemTypes.form.title-create": "Create Inventory Item Type",
                "inventoryItemTypes.form.title-edit": "Edit Inventory Item Type",
                "inventoryItemTypes.form.button": "Save",
                "inventoryItemTypes.form.is_active": "Active/Inactive",
                "inventoryItemTypes.form.title.error": "*This field is required",
                "inventoryItemTypes.form.description.error": "*This field is required",
        },

        ru: {
                "inventoryItemTypes.page.title": "Типы ТМЦ",
                "inventoryItemTypes.table.title": "Название",
                "inventoryItemTypes.table.description": "Описание",
                "inventoryItemTypes.table.is_active": "Активный/Неактивный",
                "inventoryItemTypes.table.actions": "Действия",
                "inventoryItemTypes.form.title-create": "Создать тип ТМЦ",
                "inventoryItemTypes.form.title-edit": "Редактировать тип ТМЦ",
                "inventoryItemTypes.form.button": "Сохранить",
                "inventoryItemTypes.form.is_active": "Активный/Неактивный",
                "inventoryItemTypes.form.title.error": "*Это поле обязательно",
                "inventoryItemTypes.form.description.error": "*Это поле обязательно",
        }
}


export default inventoryItemTypes;