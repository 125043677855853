const orderIncomings = {
    uk: {
        "orderIncomings.page.title": "Прибуткові ордери",
        "orderIncomings.filter.title": "Номер ордеру",
        "orderIncomings.filter.archiveHide": "Сховати архів",
        "orderIncomings.filter.archiveShow": "Показати архів",
        "create.button.title": "Створити",
        "orderIncomings.table.order_num": "Номер ордеру",
        "orderIncomings.table.contractor": "Контрагент",
        "orderIncomings.table.date_order": "Дата замовлення",
        "orderIncomings.table.date_create": "Дата створення",
        "orderIncomings.table.date_writeoff": "Дата списання",
        "orderIncomings.table.cost": "Вартість",
        "orderIncomings.table.person": "Особа",
        "orderIncomings.table.store": "Склад",
        "orderIncomings.table.is_active": "Активний/Неактивний",
        "orderIncomings.table.actions": "Дії",

        "orderIncomings.form.title-create": "Створити ордер",
        "orderIncomings.form.title-edit": "Редагувати ордер",
        "orderIncomings.form.button": "Провести",

        "orderIncomings.input.order_num": "Введіть номер ордера*",
        "orderIncomings.input.contractor_id": "Оберіть контрагента*",
        "orderIncomings.input.date_order": "Дата документа*",
        "orderIncomings.input.store_id": "Оберіть склад*",

        "orderIncomings.form.contractor_id.error": "*Поле є обов'язковим",
        "orderIncomings.form.store_id.error": "*Поле є обов'язковим",
        "orderIncomings.form.date_start.error": "*Поле є обов'язковим",
        "orderIncomings.form.order_num.error": "*Поле обов'язкове і має бути унікальним",
        "orderIncomings.form.items.error": "*ТМЦ і їх сума є обов'язковими",

        "orderIncomings.form.items.title": "Список ТМЦ",
        "orderIncomings.input.inventoryItems": "Виберіть ТМЦ",
        "orderIncomings.table.items.title": "Назва",
        "orderIncomings.table.items.unit": "Одиниця виміру",
        "orderIncomings.table.items.price": "Ціна",
        "orderIncomings.table.items.numbers": "Кількість",
        "orderIncomings.table.items.sale_price": "Вартість",
        "orderIncomings.table.items.store": "Склад",
        "orderIncomings.table.items.actions": "Дії",

        "orderIncomingsView.page.title": "Прибутковий ордер",
        "orderIncomingsView.list.order_num": "Номер ордеру",
        "orderIncomingsView.list.contractor": "Контрагент",
        "orderIncomingsView.list.person": "Особа",
        "orderIncomingsView.list.store": "Склад",
        "orderIncomingsView.list.created_at": "Дата створення документа",
        "orderIncomingsView.list.date_order": "Дата документа",
        "orderIncomingsView.list.cost": "Вартість",
        "orderIncomingsView.table.inventory_item-title": "Назва",
        "orderIncomingsView.table.unit-title": "Одиниця виміру",
        "orderIncomingsView.table.count": "Кількість",
        "orderIncomingsView.table.price": "Ціна",
        "orderIncomingsView.table.cost": "Вартість",

    },
    en: {
        "orderIncomings.page.title": "Incoming Orders",
        "orderIncomings.filter.title": "Order number",
        "orderIncomings.filter.archiveHide": "Hide Archive",
        "orderIncomings.filter.archiveShow": "Show Archive",
        "create.button.title": "Create",
        "orderIncomings.table.order_num": "Order number",
        "orderIncomings.table.contractor": "Contractor",
        "orderIncomings.table.date_order": "Order Date",
        "orderIncomings.table.date_create": "Date created",
        "orderIncomings.table.date_writeoff": "Write-off date",
        "orderIncomings.table.cost": "Cost",
        "orderIncomings.table.person": "Person",
        "orderIncomings.table.store": "Store",
        "orderIncomings.table.is_active": "Active/Inactive",
        "orderIncomings.table.actions": "Actions",

        "orderIncomings.form.title-create": "Create Order",
        "orderIncomings.form.title-edit": "Edit Order",
        "orderIncomings.form.button": "Submit",

        "orderIncomings.input.order_num": "Enter Order Number*",
        "orderIncomings.input.contractor_id": "Select Contractor*",
        "orderIncomings.input.date_order": "Document Date*",
        "orderIncomings.input.store_id": "Select Store*",

        "orderIncomings.form.contractor_id.error": "*This field is required",
        "orderIncomings.form.store_id.error": "*This field is required",
        "orderIncomings.form.date_start.error": "*This field is required",
        "orderIncomings.form.order_num.error": "*This field is required and unique",
        "orderIncomings.form.items.error": "*List of Inventory and their sum are field is required",

        "orderIncomings.form.items.title": "List of Items",
        "orderIncomings.input.inventoryItems": "Select Inventory Items",
        "orderIncomings.table.items.title": "Name",
        "orderIncomings.table.items.unit": "Unit of Measure",
        "orderIncomings.table.items.price": "Price",
        "orderIncomings.table.items.numbers": "Quantity",
        "orderIncomings.table.items.sale_price": "Cost",
        "orderIncomings.table.items.actions": "Actions",

        "orderIncomingsView.page.title": "Incomings order",
        "orderIncomingsView.list.order_num": "Order Number",
        "orderIncomingsView.list.contractor": "Contractor",
        "orderIncomingsView.list.person": "Person",
        "orderIncomingsView.list.store": "Store",
        "orderIncomingsView.list.created_at": "Document Сreated Date",
        "orderIncomingsView.list.date_order": "Document Date",
        "orderIncomingsView.list.cost": "Cost",
        "orderIncomingsView.table.inventory_item-title": "Name",
        "orderIncomingsView.table.unit-title": "Unit of Measure",
        "orderIncomingsView.table.count": "Quantity",
        "orderIncomingsView.table.price": "Price",
        "orderIncomingsView.table.cost": "Cost",
    },
    ru: {
        "orderIncomings.page.title": "Входящие заказы",
        "orderIncomings.filter.title": "Номер ордера",
        "orderIncomings.filter.archiveHide": "Скрыть архив",
        "orderIncomings.filter.archiveShow": "Показать архив",
        "create.button.title": "Создать",
        "orderIncomings.table.order_num": "Номер ордера",
        "orderIncomings.table.contractor": "Контрагент",
        "orderIncomings.table.date_order": "Дата заказа",
        "orderIncomings.table.date_create": "Дата создания",
        "orderIncomings.table.date_writeoff": "Дата списания",
        "orderIncomings.table.cost": "Стоимость",
        "orderIncomings.table.person": "Лицо",
        "orderIncomings.table.is_active": "Активный/Неактивный",
        "orderIncomings.table.store": "Склад",
        "orderIncomings.table.actions": "Действия",

        "orderIncomings.form.title-create": "Создать заказ",
        "orderIncomings.form.title-edit": "Редактировать заказ",
        "orderIncomings.form.button": "Подтвердить",

        "orderIncomings.input.order_num": "Введите номер заказа*",
        "orderIncomings.input.contractor_id": "Выберите контрагента*",
        "orderIncomings.input.date_order": "Дата документа*",
        "orderIncomings.input.store_id": "Выберите склад*",

        "orderIncomings.form.contractor_id.error": "*Это поле обязательно для заполнения",
        "orderIncomings.form.store_id.error": "*Это поле обязательно для заполнения",
        "orderIncomings.form.date_start.error": "*Это поле обязательно для заполнения",
        "orderIncomings.form.order_num.error": "*Это поле обязательно и должно быть уникальным",
        "orderIncomings.form.items.error": "*ТМЦ и их сумма есть обязательными",

        "orderIncomings.form.items.title": "Список товаров",
        "orderIncomings.input.inventoryItems": "Выберите товары",
        "orderIncomings.table.items.title": "Наименование",
        "orderIncomings.table.items.unit": "Единица измерения",
        "orderIncomings.table.items.price": "Цена",
        "orderIncomings.table.items.numbers": "Количество",
        "orderIncomings.table.items.sale_price": "Стоимость",
        "orderIncomings.table.items.actions": "Действия",
        "orderIncomingsView.page.title": "Приходный ордер",
        "orderIncomingsView.list.order_num": "Номер заказа",
        "orderIncomingsView.list.contractor": "Контрагент",
        "orderIncomingsView.list.person": "Лицо",
        "orderIncomingsView.list.store": "Склад",
        "orderIncomingsView.list.created_at": "Дата создания документа",
        "orderIncomingsView.list.date_order": "Дата документа",
        "orderIncomingsView.list.cost": "Стоимость",
        "orderIncomingsView.table.inventory_item-title": "Наименование",
        "orderIncomingsView.table.unit-title": "Единица измерения",
        "orderIncomingsView.table.count": "Количество",
        "orderIncomingsView.table.price": "Цена",
        "orderIncomingsView.table.cost": "Стоимость",
    }

}

export default orderIncomings;