export const getHandleChange = (data, setData, setValidationStatus) => (name) => (event) => {
  let value;
  if (name === 'is_active') {
    value = event?.target.checked;
  } else {
    value = event?.target.value;
  }

  setData({
    ...data,
    [name]: value,
  });
  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [name]: {
      isValid: true,
      errorMessage: ''
    }
  }));
};
