export const getLayoutRowCellsData = (dataItem, __) => {
  const userName = dataItem && `${dataItem.user.first_name} ${dataItem.user.last_name}`;

  return {
    userName: {
      th: 'Користувач',
      td: dataItem && userName,
      sort: null,
    },
    userPhone: {
      th: 'Телефон',
      td: dataItem && dataItem.user.login,
      sort: null,
    },
    userEmail: {
      th: 'Email',
      td: dataItem && dataItem.user.email,
      sort: null,
    },
  }
}