import axios from '../../helpers/private.axios';

export const
    getInventoryItems = async (
        sortOption,
        sortDirection,
        searchTitle,
        inventoryItemTypeFilter,
        currentPage,
        itemsPerPage,
        showArchive,
        allStatus,
    ) => {
        const params = {};

        if (sortOption && sortDirection !== 0) {
            params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
        }

        if (searchTitle) {
            params['search-title'] = searchTitle;
        }

        if (inventoryItemTypeFilter) {
            params['inventory-item-type'] = inventoryItemTypeFilter;
        }

        if (showArchive) {
            params.status = 'archive';
        }

        if (allStatus) {
            params.status = 'all';
        }

        params.page = currentPage;
        params.limit = itemsPerPage;

        return axios.get(`/inventory-items`, { params });
    };

