import { TextField } from '@mui/material';
import React from 'react';

export const Automatic = ({
  data,
  dataName,
  tabLocale,
  validationStatus,
  handleLocalizationChange,
  fieldsModel,
  formDisplayControl = false,
  isDisplayControl,
  __  
}) => {
  return (
    <>
    {
      fieldsModel.map( field => {
        const { fieldName, required, fieldProps, options, displayControl } = field;
        let showField = true;
        let value = data?.locale[tabLocale] ? data?.locale[tabLocale][fieldName] : '';

        if (formDisplayControl && displayControl) {
          showField = isDisplayControl;
        }

        if (options?.value) {
          value = data?.locale[tabLocale]?.[fieldName] ? data?.locale[tabLocale]?.[fieldName].slice(0, options.value.maxLength) : '';
        }

        return (
          <React.Fragment key={ fieldName }>
            {
              showField &&
                <TextField
                  fullWidth
                  value={ value }
                  onChange={ handleLocalizationChange(tabLocale) }
                  name={fieldName}
                  label={ `${ __(`${ dataName }.form.${ fieldName }`) } ${ tabLocale }${ required ? ' *' : '' }` }
                  error={ required ? !validationStatus?.[tabLocale]?.[fieldName] : false }
                  helperText={ required ? validationStatus?.[tabLocale]?.[fieldName]?.errorMessage : '' }
                  className={ required ? (validationStatus?.[tabLocale]?.[fieldName]?.isValid ? '' : 'input-error') : '' }
                  { ...fieldProps }
                />
            }
          </React.Fragment>
          
        )
      } )
    }
    </>
  );
};