import axios from '../../helpers/private.axios';

export const getLogs = async (
    sortOption,
    sortDirection,
    currentPage,
) => {
    const params = {};

    params.page = currentPage;
    
    return axios.get(`/logs`, { params });
};
