export const RESOURCES = {
  // Admin
  DASHBOARD: 'DASHBOARD',
  SCHEDULE: 'SCHEDULE',

  // clients
  CLIENT: 'CLIENT',
  CLIENT_BASE: 'CLIENT_BASE',
  CLIENT_LOYALITY: 'CLIENT_LOYALITY',
  CL_ABONNEMENTS: 'CL_ABONNEMENT',
  CL_CERTIFICATES: 'CL_CERTIFICATE',

  // horses
  HORSES: 'HORSES',
  HORSES_SCHEDULE: 'HORSES_SCHEDULE',
  HORSES_SCHEDULE_MANAGE: 'HORSES_SCHEDULE_MANAGE',

  // coaches
  COACHES: 'COACHES',
  COACHES_SCHEDULE: 'COACHES_SCHEDULE',
  COACHES_SCHEDULE_MANAGE: 'COACHES_SCHEDULE_MANAGE',

  // inventory controls
  TMC_CONTROLS: 'TMC_CONTROLS',
  TMC_AVAILABILITY: 'TMC_AVAILABILITY',
  STOCKTAKING: 'STOCKTAKING',
  TURNOVERS: 'TURNOVERS',

  // movement inventory items
  TMC_MOVEMENT: 'TMC_MOVEMENT', 
  ORDER_INCOMINGS: 'ORDER_INCOMINGS',
  ORDER_OUTCOMINGS: 'ORDER_OUTCOMINGS',
  WRITE_OFFS: 'WRITE_OFFS',

  // reference books
  REFERENCE_BOOKS: 'REFERENCE_BOOKS',
  RB_TMC: 'RB_TMC',
  RB_SERVICES: 'RB_SERVICES',
  RB_SEASON_TICKETS: 'RB_SEASON_TICKETS',
  RB_PERSONS: 'RB_PERSONS',
  RB_USERS: 'RB_USERS',
  RB_HORSES: 'RB_HORSES',
  RB_COACHES: 'RB_COACHES',
  RB_EXPENDITURE_NORMS: 'RB_EXPENDITURE_NORMS',

  // reference books additionals
  REFERENCE_BOOKS_ADDITIONALS: 'REFERENCE_BOOKS_ADDITIONALS',
  RBA_RULES: 'RBA_RULES',
  RBA_SERVICES_CATEGORIES: 'RBA_SERVICES_CATEGORIES',
  RBA_HORSES_CATEGORIES: 'RBA_HORSES_CATEGORIES',
  RBA_SEASON_TICKETS_CATEGORIES: 'RBA_SEASON_TICKETS_CATEGORIES',
  RBA_TMC_TYPES: 'RBA_TMC_TYPES',
  RBA_USERS_CATEGORIES: 'RBA_USERS_CATEGORIES',

  // analitics
  ANALITICS: 'ANALITICS',
  INDICATORS_FINANCIAL: 'INDICATORS_FINANCIAL',
  INDICATORS_ACTIVITY: 'INDICATORS_ACTIVITY',

  // logs
  LOGS: 'LOGS',
}