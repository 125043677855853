const create = {
  uk: {
    'create.button.title': 'Створити'
  },
  en: {
    'create.button.title': 'Create'
  },
  ru: {
    'create.button.title': 'Создать'
  }
};


export default create;
