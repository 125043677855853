import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const MultiAxisLineChart = ({ chartOptions }) => {
  return (
    <>
      {
        chartOptions && 
          <Line options={chartOptions?.options} data={chartOptions?.data} />
      }
    </>
  )
}