import axios from '../../helpers/private.axios';

export const updatePerson = (id, data) => {
  return axios.put(`/persons/${id}`, data)
    .then(res => res)
    .catch((error) => {
      return {
        status: error.response.status,
        errors: error.response.data.errors,
      };
    });
};
