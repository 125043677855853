import { FormControl, IconButton } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export const FilterDate = ({ date, setDate }) => {
  return (
    <div className="input-wrap">
      <FormControl
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          '.MuiInputBase-input': { boxSizing: 'border-box' },
          '.MuiInputBase-input': { pr: '40px', width: '100%' },
          '.MuiOutlinedInput-root': { width: '100%' },
          '.MuiTextField-root': { width: '100%' }
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            // sx={{ ml: 4, mr: 4 }}
            onChange={(value) => setDate(value)}
            value={date}
          />
          <IconButton
            className="input-button-icon"
            aria-label="reset"
            size="small"
            variant="contained"
            sx={{ right: '35px' }}
            onClick={() => setDate(null)}>
            <RestartAltIcon />
          </IconButton>
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

FilterDate.defaultProps = {
  menuItemDefaulValue: '',
  menuItemDefaultText: 'none'
};
