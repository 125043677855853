import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText
} from '@mui/material';

import { getModelTypes, getServiceCategories, getUnit } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

import { ImageUpload } from './../../library/ImageUpload';
import previewPicture from './../../library/ImageUpload/assets/img/no-image.png';

import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';
import config from 'src/config';


export const ServicesForm = ({
  services,
  setServices,
  handleChange,
  handleLocalizationChange,
  action,
  ctaButtonText,
  validationStatus
}) => {
  const { __ } = useTranslation();
  const [{ loaded, serviceCategories, modelTypes }, setData] = useState({
    loaded: false,
    serviceCategories: [],
    modelTypes: [],
    // units: [],
  });
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: serviceCategories } = await getServiceCategories();
        const { data: modelTypes } = await getModelTypes();
        // const { data: units } = await getUnit();

        setData({
          loaded: true,
          serviceCategories,
          modelTypes: Object.entries(modelTypes),
          // units
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    
    const cats = getCategoryOfcurrentType(services?.service_category?.type, serviceCategories.data || []);
    const catId = cats.length ? cats[0].id : '';
    setServices({...services, service_category_id: catId});
  }, [services?.service_category?.type]);

  return (
    <>
      <LocaleTabs 
        data={ services }
        setData={ setServices }
        dataName={ 'services' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />

      <FormControl>
        <InputLabel>{__('services.input.model_type')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('services.input.model_type')}
          name="type"
          value={services?.service_category?.type || config.system_mode_default}
          onChange={handleChange('type')}>
          {
            modelTypes &&
              modelTypes.map(typeData => {
                const [key, type] = typeData;
                console.log(key);
                return (
                  <MenuItem key={key} value={key}>
                    { type }
                  </MenuItem>
                )
            })
          }
        </Select>
      </FormControl>

      <FormControl className={validationStatus.service_category_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('services.input.service_category_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('services.input.service_category_id')}
          name="service_category_id"
          value={services?.service_category_id ? services?.service_category_id : services?.service_category?.category_id}
          onChange={handleChange('service_category_id')}>
          {serviceCategories.data &&
            serviceCategories.data.reduce((jsxData, category) => {
              if (category.type === services?.service_category?.type) {
                return [
                    ...jsxData, 
                    <MenuItem key={category.id} value={category.id}>
                      {category.title}
                    </MenuItem>
                ]} else {
                  return [...jsxData];
                }
            }, [])
          }
        </Select>
        {!validationStatus.service_category_id.isValid && (
          <FormHelperText>{validationStatus.service_category_id.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <TextField
        fullWidth
        value={services?.amount}
        name="amount"
        label={__('services.input.amount')}
        onChange={handleChange('amount')}
        error={!validationStatus.amount.isValid}
        helperText={validationStatus.amount.errorMessage}
        className={validationStatus.amount.isValid ? '' : 'input-error'}
      />

      <ImageUpload 
        value={services?.image} 
        onChange={handleChange('image')} 
        fileParams={{ width: 1500, height: 1500, size: 500 }}
        previewPicture={ previewPicture }
      />

      <label className="checkbox">
        <Checkbox
          name="available_for_online"
          checked={services?.available_for_online}
          onChange={handleChange('available_for_online')}
        />
        <p>{__('services.input.available_for_online')}</p>
      </label>
      <label className="checkbox">
        <Checkbox
          name="is_active"
          checked={services?.is_active}
          onChange={handleChange('is_active')}
        />
        <p>{__('services.input.is_active')}</p>
      </label>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};


function getCategoryOfcurrentType(type, categoriesArr) {
  return categoriesArr.filter(category => type === category.type);
}