import axios from '../../helpers/private.axios';

export const getChartWorkloadsCoach = async (userParams) => {
    const params = {
        ...userParams,
    };

    params.modelType = 'coaches';

    return axios.get(`/dashboards/workload`, { params })
        .then(res => res)
        .catch(error => error);
};

