import { useEffect, useState } from 'react';
import { SYSTEM_TYPES } from 'src/constants';
import { Link } from 'react-router-dom';
import { BookingHeader } from './bookingHeader';
import { CircularProgress } from '@mui/material';

import { getBookingModelsServices } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useSelector } from 'react-redux';
import { ModeToggle } from './../../common/Layout/ModeToggle';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

export const BookingServices = () => {
  const modelType = useSelector((state) => state.mode);
  const { systemType } = useSelector(state => state.systemType);
  const isDual = systemType === SYSTEM_TYPES.DUAL;

  console.log(systemType, SYSTEM_TYPES.DUAL);

  const { __ } = useTranslation();

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleServiceClick = (id, title) => {
    if (selectedServiceId === id) {
      setSelectedServiceId(null);
    } else if (selectedServiceId !== null) {
      return;
    } else {
      setSelectedServiceId(id);
    }
  };

  const isServiceActive = (id) => {
    return selectedServiceId === id;
  };

  const shouldAddNoActiveClass = (id) => {
    return selectedServiceId !== null && selectedServiceId !== id;
  };

  const isBookingActive = selectedServiceId !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingModelsServices(modelType.mode);
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [modelType.mode]);

  return (
    <>
      <BookingHeader />
      <main className="main-booking">
        <div className="booking-wrap">
          <div className="booking-tabs">
            <Link className="button  button--width100p" to="/booking/services">
              <p>{__('booking.services.tabs.services')}</p>
            </Link>
            <Link className="button button--type2 button--width100p" to="/booking/season-tickets">
              <p>{__('booking.services.tabs.seasonTickets')}</p>
            </Link>
            <Link className="button button--type2  button--width100p " to="/booking/certificates">
              <p>{__('booking.services.tabs.certificate')}</p>
            </Link>
          </div>

          { isDual && <ModeToggle size="large" sx={{ mt: 3 }} isExternal /> }

          <div className="booking-services">
            <div className="booking-title">
              <Link className="booking-title__back" to="/booking/">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <p>{__('booking.services.title')}</p>
            </div>
            <div className="booking-services-list">
              {loaded ? (
                data.map((service) => (
                  <div
                    className={
                      `booking-services-list__item 
                      ${isServiceActive(service.id) ? 'active' : ''} 
                      ${shouldAddNoActiveClass(service.id) ? 'no-active' : ''}`
                    }
                    key={service.id}
                    onClick={() => handleServiceClick(service.id)}
                  >
                    <div className="booking-services-list__item-photo">
                      <img src={ service.image || noImage } alt="" />
                    </div>
                    <div className="booking-services-list__item-info">
                      <div className="booking-services-list__item-title">
                        <p>{service.title}</p>
                      </div>
                      <div className="booking-services-list__item-text">
                        <p>{service.description}</p>
                      </div>
                      <div className="booking-services-list__item-amount">
                        <p>{service.amount}</p>
                      </div>
                      <div className="booking-services-list__icon-check">
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <CircularProgress />
              )}
            </div>
            <div className={`booking-action ${isBookingActive ? 'active' : ''}`}>
              <div className="booking-action-inside">
                <Link
                  className="button button--width100p"
                  to={`/booking/services/${selectedServiceId}`}>
                  <p>{__('booking.button.next')}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
