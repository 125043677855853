import { flatingLocaleData } from "./../../../library";
import dayjs from "dayjs";

export const getDataCloneForSend = (data) => {
  let dataClone = { ...data };
  const flatLocaleData = flatingLocaleData(dataClone);

  delete dataClone.locale;
  dataClone = {...dataClone, ...flatLocaleData};

  dataClone.is_active = Number(dataClone.is_active);

  dataClone.date_of_birth = dayjs(dataClone.date_of_birth).format('YYYY-MM-DD');
    
  return dataClone;
}