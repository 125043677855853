export const flatArrToObj = (arr, name) => {
  const obj = {};

  arr.forEach((item, index) => {
    obj[`${name}[${index}]`] = item
  });

  return obj;
}

