import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  deleteCertificates,
  getCertificates,
  updateCertificateStatus,
  updateCertificates
} from '../../../../../actions';
import { useTranslation } from '../../../../../helpers/translate';
import {
  TableAction,
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
} from '../../../../common/TableComponent';

import { getRowCellsData } from './getRowCellsData';
import { ButtonIcon, ButtonLink, FilterSearch, ToggleShowActive } from '../../../../common/FormsComponent';
import { UserSelectComponent } from '../../../../common';
import { getDateValidOrder } from './../../../../library';
import { rolesComponentConfig } from './rolesComponentConfig';

export const CertificatesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [userSearch, setUserSearch] = useState(null);
  const [titleSearch, setTitleSearch] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [archive, setArchive] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [updateStatus, setUpdateStatus] = useState(false);

  const userData = {};
  const userChangeHandler = (data) => {
    const userId = data?.user?.id;
    setUserSearch(userId);
  };

  const changeStatusHandler = async (id) => {
    await updateCertificateStatus(id);
    setUpdateStatus((prev) => !prev);
  };

  const handleCertificatesDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteCertificates(id).then(async () => {
        const { data: response } = await getCertificates(
          archive,
          userSearch,
          titleSearch,
          currentPage,
          itemsPerPage
        );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getCertificates(
          archive,
          userSearch,
          titleSearch,
          currentPage,
          itemsPerPage
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response?.meta.last_page);
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [archive, userSearch, titleSearch, currentPage, itemsPerPage, updateStatus]);

  return (
    <TableMain
      title={__('certificates.page.title')}
      getRowCellsData={getRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}>
      <TableAction className="table-action">
        <div className="table-action__block">
          <FilterSearch
            label="Номер сертифіката"
            name="filter_search_sertficate_number"
            value={titleSearch}
            handler={(e) => setTitleSearch(e.target.value)}
          />

          <UserSelectComponent
            data={userData}
            dataChangeHandler={userChangeHandler}
            direction="row"
            isValidation={false}
          />
        </div>
        <div className="table-action__block">
          <ToggleShowActive value={archive} onChange={(e) => setArchive((prev) => !prev)} />
          <ButtonLink text={__('create.button.title')} route="create" checkRoles={ rolesComponentConfig } permission={PERMISSIONS.ACTIONS.CREATE}/>
        </div>
      </TableAction>

      <TableBlock __class="table--certificates table--mob-1660">
        <TableHeader />
        <TableBody loaded={loaded}>
          {data.map((certificates) => {
            const validOrder =
              getDateValidOrder(certificates.date_end) && certificates.quantity_hours_left;

            return (
              <TrBody
                key={certificates.id}
                data={certificates}
                style={{ background: !validOrder && '#f2f2f2' }}>

                <ToggleShowActive
                  noTitle
                  value={certificates.is_active}
                  onChange={() => changeStatusHandler(certificates.id)}
                  checkRoles={ rolesComponentConfig } 
                  permission={PERMISSIONS.ACTIONS.UPDATE}
                />

                <ButtonIcon
                  icon={<DeleteIcon />}
                  onClick={handleCertificatesDelete(certificates.id)}
                  checkRoles={ rolesComponentConfig } 
                  permission={PERMISSIONS.ACTIONS.DELETE}
                />
                  
              </TrBody>
            );
          })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
