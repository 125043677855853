const horses = {
    uk: {
        "horses.page.title": "Коні",
        "horses.search.name": "Прізвисько",
        "horses.select.categories": "Категорії*",
        "horses.select.services": "Послуги*",
        "horses.select.status": "Статуси*",
        "horses.filter.select.categories": "Категорії",
        "horses.filter.select.status": "Статуси",
        "horses.input.passport": "Паспорт*",
        "horses.input.date_of_birth": "Дата народження",
        "horses.table.name": "Прізвисько",
        "horses.table.image": "Фото",
        "horses.table.description": "Опис",
        "horses.table.passport": "Паспорт",
        "horses.table.сategories": "Категорії",
        "horses.table.services": "Послуги",
        "horses.table.date_of_birth": "Дата народження",
        "horses.table.status": "Статус",
        "horses.table.is_active": "Активний/Неактивний",
        "horses.table.actions": "Дії",
        "horses.form.title-create": "Створити коня",
        "horses.form.title-edit": "Редагувати коня",
        "horses.form.button": "Зберегти",
        "horses.form.is_active": "Активний/Неактивний",
        "horses.form.name.error": "*Поле є обов'язковим",
        "horses.form.description.error": "*Поле є обов'язковим",
        "horses.form.passport.error": "*Поле є обов'язковим",
        "horses.form.date_of_birth.error": "*Поле є обов'язковим, дата повинна бути дійсна",
        "horses.form.horsCategories.error": "*Поле є обов'язковим",
        "horses.form.services.error": "*Поле є обов'язковим",
        "horses.form.status.error": "*Поле є обов'язковим",
        "horses.filter.select.status.all": "Усі"
    },
    en: {
        "horses.page.title": "Horses",
        "horses.search.name": "Nickname",
        "horses.select.categories": "Categories*",
        "horses.select.services": "Serivces*",
        "horses.select.status": "Statuses*",
        "horses.filter.select.categories": "Categories",
        "horses.filter.select.status": "Statuses",
        "horses.input.passport": "Passport*",
        "horses.input.date_of_birth": "Date of Birth",
        "horses.table.name": "Nickname",
        "horses.table.image": "Photo",
        "horses.table.description": "Description",
        "horses.table.passport": "Passport",
        "horses.table.сategories": "Categories",
        "horses.table.services": "Services",
        "horses.table.date_of_birth": "Date of Birth",
        "horses.table.status": "Status",
        "horses.table.is_active": "Active/Inactive",
        "horses.table.actions": "Actions",
        "horses.form.title-create": "Create Horse",
        "horses.form.title-edit": "Edit Horse",
        "horses.form.button": "Save",
        "horses.form.is_active": "Active/Inactive",
        "horses.form.name.error": "*Field is required",
        "horses.form.description.error": "*Field is required",
        "horses.form.passport.error": "*Field is required",
        "horses.form.date_of_birth.error": "*Field is required, date must be valid",
        "horses.form.horsCategories.error": "*Field is required",
        "horses.form.services.error": "*Field is required",
        "horses.form.status.error": "*Field is required",
        "horses.filter.select.status.all": "All"
    },
    ru: {
        "horses.page.title": "Лошади",
        "horses.search.name": "Прозвище",
        "horses.select.categories": "Категории*",
        "horses.select.services": "Услуги*",
        "horses.select.status": "Статусы*",
        "horses.filter.select.categories": "Категории",
        "horses.filter.select.status": "Статусы",
        "horses.input.passport": "Паспорт*",
        "horses.input.date_of_birth": "Дата рождения",
        "horses.table.name": "Прозвище",
        "horses.table.image": "Фото",
        "horses.table.description": "Описание",
        "horses.table.passport": "Паспорт",
        "horses.table.сategories": "Категории",
        "horses.table.services": "Услуги",
        "horses.table.date_of_birth": "Дата рождения",
        "horses.table.status": "Статус",
        "horses.table.is_active": "Активный/Неактивный",
        "horses.table.actions": "Действия",
        "horses.form.title-create": "Создать лошадь",
        "horses.form.title-edit": "Редактировать лошадь",
        "horses.form.button": "Сохранить",
        "horses.form.is_active": "Активный/Неактивный",
        "horses.form.name.error": "*Поле обязательно",
        "horses.form.description.error": "*Поле обязательно",
        "horses.form.passport.error": "*Поле обязательно",
        "horses.form.date_of_birth.error": "*Поле обязательно, дата должна быть действительной",
        "horses.form.horsCategories.error": "*Поле обязательно",
        "horses.form.services.error": "*Поле обязательно",
        "horses.form.status.error": "*Поле обязательно",
        "horses.filter.select.status.all": "Все"
    }
};



export default horses;