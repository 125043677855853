import { getBookingPaymentType } from "./../../../../../actions/booking";

export const fetchPaymentType = async (visitId, setPaymentAccessibility) => {
  try {
    const res = await getBookingPaymentType(visitId);
    const data = res?.data?.data;
    const certificate = data?.certificate;
    const abonement = data?.abonement;
    const paymentAccessibility = {};

    if (certificate) {
      paymentAccessibility.certificate = certificate;
    }
  
    if (abonement) {
      paymentAccessibility.abonnement = abonement;
    }

    setPaymentAccessibility(paymentAccessibility);
    if (!data) {
      return res;
    }
  } 
  catch (error) {
    // console.warn(error);
  }
}