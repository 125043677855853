import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { createExpenditureNormsWriteOffs, getExpenditureNormsView } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ButtonSend } from './../../common/FormsComponent';

export const ExpenditureNormsView = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const params = useParams();
  const [orderCreated, setOrderCreated] = useState(null);

  const handleCreateOrderBtn = async () => {
    setOrderCreated(null);
    const res = await createExpenditureNormsWriteOffs(data.id);
    const orderOutcomingId = res?.data?.order_outcoming_id;

    if (orderOutcomingId) {
      setOrderCreated(orderOutcomingId);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getExpenditureNormsView(params.id);
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  const periodLabels = {
    0: __('expenditureNorms.input.period.day'),
    1: __('expenditureNorms.input.period.week'),
    2: __('expenditureNorms.input.period.month'),
    3: __('expenditureNorms.input.period.quarter'),
    4: __('expenditureNorms.input.period.year')
  };

  return (
    <>
      <div className="title-back">
        <div className="title-back__link">
          <Link to="/expenditure-norms">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                fill="black"></path>
            </svg>
          </Link>
        </div>
        <div className="title">
          {__('expenditureNormsView.page.title')} {params.id}
        </div>
      </div>
      <div className="view-elemet-wrap">
        <div className="view-elemet-list">
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.name')}:</p>
            <span>{data?.name}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.created_at')}:</p>
            <span>{data.created_at ? formatDate(data.created_at) : ''}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.horse')}:</p>
            <span>{data.horse?.name}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.period')}:</p>
            <span>{periodLabels[data.period]}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.status')}:</p>
            <span>{data.status ? __('general.table.active') : __('general.table.inactive')}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('expenditureNormsView.list.total')}:</p>
            <span>{data.total}</span>
          </div>
        </div>
        <div className="view-elemet-table">
          <div className="table table--expenditure-norms-view">
            <div className="table-header">
              <div className="tr">
                <div className="td">
                  <div className="td-name">
                    <p>№</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('expenditureNormsView.table.inventory_item-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('expenditureNormsView.table.quantity')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('expenditureNormsView.table.unit-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('expenditureNormsView.table.cost')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('expenditureNormsView.table.total')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {data.items?.map((item, index) => (
                <div className="tr" key={item.id}>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>№</p>
                    </div>
                    <div className="td-content">
                      <p>{index + 1}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('expenditureNormsView.table.inventory_item-title')}</p>
                    </div>
                    <div className="td-content">{item.title}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('expenditureNormsView.table.quantity')}</p>
                    </div>
                    <div className="td-content">{item.quantity}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('expenditureNormsView.table.unit')}</p>
                    </div>
                    <div className="td-content">{item.unit}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('expenditureNormsView.table.cost')}</p>
                    </div>
                    <div className="td-content">{item.cost}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('expenditureNormsView.table.total')}</p>
                    </div>
                    <div className="td-content">{item.cost * item.quantity}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {data.status ? (
          <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column' }}>
            <ButtonSend onClick={handleCreateOrderBtn} text="Сформувати розхідний ордер" />
            {orderCreated && (
              <Link
                to={`/order-outcomings/${orderCreated}`}
                state={`/expenditure-norms/${data.id}`}>
                <Typography variant="h5" sx={{ mt: 3 }}>
                  Переглянути розхідний ордер № {orderCreated}
                </Typography>
              </Link>
            )}
          </Box>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
