import { useEffect, useState } from 'react';

import { createUser, getUserCategories, getUserRoles } from '../../../actions/users';

import { useNavigate } from 'react-router-dom';

import { ModalForm, serverErrorHandler } from './../../common';
import { UserFormEdit } from './UserFormEdit';
import { validate, validationModel } from './validation';

export const CreateUser = ({ opened }) => {
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/users');
  };

  const [errorMessages, setErrorMessages] = useState({});
  const [categories, setCategories] = useState([]);
  const [roles, setRoles] = useState([]);

  const [validationStatus, setValidationStatus] = useState(validationModel);

  const [user, setUser] = useState({
    password: '',
    password_confirmation: '',
    first_name: '',
    last_name: '',
    login: '',
    locale: '',
    is_active: 1,
    roles: [],
    categories: []
  });

  const handleChange = (name) => (event) => {
    let value;

    if (name === 'login') {
      value = value = event.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
    } else if (name === 'is_active') {
      value = event?.target.checked;
    } else if (name === 'categories') {
      value = [event?.target.value];
    } else {
      value = event?.target.value;
    }

    setUser({
      ...user,
      [name]: value
    });

    const isValid = validate(name, value, user);

    setValidationStatus({
      ...validationStatus,
      [name]: isValid
    });
  };

  const handleCreate = async () => {
    setErrorMessages({});
    user.login = user.login.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');

    try {
      const res = await createUser(user);

      if (res?.data?.success) {
        navigate('/users');
      }

      serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    (async () => {
      const categoriesRes = await getUserCategories();
      const categoriesData = categoriesRes.data.data;

      const rolesRes = await getUserRoles();
      const rolesData = rolesRes.data.data;

      setCategories(categoriesData);
      setRoles(rolesData);
    })();
  }, []);

  return (
    <ModalForm
      title="Створити користувача"
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <UserFormEdit
        user={user}
        validationStatus={validationStatus}
        categories={categories}
        roles={roles}
        handleChange={handleChange}
        action={handleCreate}
        ctaButtonText="Створити"
        extendedFormType="create"
      />
    </ModalForm>
  );
};
