import { getPersonalData } from '../../../../actions/cabinet';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { registration } from '../../../../actions/auth/registration';
import { PersonalDataForm } from '..';

export const PersonalData = () => {
  const [data, setData] = useState({
    user_id: 1,
    first_name: 'First',
    middle_name: 'Middle',
    last_name: 'Last',
    email: 'a@a.com',
    phone: '+380 12 345-67-89',
    gender: 'male',
    date_of_birth: '1970-01-01',
    passport_data: {code: "111", date: "1970-01-01", organ: "NFI", number: "123123"},
    address: 'City, Some Street, House 1 Apartment 1'
  });

  useEffect(() => {
    const fetchData = async () =>{
      try {
        const data = await getPersonalData();
        const userData = data?.data.data;
        userData && setData(userData);
      } catch (error) {
       console.error(error.message);
      }
    }
    fetchData();
  }, []);

  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState('');

  const [registrationObject, setRegistrationObject] = useState({
    user_id: 1,
    first_name: 'First',
    middle_name: 'Middle',
    last_name: 'Last',
    email: 'a@a.com',
    phone: '+380 12 345-67-89',
    gender: 'male',
    date_of_birth: '1970-01-01',
    passport_data: {code: "111", date: "1970-01-01", organ: "NFI", number: "123123"},
    address: 'City, Some Street, House 1 Apartment 1'
  });

  const [isValid, setIsValid] = useState({
    login: true,
    last_name: true,
    first_name: true,
    password: true,
    email: true,
    password_confirmation: true,
    personal_data_agreement: true
  });

  const changeHandler = (name) => (e) => {
    if (name === 'personal_data_agreement') {
      setRegistrationObject({ ...registrationObject, [name]: e.target.checked });
    } else if (name === 'login') {
      setRegistrationObject({
        ...registrationObject,
        [name]: e.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5')
      });
    } else {
      setRegistrationObject({ ...registrationObject, [name]: e.target.value });
    }
  };

  const {
    login,
    last_name,
    first_name,
    email,
    password,
    password_confirmation,
    personal_data_agreement
  } = registrationObject;

  const submitFormHandler = async () => {
    setIsValid({
      ...isValid,
      login: !!login.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i),
      first_name,
      last_name,
      email: !email || (email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)),
      password,
      password_confirmation: password === password_confirmation,
      personal_data_agreement: personal_data_agreement
    });

    if (
      login.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i) &&
      first_name &&
      last_name &&
      password &&
      (!email || (email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))) &&
      password === password_confirmation &&
      personal_data_agreement
    ) {
      try {
        await registration(registrationObject);
      } catch (e) {
        setPasswordError(e.response.data.errors.password[0]);
      }
      navigate('login');
    }
  };

  return (
    <PersonalDataForm
      changeHandler={changeHandler}
      registrationObject={data}
      isValid={isValid}
      submitFormHandler={submitFormHandler}
      passwordError={passwordError}
      disabled={ true }
    />
  );
};