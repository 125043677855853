import axios from '../../helpers/public.axios';
import { configurateToken } from '../../helpers/private.axios';

import { AUTH } from '../../constants';
import { dispatchSytemTypeAndMode } from './utils';

export const login = (
  phone, 
  password, 
  role, 
  errorHandler = error => console.warn(error)
) => (dispatch) => {
  dispatch({ type: AUTH.AUTH_REQUEST });

  axios
    .post(`/auth/login`, {
      login: phone,
      password
    })
    .then((res) => {
      const user = res.data.data;
      
      dispatch({
        type: AUTH.AUTH_RESPONSE,
        payload: {
          user: {...user, role},
          token: res.headers.authorization,
        }
      });

      dispatchSytemTypeAndMode(dispatch, user);
      configurateToken(res.headers.authorization);
    })
    .catch(error => {
      errorHandler();
    });
};
