import DialogTitle from '@mui/material/DialogTitle';
import { DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
import ClientCardSidebar from '../ClientCard/ClientCardSidebar';
import { useLocation } from 'react-router-dom';
import { ButtonBackward } from '../TableComponent';

export function EventDialog() {
  const { state } = useLocation();
  const temp = useLocation();
  const data = state?.visitData && JSON.parse(state?.visitData);
  
  const { open, clickedDate, extendedProps } = data;

  return (
    <Box open={open}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ButtonBackward route="/" />
        <DialogTitle>Карточка клієнта</DialogTitle>
      </Box>
      <DialogContent sx={{ p: '0px' }}>
        <Box>
          <ClientCardSidebar clickedDate={clickedDate} extendedProps={extendedProps} />
        </Box>
      </DialogContent>
    </Box>
  );
}
