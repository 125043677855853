const certificates = {
    uk: {
        "certificates.page.title": "Cертифікат",
        "certificates.table.code": "Код",
        "certificates.table.number": "Номер",
        "certificates.table.title": "Назва",
        "certificates.table.description": "Опис",
        "certificates.table.category": "Категорія",
        "certificates.table.service": "Послуга",
        "certificates.table.quantity_hours": "Кількість годин (всього)",
        "certificates.table.quantity_hours_last": "Кількість годин (залишилося)",
        "certificates.table.date_start": "Дата початку",
        "certificates.table.date_end": "Дата кінця",
        "certificates.table.day_last": "Термін дії (днів до закінчення)",
        "certificates.table.discount_amount": "Знижка сума",
        "certificates.table.discount_percent": "Знижка %",
        "certificates.table.person": "Клієнт",
        "certificates.table.is_active": "Активний/Неактивний",
        "certificates.table.is_valid": "Діючий/Недіючий",
        "certificates.table.valid": "діючий",
        "certificates.table.notvalid": "недіючий",
        "certificates.table.status_active": "Активний",
        "certificates.table.status_inactive": "Неактивний",
        "certificates.table.actions": "Дії",
        "certificates.form.title-create": "Створити сертифікат",
        "certificates.form.title-edit": "Редагувати сертифікат",
        "certificates.form.button": "Зберегти",
        "certificates.input.date_start": "Дата початку",
        "certificates.input.date_end": "Дата закінчення",
        "certificates.input.certificatesCategories": "Категорія сертифікату",
        
    },
    en: {
        "certificates.page.title": "Certificate",
        "certificates.table.code": "Code",
        "certificates.table.title": "Title",
        "certificates.table.number": "Number",
        "certificates.table.description": "Description",
        "certificates.table.category": "Category",
        "certificates.table.service": "Service",
        "certificates.table.quantity_hours": "Quantity hours (total)",
        "certificates.table.quantity_hours_last": "Quantity hours (last)",
        "certificates.table.date_start": "Start Date",
        "certificates.table.date_end": "End Date",
        "certificates.table.day_last": "Day last",
        "certificates.table.discount_amount": "Discount Amount",
        "certificates.table.discount_percent": "Discount %",
        "certificates.table.person": "Customer",
        "certificates.table.is_active": "Active/Inactive",
        "certificates.table.is_valid": "Valid/Not valid",
        "certificates.table.valid": "valid",
        "certificates.table.notvalid": "not valid",
        "certificates.table.status_active": "Active",
        "certificates.table.status_inactive": "Inactive",
        "certificates.table.actions": "Actions",
        "certificates.form.title-create": "Create Certificate",
        "certificates.form.title-edit": "Edit Certificate",
        "certificates.form.button": "Save",
        "certificates.input.date_start": "Start Date",
        "certificates.input.date_end": "End Date",
        "certificates.input.certificatesCategories": "Certificate Category",
    },
    ru: {
        "certificates.page.title": "Сертификат",
        "certificates.table.code": "Код",
        "certificates.table.title": "Название",
        "certificates.table.number": "Номер",
        "certificates.table.description": "Описание",
        "certificates.table.category": "Категория",
        "certificates.table.service": "Услуга",
        "certificates.table.quantity_hours": "Количество часов (всего)",
        "certificates.table.quantity_hours_last": "Количество часов (осталось)",
        "certificates.table.date_start": "Дата начала",
        "certificates.table.date_end": "Дата окончания",
        "certificates.table.day_last": "Срок действия (дней до окончания)",
        "certificates.table.discount_amount": "Скидки сумма",
        "certificates.table.discount_percent": "Скидка %",
        "certificates.table.person": "Клиент",
        "certificates.table.is_active": "Активный/Неактивный",
        "certificates.table.is_valid": "Действующий/Недействующий",
        "certificates.table.valid": "действующий",
        "certificates.table.notvalid": "недействующий",
        "certificates.table.status_active": "Активный",
        "certificates.table.status_inactive": "Неактивный",
        "certificates.table.actions": "Действия",
        "certificates.form.title-create": "Создать сертификат",
        "certificates.form.title-edit": "Редактировать сертификат",
        "certificates.form.button": "Сохранить",
        "certificates.input.date_start": "Дата начала",
        "certificates.input.date_end": "Дата окончания",
        "certificates.input.certificatesCategories": "Категория сертификата",
    }
}

export default certificates;