import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../helpers/translate';

import {
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  FilterSelect,
  TablePagination
} from '../../../common/TableComponent';
import { getRowCellsData } from './getRowCellsData';

import { getPersonalSeasonTickets } from '../../../../actions/cabinet';
import { getDateValidOrder } from './.././../../library';

export const PersonalSeasonTickets = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {}
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [visitStatusFilter, setVisitStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: abonements } = await getPersonalSeasonTickets(
          sortOption,
          sortDirection,
          visitStatusFilter,
          currentPage
        );
        const meta = abonements.meta;

        setData({
          loaded: true,
          data: {
            abonements: abonements.data,
            meta
          }
        });
      } catch (error) {
        console.warn('PersonalVisits fetch error: ', error);
      }
    };
    fetchData();
  }, [sortOption, sortDirection, visitStatusFilter, currentPage]);

  return (
    <TableMain
      title={'Абонементи'}
      getRowCellsData={getRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}>
      <TableBlock __class="table--personal-abonements">
        {/* <TableAction className="table-action">
        <div className="table-action__block">
          <FilterSelect 
              inputLabel={'Історія візитів'}
              selectLabel={'Історія візитів'}
              selectName="visits-history-select-filter"
              selectValue={visitStatusFilter}
              selectHandler={(e) => setVisitStatusFilter(e.target.value)}
            >
              {
                data &&
                data.statuses.map((status, index) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.title}
                  </MenuItem>
                ))
              }
            </FilterSelect>
        </div>
      </TableAction> */}
        <TableHeader />
        <TableBody loaded={loaded}>
          {data?.abonements?.map((abonement) => {
            const validOrder = getDateValidOrder(abonement.date_end) && abonement.visits_left;

            return (
              <TrBody
                key={abonement.id}
                data={abonement}
                style={{ background: !validOrder && '#f2f2f2' }}
              />
            );
          })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
