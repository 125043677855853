import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { filterSearchSelectStyle } from "../styles/styles";

export const FilterSelect = ({ label, name, value, onChange, dataSelect }) => {

  return (
    <div className="input-wrap">
      <FormControl
        sx={ filterSearchSelectStyle }>
        <InputLabel>{ label }</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={ label }
          name={ name }
          value={ value }
          onChange={(e) => onChange(e.target.value)}>
          <MenuItem value="">Показати всі</MenuItem>
          {
            dataSelect &&
              dataSelect?.map((dataSelectItem) => (
                <MenuItem key={dataSelectItem.id} value={dataSelectItem.id}>
                  {dataSelectItem.title}
                </MenuItem>
              ))
          }
        </Select>
      </FormControl>
    </div>
    
  );
};