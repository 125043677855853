export const handleSortButtonClick = (
  option,
  sortOption,
  setSortOption,
  sortDirection,
  setSortDirection
) => {
  if (sortOption === option && sortDirection === 1) {
    setSortDirection(-1);
  } else if (sortOption === option && sortDirection === -1) {
    setSortOption('');
    setSortDirection(0);
  } else {
    setSortOption(option);
    setSortDirection(sortDirection !== 0 ? sortDirection : 1);
  }
};