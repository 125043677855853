export const localeValidationData = {
  uk: {
    title: { isValid: true, errorMessage: '' },
    description: { isValid: true, errorMessage: '' },
  },
  ru: {
    title: { isValid: true, errorMessage: '' },
    description: { isValid: true, errorMessage: '' },
  },
  en: {
    title: { isValid: true, errorMessage: '' },
    description: { isValid: true, errorMessage: '' },
  }
};