import { CardMedia } from '@mui/material';
import { previewStyles } from './../assets/styles';

export const PreviewImgFile = ({ previewUrl, previewPicture }) => {
  return (
    <CardMedia
      component="img"
      sx={{ ...previewStyles }}
      image={ previewUrl ? previewUrl : previewPicture }
      alt="Preview"
    />
  );
};