import { toastError } from ".";

export const serverErrorHandler = (serverErrorMessages = {}, setErrorMessages) => {
  if (typeof serverErrorMessages !== 'object') {
    toastError([serverErrorMessages]);
    setErrorMessages([serverErrorMessages]);
    return;
  }

  const errorFields = Object.keys(serverErrorMessages);
  const errorMessage = errorFields
    .map((field) => serverErrorMessages[field].join('\n'))
    .join('<br>');

  toastError(errorMessage);
  setErrorMessages(serverErrorMessages);
}