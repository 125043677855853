import { useEffect, useState } from 'react';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';

import { getHorsesStatuses, getHorsesCategories, getHorsesServices } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { SelectMultyCheckbox } from './../../common/FormsComponent';
import { ImageUpload } from './../../library/ImageUpload';
import previewPicture from './../../library/ImageUpload/assets/img/no-image.png';
import dayjs from 'dayjs';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';

import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';

export const HorsesForm = ({
  horses,
  setHorses,
  action,
  selectedCategories,
  handleChange,
  handleLocalizationChange,
  ctaButtonText,
  validationStatus,
}) => {
  const { __ } = useTranslation();
  const [{ loaded, statuses, categories, services }, setData] = useState({
    loaded: false,
    statuses: {},
    categories: {},
    services: [],
  });

  const [selectedCategory, setSelectedСategory] = useState(selectedCategories || []);

  useEffect(() => {
    setSelectedСategory(horses.horsCategories);
  }, [horses.horsCategories]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: statusesResponse } = await getHorsesStatuses();
        const { data: categoriesResponse } = await getHorsesCategories();
        const { data: servicesResponse } = await getHorsesServices();

        setData({
          loaded: true,
          statuses: statusesResponse,
          categories: categoriesResponse,
          services: servicesResponse?.data,
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  console.log(horses);

  return (
    <>
      <LocaleTabs 
        data={ horses }
        setData={ setHorses }
        dataName={ 'horses' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        fillCheckbox
      />
      
      <TextField
        fullWidth
        value={horses?.passport}
        name="passport"
        label={__('horses.input.passport')}
        onChange={handleChange('passport')}
        error={!validationStatus.passport.isValid}
        helperText={validationStatus.passport.errorMessage}
        className={validationStatus.passport.isValid ? '' : 'input-error'}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('horses.input.date_of_birth')}
            value={dayjs(horses.date_of_birth)}
            onChange={handleChange('date_of_birth')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.date_of_birth.isValid}
            helperText={validationStatus.date_of_birth.errorMessage}
            className={validationStatus.date_of_birth.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>

      <SelectMultyCheckbox 
        fieldClassNames={ validationStatus.horsCategories.isValid ? '' : 'input-error' }
        label={ __('horses.select.categories') }
        dataList={ categories?.data }
        dataChecked={ horses?.horsCategories }
        error={ !validationStatus.horsCategories.isValid }
        errorMessage={ validationStatus.horsCategories.errorMessage }

        value={horses.horsCategories}
        onChange={handleChange('horsCategories')}
      />

      { console.log('datalist:', services) }
      { console.log('dataChecked:', horses?.services) }
      { console.log('value:', horses.services) }

      <SelectMultyCheckbox 
        fieldClassNames={ validationStatus?.services?.isValid ? '' : 'input-error' }
        label={ __('horses.select.services') }
        dataList={ services }
        dataChecked={ horses?.services }
        error={ !validationStatus.services.isValid }
        errorMessage={ validationStatus.services.errorMessage }

        value={horses.services}
        onChange={handleChange('services')}
      />

      <FormControl className={validationStatus.status.isValid ? '' : 'input-error'}>
        <InputLabel>{__('horses.select.status')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          value={horses?.status}
          label={__('horses.select.status')}
          name="status"
          onChange={handleChange('status')}
          error={!validationStatus.status.isValid}
          helperText={validationStatus.status.errorMessage}
          className={validationStatus.status.isValid ? '' : 'input-error'}>
          {Object.entries(statuses).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value}
            </MenuItem>
          ))}
        </Select>
        {!validationStatus.status.isValid && (
          <FormHelperText>{validationStatus.status.errorMessage}</FormHelperText>
        )}
      </FormControl>

      <ImageUpload 
        value={horses?.image} 
        onChange={handleChange('image')} 
        fileParams={{ width: 1500, height: 1500, size: 500 }}
        previewPicture={ previewPicture }
      />

      <label className="checkbox">
        <Checkbox
          name="is_active"
          value={horses?.is_active}
          checked={horses?.is_active || false}
          onChange={handleChange('is_active')}
        />
        <p>{__('horses.form.is_active')}</p>
      </label>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};