import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { a11yProps, TabPanel } from '../../../components/common/ClientCard/ClientCardSidebar/Utils';
import moment from 'moment';
import 'moment/locale/uk';
import {
  getAnyHorseDayTimeslots,
  getAnyHorseOnSomeDateTimeslots,
  getSomeHorseDayTimeslots,
  getSomeHorseOnSomeDateTimeslots
} from '../../../actions/horsesTimeslots';

export function SelectTime() {
  moment.locale('uk');
  const [currentDay, setCurrentDay] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(0);
  const [clickedTime, setClickedTime] = useState();
  const [coachTimeslots, setHorseTimeslots] = useState([]);
  const [monthCount] = useState([0, 1]);
  const [daysArray, setDaysArray] = useState([]);
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      if (id === 'any') {
        const response = await getAnyHorseDayTimeslots();
        setHorseTimeslots(response.data.data.filter((item) => moment(item.date).isAfter(moment())));
      } else {
        const response = await getSomeHorseDayTimeslots(id);
        setHorseTimeslots(response.data.data.filter((item) => moment(item.date).isAfter(moment())));
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      let countDaysTillMonthEnd;
      const daysArrayLocal = [];
      if (!currentMonth) {
        countDaysTillMonthEnd = moment().endOf('month').diff(moment(), 'days');
        for (let i = 0; i <= countDaysTillMonthEnd; i++) {
          // const response = await getSomeHorseOnSomeDateTimeslots(
          //   id,
          //   moment().add(i, 'days').format('YYYY-MM-DD')
          // );
          // daysArrayLocal.push({ dayShift: i, hasTimes: !!response.data.data.length });
          daysArrayLocal.push({ dayShift: i, hasTimes: 1 });
        }
      } else {
        countDaysTillMonthEnd = moment()
          .add(currentMonth, 'months')
          .endOf('month')
          .diff(moment().add(currentMonth, 'months').startOf('month'), 'days');
        for (let i = 0; i <= countDaysTillMonthEnd; i++) {
          // const response = await getSomeHorseOnSomeDateTimeslots(
          //   id,
          //   moment()
          //     .add(currentMonth, 'months')
          //     .startOf('month')
          //     .add(currentDay, 'days')
          //     .format('YYYY-MM-DD')
          // );
          // daysArrayLocal.push({ dayShift: i, hasTimes: !!response.data.data.length });
          daysArrayLocal.push({ dayShift: i, hasTimes: 1 });
        }
      }

      setDaysArray(daysArrayLocal);
    }

    fetchData();
  }, [currentMonth]);

  useEffect(() => {
    async function fetchData() {
      if (!currentMonth) {
        if (id === 'any') {
          const response = await getAnyHorseOnSomeDateTimeslots(
            moment().add(currentDay, 'days').format('YYYY-MM-DD')
          );
          if (!currentDay) {
            setHorseTimeslots(
              response.data.data.filter((item) => moment(item.date).isAfter(moment()))
            );
          } else {
            setHorseTimeslots(response.data.data);
          }
        } else {
          const response = await getSomeHorseOnSomeDateTimeslots(
            id,
            moment().add(currentDay, 'days').format('YYYY-MM-DD')
          );
          if (!currentDay) {
            setHorseTimeslots(
              response.data.data.filter((item) => moment(item.date).isAfter(moment()))
            );
          } else {
            setHorseTimeslots(response.data.data);
          }
        }
      } else {
        if (id === 'any') {
          const response = await getAnyHorseOnSomeDateTimeslots(
            moment()
              .add(currentMonth, 'months')
              .startOf('month')
              .add(currentDay, 'days')
              .format('YYYY-MM-DD')
          );
          if (!currentDay) {
            setHorseTimeslots(
              response.data.data.filter((item) => moment(item.date).isAfter(moment()))
            );
          } else {
            setHorseTimeslots(response.data.data);
          }
        } else {
          const response = await getSomeHorseOnSomeDateTimeslots(
            id,
            moment()
              .add(currentMonth, 'months')
              .startOf('month')
              .add(currentDay, 'days')
              .format('YYYY-MM-DD')
          );
          setHorseTimeslots(response.data.data);
        }
      }
    }

    fetchData();
  }, [currentDay, currentMonth]);

  const handleChangeTab = (_, newValue) => {
    setCurrentDay(0);
    setCurrentMonth(newValue);
  };

  return (
    <Box sx={{ maxWidth: 884, m: '0 auto' }}>
      {/* <Box sx={{ m: '0 auto' }}> */}
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          alignItems: 'center'
        }}>
        <Link to={'/select-coach/' + searchParams.get('serviceId') + '?coachId=' + id}>
          <ArrowBackIosNewIcon
            sx={{
              cursor: 'pointer',
              mr: '160px'
            }}
          />
        </Link>
        <Typography>Вибрати дату і час</Typography>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentMonth} onChange={handleChangeTab}>
            <Tab label={moment().format('MMMM')} {...a11yProps(0)} />
            <Tab label={moment().add(1, 'months').format('MMMM')} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {monthCount.map((item) => (
          <TabPanel value={currentMonth} index={item} key={item}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  maxWidth: '518px',
                  ml: '8px'
                }}>
                {daysArray.map(({ dayShift, hasTimes }) => (
                  <Typography
                    color="text.secondary"
                    sx={{
                      cursor: hasTimes ? 'pointer' : 'not-allowed',
                      opacity: hasTimes ? 1 : 0.5
                    }}
                    key={dayShift}
                    onClick={() => setCurrentDay(item)}>
                    {!currentMonth
                      ? moment().add(dayShift, 'days').format('dddd').toUpperCase()[0]
                      : moment()
                          .add(currentMonth, 'months')
                          .startOf('month')
                          .add(dayShift, 'days')
                          .format('dddd')
                          .toUpperCase()[0]}
                  </Typography>
                ))}
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {daysArray.map(({ dayShift, hasTimes }) => (
                  <Box
                    key={dayShift}
                    onClick={() => {
                      if (hasTimes) setCurrentDay(dayShift);
                    }}
                    sx={{
                      height: '30px',
                      width: '30px',
                      background: dayShift === currentDay ? 'red' : null,
                      color: dayShift === currentDay ? 'white' : 'black',
                      borderRadius: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: hasTimes ? 'pointer' : 'not-allowed',
                      opacity: hasTimes ? 1 : 0.5,
                      mb: '20px'
                    }}>
                    <Typography>
                      {!currentMonth
                        ? moment().add(dayShift, 'days').format('D')
                        : moment()
                            .add(currentMonth, 'months')
                            .startOf('month')
                            .add(dayShift, 'days')
                            .format('D')}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {coachTimeslots.length === 0 ? (
                <Typography>Сьогодні немає вільних слотів для запису</Typography>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    cursor: 'pointer'
                  }}>
                  {coachTimeslots.map((item) => (
                    <Typography
                      sx={{
                        background: item.id === clickedTime?.id ? 'red' : null,
                        color: item.id === clickedTime?.id ? 'white' : 'black',
                        borderRadius: '70px',
                        p: '3px'
                      }}
                      onClick={() => setClickedTime(item)}
                      key={item.id}>
                      {item.date.slice(11, 16)}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          </TabPanel>
        ))}
      </Box>
      {clickedTime ? (
        <Link
          to={`/create-record/${id}?date=${moment()
            .add(currentDay, 'days')
            .format('YYYY-MM-DD')}&time=${clickedTime?.date.slice(
            11,
            16
          )}&serviceId=${searchParams.get('serviceId')}`}>
          <Box
            sx={{
              background: 'blue',
              color: 'white',
              borderRadius: 5,
              height: 48,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: '865px',
              width: '584px',
              cursor: 'pointer'
            }}>
            <Typography>Продовжити</Typography>
          </Box>
        </Link>
      ) : null}
    </Box>
  );
}
