import axios from '../../helpers/private.axios';
import { path } from './constants';

export const getModelDateTimeslots = (modelId, modelType, value) => {
  const params = {
    modelType,
    modelId,
    date: value,
  }
  
  return axios.get(`/${path}/week`, { params });
};
