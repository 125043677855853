import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthWrapper, LocalizationWrapper } from './common';

import store from '../redux/store';

import { configurateToken, configurateLocale } from '../helpers/private.axios';

if( store.getState().auth.token !== null ){
  configurateToken(store.getState().auth.token);
  configurateLocale(store.getState().localization.locale)
}

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocalizationWrapper>
          <AuthWrapper />
        </LocalizationWrapper>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
