import { useEffect, useState } from 'react';
import { useTranslation } from '../../../../helpers/translate';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CachedIcon from '@mui/icons-material/Cached';

import {
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  TablePagination,
  ButtonIcon
} from '../../../common/TableComponent';
import { getRowCellsData } from './getRowCellsData';

import { getPersonalOrders, setPaymentHistoryOrder } from '../../../../actions/cabinet';
import { s } from '../styles';
import { ToggleShowActive } from './../../../common/FormsComponent';
import { getCollapseRowCellsData } from './getCollapseRowCellsData';

export const PersonalHistory = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {
      orders: [],
      meta: []
    }
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [reload, setReload] = useState(false);

  const paymentHandler = async (id) => {
    const sendData = new FormData();
    sendData.append('order', id);
    const res = await setPaymentHistoryOrder(sendData);

    if (res?.data?.data) {
      const url = res?.data?.data?.url;
      const requestUrl = decodeURIComponent(url);

      window.open(requestUrl, '_blank');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: orders } = await getPersonalOrders(sortOption, sortDirection, currentPage);
        const meta = orders.meta;

        setData({
          loaded: true,
          data: {
            orders: orders.data,
            meta
          }
        });
      } catch (error) {
        console.warn('PersonalOrders fetch error: ', error);
      }
    };
    fetchData();
  }, [sortOption, sortDirection, currentPage, reload]);

  return (
    <TableMain
      sx={{ background: 'red' }}
      title={'Мої замовлення'}
      getRowCellsData={getRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      collapsible={true}
      collapsibleTableTitle={['first_name', 'last_name', 'phone', 'email']}
      getCollapseRowCellsData={getCollapseRowCellsData}
      __={__}>
      <TableBlock __class="table--personal-history">
        <TableAction className="table-action">
          <div className="table-action__block">
            <ButtonIcon
              icon={<CachedIcon color="action" fontSize="large" />}
              onClick={() => setReload((prev) => !prev)}
            />
          </div>
          <div className="table-action__block">{/* <ToggleShowActive /> */}</div>
        </TableAction>
        <TableHeader />
        <TableBody loaded={loaded}>
          {data?.orders?.map((personalOrder) => {
            const isPaid = personalOrder.status !== 0 ? true : false;
            const paidStyle = isPaid ? s.paid : s.notpaid;

            return (
              <TrBody key={personalOrder.id} data={personalOrder} style={paidStyle}>
                {!isPaid && (
                  <ButtonIcon
                    icon={<AttachMoneyIcon />}
                    onClick={() => paymentHandler(personalOrder.id)}
                  />
                )}
              </TrBody>
            );
          })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
