import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { datePickerBlockData } from './datePickerBlockData';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

export const SelectDateBlock = ({ reportDate, setReportDate, groupValue }) => {
  const { locale } = useSelector((state) => state.localization);
  const reportType = groupValue.top;
  const handleChangeReportDate = (pickerKey, value) => {
    if (!value) {
      return;
    }

    const dateValue = dayjs(value).format('YYYY-MM-DD');
    setReportDate(prev => ({ ...prev, [pickerKey]: dateValue}));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ locale || 'uk' }>
      <DemoContainer components={ datePickerBlockData.map(item => 'DatePicker') }>

        {
          datePickerBlockData.map(options => {
            const { pickerKey, title, maxDate, blockingKeysOfReportType } = options;
            const isDisabled = blockingKeysOfReportType && blockingKeysOfReportType.includes(reportType);
            return (
              <DemoItem label={ title } key={ pickerKey }>
                <DatePicker 
                  value={ dayjs(reportDate[pickerKey]) || '' } 
                  onChange={ handleChangeReportDate.bind(null, pickerKey) } 
                  maxDate={maxDate}
                  disabled={ isDisabled }
                />
              </DemoItem>
            );
          })
        }

      </DemoContainer>
    </LocalizationProvider>
  );
};