import { Button } from "@mui/material";
import { useCheckAvailableCurrentUserPermission } from "src/configData/rolesConfig";

export const ButtonSend = ({ onClick, text, sx = {}, checkRoles = false, permission, ...props }) => {
  const componentAvailable = useCheckAvailableCurrentUserPermission(checkRoles?.resource, permission);

  return (
    <>
      {
        componentAvailable &&
          <Button
            variant="contained"
            type="submit"
            onClick={ onClick }
            sx={{ height: 40, fontSize: 14, lineHeight: 1, ...sx }}
            { ...props }
          >
            { text }
          </Button>
      }
    </>
    
  );
};
