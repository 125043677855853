import { useTranslation } from './../../../../helpers/translate';

export const TableVerticalSimple = ({ title, headerRow, data, getVerticalTableData, ...props }) => {
  const { __ } = useTranslation();
  const tableData = getVerticalTableData(data, __);
  const columnCount = Array.isArray(tableData[0].td) ? tableData[0].td.length + 1 : 2;
  const headerRowModified = headerRow ? headerRow.slice(0, columnCount) : null;

  return (
    <div { ...props }>
      {
        title && 
        <div className="title-back">
          <div className="title">{ title }</div>
        </div>
      }
      
      <div className="view-elemet-wrap" style={ !title ? { marginTop: 0 } : {} }>
        <div className="view-elemet-list">

          {
            headerRow &&
            <div className={`view-elemet-list__item view-elemet-list__item--header`}>
              {
                headerRowModified.map( th => <span key={ th }>{ th }</span> ) 
              }
            </div>
          }

          {
            tableData &&
              tableData.map(data => {
                const tdData = data.td;
                const isMultyColumn = Array.isArray(tdData);
                const className=data.__class ? ' ' + data.__class : '';

                const columnData = !isMultyColumn
                  ? <span>{ tdData }</span>
                  : tdData.map(td => <span>{ td }</span>);

                return (
                  data && <div key={ data.th } className={`view-elemet-list__item${ className }`}>
                    <p> { data.th }:</p>
                    { columnData }
                  </div>
                )
              }) 
          }

        </div>
      </div>
    </div>
  );
};
