import { Autocomplete, Box, debounce, TextField, Typography, useMask } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchClients } from './../../../actions/visit/visitRoutes';

export const SelectPhone = ({ value, setValue, isValidation, disabled = false, isFirstRender }) => {
  const [options, setOptions] = useState([]);

  const fetch = useMemo(
    () =>
      debounce(async (input) => {
        // if (isFirstRender) {
        //   return;
        // }
        
        try {
          let formatPhone = '';
          const phoneLen = input.length;
          // 992101312
          // 99 210-13-12
          // newInputValue.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2-$3-$4");
          if (phoneLen <= 5) {
            formatPhone = input.replace(/(\d{2})/, "$1 ");
          } else if (phoneLen <= 7) {
            formatPhone = input.replace(/(\d{2})(\d{3})/, "$1 $2-");
          } else if (phoneLen <= 9) {
            formatPhone = input.replace(/(\d{2})(\d{3})(\d{2})/, "$1 $2-$3-");
          } 

          const response = await searchClients(formatPhone, '', '');
          setOptions(
            response.data.data.map((item) => {
              item.phone = item.login.substring(5).replace(/\D/g, '');
              return item;
            })
          );
          

        } catch(error) {
          console.warn(error)
        }
        
      }, 1000),
    []
  );

  return (
    <Autocomplete
      openOnFocus
      disabled={ disabled }
      fullWidth
      getOptionLabel={({ phone }) => phone.replace(/\D/g, '')}
      options={options}
      value={value}
      disableClearable
      freeSolo
      isOptionEqualToValue={(option, value) => {
        const optPhone = option.phone.replace(/\D/g, '');
        const valPhone = value.phone.replace(/\D/g, '');
        return optPhone.includes(valPhone)
      }}
      onChange={(_, newValue) => {
        setValue({ ...value, ...newValue });
      }}
      onInputChange={(_, newInputValue) => {
        setValue({ ...value, phone: newInputValue });
        if (newInputValue.length >= 2) {
          fetch(newInputValue);
        }
        if (!newInputValue) {
          setOptions([]);
        }
      }}
      renderOption={(props, { id, first_name, last_name, phone, email }) => (
        <React.Fragment key={id}>
          <Box key={id} {...props} sx={{ flexDirection: 'column' }}>
            <Typography>
              {first_name} {last_name}
            </Typography>
            <Typography>{phone}</Typography>
            <Typography>{email}</Typography>
          </Box>
        </React.Fragment>
      )}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            disabled={ disabled }
            label="Введіть телефон користувача"
            style={{ marginLeft: 0, width: '100%' }}
            required={ isValidation }
            InputLabelProps={{ shrink: true }}
            error={ isValidation ? !value.phone : false }
          />
        )
      }}
    />
  );
};
