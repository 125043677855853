import { serverErrorHandler } from '../../../common/FormsComponent';
import { sendPhone } from '../../../../actions';

export const redirectToLoginForm = (phone, setErrorMessages, navigate) => {
  setErrorMessages({});
  const loginPhone = new FormData();
  loginPhone.append('phone_number', phone);

  sendPhone(loginPhone)
    .then((res) => {
      navigate('/sign-in', { state: { userIsAuth: true, authUserPhone: phone }, replace: true });
    })
    .catch((error) => serverErrorHandler(error.response.data.errors, setErrorMessages));
}