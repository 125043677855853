import { createPaymentAbonnements, getSeasonTickectsAbonnements } from '../../../../../actions/abonnements';
import { LoaylityCreate } from '../components';

export const AbonnementsCreate = () => {
  return (
    <LoaylityCreate 
      title="Створити абонемент" 
      action={ createPaymentAbonnements } 
      baseRoute="abonnements"
      getSeasonTicketsAction={ getSeasonTickectsAbonnements }
      type="Абонемент" />
    )
};
