import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../helpers/translate";
import { checkValidSingleLocale, localeModel } from "./localeValidation";
import { requiredLocaleValidationFields } from "./localeValidation";
import { ButtonSend, ToggleActiveStatus, ToggleShowActive } from "..";
import { Automatic, Manual } from "./components";
import { tabStyles } from "./styles";
import { Separator } from "../UI";

export const LocaleTabs = ({ 
  data,
  setData, 
  dataName, 
  validationStatus, 
  handleLocalizationChange, 
  requiredFields = requiredLocaleValidationFields,
  nameField = "name",
  fillCheckbox = false,
  fieldsModel = false, // only auto mode
  displayControl = false, // only auto mode
}) => {
  const { __ } = useTranslation();
  const locales = Object.keys(localeModel);

  const { locale } = useSelector((state) => state.localization);
  const [tabLocale, setTabLocale] = useState(locale);

  const [isFillLang, setIsFillLang] = useState(false);
  const [isDisplayControl, setIsDisplayControl] = useState(false);

  const handleLocale = (event, new_locale) => {
    setTabLocale(new_locale);
    setIsFillLang(false);
  };

  const handleIsFillLang = () => {
    const currentValue = !isFillLang;
    const localeData = data?.locale;
    const currentLocaleData = localeData[tabLocale];
    let newLocaleData = {};
    
    setIsFillLang(currentValue);

    locales.forEach(locale => newLocaleData[locale] = currentLocaleData);

    setData({...data, locale: newLocaleData});
  }

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={tabLocale}
        onChange={handleLocale}
        sx={{ display: 'grid', gridTemplateColumns: `repeat(${locales.length}, 1fr)` }}
      >
        {
          locales.map(locale => {
            const localeIsValid = checkValidSingleLocale(validationStatus[locale]);
            const tabErrorStyles = !localeIsValid ? { outline: '3px solid tomato' } : {};
            return (
              <ToggleButton 
                // disabled={ isFillLang } 
                key={ locale } 
                value={ locale } 
                sx={{ ...tabStyles, ...tabErrorStyles }}
              > 
                {' '}{locale.toUpperCase()}{' '}
              </ToggleButton>)
          })
        }
      </ToggleButtonGroup>

      {
        displayControl &&
          <ToggleActiveStatus 
            title={`Локалізація прізвища та імені`}
            value={ isDisplayControl }
            checked={ isDisplayControl }
            onChange={ () => setIsDisplayControl(prev => !prev) }
          />
      }

      {
        fieldsModel
          ? <Automatic 
              data={ data }
              dataName={ dataName }
              tabLocale={ tabLocale }
              nameField={ nameField }
              requiredFields={ requiredFields }
              validationStatus={ validationStatus }
              handleLocalizationChange={ handleLocalizationChange }
              fieldsModel={ fieldsModel }
              formDisplayControl={ displayControl }
              isDisplayControl={ isDisplayControl }
              __={ __ }
            />
          : <Manual 
              data={ data }
              dataName={ dataName }
              tabLocale={ tabLocale }
              nameField={ nameField }
              requiredFields={ requiredFields }
              validationStatus={ validationStatus }
              handleLocalizationChange={ handleLocalizationChange }
              __={ __ }
            />
      }

      {/* {
        fillCheckbox &&
          <ToggleActiveStatus 
            title={`Заповнити інші локалізації поточною мовою (${ tabLocale })`}
            value={ isFillLang }
            checked={ isFillLang }
            onChange={ handleIsFillLang }
          />
      } */}
      {/* {
        fillCheckbox &&
          <ToggleShowActive 
            title={`Заповнити інші локалізації поточною мовою (${ tabLocale })`}
            value={ isFillLang }
            checked={ isFillLang }
            onChange={ handleIsFillLang }
          />
      } */}
      {
        fillCheckbox &&
          <ButtonSend
            text={`Заповнити інші локалізації поточною мовою (${ tabLocale })`} 
            onClick={ handleIsFillLang }
            variant="outlined"
          />
      }

      <Separator />
    </>
  )
}