import axios from '../../helpers/private.axios';

export const getOrderOutcomings = async (
    searchTitle,
    currentPage,
    itemsPerPage,
    showArchive
) => {
    const params = {};

    if (searchTitle) {
        params['order_num'] = searchTitle;
    }

    if (showArchive) {
        params.status = 'archive';
    }

    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/order-outcomings`, { params });
};

