import axios from '../../helpers/private.axios';

export const getClients = (
  archive,
  searchPhone,
  searchName,
  filterStatus,
  filterGroup, 
  currentPage,
  itemsPerPage,
  sortOption, 
  sortDirection
) => {
  const params = {};

    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (searchPhone) {
      params.login = searchPhone;
    }

    if (archive) {
      params.status = 'archive';
    }

    if (searchName) {
      params.fullName = searchName;
    }

    if (filterStatus) {
      params.status = filterStatus;
    }

    if (filterGroup) {
      params.group = filterGroup;
    }

    params.page = currentPage;

    // params.limit = itemsPerPage;

  return axios.get(`/clients`, { params });
};
