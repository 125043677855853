import { useEffect, useState } from 'react';

import { getClientCertificates } from './../../../../../actions';
import { useTranslation } from './../../../../../helpers/translate';
import {
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination
} from './../../../../common/TableComponent';

import { getCertificatesRowCellsData } from './../../TablesData';
import { getDateValidOrder } from './../../../../library';

export const ClientCertificates = ({ clientId }) => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getClientCertificates(clientId, currentPage, itemsPerPage);
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response?.meta.last_page);
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage]);

  return (
    <TableMain
      getRowCellsData={getCertificatesRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}>
      <TableBlock __class="table--client-certificates table--mob-1660">
        <TableHeader />
        <TableBody loaded={loaded}>
          {data &&
            data.map((certificates) => {
              const validOrder =
                getDateValidOrder(certificates.date_end) && certificates.quantity_hours_left;

              return (
                <TrBody
                  key={certificates.id}
                  data={certificates}
                  style={{ background: !validOrder && '#f2f2f2' }}
                />
              );
            })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
