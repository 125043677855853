import axios from '../../helpers/private.axios';

export const getUsers = (
  searchPhone,
  searchFullName,
  rolesFilter,
  categoriesFilter,
  showArchive,
  currentPage,
  itemsPerPage,
) => {
  const params = {};

  if (searchPhone) {
    params.login = searchPhone;
  }

  if (rolesFilter) {
      params['roles'] = rolesFilter;
  }

  if (searchFullName) {
    params.full_name = searchFullName;
  }

  if (categoriesFilter) {
    params.user_category = categoriesFilter;
  }

  if (showArchive) {
    params.status = 'archive';
  }

  params.page = currentPage;

  return axios.get(`/users`, { params });
};
