import { useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';

import { enUS, ruRU, ukUA } from '@mui/material/locale';
import localizationMessage from '../../../translations';

import dayjs_en from 'dayjs/locale/en';
import dayjs_uk from 'dayjs/locale/uk';
import dayjs_ru from 'dayjs/locale/ru';

const DayJsLocalization = { en: dayjs_en, uk: dayjs_uk, ru: dayjs_ru, }

const MUILocalization = { en: enUS, ru: ruRU, uk: ukUA, }

export const LocalizationWrapper = ({ children }) => {

  const { locale } = useSelector( state => state.localization );

  dayjs.locale({
    ...DayJsLocalization[locale],
    weekStart: 1,
  });


  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    MUILocalization[locale]
  );

  return(
    <IntlProvider key={locale}  locale={locale} messages={ localizationMessage[locale] }>
      <ThemeProvider theme={theme}> 
        {children}
      </ThemeProvider>
    </IntlProvider>
  )

}