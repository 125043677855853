import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Switch,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getServicesNew, deleteServices } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ToggleShowActive } from './../../common';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

export const ServicesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);
  const [categories, setCategories] = useState([]);
  const [units, setUnits] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState('');

  const handleInventoryItemDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteServices(id).then(async () => {
        const { data: response } = await getServicesNew(
          sortOption,
          sortDirection,
          searchTitle,
          categoriesFilter,
          showArchive,
          currentPage,
          itemsPerPage,
        );

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
        setUnits(response.meta.options.units);
        setCategories(response.meta.options.service_category);
      });
    }
  };

  const handleSortButtonClick = (option) => {
    if (sortOption === option && sortDirection === 1) {
      setSortDirection(-1);
    } else if (sortOption === option && sortDirection === -1) {
      setSortOption('');
      setSortDirection(0);
    } else {
      setSortOption(option);
      setSortDirection(sortDirection !== 0 ? sortDirection : 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getServicesNew(
          sortOption,
          sortDirection,
          searchTitle,
          categoriesFilter,
          showArchive,
          currentPage,
          itemsPerPage,
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
        setUnits(response.meta.options.units);
        setCategories(response.meta.options.service_category);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [
    sortOption,
    sortDirection,
    searchTitle,
    currentPage,
    itemsPerPage,
    categoriesFilter,
    showArchive
  ]);

  return (
    <>
      <div className="title title--mb36">{__('services.page.title')}</div>
      <div className="table-action">
        
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { pr: '40px' },
                '.MuiOutlinedInput-root': { width: '100%' }
              }}
              label={__('services.filter.title')}
              name="services-title-filter"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />

            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
          <div className="input-wrap">
            <FormControl
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { boxSizing: 'border-box' }
              }}>
              <InputLabel>{__('inventoryItem.filter.inventory_item_type')}</InputLabel>
              <Select
                sx={{ width: '100%', textAlign: 'left' }}
                label={__('inventoryItem.filter.inventory_item_type')}
                name="inventory-item-type-select-filter"
                value={categoriesFilter}
                onChange={(e) => setCategoriesFilter(e.target.value)}>
                <MenuItem value="">none</MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="table-action__block">
          <ToggleShowActive value={showArchive} onChange={() => setShowArchive(prev => !prev)} />

          <Link to="/services/create">
            <Button
              variant="contained"
              type="submit"
              sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
              {__('create.button.title')}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-block">
        <div className="table table--services">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <button
                  onClick={() => handleSortButtonClick('title')}
                  className="table-button-short">
                  <span className="td-name">
                    <span>{__('services.table.title')}</span>
                  </span>
                  <span className="table-button-short__arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      className={
                        sortOption === 'title' ? `${sortDirection === 1 ? 'active' : ''}` : ''
                      }>
                      <path
                        d="M6 0L0 6.47773L1.41 8L6 3.05533L10.59 8L12 6.47773L6 0Z"
                        fill="black"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      className={
                        sortOption === 'title' ? `${sortDirection === 1 ? '' : 'active'}` : ''
                      }>
                      <path
                        d="M6 8L12 1.52227L10.59 3.71833e-08L6 4.94467L1.41 3.71833e-08L0 1.52227L6 8Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.service_category_id')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.model_type')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <button
                    onClick={() => handleSortButtonClick('amount')}
                    className="table-button-short">
                    <span className="td-name">
                      <span>{__('services.table.amount')}</span>
                    </span>
                    <span className="table-button-short__arrow">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        className={
                          sortOption === 'amount' ? `${sortDirection === 1 ? 'active' : ''}` : ''
                        }>
                        <path
                          d="M6 0L0 6.47773L1.41 8L6 3.05533L10.59 8L12 6.47773L6 0Z"
                          fill="black"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        className={
                          sortOption === 'amount' ? `${sortDirection === 1 ? '' : 'active'}` : ''
                        }>
                        <path
                          d="M6 8L12 1.52227L10.59 3.71833e-08L6 4.94467L1.41 3.71833e-08L0 1.52227L6 8Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.available_for_online')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('services.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((services) => (
              <div className="tr" key={services.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.title')}</p>
                  </div>
                  <div className="td-content">{services.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.description')}</p>
                  </div>
                  <div className="td-content">{services.description}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.image')}</p>
                  </div>
                  <div className="td-content">
                    <img src={ services.image || noImage } alt="" />
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.service_category_id')}</p>
                  </div>
                  <div className="td-content">
                    { services.service_category.category_title }
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.model_type')}</p>
                  </div>
                  <div className="td-content">
                  { services.service_category.type_title }
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.amount')}</p>
                  </div>
                  <div className="td-content">{services.amount}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.available_for_online')}</p>
                  </div>
                  <div className="td-content">
                    {
                      services.available_for_online
                        ? __('services.form.online_checkbox_true')
                        : __('services.form.online_checkbox_false')
                    }
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {
                      services.is_active
                        ? __('services.form.active_checkbox')
                        : __('services.form.inactive_checkbox')
                    }
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('services.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/services/${services.id}/edit`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <Link to={`/services/${services.id}/copy`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <ContentCopyIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleInventoryItemDelete(services.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
