import axios from '../../helpers/private.axios';

export const getAnyHorseDayTimeslots = () => {
  return axios.get(`/horse-timeslots?anyhors=1&span=day`);
};

export const getAnyHorseOnSomeDateTimeslots = (date) => {
  return axios.get(`/hors-timeslots?anyhors=1&date=${date}&span=day`);
};

export const getSomeHorseDayTimeslots = (horsId) => {
  return axios.get(`/hors-timeslots?hors=${horsId}&span=day`);
};

export const getSomeHorseOnSomeDateTimeslots = (horsId, date) => {
  return axios.get(`/hors-timeslots?hors=${horsId}&span=day&date=${date}`);
};

export * from './createHorseTimeslotWeek';
export * from './getHorseDateTimeslots';
export * from './getHorseTimeslotsSchedule';
export * from './getHorseTimeslot';
