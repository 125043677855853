import { Pagination } from "@mui/material";

export const TablePagination = ({ totalPages, currentPage, setCurrentPage }) => {
  return (
    <div className="table-pagination">
      <Pagination
        count={totalPages}
        color="primary"
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
      />
    </div>
  );
};