import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Modal } from '@mui/material';
import dayjs from 'dayjs';

import { createPerson } from '../../../actions/persons';
import { PersonFormEdit } from './PersonFormEdit';
import { handleServerErrors, handleChangeCreator, handleRoleChangeCreator } from './utils';

import styles from './persons.scss';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const CreatePerson = ({ opened }) => {
  const [isValid, setFromValidation] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    number: false,
    individual_taxpayer_number: false,
    email: false,
    phone: true
  });
  const [serverErrors, setServerError] = useState(null);

  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/persons');
  };

  const [person, setPerson] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    phone: '',
    email: '',
    date_of_birth: '',
    passport_data: {
      code: '',
      number: '',
      date: '',
      organ: ''
    },
    individual_taxpayer_number: '',
    address: ''
  });

  const handleChange = handleChangeCreator(
    person,
    setPerson,
    validationStatus,
    setValidationStatus,
    setFromValidation
  );
  const handleRoleChange = handleRoleChangeCreator(person, setPerson);

  const handleCreate = () => {

    if (typeof person.date_of_birth == 'object') {
      person.date_of_birth = person.date_of_birth.format('YYYY-MM-DD');
    }

    if (typeof person.passport_data.date == 'object') {
      person.passport_data.date = person.passport_data.date.format('YYYY-MM-DD');
    }

    person.phone = person.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');

    createPerson(person).then((res) => {
      if (res.status === 200) {
        navigate('/persons');
      }

      const errors = res?.errors;
      if (res?.errors) {
        setServerError(errors);
        handleServerErrors(errors, setFromValidation, validationStatus, setValidationStatus);
      }
    });
  };

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div className={styles.persons}>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>Створити особу</h3>
              </div>

              <PersonFormEdit
                person={person}
                handleChange={handleChange}
                handleRoleChange={handleRoleChange}
                validationStatus={validationStatus}
                action={handleCreate}
                isValid={isValid}
                ctaButtonText="Створити"
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
