import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getOrderIncomingsView } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const OrderIncomingsView = () => {
  const { __ } = useTranslation();
  const { state } = useLocation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const params = useParams();

  let isCorrective =  data?.type > 0;
  let title = __('orderIncomingsView.page.title');
  let titleStyle = { color: 'black' };

  if (isCorrective) {
    title = `${__('orderIncomingsView.page.title')} ${params.id} (Коригуючий)`;
    titleStyle = { color: 'green' };
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getOrderIncomingsView(params.id);
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="title-back">
        <div className="title-back__link">
          <Link to={ state ? state : "/order-incomings" }>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                fill="black"></path>
            </svg>
          </Link>
        </div>
        <div className="title" style={ titleStyle }>
          { title }
        </div>
      </div>
      <div className="view-elemet-wrap">
        <div className="view-elemet-list">
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.order_num')}:</p>
            <span>{data.order_num}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.contractor')}:</p>
            <span>{data.contractor?.title}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.person')}:</p>
            <span>{`${data.person?.first_name} ${data.person?.last_name}`}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.store')}:</p>
            <span>{data.store?.title}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.created_at')}:</p>
            <span>{dayjs(data.created_at).format('YYYY-MM-DD')}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.date_order')}:</p>
            <span>{data.date_order}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('orderIncomingsView.list.cost')}:</p>
            <span>{data.cost}</span>
          </div>
        </div>
        <div className="view-elemet-table">
          <div className="table table--order-incomings-view">
            <div className="table-header">
              <div className="tr">
                <div className="td">
                  <div className="td-name">
                    <p>№</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomingsView.table.inventory_item-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomingsView.table.unit-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomingsView.table.count')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomingsView.table.price')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomingsView.table.cost')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {data.items?.map((item, index) => (
                <div className="tr" key={item.id}>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>№</p>
                    </div>
                    <div className="td-content">
                      <p>{index + 1}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomingsView.table.inventory_item-title')}</p>
                    </div>
                    <div className="td-content">{item.inventory_item?.title}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomingsView.table.unit-title')}</p>
                    </div>
                    <div className="td-content">{item.unit?.title}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomingsView.table.count')}</p>
                    </div>
                    <div className="td-content">{item.count}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomingsView.table.price')}</p>
                    </div>
                    <div className="td-content">{item.price}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomingsView.table.cost')}</p>
                    </div>
                    <div className="td-content">{(item.price * item.count).toFixed(2)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
