const inventoryItem = {
        uk: {
                "inventoryItem.page.title": "ТМЦ",
                // table
                "inventoryItem.table.title": "Назва",
                "inventoryItem.table.name": "Назва",
                "inventoryItem.table.description": "Опис",
                "inventoryItem.table.inventory_item_type": "Тип",
                "inventoryItem.table.unit": "Одиниця виміру",
                "inventoryItem.table.cost": "Собівартість",
                "inventoryItem.table.reserve": "Страховий запас",
                "inventoryItem.table.sale_price": "Відпускна на ціна",
                "inventoryItem.table.image": "Фото",
                "inventoryItem.table.is_active": "Активний/Неактивний",
                "inventoryItem.table.actions": "Дії",
                // table end

                //filter
                "inventoryItem.filter.title": "Назва",
                "inventoryItem.filter.inventory_item_type": "Тип",
                "inventoryItem.filter.archiveShow": "Показати неактивні",
                "inventoryItem.filter.archiveHide": "Показати активні",
                //filterend

                //form
                "inventoryItem.form.title-create": "Створити ТМЦ",
                "inventoryItem.form.title-edit": "Редагувати ТМЦ",
                "inventoryItem.form.button": "Зберегти",
                "inventoryItem.form.is_active": "Активний/Неактивний",
                "inventoryItem.input.inventory_item_type": "Тип*",
                "inventoryItem.input.unit": "Одиниця виміру*",
                "inventoryItem.input.cost": "Собівартість*",
                "inventoryItem.input.reserve": "Страховий запас",
                "inventoryItem.input.sale_price": "Відпускна на ціна*",
                // form value
                "inventoryItem.form.active": "Активний",
                "inventoryItem.form.inactive": "Неактивний",
                //form error
                "inventoryItem.form.title.error": "*Поле є обов'язковим",
                "inventoryItem.form.description.error": "*Поле є обов'язковим",
                "inventoryItem.form.numbers.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "inventoryItem.form.unit_id.error": "*Поле є обов'язковим",
                "inventoryItem.form.inventory_item_type_id.error": "*Поле є обов'язковим",
                "inventoryItem.form.cost.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "inventoryItem.form.sale_price.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                //form end
        },
        en: {
                "inventoryItem.page.title": "Inventory Items",
                // table
                "inventoryItem.table.title": "Name",
                "inventoryItem.table.name": "Name",
                "inventoryItem.table.description": "Description",
                "inventoryItem.table.inventory_item_type": "Type",
                "inventoryItem.table.unit": "Unit",
                "inventoryItem.table.cost": "Cost",
                "inventoryItem.table.reserve": "Reserve",
                "inventoryItem.table.sale_price": "Sale Price",
                "inventoryItem.table.image": "Image",
                "inventoryItem.table.is_active": "Active/Inactive",
                "inventoryItem.table.actions": "Actions",
                // table end

                //filter
                "inventoryItem.filter.title": "Name",
                "inventoryItem.filter.inventory_item_type": "Type",
                "inventoryItem.filter.archiveShow": "Show Inactive",
                "inventoryItem.filter.archiveHide": "Show Active",
                //filterend

                //form
                "inventoryItem.form.title-create": "Create Inventory Item",
                "inventoryItem.form.title-edit": "Edit Inventory Item",
                "inventoryItem.form.button": "Save",
                "inventoryItem.form.is_active": "Active/Inactive",
                "inventoryItem.input.inventory_item_type": "Type*",
                "inventoryItem.input.unit": "Unit*",
                "inventoryItem.input.cost": "Cost*",
                "inventoryItem.input.reserve": "Reserve",
                "inventoryItem.input.sale_price": "Sale Price*",
                // form value
                "inventoryItem.form.active": "Active",
                "inventoryItem.form.inactive": "Inactive",
                //form error
                "inventoryItem.form.title.error": "*Field is required",
                "inventoryItem.form.description.error": "*Field is required",
                "inventoryItem.form.numbers.error": "*Field is required, only 0-9 allowed.",
                "inventoryItem.form.unit_id.error": "*Field is required",
                "inventoryItem.form.inventory_item_type_id.error": "*Field is required",
                "inventoryItem.form.cost.error": "*Field is required, only 0-9 allowed.",
                "inventoryItem.form.sale_price.error": "*Field is required, only 0-9 allowed.",
                //form end
        },
        ru: {
                "inventoryItem.page.title": "Товары на складе",
                // table
                "inventoryItem.table.title": "Название",
                "inventoryItem.table.name": "Название",
                "inventoryItem.table.description": "Описание",
                "inventoryItem.table.inventory_item_type": "Тип",
                "inventoryItem.table.unit": "Единица измерения",
                "inventoryItem.table.cost": "Себестоимость",
                "inventoryItem.table.reserve": "Запас",
                "inventoryItem.table.sale_price": "Цена продажи",
                "inventoryItem.table.image": "Фото",
                "inventoryItem.table.is_active": "Активный/Неактивный",
                "inventoryItem.table.actions": "Действия",
                // table end

                //filter
                "inventoryItem.filter.title": "Название",
                "inventoryItem.filter.inventory_item_type": "Тип",
                "inventoryItem.filter.archiveShow": "Показать неактивные",
                "inventoryItem.filter.archiveHide": "Показать активные",
                //filterend

                //form
                "inventoryItem.form.title-create": "Создать товар",
                "inventoryItem.form.title-edit": "Редактировать товар",
                "inventoryItem.form.button": "Сохранить",
                "inventoryItem.form.is_active": "Активный/Неактивный",
                "inventoryItem.input.inventory_item_type": "Тип*",
                "inventoryItem.input.unit": "Единица измерения*",
                "inventoryItem.input.cost": "Себестоимость*",
                "inventoryItem.input.reserve": "Запас",
                "inventoryItem.input.sale_price": "Цена продажи*",
                // form value
                "inventoryItem.form.active": "Активний",
                "inventoryItem.form.inactive": "Неактивний",
                //form error
                "inventoryItem.form.title.error": "*Поле является обязательным",
                "inventoryItem.form.description.error": "*Поле является обязательным",
                "inventoryItem.form.numbers.error": "*Поле является обязательным, разрешены только цифры 0-9.",
                "inventoryItem.form.unit_id.error": "*Поле является обязательным",
                "inventoryItem.form.inventory_item_type_id.error": "*Поле является обязательным",
                "inventoryItem.form.cost.error": "*Поле является обязательным, разрешены только цифры 0-9.",
                "inventoryItem.form.sale_price.error": "*Поле является обязательным, разрешены только цифры 0-9.",
                //form end
        }
}


export default inventoryItem;