import { CircularProgress } from "@mui/material";

export const TableBody = ({ children, loaded }) => {
  return (
    <div className="table-body">
      {!loaded && (
        <div className="tr">
          <div className="td">
            <CircularProgress />
          </div>
        </div>
      )}
      { children }
    </div>
  );
};
