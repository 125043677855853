export const localeModel = {
  uk: {
    title: '',
    description: '',
  },
  ru: {
    title: '',
    description: '',
  },
  en: {
    title: '',
    description: '',
  }
}