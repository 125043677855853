import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { getWriteOff } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

export const WriteOffsView = () => {
  const { __ } = useTranslation();
  const { state: backUrl } = useLocation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getWriteOff(params.id);
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="title-back">
        <div className="title-back__link">
          <Link to={ backUrl ? backUrl : '/write-offs' }>
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                fill="black"></path>
            </svg>
          </Link>
        </div>
        <div className="title">
          {__('writeOffsView.page.title')} {params.id}
        </div>
      </div>
      <div className="view-elemet-wrap">
        <div className="view-elemet-list">
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.serial_number')}:</p>
            <span>{data.serial_number}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.created_at')}:</p>
            <span>{dayjs(data.created_at).format('YYYY-MM-DD')}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.person')}:</p>
            <span>
              {data.person?.last_name} {data.person?.first_name}
            </span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.reason')}:</p>
            <span>{data.reason?.title}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.subdivision')}:</p>
            <span>{data.subdivision?.title}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.commentary')}:</p>
            <span>{data.commentary}</span>
          </div>
          <div className="view-elemet-list__item">
            <p> {__('writeOffsView.list.summary')}:</p>
            <span>{data.summary}</span>
          </div>
        </div>
        <div className="view-elemet-table">
          <div className="table table--write-offs-view">
            <div className="table-header">
              <div className="tr">
                <div className="td">
                  <div className="td-name">
                    <p>№</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('writeOffsView.table.inventory_item-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('writeOffsView.table.unit-title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('writeOffsView.table.count')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('writeOffsView.table.price')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('writeOffsView.table.cost')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {data.items?.map((item, index) => (
                <div className="tr" key={item.id}>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>№</p>
                    </div>
                    <div className="td-content">
                      <p>{index + 1}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('writeOffsView.table.inventory_item-title')}</p>
                    </div>
                    <div className="td-content">{item.inventory_item_type?.title}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('writeOffsView.table.unit-title')}</p>
                    </div>
                    <div className="td-content">{item.unit}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('writeOffsView.table.quantity')}</p>
                    </div>
                    <div className="td-content">{item.quantity}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('writeOffsView.table.price')}</p>
                    </div>
                    <div className="td-content">{item.cost}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('writeOffsView.table.cost')}</p>
                    </div>
                    <div className="td-content">{(item.cost * item.quantity).toFixed(2)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
