import { useState } from 'react';

import { clientSingUp } from './../../../../actions';

import { useNavigate } from 'react-router-dom';

import { ModalForm, serverErrorHandler } from './../../../common/FormsComponent';
import { Form } from './Form';
import { validate } from './utils';
import { BookingHeader } from './../../../pages/Booking/bookingHeader';
import { useDispatch } from 'react-redux';
import { autoLoginIntoCabinet } from './../../Booking/utils';
import { configurateToken } from './../../../../helpers/private.axios';
import { AUTH } from './../../../../constants';

const dataObj = {
  login: '',
  first_name: '',
  last_name: '',
  personal_data_agreement: true,
}

export const SingUpExternalUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleOpened = () => {
    navigate('/');
  }

  const [user, setUser] = useState(dataObj);
  const [role, setRole] = useState('USER');

  const [errorMessages, setErrorMessages] = useState({});
  const [validationStatus, setValidationStatus ] = useState(dataObj);

  const handleChange = ( name ) => ( event ) => {
    if (name === 'email') {
      return;
    }

    setErrorMessages({});
    let value;

    if( name === "login" ){
      value = event.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
    } else if (name === 'personal_data_agreement') {
      value = event?.target.checked;
    } else {
      value = event?.target.value;
    }

    setUser({
      ...user,
      [name]: value
    }) 

    const isValid = validate( name, value, user );
  
    setValidationStatus({
      ...validationStatus,
      [name]: isValid,
    });
  }

  const handleCreate = async () => {
    setErrorMessages({});
    try {
      const res = await clientSingUp(user);

      if (res?.data?.success) {
        const token = res.headers.authorization;
        autoLoginIntoCabinet(dispatch, navigate, role, token, configurateToken, '/visits', AUTH);
        // navigate("/sign-up/success", { replace: true });
      }

      serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
    } catch(error) {
      console.warn(error);
    }
    
  };

  console.log('Sing-up');

  return(
    <>
      <BookingHeader />
      <ModalForm
      title='Зареєструватися'
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Form 
        user={user}
        validationStatus={validationStatus}
        serverErrorMessages={ errorMessages } 
        handleChange={handleChange}
        action={handleCreate}
        ctaButtonText="Записатися"
      />
    </ModalForm>
    </>
  );

}