export const buildQueryParams = (selectedData) => {
  let index = 0;
  const newQueryParams = {
    search: {
      abonnement: [],
      certificate: []
    }
  };

  Object.keys(selectedData).forEach((key) => {
    const item = selectedData[key];

    if (item.selected === true) {
      if (item.abonnement === true) {
        newQueryParams.search.abonnement[index] = key;
      }

      if (item.certificate === true) {
        newQueryParams.search.certificate[index] = key;
      }

      index++;
    }
  });

  return newQueryParams;
};