import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { BookingHeader } from './bookingHeader';
import { CircularProgress, Typography } from '@mui/material';

import { getBookingProviders } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { modelTitle } from '../../../configData/modelConfig';

export const BookingHorse = () => {
  const modelType = useSelector((state) => state.mode);
  const { __ } = useTranslation();
  const { selectedServiceId } = useParams();

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const [selectedHorseId, setSelectedHorseId] = useState(null);
  const [isAnyHorseSelected, setAnyHorseSelected] = useState(false);

  const handleAnyHorseClick = () => {
    setSelectedHorseId(null);
    setAnyHorseSelected(!isAnyHorseSelected);
  };

  const handleHorseClick = (id, title) => {
    if (selectedHorseId === id) {
      setSelectedHorseId(null);
      setAnyHorseSelected(false);
    } else {
      setSelectedHorseId(id);
      setAnyHorseSelected(false);
    }
  };

  const isHorseActive = (id) => {
    return selectedHorseId === id;
  };

  const isBookingActive = selectedHorseId !== null || isAnyHorseSelected;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingProviders(selectedServiceId);
        
        setData({
          loaded: true,
          data: getFreeHorse(response.data)
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [selectedServiceId]);

  return (
    <>
      <BookingHeader />
      <main className="main-booking">
        <div className="booking-wrap">
          <div className="booking-horses">
            <div className="booking-title">
              <Link className="booking-title__back" to="/booking/services/">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <p>{__('booking.horse.title')}</p>
            </div>
            {
              data.length
                ? <>
                    <div className="booking-horses-list">
                      <div
                        className={`booking-horses-list__item booking-horses-list__item--center ${
                          isAnyHorseSelected ? 'active' : ''
                        }`}
                        onClick={handleAnyHorseClick}>
                        <div className="booking-horses-list__item-photo">
                          <img src="/images/services.png" alt="" />
                        </div>
                        <div className="booking-horses-list__item-info">
                          <div className="booking-horses-list__item-name">
                            <p>{__(modelTitle[modelType.mode].anyModel)}</p>
                          </div>
                        </div>
                      </div>
                      {loaded ? (
                        data.map((horse) => (
                          <div
                            className={`booking-horses-list__item ${
                              isHorseActive(horse.id) ? 'active' : ''
                            } `}
                            key={horse.id}
                            onClick={() => handleHorseClick(horse.id)}>
                            <div className="booking-horses-list__item-photo">
                              <img src={horse.image ? horse.image : "/images/services.png"} alt="" />
                            </div>
                            <div className="booking-horses-list__item-info">
                              <div className="booking-horses-list__item-service">
                                <p>
                                  {horse.services.map((service) => (
                                    <span key={service.id}>{service.title}, </span>
                                  ))}
                                </p>
                              </div>
                              <div className="booking-horses-list__item-name">
                                <p>
                                  { horse.name }
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                    <div className={`booking-action ${isBookingActive ? 'active' : ''}`}>
                      <div className="booking-action-inside">
                        <Link
                          className="button button--width100p"
                          to={`/booking/services/${selectedServiceId}/horse/${
                            isAnyHorseSelected ? 'any' : selectedHorseId
                          }`}>
                          <p>{__('booking.button.next')}</p>
                        </Link>
                      </div>
                    </div>
                  </>
                : <Typography variant="h5">Для обраної послуги немає вільних коней</Typography>
            }
            
          </div>
        </div>
      </main>
    </>
  );
};


function getFreeHorse(horserArr) {
  return horserArr.filter(horse => horse.free_timeslots_count);
}