import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Avatar, Typography } from '@mui/material';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getOneCoach } from '../../../actions/coaches/getOneCoach';
import { getOneService } from '../../../actions/services';
import { YourData } from '../../../components/pages/CreateRecord/YourData';
import { createVisit } from '../../../actions/visit/visitRoutes';
import moment from 'moment';

export function CreateRecord() {
  const navigate = useNavigate();
  const [coach, setCoach] = useState({});
  const [service, setService] = useState({});
  const [visit, setVisit] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
  });
  const [validVisit, setValidVisit] = useState({
    first_name: true,
    last_name: true,
    email: true,
    phone: true
  });
  const [searchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const response = await getOneCoach(id);
      setCoach(response.data.data);
      const res = await getOneService(searchParams.get('serviceId'));
      setService(res.data.data);
    }

    fetchData();
  }, []);

  const { first_name, last_name, email, phone } = visit;
  const { title, amount } = service;

  const createVisitHandler = async () => {
    setValidVisit({
      first_name: !!first_name,
      last_name: !!last_name,
      phone: !!phone.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i),
      email: email ? email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) : true
    });

    if (
      first_name &&
      last_name &&
      phone.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i) &&
      (email ? email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) : true)
    ) {
      await createVisit({
        user_id: visit.id,
        coach_id: id,
        first_name,
        last_name,
        email,
        phone,
        status_id: 0,
        date: searchParams.get('date'),
        another_name: null,
        time: searchParams.get('time'),
        visit_services: JSON.stringify({
          0: {
            service_id: searchParams.get('serviceId'),
            count: 1,
            amount,
            discount: 0,
            summa: amount
          }
        }),
        length: 1
      });
      navigate('/');
    }
  };
  return (
    <Box sx={{ maxWidth: 584, m: '0 auto' }}>
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          alignItems: 'center'
        }}>
        <Link to={'/select-time/' + searchParams.get('serviceId')}>
          <ArrowBackIosNewIcon
            sx={{
              cursor: 'pointer',
              mr: '160px'
            }}
          />
        </Link>
        <Typography>Запис</Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Avatar alt="Coach avatar" src="/images/avatar/1.jpg" />
        <Box sx={{ ml: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {coach.category}
          </Typography>
          <Typography variant="h7">{coach.first_name + ' ' + coach.last_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {moment(searchParams.get('date') + ' ' + searchParams.get('time')).format(
              'dddd, D MMMM, HH:mm'
            ) + ' '}
            -
            {' ' +
              moment(searchParams.get('date') + ' ' + searchParams.get('time'))
                .add(1, 'hours')
                .format('HH:mm')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Typography variant="body2">
          <b>{amount} &#8372;</b>
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2" sx={{ mr: 1 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            1 год.
          </Typography>
        </Box>
      </Box>
      <Typography variant="h6">Ваші дані</Typography>
      <YourData setVisit={setVisit} visit={visit} validVisit={validVisit} />
      <Box
        sx={{
          background: 'blue',
          color: 'white',
          borderRadius: 5,
          height: 48,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          top: '865px',
          width: '584px',
          cursor: 'pointer'
        }}
        onClick={createVisitHandler}>
        <Typography>Записатися</Typography>
      </Box>
    </Box>
  );
}
