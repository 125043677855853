const certificateCategories = {
    uk: {
        "certificateCategories.page.title": "Категорії сертифікатів та абонементів",
        "certificateCategories.table.title": "Назва",
        "certificateCategories.table.description": "Опис",
        "certificateCategories.table.is_active": "Активний/Неактивний",
        "certificateCategories.table.actions": "Дії",
        "certificateCategories.form.title-create": "Створити категорію сертифікатів та абонементів",
        "certificateCategories.form.title-edit": "Редагувати категорію сертифікатів та абонементів",
        "certificateCategories.form.button": "Зберегти",
    },
    en: {
        "certificateCategories.page.title": "Certificate Categories and Subscriptions",
        "certificateCategories.table.title": "Title",
        "certificateCategories.table.description": "Description",
        "certificateCategories.table.is_active": "Active/Inactive",
        "certificateCategories.table.actions": "Actions",
        "certificateCategories.form.title-create": "Create Certificate Category and Subscription",
        "certificateCategories.form.title-edit": "Edit Certificate Category and Subscription",
        "certificateCategories.form.button": "Save",
    },
    ru: {
        "certificateCategories.page.title": "Категории сертификатов и абонементов",
        "certificateCategories.table.title": "Название",
        "certificateCategories.table.description": "Описание",
        "certificateCategories.table.is_active": "Активный/Неактивный",
        "certificateCategories.table.actions": "Действия",
        "certificateCategories.form.title-create": "Создать категорию сертификатов и абонементов",
        "certificateCategories.form.title-edit": "Редактировать категорию сертификатов и абонементов",
        "certificateCategories.form.button": "Сохранить",
    }
}

export default certificateCategories;