import axios from "axios";

import config from '../config';

const privateInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept-Language': config.default_locale,
    // 'roles': 'super-admin',
  }
});

export const configurateToken = (token) => {
  privateInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  if (!token) {
    delete privateInstance.defaults.headers.common.Authorization;
  }
};

export const configurateLocale = (locale) => {
  privateInstance.defaults.headers.common['Accept-Language'] = locale;
};

export default privateInstance;
