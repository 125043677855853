export const getFormatedPhone = (phone) => {
  let formattedPhoneNumber = '';
  let phonePart = '';

  phonePart = phone.slice(0, 3);
  if (!phonePart) {return formattedPhoneNumber;}
  formattedPhoneNumber += phonePart;

  phonePart = phone.slice(3, 5);
  if (!phonePart) {return formattedPhoneNumber;}
  formattedPhoneNumber += ' ';
  formattedPhoneNumber += phonePart;
  
  phonePart = phone.slice(5, 8);
  if (!phonePart) {return formattedPhoneNumber;}
  formattedPhoneNumber += ' ';
  formattedPhoneNumber += phonePart;

  phonePart = phone.slice(8, 10);
  if (!phonePart) {return formattedPhoneNumber;}
  formattedPhoneNumber += '-';
  formattedPhoneNumber += phonePart;

  phonePart = phone.slice(10, 12);
  if (!phonePart) {return formattedPhoneNumber;}
  formattedPhoneNumber += '-';
  formattedPhoneNumber += phonePart;

  return formattedPhoneNumber;
};
