import Box from '@mui/material/Box';
import Table from '@mui/material/Table';

import Typography from '@mui/material/Typography';
import { TableBodySimple, TableHeaderSimple } from './components';
import { TableSimpleContext } from './tableSimpleContext';
import { useEffect, useState } from 'react';
import { getCombineTitle } from './utils';

export const TableSimple = ({ title, loaded = true, size = 'small', ...context }) => {
  const { DATA_ACTION, FORMAT_DATA_CALLBACK, data } = context;
  const [tableData, setTableData] = useState(!!data?.length ? data : []);

  const isStrTitle = typeof title === 'string';
  const [tableTitle, setTableTitle] = useState(isStrTitle ? title : '');

  useEffect(() => {
    if (!DATA_ACTION) {
      return;
    }

    (async () => {
      const res = await DATA_ACTION();
      const data = res.data.data;
      if (FORMAT_DATA_CALLBACK) {
        setTableData(FORMAT_DATA_CALLBACK(data));
      }
      if (!isStrTitle) {
        setTableTitle(getCombineTitle(title, data));
      }
    })();
  }, []);

  return (
    <TableSimpleContext.Provider value={{ ...context }}>
      <Box>
        {tableTitle && (
          <Typography variant="subtitle2" gutterBottom component="div">
            {tableTitle}
          </Typography>
        )}

        <div className="table-scroll">
          <Table size={size} aria-label="purchases" sx={{ minWidth: '980px' }}>
            <TableHeaderSimple />
            <TableBodySimple data={tableData} />
          </Table>
        </div>
      </Box>
    </TableSimpleContext.Provider>
  );
};
