import { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { chartsAssociatesObj } from '../charts';

export default function ChartToggleViewBlock({ chartsViews = [], setChartView }) {
  const [value, setValue] = useState(chartsViews[0] || '');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setChartView(newValue);
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      sx={{ mb: 3 }}
    >
      {
        chartsViews.map(item => {
          const chartAssocObj = chartsAssociatesObj[item];

          if (!chartAssocObj) {
            return <></>
          }

          const iconComponent = chartAssocObj.iconComponent;
          const value = chartAssocObj.value;

          return (
            <ToggleButton key={ value } value={ value } aria-label={ value }>
              { iconComponent }
            </ToggleButton>
          )
        })
      }
    </ToggleButtonGroup>
  );
}