import axios from '../../helpers/private.axios';
import moment from 'moment';

export const createPerson = (person) => {
  return axios.post(`/persons`, person)
  .then((res) => {
    return res;
  })
  .catch((error) => {
    return {
      status: error.response.status,
      errors: error.response.data.errors,
    };
  });
};
