import { useState, useEffect } from 'react';
import { Box, Modal } from '@mui/material';
import { createExpenditureNorms } from '../../../actions/';
import { useTranslation } from '../../../helpers/translate';
import { useNavigate } from 'react-router-dom';
import { ExpenditureNormsForm } from './expenditureNormsForm';

import moment from 'moment';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 960,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};
export const ExpenditureNormsCreate = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/expenditure-norms');
  };

  const [expenditureNorms, setExpenditureNorms] = useState({
    name: '',
    //created_at: moment().format('YYYY-MM-DD'),
    horse_id: '',
    period: 0,
    status: true,
    items: []
  });

  const [items, setItems] = useState([]);
  const [itemsPush, setItemsPush] = useState([]);

  const updateItemСount = (newItems) => {
    setItems(newItems);

    const itemsArray = Object.entries(newItems).map(([inventory_item_id, itemData]) => ({
      item_id: parseInt(inventory_item_id),
      quantity: itemData.count
    }));

    setExpenditureNorms((prevExpenditureNorms) => ({
      ...prevExpenditureNorms,
      items: itemsArray
    }));

  };

  const [validationStatus, setValidationStatus] = useState({
    name: { isValid: true, errorMessage: '' },
    //created_at: { isValid: true, errorMessage: '' },
    horse_id: { isValid: true, errorMessage: '' },
    inventoryItems: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    if (!expenditureNorms.name) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        name: {
          isValid: false,
          errorMessage: `${__('expenditureNorms.form.name.error')}`
        }
      }));
    }

    /*if (!expenditureNorms.created_at) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        created_at: {
          isValid: false,
          errorMessage: `${__('expenditureNorms.form.created_at.error')}`
        }
      }));
    }*/

    if (!expenditureNorms.horse_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        horse_id: {
          isValid: false,
          errorMessage: `${__('expenditureNorms.form.horse_id.error')}`
        }
      }));
    }

    if (!expenditureNorms.items.length > 0) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        inventoryItems: {
          isValid: false
        }
      }));
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'status') {
      value = event.target.checked;
    } else if (name === 'created_at') {
      value = moment(event).format('YYYY-MM-DD');
    } else {
      value = event.target.value;
    }

    setExpenditureNorms({
      ...expenditureNorms,
      [name]: value
    });

    /*setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('expenditureNorms.form.numbers.error')}`
      }
    }));*/
  };

  const handleCreate = async () => {
    const isValid = validateForm();
    if (isValid) {
      const sendData = { ...expenditureNorms };
      sendData.status = Number(sendData.status);

      createExpenditureNorms(sendData);
      navigate('/expenditure-norms');
    }
  };

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('expenditureNorms.form.title-create')}</h3>
              </div>

              <ExpenditureNormsForm
                expenditureNorms={expenditureNorms}
                setExpenditureNorms={setExpenditureNorms}
                action={handleCreate}
                handleChange={handleChange}
                ctaButtonText={__('expenditureNorms.form.button')}
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                updateItemСount={updateItemСount}
                itemsPush={itemsPush}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
