import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BookingHeader } from './bookingHeader';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { getBookingModelTime, setPersonalVisitMove } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ButtonSend } from './../../common/FormsComponent';
dayjs.extend(isoWeek);

export const BookingHorseTime = () => {
  const modelType = useSelector((state) => state.mode);
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const visitId = state?.visitId;

  const { selectedServiceId, selectedHorseId } = useParams();

  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const today = dayjs();
  const formattedDate = today.format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [selectedDay, setSelectedDay] = useState(dayjs().format('D'));
  const currentWeekStart = dayjs().startOf('isoWeek');
  const currentWeekEnd = dayjs().endOf('isoWeek');
  const [currentWeek, setCurrentWeek] = useState([currentWeekStart, currentWeekEnd]);
  const [selectedMonth, setSelectedMonth] = useState(currentWeekStart.format('MMMM'));
  const availableDays = data.map((coachesTime) => dayjs(coachesTime.date).format('D'));
  const [selectedTime, setSelectedTime] = useState(null);

  const handlePrevWeek = () => {
    const prevWeekStart = currentWeek[0].subtract(1, 'week');
    const prevWeekEnd = currentWeek[1].subtract(1, 'week');

    if (prevWeekStart.isBefore(today.startOf('isoWeek'))) {
      return;
    }

    setCurrentWeek([prevWeekStart, prevWeekEnd]);
    setSelectedMonth(prevWeekStart.format('MMMM'));

    if (prevWeekStart.isSame(today, 'isoWeek')) {
      setSelectedDate(today.format('YYYY-MM-DD'));
      setSelectedDay(today.format('D'));
    } else {
      setSelectedDate(prevWeekStart.format('YYYY-MM-DD'));
      setSelectedDay(prevWeekStart.format('D'));
    }
  };

  const handleNextWeek = () => {
    const nextWeekStart = currentWeek[0].add(1, 'week');
    const nextWeekEnd = currentWeek[1].add(1, 'week');

    setCurrentWeek([nextWeekStart, nextWeekEnd]);
    setSelectedMonth(nextWeekStart.format('MMMM'));
    setSelectedDate(nextWeekStart.format('YYYY-MM-DD'));
    setSelectedDay(nextWeekStart.format('D'));
  };

  const handleDateChange = (date) => {
    setSelectedDay(date.format('D'));
  };

  const handleVisitMove = async () => {
    const userConfirm = window.confirm('Ви впевнені, що хочете перенести візит?');
    if (!userConfirm || !selectedTime) {
      navigate('/visits');
      return;
    }

    try {
      const res = await setPersonalVisitMove(visitId, {hors_timeslot_id: selectedTime});
      const data = res.data;

      if (!data) {
        console.warn(res);
        alert('Візит можна перенести не раніше ніж за 24г, до поточної дати');
        return;
      }

      alert('Візит успішно перенесено !!!');
      navigate('/visits');

    } catch (error) {
      console.warn('PersonalVisits move error !!!', error);
    }
    
  }

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'];

  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    const day = currentWeek[0].add(i, 'day');
    const isSameMonth = day.isSame(selectedDate, 'month');
    const isDisabled = day.isBefore(today, 'day');
    weekDays.push({ day, isSameMonth, isDisabled });
  }
  const isBookingActive = selectedTime !== null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingModelTime(
          modelType.mode,
          selectedHorseId,
          selectedServiceId,
          selectedDate
        );

        // Видалення попередніх даних та додавання нових з унікальною датою
        const uniqueDates = new Set();
        const filteredData = response.data.filter((horsesTime) => {
          const dateTime = dayjs(horsesTime.date).format('YYYY-MM-DD HH:mm:ss');
          if (!uniqueDates.has(dateTime)) {
            uniqueDates.add(dateTime);
            return true;
          }
          return false;
        });

        setData({
          loaded: true,
          data: filteredData
        });
      } catch (error) {
        // Обробка помилки
      }
    };

    fetchData();
  }, [selectedServiceId, selectedHorseId, selectedDate]);

  const selectedHorsesTime = data.find((horseTime) => horseTime.id === selectedTime);
  const horsesAllTimeslots = loaded ? (
    data.filter((horsesTime) => dayjs(horsesTime.date).format('D') === selectedDay).length > 0 
      ? data
          .filter((horsesTime) => dayjs(horsesTime.date).format('D') === selectedDay)
          .sort((a, b) => {
            const timeA = dayjs(a.date).format('HH:mm');
            const timeB = dayjs(b.date).format('HH:mm');
            return timeA.localeCompare(timeB);
          })
      : []
  ) : [];

  const horsesTimeSlots = horsesAllTimeslots.filter(timeslot => {
    const time1 = dayjs();
    const time2 = dayjs(timeslot.date);
    const diff = time2.diff(time1, 'm');

    return diff > 0;
  });
  

  return (
    <>
      <BookingHeader />
      <main className="main-booking">
        <div className="booking-wrap">
          <div className="booking-horses">
            <div className="booking-title">
              <Link className="booking-title__back" to={`/booking/services/${selectedServiceId}`}>
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <p>{__('booking.coach-time.title')}</p>
            </div>
            <div className="booking-date-picker">
              <div className="booking-date-picker__month">
                <p>{selectedMonth}</p>
              </div>
              <div className="booking-date-picker__inside">
                <button className="booking-date-picker__arrow" onClick={handlePrevWeek}>
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <div className="booking-date-picker__content">
                  <div className="booking-date-picker__header">
                    {daysOfWeek.map((day, index) => (
                      <div key={index}>{day}</div>
                    ))}
                  </div>
                  <div className="booking-date-picker__body">
                    {weekDays.map(({ day, isSameMonth, isDisabled }) => {
                      const isAvailable = availableDays.includes(day.format('D'));

                      return (
                        <div
                          className={`booking-date-picker__day ${
                            isSameMonth && selectedDay === day.format('D') ? 'active' : ''
                          } ${isDisabled || !isAvailable ? 'disable' : ''}`}
                          key={day.format('DD')}
                          onClick={() => {
                            if (!isDisabled && selectedDay !== day.format('D') && isAvailable) {
                              handleDateChange(day);
                            }
                          }}>
                          {day.format('D')}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <button className="booking-date-picker__arrow" onClick={handleNextWeek}>
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.292876 17.7071C-0.0976238 17.3166 -0.0976238 16.6834 0.292876 16.2929L7.58577 8.99998L0.292876 1.70708C-0.0976238 1.31658 -0.0976238 0.683375 0.292876 0.292875C0.683376 -0.0976247 1.31656 -0.0976247 1.70709 0.292875L9.70709 8.29288C10.0976 8.68338 10.0976 9.31658 9.70709 9.70708L1.70709 17.7071C1.31656 18.0976 0.683376 18.0976 0.292876 17.7071Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="booking-horses-time-list">
              {loaded ? (horsesTimeSlots.length > 0 ? (
                  horsesTimeSlots.map((horsesTime) => (
                    <div
                      className={`booking-horses-time-list__item ${
                        selectedTime === horsesTime.id ? 'active' : ''
                      }`}
                      key={horsesTime.id}
                      onClick={() => setSelectedTime(horsesTime.id)}>
                      <div className="booking-horses-time-list__item-time">
                        <p>{dayjs(horsesTime.date).format('HH:mm')}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="plug grid-line">
                    <div className="plug__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none">
                        <path
                          d="M12.4958 4.9939V9.99598"
                          stroke="#323232"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M27.5021 4.9939V9.99598"
                          stroke="#323232"
                          stroke-width="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="4.99365"
                          y="7.49487"
                          width="30.0125"
                          height="27.5115"
                          rx="3"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3357 28.3369H11.6621"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5001 17.499L17.498 22.5011"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.498 17.499L22.5001 22.5011"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="plug__text">
                      <p>{__('booking.coach-time.time-null')}</p>
                    </div>
                  </div>
                )
              ) : (
                <CircularProgress className="grid-line" />
              )}
            </div>

            <div className={`booking-action ${isBookingActive ? 'active' : ''}`}>
              <div className="booking-action-inside">
                {
                  !visitId
                    ? <Link
                        className="button button--width100p"
                        to={`/booking/services/${selectedServiceId}/horse/${selectedHorseId}/time/${selectedTime}?horse_id=${
                          selectedHorsesTime ? selectedHorsesTime.model_id : ''
                        }`}>
                        <p>{__('booking.button.next')}</p>
                      </Link>
                    : <ButtonSend onClick={ handleVisitMove } text="Перенести візит" sx={{ height: '50px', width: '100%' }}/>
                }
                
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
