import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export const FilterSelect = ({
  children,
  inputLabel, 
  selectLabel, selectName, selectValue, selectHandler,
  menuItemDefaulValue, menuItemDefaultText, sx,
}) => {
  return (
    <div className="input-wrap">
      <FormControl
        sx={{
          width: '100%',
          display: 'block',
          '.MuiInputBase-input': { boxSizing: 'border-box' },
          ...sx,
        }}>
        <InputLabel>{ inputLabel }</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={ selectLabel }
          name={ selectName }
          value={ selectValue }
          onChange={ selectHandler }>
          <MenuItem value={ menuItemDefaulValue }>{ menuItemDefaultText }</MenuItem>

          { children }

        </Select>
      </FormControl>
    </div>
  );
};

FilterSelect.defaultProps = {
  menuItemDefaulValue: '',
  menuItemDefaultText: 'none',
  sx: {},
}