import { flatingLocaleData } from "./../../../library";

export const getDataCloneForSend = (data) => {
  let dataClone = { ...data };
  const flatLocaleData = flatingLocaleData(data);

  delete dataClone.locale;
  dataClone = {...dataClone, ...flatLocaleData};

  dataClone.is_active = Number(dataClone.is_active);

  return dataClone;
}