import { FormHelperText, TextField } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

export const UserDataForm = ({ data, onChange, validationStatus, userDataField, __ }) => {
  const { first_name, last_name, phone } = userDataField;
  return (
    <>
      <TextField
        fullWidth
        value={data[first_name]}
        name={ first_name }
        label={__(`booking.input.first_name`)}
        onChange={onChange(first_name)}
        error={!validationStatus[first_name]?.isValid}
        helperText={validationStatus[first_name]?.errorMessage}
        className={validationStatus[first_name]?.isValid ? '' : 'input-error'}
      />
      <TextField
        fullWidth
        value={data[last_name]}
        name={ last_name }
        label={__('booking.input.last_name')}
        onChange={onChange(last_name)}
        error={!validationStatus[last_name]?.isValid}
        helperText={validationStatus[last_name]?.errorMessage}
        className={validationStatus[last_name]?.isValid ? '' : 'input-error'}
      />
      <PhoneInput
        className={`phone-input ${
          !validationStatus[phone]?.isValid ? 'input-error' : ''
        }`}
        fullWidth
        placeholder={'Телефон'}
        name={ phone }
        id="number"
        type="number"
        country={'ua'}
        preferredCountries={['ua', 'ru', 'us']}
        masks={{ ua: '(..) ...-..-..' }}
        onChange={onChange(phone)}
      />
      {!validationStatus[phone]?.isValid && (
        <FormHelperText error>{validationStatus[phone]?.errorMessage}</FormHelperText>
      )}
    </>
  );
};

UserDataForm.defaultProps = {
  userDataField: {
    first_name: 'first_name',
    last_name: 'last_name',
    phone: 'phone'
  }
}