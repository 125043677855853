import { flatingLocaleData } from "./../../../library";

export const getDataCloneForSend = (data) => {
  const amountWithTwoDecimalPlaces = parseFloat(data.amount).toFixed(2);
  let dataClone = { ...data, amount: amountWithTwoDecimalPlaces };
  const flatLocaleData = flatingLocaleData(dataClone);

  delete dataClone.locale;
  dataClone = {...dataClone, ...flatLocaleData};

  if (typeof dataClone.image === 'string') {
    dataClone.image = '';
  }

  dataClone.is_active = Number(dataClone.is_active);
  dataClone.available_for_online = Number(dataClone.available_for_online);

  dataClone.service_category_id = 
    dataClone.service_category_id 
      ? dataClone.service_category_id 
      : dataClone?.service_category.category_id;
    
  return dataClone;
}