import { useState } from 'react';

import { createHorsesCategories } from '../../../actions/';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate } from 'react-router-dom';

import { HorsesCategoriesForm } from './HorsesCategoriesForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { flatingLocaleData } from 'src/components/library';
import { getValidateForm, localeValidationData, localeModel } from './validation';

export const HorsesCategoriesCreate = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/horses-categories');
  };

  const [horsesCategories, setHorsesCategories] = useState({
    is_active: true,
    locale: { ...localeModel }
  });

  const [validationStatus, setValidationStatus] = useState({...localeValidationData});

  const validateForm = getValidateForm(horsesCategories, setValidationStatus, __, 'horsesCategories');

  const handleLocalizationChange = getHandleLocalizationChange(
    __('horsesCategories.form.title.error'),
    setHorsesCategories,
    setValidationStatus
  );

  const handleCreate = () => {
    const isValid = validateForm();
    if (isValid) {
      // horsesCategories.locale = JSON.stringify(horsesCategories.locale);

      createHorsesCategories(horsesCategories).then((res) => {
        navigate('/horses-categories');
      });
    }
  };

  return (
    <ModalForm title={__('horsesCategories.form.title-create')} open={true} onClose={toggleOpened}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <HorsesCategoriesForm
        horsesCategories={horsesCategories}
        setHorsesCategories={setHorsesCategories}
        action={handleCreate}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('horsesCategories.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
