import { useEffect, useState } from 'react';
import { updatePerson } from '../../../actions/persons';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';

import { PersonFormEdit } from './PersonFormEdit';
import { getPerson } from '../../../actions';

import dayjs from 'dayjs';
import {
  handleChangeCreator,
  handleRoleChangeCreator,
  handleServerErrors,
  validEditFormUploadData
} from './utils';
import { ModalForm } from './../../common/FormsComponent';

export const EditPerson = ({ opened }) => {
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/persons');
  };

  const [loaded, setLoaded] = useState(false);
  const [serverErrors, setServerError] = useState(null);

  const [isValid, setFromValidation] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    number: false,
    individual_taxpayer_number: false
  });

  const [person, setPerson] = useState({
    id: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    phone: '',
    email: '',
    passport_data: '',
    individual_taxpayer_number: '',
    date_of_birth: dayjs(new Date()),
    roles: [
      {
        id: '',
        name: ''
      }
    ]
  });

  const handleChange = handleChangeCreator(
    person,
    setPerson,
    validationStatus,
    setValidationStatus,
    setFromValidation
  );
  const handleRoleChange = handleRoleChangeCreator(person, setPerson);

  const handleUpdate = () => {
    if (typeof person.date_of_birth == 'object') {
      person.date_of_birth = person.date_of_birth.format('YYYY-MM-DD');
    }

    if (typeof person.passport_data.date == 'object') {
      person.passport_data.date = person.passport_data.date.format('YYYY-MM-DD');
    }

    person.phone = person.phone.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');

    updatePerson(params.id, person).then((res) => {
      if (res.status === 200) {
        navigate('/persons');
      }

      const errors = res?.errors;
      if (res?.errors) {
        setServerError(errors);
        handleServerErrors(errors, setFromValidation, validationStatus, setValidationStatus);
      }
    });
  };

  useEffect(() => {
    getPerson(params.id).then((res) => {
      const data = res?.data?.data ? res.data.data : {};

      if (data.date_of_birth === true) {
        data.date_of_birth = dayjs(moment().format('YYYY-MM-DD'));
      }
      if (data.passport_data.date) {
        data.passport_data.date = dayjs(moment(data.passport_data.date).format('YYYY-MM-DD'));
      }

      setPerson(data);
      setLoaded(true);

      const [validateStatus, allValid] = validEditFormUploadData(data);
      setFromValidation(allValid);
      setValidationStatus(validateStatus);
    });
  }, []);

  return (
    <ModalForm
      title="Редагувати користувача"
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      closeBtn
    >
      <PersonFormEdit
        person={person}
        handleChange={handleChange}
        handleRoleChange={handleRoleChange}
        action={handleUpdate}
        ctaButtonText="Редагувати"
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
