import { useEffect, useState } from 'react';
// import { AddInventoryItems } from './addInventoryItems';
import { AddInventoryItems } from './../../common/FormsComponent';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  CircularProgress,
  FormHelperText
} from '@mui/material';

import { getExpenditureNormsHorses } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const ExpenditureNormsForm = ({
  expenditureNorms,
  handleChange,
  action,
  ctaButtonText,
  validationStatus,
  updateItemСount,
  itemsPush
}) => {
  const { __ } = useTranslation();

  const [{ loaded, horses }, setData] = useState({
    loaded: false,
    horses: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: expenditureNormsHorses } = await getExpenditureNormsHorses();
        setData({
          loaded: true,
          horses: expenditureNormsHorses
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <TextField
        fullWidth
        value={expenditureNorms?.name}
        onChange={handleChange('name')}
        name="name"
        label={__('expenditureNorms.input.name')}
        error={!validationStatus.name.isValid}
        helperText={validationStatus.name.errorMessage}
        className={validationStatus.name.isValid ? '' : 'input-error'}
      />

      <FormControl className={validationStatus.horse_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('expenditureNorms.input.horses_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('expenditureNorms.input.horses_id')}
          name="horses_id"
          value={expenditureNorms?.horse_id}
          onChange={handleChange('horse_id')}
          error={!validationStatus.horse_id.isValid}
          helperText={validationStatus.horse_id.errorMessage}>
          {horses.data &&
            horses.data.map((horse) => (
              <MenuItem key={horse.id} value={horse.id}>
                {horse.name}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.horse_id.isValid && (
          <FormHelperText>{validationStatus.horse_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <InputLabel>{__('expenditureNorms.input.period')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('expenditureNorms.input.period')}
          name="period"
          value={expenditureNorms?.period}
          onChange={handleChange('period')}>
          <MenuItem value={0}>{__('expenditureNorms.input.period.day')}</MenuItem>
          <MenuItem value={1}>{__('expenditureNorms.input.period.week')}</MenuItem>
          <MenuItem value={2}>{__('expenditureNorms.input.period.month')}</MenuItem>
          <MenuItem value={3}>{__('expenditureNorms.input.period.quarter')}</MenuItem>
          <MenuItem value={4}>{__('expenditureNorms.input.period.year')}</MenuItem>
        </Select>
      </FormControl>
      <label className="checkbox">
        <Checkbox
          name="status"
          value={expenditureNorms?.status}
          checked={expenditureNorms?.status || false}
          onChange={handleChange('status')}
        />
        <p>{__('expenditureNorms.input.status')}</p>
      </label>

      <div className={validationStatus.inventoryItems.isValid ? '' : 'error'}>
        <AddInventoryItems type="expenditureNorms" updateItemСount={updateItemСount} itemsPush={itemsPush} />
      </div>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
