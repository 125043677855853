  // sidebar list common styles
export const s = {
  accordion: {
    border: 'none',
    boxShadow: 'none',
    '&:before': { display: 'none' },
    '&.Mui-expanded': { m: 0 }
  },
  accordionSummary: {
    '&.Mui-expanded': { minHeight: 0 },
    '.MuiAccordionSummary-content.Mui-expanded': { mt: '12px', mb: '12px' }
  },
  listItemIcon: { minWidth: 36 },
  accordionDetails: { pl: 1 },
  listItemButton: { pl: 2 },
}