import { Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useState } from 'react';
import Box from '@mui/material/Box';

export default function OneService({
  service,
  amount,
  setAmount,
  clickedServiceId,
  setClickedServiceId,
  serviceId
}) {
  const { id, title, description } = service;

  const [isPlusShow, setIsPlusShow] = useState(Number(serviceId) !== id);

  return (
    <Card
      sx={{
        mb: 5,
        cursor: clickedServiceId && Number(clickedServiceId) !== id ? 'not-allowed' : 'pointer',
        opacity: clickedServiceId && Number(clickedServiceId) !== id ? 0.5 : 1
      }}
      onClick={() => {
        if (clickedServiceId && Number(clickedServiceId) !== id) {
          return;
        }
        if (isPlusShow) {
          setClickedServiceId(id);
          setAmount(amount + 1);
        } else {
          setClickedServiceId(null);
          setAmount(amount - 1);
        }
        setIsPlusShow(!isPlusShow);
      }}>
      <CardMedia
        sx={{ height: 240 }}
        image="https://assets.alteg.io/main_service_image/basic/7/7b/7b8ca0ccdbc7cb1_20210727234907.png"
        title="service"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ color: 'red' }}>{service.amount} &#8372;</Typography>
          <IconButton
            id="plusServiceAmount"
            size="large"
            sx={{
              p: 0,
              cursor:
                clickedServiceId && Number(clickedServiceId) !== id ? 'not-allowed' : 'pointer'
            }}>
            {isPlusShow ? (
              <AddCircleOutlineIcon fontSize="inherit" />
            ) : (
              <RemoveCircleIcon fontSize="inherit" />
            )}
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}
