import { OtherUserForm, UserDataForm, UserDataInfo } from ".";

export const UserDataBlock = ({ children, user, data, setData,  onChange, validationStatus, setValidationStatus, __, isOtherUser }) => {
  return (
    <>
      {
        !user &&
          <UserDataForm
            data={ data }
            onChange={ onChange }
            validationStatus={ validationStatus }
            __={ __ }
          />
      }
      {
        user &&
          <UserDataInfo
            data={ data }
            __={ __ }
          />
      }

      { children }

      {
        isOtherUser &&
          <OtherUserForm 
            data={ data }
            setData={ setData }
            onChange={ onChange }
            validationStatus={ validationStatus }
            setValidationStatus={ setValidationStatus }
            __={ __ }
          />
      }
    </>
  );
};

export default UserDataBlock;

