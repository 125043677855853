import axios from '../../helpers/private.axios';

export const createInventoryItems = (inventoryItem) => {
    return axios.post(`/inventory-items/`, inventoryItem, {
        headers: { 'content-type': 'multipart/form-data' }
    }).then((res) => {
        if (res.success) {
            return res.success;
        }
    });
};
