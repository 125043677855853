import { formatDataForHistoryCollaps } from "../utils";

export const getRowCellsData = (dataItem, __) => {
  const causer = dataItem?.user 
    ? dataItem?.user?.first_name + ' ' + dataItem?.user?.last_name
    : 'Системна подія'

  const subject = dataItem?.subject?.subject_type.split('\\')[2];

  return {
    title: {
      th: 'Назва',
      td: dataItem && dataItem.log_name,
      sort: null,
    },
    dataCreated: {
      th: 'Дата(створення)',
      td: dataItem && dataItem.created_at,
      sort: null,
    },
    dataUpdated: {
      th: 'Дата(оновлення)',
      td: dataItem && dataItem.updated_at,
      sort: null,
    },
    causer: {
      th: 'Ініціатор',
      td: dataItem && causer,
      sort: null,
    },
    event: {
      th: 'Подія',
      td: dataItem && dataItem.event,
      sort: null,
    },
    subject: {
      th: 'Об`єкт застосування',
      td: dataItem && subject,
      sort: null,
    },
  }
}