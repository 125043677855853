import { Box, Button, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


export const SelectImgFile = ({
  changeHandler, 
  fileName, 
  isEmpty,
  isValid,
  imageInfo,
}) => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      <Typography sx={{ maxWidth: '250px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}gutterBottom>
        File: { 
          fileName 
            ? fileName
            : !isEmpty ? 'UPLOAD' : ''
        }
      </Typography>
      {
        fileName &&
          <Box sx={{ color: isValid ? 'black' : 'red', textAlign: 'left' }}>
            <Typography variant="body2" gutterBottom>size: <b>{ imageInfo.size }Kb</b></Typography>
            <Typography variant="body2" gutterBottom>resolution: <b>{`${imageInfo.width}x${imageInfo.height}`}</b></Typography>
          </Box>
      }
      
      <input
        type="file"
        id="file-input"
        accept=".jpg, .png" // Определите допустимые типы файлов
        style={{ display: 'none' }}
        onChange={changeHandler}
      />
      <label htmlFor="file-input">
        <Button
          variant="contained"
          component="span"
          startIcon={<CloudUploadIcon />}
          sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
        >
          Вибрати файл
        </Button>
      </label>
    </Box>
  );
};