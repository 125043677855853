import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ButtonIcon } from '../../TableAction';

export const CollapseToggleBtn = ({ collapsible, open, setOpen }) => {
  return (
    <>
      { 
        collapsible &&
          <ButtonIcon
            className="collapse-btn" 
            icon={ open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
            onClick={ () => setOpen(!open) }
            sx={{ justifySelf: 'center' }}
          /> 
      } 
    </>
  );
};