import { rolesConfig } from "../rolesConfig";
import { useGetRoles } from "src/hooks";

export const useCheckAvailableCurrentUserPermission = (resource, permission) => {
  const roles = useGetRoles();
  
  if (!resource) {
    return true;
  }

  let availableByRole = false;

  for (let roleObj of roles) {
    const role = roleObj.name;
    const permissionsExceptions = rolesConfig[role].permissionsExceptions ? rolesConfig[role].permissionsExceptions[resource] : null;

    const permissions = permissionsExceptions ? permissionsExceptions : rolesConfig[role].permissions;

    availableByRole = permissions.includes(permission);
    if (availableByRole) {
      break;
    }
  }

  return availableByRole;
}