const units = {
    uk: {
        "units.page.title": "Одиниці вимірювання",
        "units.table.title": "Назва",
        "units.table.name": "Назва",
        "units.table.description": "Опис",
        "units.table.is_active": "Активний/Неактивний",
        "units.table.actions": "Дії",
        "units.form.title-create": "Створити одиницю вимірювання",
        "units.form.title-edit": "Редагувати одиницю вимірювання",
        "units.form.button": "Зберегти",
        "units.form.title.error": "*Поле є обов'язковим",
    },
    en: {
        "units.page.title": "Unit of measurement",
        "units.table.title": "Name",
        "units.table.name": "Name",
        "units.table.description": "Description",
        "units.table.is_active": "Active/Inactive",
        "units.table.actions": "Actions",
        "units.form.title-create": "Create a unit of measure",
        "units.form.title-edit": "Edit unit of measure",
        "units.form.button": "Save",
        "units.form.title.error": "*The field is mandatory",
    },
    ru: {
        "units.page.title": "Единицы измерения",
        "units.table.title": "Название",
        "units.table.name": "Название",
        "units.table.description": "Описание",
        "units.table.is_active": "Активный/Неактивный",
        "units.table.actions": "Действия",
        "units.form.title-create": "Создать единицу измерения",
        "units.form.title-edit": "Редактировать единицу измерения",
        "units.form.button": "Сохранить",
        "units.form.title.error": "*Поле является обязательным",

    }
}


export default units;