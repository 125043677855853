import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

export const ButtonLinkIcon = ({icon, route, state, sx, ...props}) => {
  return (
    <Link to={ route } state={state} >
      <IconButton
        variant="contained"
        type="submit"
        sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
        { ...props }
      >
        <>{ icon }</>
      </IconButton>
    </Link>
  );
};

ButtonLinkIcon.defaultProps = {
  sx: {},
  state: {},
}