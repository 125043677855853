const abonnements = {
        uk: {
                "abonnements.page.title": "Абонементи",
                // table
                "abonnements.table.title": "Назва",
                "abonnements.table.description": "Опис",
                "abonnements.table.type": "Тип",
                "abonnements.table.category": "Категорія",
                "abonnements.table.service": "Послуга",
                "abonnements.table.amount": "Вартість",
                "abonnements.table.quantity_hours": "Кількість годин (всього)",
                "abonnements.table.quantity_hours_last": "Кількість годин (залишилося)",
                "abonnements.table.discount_amount": "Зижка (сума)",
                "abonnements.table.discount_percent": "Знижка %",
                "abonnements.table.date_start": "Дата початку",
                "abonnements.table.date_end": "Дата кінця",
                "abonnements.table.day_last": "Термін дії (днів до закінчення)",
                "abonnements.table.person": "Клієнт",
                "abonnements.table.is_active": "Активний/Неактивний",
                "abonnements.table.is_valid": "Діючий/Недіючий",
                "abonnements.table.valid": "діючий",
                "abonnements.table.notvalid": "недіючий",
                "abonnements.table.actions": "Дії",
                // table end

                //form
                "abonnements.form.title-create": "Створити абонемент",
                "abonnements.form.title-edit": "Редагувати абонемент",
                "abonnements.form.button": "Зберегти",
                "abonnements.form.is_active": "Активний/Неактивний",
                "abonnements.input.type": "Тип*",
                "abonnements.input.category_id": "Категорії*",
                "abonnements.input.service_id": "Сервіси*",
                "abonnements.input.amount": "Ціна*",
                "abonnements.input.quantity_hours": "Кількість годин",
                "abonnements.input.discount_amount": "Зижка (сума)",
                "abonnements.input.discount_percent": "Знижка %",
                "abonnements.input.date_start": "Дата початку дії*",
                "abonnements.input.date_end": "Дата кінця дії*",
                //form error
                "abonnements.form.null.error": "*Поле є обов'язковим",
                "abonnements.form.title.error": "*Поле є обов'язковим",
                "abonnements.form.description.error": "*Поле є обов'язковим",
                "abonnements.form.category_id.error": "*Поле є обов'язковим",
                "abonnements.form.service_id.error": "*Поле є обов'язковим",
                "abonnements.form.amount.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "abonnements.form.quantity_hours.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9",
                "abonnements.form.date_start.error": "*Поле є обов'язковим, дата не може бути після дати закічення, дати не можуть бути однаковими",
                "abonnements.form.date_end.error": "*Поле є обов'язковим, дата не може бути перед датою початку, дати не можуть бути однаковими",
                "abonnements.form.one_of_three_error": "*Поле є обов'язковим, в поле можна водити лише - 0-9. Тільки одне поле може з трьох містити значення"

                //form end
        },
        "en": {
                "abonnements.page.title": "Season Tickets",
                // table
                "abonnements.table.title": "Title",
                "abonnements.table.description": "Description",
                "abonnements.table.type": "Type",
                "abonnements.table.category": "Category",
                "abonnements.table.service": "Service",
                "abonnements.table.amount": "Price",
                "abonnements.table.quantity_hours": "Quantity hours (total)",
                "abonnements.table.quantity_hours_last": "Quantity hours (last)",
                "abonnements.table.discount_amount": "Sale (amount)",
                "abonnements.table.discount_percent": "Sale %",
                "abonnements.table.date_start": "Start Date",
                "abonnements.table.date_end": "End Date",
                "abonnements.table.day_last": "Day last",
                "abonnements.table.person": "Client",
                "abonnements.table.is_active": "Active/Inactive",
                "abonnements.table.is_valid": "Valid/Not valid",
                "abonnements.table.valid": "valid",
                "abonnements.table.notvalid": "not valid",
                "abonnements.table.actions": "Actions",
                // table end

                //form
                "abonnements.form.title-create": "Create Season Ticket",
                "abonnements.form.title-edit": "Edit Season Ticket",
                "abonnements.form.button": "Save",
                "abonnements.form.is_active": "Active/Inactive",
                "abonnements.input.type": "Type*",
                "abonnements.input.category_id": "Categories*",
                "abonnements.input.service_id": "Services*",
                "abonnements.input.amount": "Price*",
                "abonnements.input.quantity_hours": "Quantity Hours",
                "abonnements.input.discount_amount": "Discount (amount)",
                "abonnements.input.discount_percent": "Discount %",
                "abonnements.input.date_start": "Start Date*",
                "abonnements.input.date_end": "End Date*",
                //form error
                "abonnements.form.null.error": "*This field is required",
                "abonnements.form.title.error": "*This field is required",
                "abonnements.form.description.error": "*This field is required",
                "abonnements.form.category_id.error": "*This field is required",
                "abonnements.form.service_id.error": "*This field is required",
                "abonnements.form.amount.error": "*This field is required, only 0-9 are allowed.",
                "abonnements.form.quantity_hours.error": "*This field is required, only 0-9 are allowed.",
                "abonnements.form.date_start.error": "*This field is required, date cannot be after the end date, dates cannot be the same",
                "abonnements.form.date_end.error": "*This field is required, date cannot be before the start date, dates cannot be the same",
                "abonnements.form.one_of_three_error": "*This field is required, only 0-9 are allowed. Only one field out of three can have a value."
                //form end
        },

        "ru": {
                "abonnements.page.title": "Абонементы",
                // table
                "abonnements.table.title": "Название",
                "abonnements.table.description": "Описание",
                "abonnements.table.type": "Тип",
                "abonnements.table.category": "Категория",
                "abonnements.table.service": "Услуга",
                "abonnements.table.amount": "Цена",
                "abonnements.table.quantity_hours": "Количество часов (всего)",
                "abonnements.table.quantity_hours_last": "Количество часов (осталось)",
                "abonnements.table.discount_amount": "Скидка (сумма)",
                "abonnements.table.discount_percent": "Скидка %",
                "abonnements.table.date_start": "Дата начала",
                "abonnements.table.date_end": "Дата окончания",
                "abonnements.table.day_last": "Срок действия (дней до окончания)",
                "abonnements.table.person": "Клиент",
                "abonnements.table.is_active": "Активный/Неактивный",
                "abonnements.table.is_valid": "Действующий/Недействующий",
                "abonnements.table.valid": "действующий",
                "abonnements.table.notvalid": "недействующий",
                "abonnements.table.actions": "Действия",
                // table end

                //form
                "abonnements.form.title-create": "Создать абонемент",
                "abonnements.form.title-edit": "Редактировать абонемент",
                "abonnements.form.button": "Сохранить",
                "abonnements.form.is_active": "Активный/Неактивный",
                "abonnements.input.type": "Тип*",
                "abonnements.input.category_id": "Категории*",
                "abonnements.input.service_id": "Услуги*",
                "abonnements.input.amount": "Цена*",
                "abonnements.input.quantity_hours": "Количество часов",
                "abonnements.input.discount_amount": "Скидка (сумма)",
                "abonnements.input.discount_percent": "Скидка %",
                "abonnements.input.date_start": "Дата начала*",
                "abonnements.input.date_end": "Дата окончания*",
                //form error
                "abonnements.form.null.error": "*Это поле обязательно для заполнения",
                "abonnements.form.title.error": "*Это поле обязательно для заполнения",
                "abonnements.form.description.error": "*Это поле обязательно для заполнения",
                "abonnements.form.category_id.error": "*Это поле обязательно для заполнения",
                "abonnements.form.service_id.error": "*Это поле обязательно для заполнения",
                "abonnements.form.amount.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "abonnements.form.quantity_hours.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "abonnements.form.date_start.error": "*Это поле обязательно для заполнения, дата не может быть позже даты окончания, даты не могут совпадать",
                "abonnements.form.date_end.error": "*Это поле обязательно для заполнения, дата не может быть раньше даты начала, даты не могут совпадать",
                "abonnements.form.one_of_three_error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9. Только одно из трех полей может содержать значение."
                //form end
        }


}


export default abonnements;