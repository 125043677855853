import { validate } from ".";

export const handleChangeCreator = (handleData, setHandleData, validationStatus, setValidationStatus, setFromValidation) => ( name ) => ( event ) => {
    
  const passport_fields = ['code', 'number', 'date', 'organ' ];
  const event_value_fields = ['phone'];
  const date_fields = ['date', 'date_of_birth'];

  let value = event?.target?.value;
  if( event_value_fields.some( item => item === name ) ){
    value = event;
  }
  if( date_fields.some( item => item === name ) ){
    value = event;
  }

  if( passport_fields.some( item => item === name )){

    setHandleData({
      ...handleData,
      passport_data: {
        ...handleData.passport_data, 
        [name]: value
      }
    });

  } else {
    setHandleData({
        ...handleData,
      [name]: value
    });
  }

  const isValid = validate( name, value );
  
  setValidationStatus({
    ...validationStatus,
    [name]: isValid,
  });
  
  let isAllValid = Object.keys( validationStatus ).every( key => validationStatus[key] === true );
  setFromValidation( isAllValid );
}