import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { deleteClient, getClients, getClientsStatuses, getClientsGroups } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { 
  TableAction, 
  TableBody,
  TrBody, 
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
} from '../../common/TableComponent';

import { ButtonIcon, ButtonLink, ButtonLinkIcon, FilterSearch, FilterSelect } from './../../common/FormsComponent';

import { getClientsRowCellsData } from './TablesData';
import { PhoneInputBlock } from './../../common/FormsComponent';
import { rolesComponentConfig } from './rolesComponentConfig';

export const ClientsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: [],
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(0);
  const [archive, setArchive] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [searchPhone, setSearchPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [filterStatus, setFilterStatus] = useState('');
  const [statuses, setStatuses] = useState([]);

  const [filterGroup, setFilterGroup] = useState('');
  const [groups, setGroups] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleSearchPhone = (name) => (value) => {
    const phone = value.substring(3);
    const phoneLen = phone.length;
    const code = value.substring(0, 3);

    if (!phone) {
      setPhoneNumber('');
      setSearchPhone('');
      return;
    }

    if (phone.length < 2) {
      return;
    }

    let formatPhone = '';

    if (phoneLen <= 5) {
      formatPhone = phone.replace(/(\d{2})/, "$1 ");
    } else if (phoneLen <= 7) {
      formatPhone = phone.replace(/(\d{2})(\d{3})/, "$1 $2-");
    } else if (phoneLen <= 9) {
      formatPhone = phone.replace(/(\d{2})(\d{3})(\d{2})/, "$1 $2-$3-");
    }

    setSearchPhone(`${code} ${formatPhone}`);
  }

  const handleSearchNameChange = (event) => {
    setSearchName(event.target.value);
  };

  const getClientsAction = () => 
    getClients(
      archive,
      searchPhone, 
      searchName,
      filterStatus,
      filterGroup, 
      currentPage, 
      itemsPerPage, 
      sortOption, 
      sortDirection
    );

  const handleClientDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteClient(id).then(async () => {
        const { data: response } = await getClientsAction();
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getClientsAction();
        setData({
          loaded: true,
          data: response.data,
        });
        setTotalPages(response?.meta.last_page);
      }
      catch (error) {
        console.warn('Clients fetch error: ', error);
      }
    }
    fetchData();
  }, [
    archive,
    searchPhone,
    searchName,
    filterStatus,
    filterGroup, 
    currentPage,
    itemsPerPage,
    sortOption, 
    sortDirection,
  ]);

  useEffect(() => {
    (async () => {
      const { data: statusesResponse } = await getClientsStatuses();
      const { data: groupsResponse } = await getClientsGroups();
      setStatuses(statusesResponse.data);
      setGroups(groupsResponse.data);
    })()
  }, []);

  return (
    <TableMain
      title={'Клієнтська база'} 
      getRowCellsData={getClientsRowCellsData}
      sortOption={sortOption} 
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}
    >

      <TableAction className="table-action">
        <div className="table-action__block">
          <PhoneInputBlock
            isError={ false }
            value={ phoneNumber }
            setValue={ handleSearchPhone('phone') }
            noMarginBottom
            noValidate={ true }
            countryCodeEditable={ false }
          />
          <FilterSearch
            label="Клієнт"
            name="filter_search_last_name"
            value= { searchName }
            handler={ handleSearchNameChange } 
          />
          <FilterSelect
            label="Статус"
            name="filter_select_status"
            value={ filterStatus } 
            onChange={ setFilterStatus } 
            dataSelect={ statuses } 
          />
          <FilterSelect
            label="Група"
            name="filter_group_status"
            value={ filterGroup } 
            onChange={ setFilterGroup } 
            dataSelect={ groups } 
          />
        </div>
        <div className="table-action__block">
          <ButtonLink
            text={ __('create.button.title') }
            route="create"
            state={ { action: 'create', backRoute: '/clients'} }
            checkRoles={ rolesComponentConfig }
            permission={ PERMISSIONS.ACTIONS.CREATE }
          />
        </div>
      </TableAction>

      <TableBlock __class="table--clients">
        <TableHeader />
        <TableBody loaded={loaded}>
          { 
            data.map((client) => (
              <TrBody key={client.id} data={client}>
                <ButtonLinkIcon 
                  icon={ <EditIcon /> }
                  route={ `/clients/${client.id}/edit` }
                  state={{ action: 'edit', backRoute: '/clients'}}
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.UPDATE}
                />
                <ButtonIcon 
                  icon={ <DeleteIcon /> } 
                  onClick={ handleClientDelete(client.id) }
                  checkRoles={ rolesComponentConfig }
                  permission={PERMISSIONS.ACTIONS.DELETE}
                />
              </TrBody>
            )) 
          }
        </TableBody>
      </TableBlock>
      
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage} 
      />
      
    </TableMain>
  );
};