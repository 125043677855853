import { useEffect, useState } from 'react';
import { useTranslation } from './../../../../../helpers/translate';

import {
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TableAction,
  FilterSelect,
  TablePagination
} from './../../../../common/TableComponent';
import { getAbonementsRowCellsData } from './../../TablesData';

import { getClientSeasonTickets } from './../../../../../actions';
import { getDateValidOrder } from './../../../../library';

export const ClientSeasonTickets = ({ clientId }) => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: {}
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: abonements } = await getClientSeasonTickets(
          clientId,
          sortOption,
          sortDirection,
          currentPage
        );
        const meta = abonements.meta;

        setData({
          loaded: true,
          data: {
            abonements: abonements.data,
            meta
          }
        });
      } catch (error) {
        console.warn('PersonalVisits fetch error: ', error);
      }
    };
    fetchData();
  }, [sortOption, sortDirection, currentPage]);

  return (
    <TableMain
      getRowCellsData={getAbonementsRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}>
      <TableBlock __class="table--client-abonements table--mob-1660">
        <TableHeader />
        <TableBody loaded={loaded}>
          {data?.abonements?.map((abonement) => {
            const validOrder = getDateValidOrder(abonement.date_end) && abonement.visits_left;

            return (
              <TrBody
                key={abonement.id}
                data={abonement}
                style={{ background: !validOrder && '#f2f2f2' }}
              />
            );
          })}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={data?.meta?.last_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
