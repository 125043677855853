import React from 'react';

export const ScheduleGrid = ({
  weekDays,
  handleTimeChange,
  handleTimeFill,
  handleDaysFill
}) => {
  const days_dictionary = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'НД'];

  return (
    <>
      <div className="schedule-title">Розклад</div>
      <div className="schedule-wrap">
        <div className="schedule">
          <div className="schedule__day schedule__day--header">
            <div className="schedule__time"></div>
            {weekDays[0].hours.map((time, time_index) => (
              <div
                onDoubleClick={(e) => handleTimeFill(e, time_index)}
                key={time_index}
                className="schedule__time">
                {time_index}
              </div>
            ))}
          </div>
          {weekDays.map(({ hours, date }, index) => {
            const day_index = index + 1;
            return (
              <div className="schedule__day" key={day_index}>
                {hours.map((time, time_index) => {
                  return (
                    <React.Fragment key={time_index}>
                      {time_index === 0 && (
                        <div
                          onDoubleClick={(e) => handleDaysFill(e, day_index - 1)}
                          className="schedule__time">
                          {days_dictionary[day_index - 1]}
                        </div>
                      )}
                      <label className="schedule__time">
                        {time.status === 0 ? (
                          <input
                            hidden
                            type="checkbox"
                            checked={time.status}
                            value={time.status}
                            onChange={handleTimeChange(day_index - 1, time_index, date)}
                          />
                        ) : time.status === 1 ? (
                          <input
                            hidden
                            checked={time.status}
                            type="checkbox"
                            value={time.status}
                            onChange={handleTimeChange(day_index - 1, time_index, date)}
                          />
                        ) : (
                          <></>
                        )}
                        {time.status === 1 && <div className="schedule__circle selected"></div>}
                        {time.status === 2 && (
                          <div className="schedule__circle schedule__circle--red"></div>
                        )}
                        {time.status === 3 && <div className="schedule__circle">x</div>}
                      </label>
                    </React.Fragment>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
