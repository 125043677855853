import { Checkbox } from '@mui/material';
import { useTranslation } from '../../../helpers/translate';

export const ToggleActiveStatus = ({name, checked, value, onChange, title, ...props}) => {
  const { __ } = useTranslation();
  return (
    <label className="checkbox">
      <Checkbox
        name={ name }
        value={ value }
        checked={ checked }
        onChange={ onChange }
        {...props}
      />
      <p>{ title || __('general.form.is_active') }</p>
    </label>
  );
};