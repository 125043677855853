import { useState } from "react";
import { Link } from "react-router-dom";

export const TdEditable = ({ value, link, __class, __classTd, editable,  ...props }) => {
  const { className, inputType, action, callback, data } = editable;
  const [inputValue, setInputValue] = useState(value);

  const inputChangeHandler = (e) => {
    const enterValue = e.target.value.trim();
    setInputValue(enterValue);
  }

  const inputBlurHandler = (e) => {
    const enterValue = checkValueOfZeroOrEmty(e.target.value.trim());
    sendData(enterValue, value, setInputValue, action, callback, data);
  }

  const inputPressEnterHandler = (e) => {
    let enterValue = checkValueOfZeroOrEmty(e.target.value.trim());
    const code = e.code;

    if (code === 'Enter') {
      sendData(enterValue, value, setInputValue, action, callback, data);
      e.target.blur();
    }
  }

  if (link) {
    value = <Link to={ link.url } state={link.state}>{ value }</Link>
  }

  return (
    <label className={ `td${__class && ' ' + __class}${__classTd && ' ' + __classTd}${className && ' ' + className}`} {...props}>
      <input 
        className="td-content" 
        type={ inputType }
        value={inputValue} 
        tabIndex={1}
        onChange={ inputChangeHandler }
        onBlur={ inputBlurHandler }
        onKeyDown={ inputPressEnterHandler }
      />
    </label>
  );
};

TdEditable.defaultProps = {
  __class: '',
  __classTd: '',
}

function checkValueOfZeroOrEmty(value) {
  if (value === '') {
    value = null;
  } else {
    value = +value;
  }

  return value;
}

function sendData(fieldValue, propsValue, setState, action, callback, data) {
  const sendingValue = fieldValue || fieldValue === 0 ? fieldValue : propsValue;

  if (fieldValue !== 0 && !fieldValue) {
    setState(propsValue);
    return;
  }

  if (sendingValue !== propsValue) {
    setState(sendingValue);
    action(data?.id, {
      actual_amount: sendingValue,
      actual_price: data?.actual_price
    }, callback);
  }
}