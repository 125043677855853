import { Box, Typography } from "@mui/material";

const EmptyChartsInfo = () => {
  return (
    <Box sx={{ minHeight: '100px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <Typography variant="h5">Данні на цю дату(період)< br/>для обраного типу звіту відсутні</Typography>
    </Box>
  );
};

export default EmptyChartsInfo;