import { TextField } from "@mui/material";

export const InputField = ({
  type = 'text',
  value,
  field,
  label,
  onChange,
  validationStatus,
  disabled,
}) => {
  return (
    <>
      {
        !disabled
          ? <TextField
              value={ value }
              name={ field }
              label={ label }
              onChange={ onChange(field) }
              error={!validationStatus[field]?.isValid}
              helperText={validationStatus[field]?.errorMessage}
              className={validationStatus[field]?.isValid ? '' : 'input-error'}
              
              fullWidth
              type={ type }
            />
          : <TextField
              disabled
              value={ value }
              name={ field }
              label={ label }
              onChange={ onChange(field) }
              
              fullWidth
              type={ type }
            />
      }
    </>
  );
};