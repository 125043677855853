import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DetailsIcon from '@mui/icons-material/Details';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ForumIcon from '@mui/icons-material/Forum';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { a11yProps, TabPanel } from './Utils';
import VisitDetails from '../VisitDetails';
import { VisitStatus } from '../../../../components/common/ClientCard/VisitStatus';
import { Button } from '@mui/material';
import { ClientNotify } from '../ClientNotify';
import VisitCopy from '../VisitCopy';
import { VisitPayment } from '../VisitPayment';
import { VisitRemove } from '../VisitRemove';
import { getPossibilityVisitCancel } from './../../../library';
import VisitHistory from '../VisitHistory';

export default function ClientCardSidebar({ clickedDate, extendedProps }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const visitId = extendedProps.visit?.id;
  const possibilityVisitCancel = getPossibilityVisitCancel(clickedDate, 2);

  const mainTitle = visitId ? 'Редагування візиту' : 'Створення візиту';

  const tabStyles = {
    justifyContent: 'flex-start',
    flexBasis: 'auto',
    flexGrow: '0',
    '@media screen and (max-width: 920px)': {
      width: '50%',
      justifyContent: 'flex-start'
    },
    '@media screen and (max-width: 520px)': {
      width: '100%'
    }
  };

  return (
    <>
      <Box sx={{ bgcolor: 'background.paper', minHeight: '1000px' }}>
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            display: 'flex',
            '.MuiTabs-indicator': {
              '@media screen and (max-width: 920px)': {
                display: 'none'
              }
            },
            '.MuiTabs-flexContainer': {
              '@media screen and (max-width: 920px)': {
                flexWrap: 'wrap'
              }
            }
          }}>
          <Tab
            label={mainTitle}
            icon={<DetailsIcon />}
            iconPosition="start"
            sx={{ ...tabStyles }}
            {...a11yProps(0)}
          />
          <Tab
            label="Деталі візиту"
            icon={<AccessTimeIcon />}
            iconPosition="start"
            disabled={!visitId}
            sx={{ ...tabStyles }}
            {...a11yProps(1)}
          />
          <Tab
            label="Оплата візиту"
            icon={<AttachMoneyIcon />}
            iconPosition="start"
            disabled={!visitId}
            sx={{ ...tabStyles }}
            {...a11yProps(2)}
          />
          <Tab
            label="Повторення"
            icon={<FormatListBulletedIcon />}
            iconPosition="start"
            disabled={!visitId}
            sx={{ ...tabStyles }}
            {...a11yProps(3)}
          />
          <Tab
            label="Історія"
            icon={<HistoryEduIcon />}
            iconPosition="start"
            disabled={!visitId}
            sx={{ ...tabStyles }}
            {...a11yProps(4)}
          />
          { visitId && (
            <Tab
              label="Скасування візиту"
              title="можливе не пізніше ніж за 2г до візиту"
              icon={<DeleteForeverIcon />}
              iconPosition="start"
              sx={{ color: possibilityVisitCancel ? 'red' : 'silver', ...tabStyles }}
              {...a11yProps(5)}
            />
          )}
          {/* <Tab 
            label="Повідомлення" 
            icon={<ForumIcon />} 
            iconPosition="start" 
            disabled={ !visitId }
            {...a11yProps(4)} 
          />
          <Tab
            label="Історія змін"
            icon={<TextSnippetIcon />}
            iconPosition="start"
            disabled={ !visitId }
            {...a11yProps(5)}
          /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          <VisitDetails clickedDate={clickedDate} extendedProps={extendedProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VisitStatus clickedDate={clickedDate} extendedProps={extendedProps} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <VisitPayment extendedProps={extendedProps} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <VisitCopy clickedDate={clickedDate} extendedProps={extendedProps} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <VisitHistory clickedDate={clickedDate} extendedProps={extendedProps} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <VisitRemove clickedDate={clickedDate} extendedProps={extendedProps} />
        </TabPanel>
        {/* <TabPanel value={value} index={4}>
          <ClientNotify />
        </TabPanel> */}
        {/* <TabPanel value={value} index={5}>
          Історія змін
        </TabPanel> */}
      </Box>
    </>
  );
}
