export const getHandleLocalizationChange = (errorText, setData, setValidationStatus) => (locale) => (event) => {
  const { name, value } = event.target;

  setData((prevData) => {
    const newData = {
      ...prevData,
      locale: {
        ...prevData.locale,
        [locale]: {
          ...prevData.locale[locale],
          [name]: value
        }
      }
    }

    console.log('handler', newData);
    return newData;
  });

  const isTitleValid = value.trim() !== '';

  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [locale]: {
      ...prevValidationStatus[locale],
      [name]: {
        isValid: isTitleValid,
        errorMessage: isTitleValid ? '' : errorText,
      }
    }
  }));
};