import { Link } from 'react-router-dom';
import { useTranslation } from '../../../helpers/translate';
import { BookingHeader } from './bookingHeader';

export const BookingHome = () => {
  const { __ } = useTranslation();
  return (
    <>
      <BookingHeader />
      <div className="center-block">
        <div className="inside">
          <div className="center-block-title-home">
            <p>{__('booking.home.title')}</p>
          </div>
          <div className="center-block-links">
            <Link className="button button--width50p" to="/booking/services">
              <p>{__('booking.home.new-user')}</p>
            </Link>
            <Link className="button button--width50p" to="/booking/authorization">
              <p>{__('booking.home.user')}</p>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
