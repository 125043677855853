import axios from '../../helpers/private.axios';

export const getChartBookingFirsrtVisit = async (userParams) => {
    const params = {
        ...userParams,
    };

    return axios.get(`/dashboards/booking-first-visit`, { params })
        .then(res => res)
        .catch(error => error);
};

