import axios from '../../helpers/private.axios';

export const getExpenditureNorms = async (
    currentPage,
    itemsPerPage,
    showArchive,
    searchTitle
) => {
    const params = {};


    if (showArchive) {
        params.status = 'archive';
    } 

    if (searchTitle) {
        params['name'] = searchTitle;
    }


    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/expenditure-norms`, { params });
};

