const general = {
    uk: {
        //form fields
        "general.form.is_active": "Активний/Неактивний",
        // form fields end

        // form error
        "general.form.required_field.error": "*Поле є обов'язковим",
        // form error end

        // form filters
        "general.form.filter.active_show": "Показати активні",
        "general.form.filter.inactive_show": "Показати неактивні",
        "general.form.filter.last_name": "Прізвище",
        // end form filters

        // authorization error
        "general.authorization.phone-error": "Номер телефону некорректний",
        "general.authorization.password-error": "Пароль некорректний",
        // authorization end

        "general.table.active": "Активний",
        "general.table.inactive": "Неактивний",

        "general.imageUpload.file": "Файл",
        "general.imageUpload.size": "Розмір",
        "general.imageUpload.resolution": "Розширення",
        "general.imageUpload.sizeMax": "Макс. розмір",
        "general.imageUpload.resolutionMax": "Макс. розширення",
        "general.imageUpload.button": "Вибрати файл",

        "general.select.none": "Нічого",
    },
    ru: {
        //form fields
        "general.form.is_active": "Активный/Неактивный",
        // form fields end

        // form error
        "general.form.required_field.error": "*Это поле обязательно",
        // form error end

        // form filters
        "general.form.filter.active_show": "Показать активные",
        "general.form.filter.inactive_show": "Показать неактивные",
        "general.form.filter.last_name": "Фамилия",
        // end form filters

        // authorization error
        "general.authorization.phone-error": "Номер телефона некорректный",
        "general.authorization.password-error": "Пароль некорректный",
        // authorization end
        "general.table.active": "Активний",
        "general.table.inactive": "Неактивний",


        "general.imageUpload.file": "Файл",
        "general.imageUpload.size": "Размер",
        "general.imageUpload.resolution": "Разрешение",
        "general.imageUpload.sizeMax": "Макс. размер",
        "general.imageUpload.resolutionMax": "Макс. разрешение",
        "general.imageUpload.button": "Выбрать файл",

        "general.select.none": "Ничего",
    },
    en: {
        //form fields
        "general.form.is_active": "Active/Inactive",
        // form fields end

        // form error
        "general.form.required_field.error": "*This field is required",
        // form error end

        // form filters
        "general.form.filter.active_show": "Show active",
        "general.form.filter.inactive_show": "Show inactive",
        "general.form.filter.last_name": "Surname",
        // end form filters

        // authorization error
        "general.authorization.phone-error": "Phone number is incorrect",
        "general.authorization.password-error": "Password is incorrect",
        // authorization end
        "general.table.active": "Active",
        "general.table.inactive": "Inactive",

        "general.imageUpload.file": "File",
        "general.imageUpload.size": "Size",
        "general.imageUpload.resolution": "Resolution",
        "general.imageUpload.sizeMax": "Max Size",
        "general.imageUpload.resolutionMax": "Max Resolution",
        "general.imageUpload.button": "Choose File",

        "general.select.none": "None",
    }
};

export default general;
