const serviceCategories = {
    uk: {
        "serviceCategories.page.title": "Категорії послуг",
        "serviceCategories.table.title": "Назва",
        "serviceCategories.table.description": "Опис",
        "serviceCategories.table.is_active": "Активний/Неактивний",
        "serviceCategories.table.actions": "Дії",
        "serviceCategories.form.title-create": "Створити категорію послуги",
        "serviceCategories.form.title-edit": "Редагувати категорію послуги",
        "serviceCategories.form.button": "Зберегти",
        "serviceCategories.form.title.error": "*Поле є обов'язковим",
    },
    en: {
        "serviceCategories.page.title": "Categories of services",
        "serviceCategories.table.title": "Name",
        "serviceCategories.table.description": "Description",
        "serviceCategories.table.is_active": "Active/Inactive",
        "serviceCategories.table.actions": "Actions",
        "serviceCategories.form.title-create": "Create a service category",
        "serviceCategories.form.title-edit": "Edit service category",
        "serviceCategories.form.button": "Save",
        "serviceCategories.form.title.error": "*The field is mandatory",
    },
    ru: {
        "serviceCategories.page.title": "Категории услуг",
        "serviceCategories.table.title": "Название",
        "serviceCategories.table.description": "Описание",
        "serviceCategories.table.is_active": "Активный/Неактивный",
        "serviceCategories.table.actions": "Действия",
        "serviceCategories.form.title-create": "Создать категорию услуги",
        "serviceCategories.form.title-edit": "Редактировать категорию услуги",
        "serviceCategories.form.button": "Сохранить",
        "serviceCategories.form.title.error": "*Поле является обязательным",
    }
}


export default serviceCategories;