import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from './../../../../../../helpers/translate';
import isoWeek from 'dayjs/plugin/isoWeek';
import { equalDates, getEmptySlots } from '../Utils';
dayjs.extend(isoWeek);

export const HorseTimeSlots = ({
  loaded,
  data, 
  setTimeSlot, 
  originalSlotId, 
}) => {
  const { __ } = useTranslation();

  const [selectedTime, setSelectedTime] = useState();

  return (
    <>
      <main className="main-booking main-booking_admin">
        <div className="booking-wrap booking-wrap_admin">
          <div className="booking-horses">
            <div className="booking-horses-time-list booking-horses-time-list_admin">
              {loaded ? (data.length > 0 ? (
                  data.map((horsesTime) => {
                    // const isCurrentSlot = dayjs(horsesTime.date).format('HH:mm') === visitTime;
                    const isOriginalSlot = originalSlotId === horsesTime.id;
                    const isActive = selectedTime === horsesTime.id;
                    return (
                      <div
                        className={
                          `booking-horses-time-list__item 
                          ${ isActive ? 'active' : ''}
                          ${ isOriginalSlot ? 'current' : '' }
                          `
                        }
                        key={horsesTime.id}
                        onClick={() => {
                          setSelectedTime(horsesTime.id);
                          setTimeSlot(horsesTime);
                        }}
                      >
                        <div className="booking-horses-time-list__item-time">
                          <p>{dayjs(horsesTime.date).format('HH:mm')}</p>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="plug grid-line">
                    <div className="plug__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none">
                        <path
                          d="M12.4958 4.9939V9.99598"
                          stroke="#323232"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M27.5021 4.9939V9.99598"
                          stroke="#323232"
                          stroke-width="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="4.99365"
                          y="7.49487"
                          width="30.0125"
                          height="27.5115"
                          rx="3"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3357 28.3369H11.6621"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5001 17.499L17.498 22.5011"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.498 17.499L22.5001 22.5011"
                          stroke="#323232"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="plug__text">
                      <p>{__('booking.coach-time.time-null')}</p>
                    </div>
                  </div>
                )
              ) : (
                <CircularProgress className="grid-line" />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
