import axios from '../../helpers/private.axios';

export const getCoach = (id) => {
  return axios.get(`/coaches/${id}`)
    .then(res => res)
    .catch(error => error);
};

export const getCoachByService = (serviceId) => {
  const params = { 
      paginated: false,
      services: serviceId,
  };


  return axios.get(`/coaches`, {params});
};