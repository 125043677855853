import axios from '../../helpers/private.axios';

export const getClientSeasonTickets = (
  clientId,
  sortOption,
  sortDirection,
  currentPage
) => {
  const params = {};

  params.page = currentPage;

  return axios.get(`/clients/season-tickets/${clientId}`, {params});
};
