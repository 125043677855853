import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from './../../Auth/auth.module.scss';
import { FormControl, FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';

export function PersonalDataForm({
  changeHandler,
  registrationObject,
  isValid,
  submitFormHandler,
  passwordError,
  disabled,
}) {
  // const { login, last_name, first_name, email, password, password_confirmation } =
  //   registrationObject;

  const {phone, first_name, last_name, email} = registrationObject;

  return (
    <div className={styles.authorization}>
      <div className="inside">
        <FormControl className={styles['authorization-form']}>

          <div className={styles['authorization-title']}>
            <p>Персональні данні</p>
          </div>
          <PhoneInput
            isValid={isValid.login}
            fullWidth
            placeholder={'Телефон'}
            name="login"
            id="number"
            type="number"
            country={'ua'}
            preferredCountries={['ua', 'ru', 'us']}
            masks={{ ua: '(..) ...-..-..' }}
            value={phone}
            onChange={changeHandler('login')}
            disabled={ disabled }
          />
          <TextField
            name="last_name"
            label="Прізвище"
            onChange={changeHandler('last_name')}
            value={last_name}
            error={!isValid.last_name}
            required
            disabled={ disabled }
          />
          <TextField
            name="firstName"
            label="Ім’я"
            onChange={changeHandler('first_name')}
            value={first_name}
            error={!isValid.first_name}
            required
            disabled={ disabled }
          />
          <TextField
            name="email"
            label="E-mail"
            onChange={changeHandler('email')}
            value={email || ''}
            error={!isValid.email}
            disabled={ disabled }
          />
          {/* <TextField
            name="password"
            type="password"
            label="Пароль"
            onChange={changeHandler('password')}
            value={password}
            error={!isValid.password || passwordError}
            required
            helperText={passwordError}
            disabled={ disabled }
          />
          <TextField
            name="password_confirmation"
            type="password"
            label="Підтвердження пароля"
            onChange={changeHandler('password_confirmation')}
            value={password_confirmation}
            error={!isValid.password_confirmation}
            required
            disabled={ disabled }
          /> */}
          {
            !disabled &&
              <div className={styles['authorization-form__action']}>
                <FormControl error={!isValid.personal_data_agreement} disabled={ disabled }>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked
                          name="personal_data_agreement"
                          onChange={changeHandler('personal_data_agreement')}
                          required
                        />
                      }
                      label={
                        <span>
                          Згоден з{' '}
                          <Link to="/terms" className={styles.link}>
                            умовами
                          </Link>{' '}
                          використання персональних даних
                        </span>
                      }
                    />
                  </FormGroup>
                  {!isValid.personal_data_agreement ? (
                    <FormHelperText>Для реєстрації ви маєте погодитися з умовами</FormHelperText>
                  ) : null}
                </FormControl>
              </div>
          }
          {
            !disabled &&
              <Button
                variant="contained"
                type="submit"
                sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
                onClick={submitFormHandler}
              >
                Записати
              </Button>
          }
        </FormControl>
      </div>
    </div>
  );
}
