import { PERMISSIONS, RESOURCES } from "./../../constants";

const {CREATE, READ, UPDATE, DELETE} = PERMISSIONS.ACTIONS;
const {
  SCHEDULE, DASHBOARD,
  // clients 
  CLIENT, CLIENT_BASE, CL_CERTIFICATES,
  // horses
  HORSES,
  // tmc controls
  STOCKTAKING,
  // tmc movement
  TMC_MOVEMENT, ORDER_INCOMINGS,
  // reference books 
  REFERENCE_BOOKS, REFERENCE_BOOKS_ADDITIONALS, 
  RB_EXPENDITURE_NORMS,
} = RESOURCES;

export const rolesConfig = {
  'super-admin': {
    permissions: [CREATE, READ, UPDATE, DELETE],
    permissionsExceptions: {
      // [CLIENT_BASE]: [UPDATE],
      // [SCHEDULE]: [READ],
      // [DASHBOARD]: [READ],
      // [TMC_MOVEMENT]: [],
      // [ORDER_INCOMINGS]: [READ],
      // [CL_CERTIFICATES]: [CREATE],
      // [RB_EXPENDITURE_NORMS]: [READ],
      // [STOCKTAKING]: [READ, UPDATE, CREATE],
    }
  },
  'owner': {
    permissions: [CREATE, READ, UPDATE, DELETE],
  },
  'manager': {
    permissions: [CREATE, READ, UPDATE],
  },
  'coach': {
    permissions: [READ],
    permissionsExceptions: {
      [SCHEDULE]: [READ],
      [DASHBOARD]: [READ],
      [HORSES]: [],
    }
  },
  'user': {
    permissions: [],
    permissionsExceptions: {
      // [TMC_MOVEMENT]: [READ],
    }
  },
  'client': {
    permissions: [],
  },
};

/*
  // usage example
  const Button = ({checkRoles = false, permission}) => {
    const roles = useSelector(state => state.user.roles);
    const componentAvailable = checkComponentAvailableByRole(checkRoles, roles, permission);

    return (
      <>
        {
          componentAvailable &&
            <div>

            </div>
        }
        
      </>
    )
  }
*/

