const coaches = {
    uk: {
        "coaches.page.title": "Тренери",
        "coaches.filter.select.categories": "Категорії",
        "coaches.filter.select.status": "Статуси",
        "coaches.input.date_of_birth": "Дата народження",
        "coaches.table.image": "Фото",
        "coaches.table.name": "Ім'я",
        "coaches.table.description": "Опис",
        "coaches.table.сategories": "Категорії",
        "coaches.table.services": "Послуги",
        "coaches.table.date_of_birth": "Дата народження",
        "coaches.table.status": "Статус",
        "coaches.table.is_active": "Активний/Неактивний",
        "coaches.table.actions": "Дії",
        "coaches.form.title-create": "Створити тренера",
        "coaches.form.title-edit": "Редагувати тренера",
        "coaches.form.job_start": "Початок роботи",
        "coaches.form.button": "Зберегти",
        "coaches.form.experience": "Досвід (років)",
        "coaches.form.first_name": "Ім'я",
        "coaches.form.last_name": "Прізвище",
        "coaches.form.description": "Опис",
        "coaches.form.body": "Опис для сайта",
        "coaches.form.categories": "Категорії*",
        "coaches.form.services": "Послуги*",
        "coaches.form.status": "Статуси*",
        "coaches.form.is_active": "Активний/Неактивний",
        "coaches.form.name.error": "*Поле є обов'язковим",
        "coaches.form.user_id.error": "*Поле є обов'язковим",
        "coaches.form.first_name.error": "*Поле є обов'язковим",
        "coaches.form.last_name.error": "*Поле є обов'язковим",
        "coaches.form.description.error": "*Поле є обов'язковим",
        "coaches.form.body.error": "*Поле є обов'язковим",
        "coaches.form.date_of_birth.error": "*Поле є обов'язковим, дата повинна бути дійсна",
        "coaches.form.services.error": "*Поле є обов'язковим",
        "coaches.form.status.error": "*Поле є обов'язковим",
        "coaches.form.job_start.error": "*Поле є обов'язковим",
        "coaches.form.experience.error": "*Поле є обов'язковим",
        "coaches.filter.select.status.all": "Усі"
    },
    en: {
        "coaches.page.title": "Coaches",
        "coaches.filter.select.categories": "Categories",
        "coaches.filter.select.status": "Statuses",
        "coaches.input.date_of_birth": "Date of Birth",
        "coaches.table.name": "Name",
        "coaches.table.image": "Photo",
        "coaches.table.description": "Description",
        "coaches.table.сategories": "Categories",
        "coaches.table.services": "Services",
        "coaches.table.date_of_birth": "Date of Birth",
        "coaches.table.status": "Status",
        "coaches.table.is_active": "Active/Inactive",
        "coaches.table.actions": "Actions",
        "coaches.form.title-create": "Create Coach",
        "coaches.form.title-edit": "Edit Coach",
        "coaches.form.job_start": "Job start",
        "coaches.form.button": "Save",
        "coaches.form.experience": "Experience (years)",
        "coaches.form.first_name": "First name",
        "coaches.form.last_name": "Last name",
        "coaches.form.description": "Description",
        "coaches.form.body": "Description for site",
        "coaches.form.categories": "Categories*",
        "coaches.form.services": "Serivces*",
        "coaches.form.status": "Statuses*",
        "coaches.form.is_active": "Active/Inactive",
        "coaches.form.name.error": "*Field is required",
        "coaches.form.user_id.error": "*Field is required",
        "coaches.form.first_name.error": "*Field is required",
        "coaches.form.last_name.error": "*Field is required",
        "coaches.form.description.error": "*Field is required",
        "coaches.form.body.error": "*Field is required",
        "coaches.form.date_of_birth.error": "*Field is required, date must be valid",
        "coaches.form.services.error": "*Field is required",
        "coaches.form.status.error": "*Field is required",
        "coaches.form.job_start.error": "*Field is required",
        "coaches.form.experience.error": "*Field is required",
        "coaches.filter.select.status.all": "All"
    },
    ru: {
        "coaches.page.title": "Тренера",
        "coaches.filter.select.categories": "Категории",
        "coaches.filter.select.status": "Статусы",
        "coaches.input.date_of_birth": "Дата рождения",
        "coaches.table.name": "Имя",
        "coaches.table.image": "Фото",
        "coaches.table.description": "Описание",
        "coaches.table.сategories": "Категории",
        "coaches.table.services": "Услуги",
        "coaches.table.date_of_birth": "Дата рождения",
        "coaches.table.status": "Статус",
        "coaches.table.is_active": "Активный/Неактивный",
        "coaches.table.actions": "Действия",
        "coaches.form.title-create": "Создать тренера",
        "coaches.form.title-edit": "Редактировать тренера",
        "coaches.form.experience": "Опыт (лет)",
        "coaches.form.first_name": "Имя",
        "coaches.form.last_name": "Фамилия",
        "coaches.form.description": "Описание",
        "coaches.form.body": "Описание для сайта",
        "coaches.form.job_start": "Начало работы",
        "coaches.form.categories": "Категории*",
        "coaches.form.services": "Услуги*",
        "coaches.form.status": "Статусы*",
        "coaches.form.button": "Сохранить",
        "coaches.form.is_active": "Активный/Неактивный",
        "coaches.form.name.error": "*Поле обязательно",
        "coaches.form.user_id.error": "*Поле обязательно",
        "coaches.form.first_name.error": "*Поле обязательно",
        "coaches.form.last_name.error": "*Поле обязательно",
        "coaches.form.description.error": "*Поле обязательно",
        "coaches.form.body.error": "*Поле обязательно",
        "coaches.form.services.error": "*Поле обязательно",
        "coaches.form.status.error": "*Поле обязательно",
        "coaches.form.job_start.error": "*Поле обязательно",
        "coaches.form.experience.error": "*Поле обязательно",
        "coaches.filter.select.status.all": "Все"
    }
};



export default coaches;