import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
  Pagination
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getUserCategoriesDirectory, deleteUserCategories } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const UserCategoriesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleUserCategoriesDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteUserCategories(id).then(async () => {
        const { data: response } = await getUserCategoriesDirectory(
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getUserCategoriesDirectory(
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('userCategories.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block"></div>
        <div className="table-action__block">
          <FormControlLabel
            label={
              showArchive
                ? `${__('expenditureNorms.filter.archiveHide')}`
                : `${__('expenditureNorms.filter.archiveShow')}`
            }
            control={
              <Switch checked={showArchive} onChange={(e) => setShowArchive(e.target.checked)} />
            }
          />
          <Link to="/user-categories/create">
            <Button
              variant="contained"
              type="submit"
              sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
              {__('create.button.title')}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-block">
        <div className="table table--user-categories">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('userCategories.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('userCategories.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('userCategories.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('userCategories.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((userCategories) => (
              <div className="tr" key={userCategories.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('userCategories.table.title')}</p>
                  </div>
                  <div className="td-content">{userCategories.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('userCategories.table.description')}</p>
                  </div>
                  <div className="td-content">{userCategories.description}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('userCategories.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {userCategories.is_active
                      ? __('inventoryItem.form.active')
                      : __('inventoryItem.form.inactive')}
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('userCategories.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/user-categories/${userCategories.id}/edit`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleUserCategoriesDelete(userCategories.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
