import { SidebarLink } from './../../../common/Layout/MenuItems';

import {
  PersonIcon,
  CalendarMonthIcon,
  AttachMoneyIcon,
  QueryStatsIcon
} from './../../../common/Layout/icons';

export const SidebarMenu = ({ id, user }) => {
  return (
    <div className="clients-nav">
      <SidebarLink
        title="Карточка кліента"
        link={`/clients/${id}/card`}
        icon={<PersonIcon />}
        state={{ id }}
      />
      <SidebarLink
        title="Історія відвідувань"
        link={`/clients/${id}/visits-history`}
        icon={<CalendarMonthIcon />}
        state={{ id }}
      />
      <SidebarLink
        title="Лояльність"
        link={`/clients/${id}/loyality`}
        icon={<AttachMoneyIcon />}
        state={{ id }}
      />
      <SidebarLink
        title="Аналітика"
        link={`/clients/${id}/financial-analytics`}
        icon={<QueryStatsIcon />}
        state={{ id, user }}
      />
    </div>
  );
};
