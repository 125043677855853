import { IconButton } from "@mui/material";

export const ButtonIcon = ({ icon, sx, ...props }) => {
  return (
    <IconButton
      variant="contained"
      type="submit"
      sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
      {...props}
    >
      <>{ icon }</>
    </IconButton>
  );
};

ButtonIcon.defaultProps = {
  sx: {},
}