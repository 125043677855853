import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import defaultPreviewPicture from './assets/img/preview-img.png';
import { flexBoxCenter } from './assets/styles';
import { BoxHeader, PreviewImgFile, SelectImgFile } from './components';

export const ImageUpload = ({ value, onChange, previewPicture, fileParams }) => {
  const [fileName, setFileName] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(value || previewPicture);
  const [imageInfo, setImageInfo] = useState({ size: 0, width: 0, height: 0 });
  const [isValid, setIsValid] = useState(!!value);

  const isEmpty = previewUrl === previewPicture;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    let fileName = file.name;
    const fileSize = Number((file.size / 1024).toFixed(2));
    const imageParams = {
      width: 0,
      height: 0,
      size: fileSize,
    };

    const filePreviewUrl = URL.createObjectURL(file);
    setPreviewUrl(filePreviewUrl);
    setFileName(fileName);
    setImgFile(file);

    const img = new Image();
    img.src = filePreviewUrl;

    img.onload = function() {
      imageParams.width = this.width;
      imageParams.height = this.height;
      setImageInfo(imageParams);
    }
  };

  useEffect(() => {
    let validParams = false;
    if (
        imageInfo.width > fileParams.width ||
        imageInfo.height > fileParams.height ||
        imageInfo.size > fileParams.size
    ) {
      setIsValid(validParams = false);
    } else {
      setIsValid(validParams = true);
    }

    if (validParams && imgFile) {
      onChange(imgFile);
    }
     
  }, [imageInfo]);

  return (
    <Box sx={{ border: '1px dashed grey', borderColor: isValid ? 'grey': 'red', p: 2 }}>
      <BoxHeader fileParams={ fileParams } />
      <Box sx={{ ...flexBoxCenter }}>
        <PreviewImgFile
          previewUrl={ previewUrl }
          previewPicture={ previewPicture } 
        />
        <SelectImgFile
          changeHandler={ handleFileChange }
          fileName={ fileName }
          isEmpty={ isEmpty }
          isValid={ isValid }
          imageInfo={ imageInfo }
        />
      </Box>
    </Box>
  );
};

ImageUpload.defaultProps = {
  previewPicture: defaultPreviewPicture,
  fileParams: {
    width: 1500,
    height: 1500,
    size: 500,
  }
}