import { useEffect, useState } from 'react';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';

import { getSeasonTickectsCertificates } from '../../../../../actions';
import { useTranslation } from '../../../../../helpers/translate';
import { ButtonSend, ToggleActiveStatus, UserSelectComponent, toastError } from '../../../../common';

export const CertificatesForm = ({ certificates, handleChange, action, ctaButtonText, userEditDisabled = false }) => {
  const { __ } = useTranslation();
  const [{ seasonTickects }, setData] = useState({
    loaded: false,
    seasonTickects: []
  });

  const [error, setError] = useState({});

  useEffect(() => {
    const errorsObject = {};

    if (!certificates?.season_ticket) {
      errorsObject.season_ticket = 'Сертифікат';
    }

    // !!! this data be changed !!! on last commit BUGFIX Certificates )))
    if (!(certificates?.user_id || certificates?.user?.id || certificates?.user?.user_id)) {
      errorsObject.user_id = 'Данні користувача';
    }

    setError(errorsObject);
  }, [certificates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: seasonTickectsResponse } = await getSeasonTickectsCertificates();
        setData({
          loaded: true,
          seasonTickects: seasonTickectsResponse.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const submitHandler = () => {
    if (!Object.keys(error).length) {
      action();
      return;
    }
    Object.values(error).forEach(fieldName => toastError(`Заповніть поле "${fieldName}"`));
  }

  return (
    <>
      <FormControl>
        <InputLabel>{'Сертифікат'}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={'Послуга'}
          name="season_ticket"
          value={ seasonTickects?.length ? certificates?.season_ticket : '' }
          onChange={handleChange('season_ticket')}
          error={ !certificates?.season_ticket }
        >
          {
            seasonTickects?.length &&
            seasonTickects?.map((seasonTicket) => (
                <MenuItem key={seasonTicket.id} value={seasonTicket.id}>
                  {seasonTicket.title}
                </MenuItem>
              ))
          }
        </Select>
      </FormControl>

      <UserSelectComponent 
        data={ certificates } 
        dataChangeHandler={ handleChange('user') }
        disabled={ userEditDisabled } 
      />

      <ToggleActiveStatus 
        name="is_active"
        checked={!!certificates?.is_active}
        value={certificates?.is_active}
        onChange={handleChange('is_active')}
        title="Активний/Неактивний"
      />

      <ButtonSend onClick={ submitHandler } text={ ctaButtonText } />
    </>
  );
};