import { Avatar, Card, Rating, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { Link, useParams } from 'react-router-dom';
import s from './oneHorse.module.scss';
import { getRandInt } from '../../library';
import { useState } from 'react';
import { OpenImgDialog } from '../Popups/OpenImgDialog';
import { getModelName } from '../../../configData/modelConfig';

export default function OneModel({ model, modelType, isReadOnly }) {
  const { modelId } = useParams();
  const { id, isHorseClicked, category, image } = model;
  const name = getModelName(model, modelType);
  const isActive = id === Number(modelId);
  const rating = getRandInt(3, 5);
  const [infoOpen, setInfoOpen] = useState(false);

  const handleInfoOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setInfoOpen(true);
  };

  return (
    <>
      <Link to={`/schedule/${modelType}/${id}`} state={{ isReadOnly }}>
        <Card
          className={isActive ? s.active : ''}
          sx={{
            p: 2,
            display: 'flex',
            cursor: 'pointer',
            border: isHorseClicked ? '1px solid red' : '2px solid #e0e0e0',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100%',
            transition: '0.3s',
            boxShadow: 'none'
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt="Horse avatar" src={image} />
            <Box sx={{ ml: 1 }}>
              <Typography variant="h6">{name}</Typography>
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Rating name="read-only" value={ rating } readOnly sx={{ mr: 1 }} />
              </Box> */}
            </Box>
          </Box>
          <InfoIcon fontSize="large" onClick={(e) => handleInfoOpen(e)} sx={{ ml: 1 }} />
        </Card>
        <OpenImgDialog open={infoOpen} setOpen={setInfoOpen} data={model} />
      </Link>
    </>
  );
}
