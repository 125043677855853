import { localeModel } from "./../../../common/FormsComponent/LocaleTabs/localeValidation";
import { 
  ruleHorsePassport,
  ruleDateOfBirth, 
  ruleStatus, 
  ruleHorseCategory, 
  ruleHorseServices,
} from "./../validation/validationRules";

export const horsesModel = {
  passport: '',
  date_of_birth: '',
  status: '',
  image: '',
  is_active: true,
  horsCategories: [],
  services: [],
  locale: {...localeModel},
}

export const horsesValidationModel = {
  passport: ruleHorsePassport,
  date_of_birth: ruleDateOfBirth,
  status: ruleStatus,
  horsCategories: ruleHorseCategory,
  services: ruleHorseServices,
}