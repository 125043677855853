export const BreakSlot = ({styles, eventInfo, extendedClassName}) => {
  const { timeText, event: { title } } = eventInfo;
  const { slot, slot_break, header, content } = styles;
  
  return (
    <div className={ `${slot} ${slot_break}${extendedClassName ? ' ' + extendedClassName : ' '}` }>
      <div className={ header }>
        <span>{ title }</span>
      </div>
      <div className={ content }>
        <span>{ timeText }</span>
      </div>
    </div>
  );
};
