import { TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export const FilterSearch = ({ name, label, value, handler, ...props }) => {

  return (
    <div className="input-wrap">
      <TextField
        sx={{
          width: '100%',
          display: 'block',
          '.MuiInputBase-input': { pr: '40px' },
          '.MuiOutlinedInput-root': { width: '100%' }
        }}
        label={label}
        name={ name }
        value={value}
        onChange={handler}
        {...props}
      />

      <SearchIcon
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%);',
          color: 'var(--icon-color)'
        }}
      />
    </div>
  );
};
