import { TextField } from '@mui/material';
import React from 'react';

export const Manual = ({ 
  data,
  dataName,
  tabLocale,
  nameField,
  requiredFields,
  validationStatus,
  handleLocalizationChange,
  __  
}) => {

  const maxLength = 1024;
  const descrValue = data?.locale[tabLocale]?.description ? data?.locale[tabLocale]?.description.slice(0, maxLength) : '';
  const descrIsRequired = requiredFields.includes('description');

  console.log('manual');

  return (
    <>
      <TextField
        fullWidth
        value={ 
          data?.locale[tabLocale] 
            ? data?.locale[tabLocale][nameField] 
              ? data?.locale[tabLocale][nameField]
              : ''
            : '' 
        }
        onChange={ handleLocalizationChange(tabLocale) }
        name={ nameField }
        label={ `${ __(`${ dataName }.table.${ nameField }`) } ${ tabLocale }` }
        error={ !validationStatus[tabLocale][nameField] }
        helperText={ validationStatus[tabLocale][nameField]?.errorMessage }
        className={ validationStatus[tabLocale][nameField]?.isValid ? '' : 'input-error' }
      />
      <TextField
        fullWidth
        multiline
        maxRows={ 8 }
        value={ descrValue }
        onChange={ handleLocalizationChange(tabLocale) }
        name="description"
        label={ `${ __(`${ dataName }.table.description`) } ${ tabLocale }` }
        error={ descrIsRequired ? !validationStatus[tabLocale]?.description : false }
        helperText={ descrIsRequired ? validationStatus[tabLocale]?.description?.errorMessage : '' }
        className={ descrIsRequired ? (validationStatus[tabLocale]?.description?.isValid ? '' : 'input-error') : '' }
      />
    </>
  );
};