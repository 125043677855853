import axios from '../../helpers/private.axios';

export const
    getWriteOffs = async (
        sortOption,
        sortDirection,
        searchTitle,
        currentPage,
        itemsPerPage,
        showArchive
    ) => {
        const params = {};

        if (sortOption && sortDirection !== 0) {
            params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
        }

        if (searchTitle) {
            params['search-title'] = searchTitle;
        }

        if (showArchive) {
            params.status = 'archive';
        }

        params.page = currentPage;
        params.limit = itemsPerPage;

        return axios.get(`/writeoffs`, { params });
    };

