const booking = {
    uk: {
        //header button
        "booking.header.sign-in": "Увійти",
        "booking.header.sign-up": "Реєстрація",

        //home
        "booking.home.title": "Бронювання запису для",
        "booking.home.new-user": "Нового користувача",
        "booking.home.user": "Зареєстрованого користувача",

        // home end
        "booking.button.next": "Продовжити",
        // services
        "booking.services.tabs.services": "Послуги",
        "booking.services.tabs.seasonTickets": "Абонементи",
        "booking.services.tabs.certificate": "Сертифікати",
        "booking.services.title": "Оберіть послугу",
        // services end

        // coach
        "booking.coach.title": "Послуги",
        "booking.coach.any-coach": "Будь-який вільний фахівець",
        // coach end

        // сoach time
        "booking.coach-time.title": "Виберіть дату і час",
        "booking.coach-time.time-null": "На обраний день немає вільних сеансів",
        // сoach time end

        // horse
        "booking.horse.title": "Послуги",
        "booking.horse.any-horse": "Будь-який вільний кінь",
        // horse end

        // horse time
        "booking.horse-time.title": "Виберіть дату і час",
        "booking.horse-time.time-null": "На обраний день немає вільних сеансів",
        // horse time end

        // booking form
        "booking.form.title": "Запис",
        "booking.form.title.details": "Деталі візиту",
        "booking.form.info.time-start": "Час початку",
        "booking.form.info.date-start": "Дата візиту",
        "booking.form.info.price": "Ціна:",
        "booking.form.info.hour": "година",
        "booking.form.title.form": "Ваші дані",
        "booking.input.first_name": "*Ім'я",
        "booking.input.last_name": "*Прізвище",
        "booking.input.email": "Електронна пошта",
        "booking.input.description": "*Коментар",
        "booking.input.description.info": "Вкажіть Ваш вік та рівень підготовки: 1- не самостійно; 2 - риссю самостійно; 3 - галоп самостійно *",
        "booking.input.notifyBySms": "Нагадування",
        "booking.input.notifyBySms.time0": "Не відправляти",
        "booking.input.notifyBySms.time1": "За 1 годину",
        "booking.input.notifyBySms.time2": "За 2 години",
        "booking.input.notifyBySms.time3": "За 3 години",
        "booking.input.notifyBySms.time4": "За 4 години",
        "booking.input.notifyBySms.time5": "За 5 годин",
        "booking.input.notifyBySms.time6": "За 6 годин",
        "booking.input.notifyBySms.time9": "За 9 годин",
        "booking.input.notifyBySms.time12": "За 12 годин",
        "booking.input.notifyBySms.time15": "За 15 годин",
        "booking.input.notifyBySms.time18": "За 18 годин",
        "booking.input.notifyBySms.time21": "За 21 годину",
        "booking.input.notifyBySms.time24": "За 24 годину",
        "booking.input.notifyBySms.time48": "За 2 дня",
        "booking.input.notifyBySms.time72": "За 3 дня",
        "booking.input.notifyBySms.time120": "За 5 днів",
        "booking.input.notifyBySms.time168": "За 7 днів",
        "booking.сheckbox.text": "Натискаючи «Записатися», ви приймаєте ",
        "booking.сheckbox.text2": "Користувача угода і Політику конфіденційності",
        "booking.button.end": "Записатися",
        "booking.button.end": "Записатися",
        "booking.form.first_name.error": "*Поле є обов'язковим",
        "booking.form.last_name.error": "*Поле є обов'язковим",
        "booking.form.description.error": "*Поле є обов'язковим",
        "booking.form.invalid_phone.error": "*Поле є обов'язковим",
        "booking.form.null.error": "*Поле є обов'язковим",
        "booking.popup.title": "Ваше бронювання було успішно здійснено.",
        "booking.popup.title.details": "Деталі бронювання:",
        "booking.popup.date": "Дата:",
        "booking.popup.time": "Час:",
        "booking.popup.coach": "Тренер:",
        "booking.popup.horse": "Кінь:",
        "booking.popup.sum": "Сума:",
        "booking.popup.duration": "Тривалість: 1 година",
        "booking.popup.button.more": "Записатися ще",
        "booking.popup.button.more_buy": "Замовити ще",
        "booking.popup.button.cabinet": "Увійти в кабінет",
        "booking.popup.error-api": "Упс, цей час вже зайнятий, або цього тренування не існує.",
        "booking.popup.error-api2": "Спробуйте інше бронювання",
        "booking.popup.error-button": "Нове бронювання",

        "booking.form.date.first_name": "Ім'я",
        "booking.form.date.last_name": "Прізвище",
        "booking.form.date.phone": "Номер",
        // booking form end

        "booking.seasonTickets.title": "Абонименти",
        "booking.certificates.title": "Сертифікати",


        // booking authorization
        "booking.authorization.title1": "Введіть код",
        "booking.authorization.title2": "Введіть номер телефону",
        "booking.authorization.button-action1": "Змінити номер",
        "booking.authorization.button-action2": "Надіслади код ще раз",
        "booking.authorization.button1": " Відправити код",
        "booking.authorization.button2": "Отримати смс",
        "booking.authorization.input-code": "Введіть отриманий код",
        // booking authorization error
        "booking.authorization.phone-error": "Номер телефону некорректний",
        // booking authorization end

        // booking basket
        "booking.basket.title": "Кошик замовлення",
        "booking.basket.title.empty": "Кошик пустий",
        "booking.basket.title.basket-season-tickets": "Абонементи",
        "booking.basket.title.basket-certificate": "Сертифікати",
        "booking.basket.title.total-amount": "Загальна сума:",
        "booking.basket.button.end": "Сплатити замовлення",
        "booking.popup.basket.title": "Ваше замовлення було успішно здійснено.",
        "booking.popup.basket.more": "Нове замовлення",

        // booking basket end

    },
    en: {
        "booking.header.sign-in": "Войти",
        "booking.header.sign-up": "Регистрация",

        "booking.home.title": "Booking appointment for",
        "booking.home.new-user": "New User",
        "booking.home.user": "Registered User",

        "booking.button.next": "Next",
        "booking.services.tabs.services": "Services",
        "booking.services.tabs.seasonTickets": "Season Tickets",
        "booking.services.tabs.certificate": "Certificates",
        "booking.services.title": "Select a Service",

        "booking.coach.title": "Services",
        "booking.coach.any-coach": "Any Available Coach",
        "booking.coach-time.title": "Select Date and Time",
        "booking.coach-time.time-null": "There are no available sessions for the selected day",

        "booking.horse.title": "Services",
        "booking.horse.any-horse": "Any Available Horse",
        "booking.horse-time.title": "Select Date and Time",
        "booking.horse-time.time-null": "There are no available sessions for the selected day",

        "booking.form.title": "Booking",
        "booking.form.title.details": "Visit Details",
        "booking.form.info.time-start": "Start Time",
        "booking.form.info.date-start": "Visit Date",
        "booking.form.info.price": "Price:",
        "booking.form.info.hour": "hour",
        "booking.form.title.form": "Your Information",
        "booking.input.first_name": "*First Name",
        "booking.input.last_name": "*Last Name",
        "booking.input.email": "Email",
        "booking.input.description": "*Comment",
        "booking.input.description.info": "Specify Your Age and Skill Level: 1 - Not self-reliant; 2 - Trot self-reliant; 3 - Gallop self-reliant *",
        "booking.input.notifyBySms": "Reminder",
        "booking.input.notifyBySms.time0": "Do not send",
        "booking.input.notifyBySms.time1": "1 hour before",
        "booking.input.notifyBySms.time2": "2 hours before",
        "booking.input.notifyBySms.time3": "3 hours before",
        "booking.input.notifyBySms.time4": "4 hours before",
        "booking.input.notifyBySms.time5": "5 hours before",
        "booking.input.notifyBySms.time6": "6 hours before",
        "booking.input.notifyBySms.time9": "9 hours before",
        "booking.input.notifyBySms.time12": "12 hours before",
        "booking.input.notifyBySms.time15": "15 hours before",
        "booking.input.notifyBySms.time18": "18 hours before",
        "booking.input.notifyBySms.time21": "21 hours before",
        "booking.input.notifyBySms.time24": "24 hours before",
        "booking.input.notifyBySms.time48": "2 days before",
        "booking.input.notifyBySms.time72": "3 days before",
        "booking.input.notifyBySms.time120": "5 days before",
        "booking.input.notifyBySms.time168": "7 days before",
        "booking.сheckbox.text": "By clicking 'Book', you accept the ",
        "booking.сheckbox.text2": "User Agreement and Privacy Policy",
        "booking.button.end": "Book",
        "booking.form.first_name.error": "*This field is required",
        "booking.form.last_name.error": "*This field is required",
        "booking.form.description.error": "*This field is required",
        "booking.form.invalid_phone.error": "*This field is required",
        "booking.form.null.error": "*This field is required",
        "booking.popup.title": "Your booking was successfully made.",
        "booking.popup.title.details": "Booking Details:",
        "booking.popup.date": "Date:",
        "booking.popup.time": "Time:",
        "booking.popup.coach": "Coach:",
        "booking.popup.horse": "Horse:",
        "booking.popup.sum": "Total:",
        "booking.popup.duration": "Duration: 1 hour",
        "booking.popup.button.more": "Book Another",
        "booking.popup.button.more_buy": "Buy Another",
        "booking.popup.button.cabinet": "Enter the cabinet",
        "booking.popup.error-api": "Oops, this time slot is already taken or this training session does not exist.",
        "booking.popup.error-api2": "Please try another booking.",
        "booking.popup.error-button": "New Booking",
        "booking.form.date.first_name": "First",
        "booking.form.date.last_name": "Last",
        "booking.form.date.phone": "Phone",

        "booking.seasonTickets.title": "Season Tickets",
        "booking.certificates.title": "Certificates",


        // booking authorization
        "booking.authorization.title1": "Enter code",
        "booking.authorization.title2": "Enter phone number",
        "booking.authorization.button-action1": "Change number",
        "booking.authorization.button-action2": "Resend code",
        "booking.authorization.button1": "Send code",
        "booking.authorization.button2": "Get SMS",
        "booking.authorization.input-code": "Enter the received code",
        // booking authorization error
        "booking.authorization.phone-error": "Phone number is incorrect",
        // booking authorization end

        // booking basket
        "booking.basket.title": "Order basket",
        "booking.basket.title.empty": "Basket empty",
        "booking.basket.title.basket-season-tickets": "Season tickets",
        "booking.basket.title.basket-certificate": "Certificates",
        "booking.basket.title.total-amount": "Total amount:",
        "booking.basket.button.end": "Pay the order",
        "booking.popup.basket.title": "Your order has been successfully placed.",
        "booking.popup.basket.more": "New order",
        // booking basket end
    }
    ,
    ru: {
        "booking.header.sign-in": "Sign in",
        "booking.header.sign-up": "Sign up",


        "booking.home.title": "Бронирование записи для",
        "booking.home.new-user": "Нового пользователя",
        "booking.home.user": "Зарегистрированного пользователя",

        "booking.button.next": "Продолжить",
        "booking.services.tabs.services": "Услуги",
        "booking.services.tabs.seasonTickets": "Абонементы",
        "booking.services.tabs.certificate": "Сертификаты",
        "booking.services.title": "Выберите услугу",

        "booking.coach.title": "Услуги",
        "booking.coach.any-coach": "Любой свободный специалист",
        "booking.coach-time.title": "Выберите дату и время",
        "booking.coach-time.time-null": "На выбранный день нет свободных сеансов",

        "booking.horse.title": "Услуги",
        "booking.horse.any-horse": "Любая свободная лошадь",
        "booking.horse-time.title": "Выберите дату и время",
        "booking.horse-time.time-null": "На выбранный день нет свободных сеансов",

        "booking.form.title": "Запись",
        "booking.form.title.details": "Детали визита",
        "booking.form.info.time-start": "Время начала",
        "booking.form.info.date-start": "Дата визита",
        "booking.form.info.price": "Цена:",
        "booking.form.info.hour": "час",
        "booking.form.title.form": "Ваши данные",
        "booking.input.first_name": "*Имя",
        "booking.input.last_name": "*Фамилия",
        "booking.input.email": "Электронная почта",
        "booking.input.description": "*Комментарий",
        "booking.input.description.info": "Укажите Ваш возраст и уровень подготовки: 1 - Не самостоятельно; 2 - Рысь самостоятельно; 3 - Галоп самостоятельно *",
        "booking.input.notifyBySms": "Напоминание",
        "booking.input.notifyBySms.time0": "Не отправлять",
        "booking.input.notifyBySms.time1": "За 1 час",
        "booking.input.notifyBySms.time2": "За 2 часа",
        "booking.input.notifyBySms.time3": "За 3 часа",
        "booking.input.notifyBySms.time4": "За 4 часа",
        "booking.input.notifyBySms.time5": "За 5 часов",
        "booking.input.notifyBySms.time6": "За 6 часов",
        "booking.input.notifyBySms.time9": "За 9 часов",
        "booking.input.notifyBySms.time12": "За 12 часов",
        "booking.input.notifyBySms.time15": "За 15 часов",
        "booking.input.notifyBySms.time18": "За 18 часов",
        "booking.input.notifyBySms.time21": "За 21 час",
        "booking.input.notifyBySms.time24": "За 24 часа",
        "booking.input.notifyBySms.time48": "За 2 дня",
        "booking.input.notifyBySms.time72": "За 3 дня",
        "booking.input.notifyBySms.time120": "За 5 дней",
        "booking.input.notifyBySms.time168": "За 7 дней",
        "booking.сheckbox.text": "Нажимая «Записаться», вы принимаете ",
        "booking.сheckbox.text2": "Пользовательское соглашение и Политику конфиденциальности",
        "booking.button.end": "Записаться",
        "booking.form.first_name.error": "*Это поле обязательно",
        "booking.form.last_name.error": "*Это поле обязательно",
        "booking.form.description.error": "*Это поле обязательно",
        "booking.form.invalid_phone.error": "*Это поле обязательно",
        "booking.form.null.error": "*Это поле обязательно",
        "booking.popup.title": "Ваше бронирование прошло успешно.",
        "booking.popup.title.details": "Детали бронирования:",
        "booking.popup.date": "Дата:",
        "booking.popup.time": "Время:",
        "booking.popup.coach": "Тренер:",
        "booking.popup.horse": "Лошадь:",
        "booking.popup.sum": "Итого:",
        "booking.popup.duration": "Продолжительность: 1 час",
        "booking.popup.button.more": "Забронировать еще",
        "booking.popup.button.more_buy": "Заказать еще",
        "booking.popup.button.cabinet": "Войти в кабинет",
        "booking.popup.error-api": "Упс, это время уже занято или данное занятие не существует.",
        "booking.popup.error-api2": "Пожалуйста, попробуйте другое бронирование.",
        "booking.popup.error-button": "Новое бронирование",
        "booking.form.date.first_name": "Имя",
        "booking.form.date.last_name": "Фамилия",
        "booking.form.date.phone": "Телефон",

        "booking.seasonTickets.title": "Абонементы",
        "booking.certificates.title": "Сертификаты",


        // booking authorization
        "booking.authorization.title1": "Введите код",
        "booking.authorization.title2": "Введите номер телефона",
        "booking.authorization.button-action1": "Изменить номер",
        "booking.authorization.button-action2": "Отправить код еще раз",
        "booking.authorization.button1": "Отправить код",
        "booking.authorization.button2": "Получить СМС",
        "booking.authorization.input-code": "Введите полученный код",
        // booking authorization error
        "booking.authorization.phone-error": "Номер телефона некорректный",
        // booking authorization end

        // booking basket
        "booking.basket.title": "Корзина заказа",
        "booking.basket.title.empty": "Корзина пустая",
        "booking.basket.title.basket-season-tickets": "Абонементы",
        "booking.basket.title.basket-certificate": "Сертификаты",
        "booking.basket.title.total-amount": "Общая сумма:",
        "booking.basket.button.end": "Оплатить заказ",
        "booking.popup.basket.title": "Ваше заказ был успешно оформлен.",
        "booking.popup.basket.more": "Новый заказ",
        // booking basket end
    }

}

export default booking;