import dayjs from "dayjs"
import { visitStatuses } from "../components/visitStatuses";
import { modelSelect } from "../../../../configData/modelConfig";
export const getClientCardVerticalTableData = (dataItem, __) => {

  const timeslot = dataItem && dataItem?.timeslot;
  const modelType = dataItem && timeslot?.model_type;
  const date = timeslot && dayjs(timeslot.date).format('YYYY-MM-DD');
  const time = timeslot && dayjs(timeslot.date).format('HH:mm');
  const timeTo = timeslot && dayjs(timeslot.date).add(1, 'hour').format('HH:mm');
  const phone = dataItem && dataItem.phoneCode + dataItem.phone;
  const service = dataItem && dataItem?.visit_services[0];
  const serviceName = service && service?.service_title;
  const serviceAmount = service && service?.amount;
  const visitStatus = dataItem && visitStatuses[dataItem.status_id].title;
  const horse = timeslot && timeslot.model_title;
  const paymentStatus = dataItem && dataItem?.billing_client_service_id
    ? 'Сплачено'
    : 'Не cплачено';

  const notifyValue = dataItem && dataItem?.notifyBySms <= 24 ? dataItem?.notifyBySms : dataItem?.notifyBySms / 24;
  const notifyUnit = dataItem && dataItem?.notifyBySms <= 24 ? 'г' : 'д';
  const notifyText = dataItem && dataItem?.notifyBySms ? `за ${notifyValue} ${notifyUnit}.` : 'Не повідомляти';
  const notifyBySms = dataItem && notifyText;

  return [
    {
      th: 'Прізвище',
      td: dataItem && dataItem.last_name,
    },
    {
      th: "Ім'я",
      td: dataItem && dataItem.first_name,
    },
    {
      th: "Телефон",
      td: dataItem && phone,
    },
    {
      th: "E-mail",
      td: dataItem && dataItem.email || '',
    },
        // {
    //   th: 'Опис',
    //   td: dataItem && dataItem.description,
    // },
    {
      th: 'Дата візиту',
      td: dataItem && date,
    },
    {
      th: 'Час візиту',
      td: dataItem && `${time}-${timeTo}`,
    },
    {
      th: 'Послуга',
      td: dataItem && serviceName,
    },
    {
      th: 'Вартість послуги',
      td: dataItem && serviceAmount,
    },
    {
      th: modelSelect[modelType],
      td: dataItem && horse,
    },
    {
      th: 'Статус візиту',
      td: dataItem && visitStatus,
    },
    {
      th: 'Статус оплати',
      td: dataItem && paymentStatus,
    },
    {
      th: 'Повідомити клієнта',
      td: dataItem && notifyBySms,
    },
  ]
}