import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MultyDataChart } from './charts';
import { CustomTabPanel, a11yProps } from './components/CustomTabPanel';
import { useChangeTab } from './hooks/useChangeTab';
import { getChartBookingClientsOrAdmin, getChartBookingFirsrtVisit, getChartClientArrivedOrNot, getChartIncome, getChartLostClients, getChartNewClients, getChartTimeUsedClients, getChartWorkload, getChartWorkloadsCoach, getChartWorkloadsHorse } from 'src/actions';
import { tabStyles } from './styles';

export const ChartIndicatorsActivity = () => {
  const [tabIndex, handleChangeTab] = useChangeTab(0);
  const tabWidth = '100%';

  return (
    <Box sx={{ width: '100%', height: '200px' }}>
      <div className="title title--mb36">
        Показники активності
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
          <Tab sx={ tabStyles } label="Завантаженість середня % (загальна)" {...a11yProps(0)} wrapped />
          <Tab sx={ tabStyles } label="Завантаженість середня % (коні)" {...a11yProps(1)} wrapped />
          <Tab sx={ tabStyles } label="Завантаженість середня % (тренери)" {...a11yProps(2)} wrapped />
          <Tab sx={ tabStyles } label="Кількість записів нові/існуючі" {...a11yProps(3)} wrapped />
          <Tab sx={ tabStyles } label="Структура записів: проведені/інші" {...a11yProps(4)} wrapped />
          <Tab sx={ tabStyles } label="Структура записів: створені клієнтом/адміністраторм" {...a11yProps(5)} wrapped />
        </Tabs>
      </Box>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={0}>
        <MultyDataChart 
          action={ getChartWorkload } 
          fieldsName={['count', 'count_usages']}
          actionType="average"
          chartTitle='' 
          chartLabels={['Завантаженність']}
          chartsViews = {['bars', 'lines']}
          outputAsPercents
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={1}>
        <MultyDataChart 
          action={ getChartWorkloadsHorse } 
          fieldsName={['count', 'count_usages']}
          actionType="average"
          chartTitle='' 
          chartLabels={['Завантаженність']}
          chartsViews = {['bars', 'lines']}
          outputAsPercents
          difference="horses"
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={2}>
        <MultyDataChart 
          action={ getChartWorkloadsCoach } 
          fieldsName={['count', 'count_usages']}
          actionType="average"
          chartTitle='' 
          chartLabels={['Завантаженність']}
          chartsViews = {['bars', 'lines']}
          outputAsPercents
          difference="coaches"
        />
      </CustomTabPanel>
      
      <CustomTabPanel width={ tabWidth } value={tabIndex} index={3}>
        <MultyDataChart 
          action={ getChartBookingFirsrtVisit } 
          fieldsName={['count', 'first',]}
          chartTitle='' 
          chartLabels={['Існуючи', 'Нові']}
          chartsViews = {['stackedbars', 'bars', 'lines']}
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={4}>
        <MultyDataChart 
          action={ getChartClientArrivedOrNot } 
          fieldsName={['arrived', 'other']}
          chartTitle='' 
          chartLabels={['Проведені', 'Інші']}
          chartsViews = {['stackedbars', 'bars', 'lines']}
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={5}>
        <MultyDataChart 
          action={ getChartBookingClientsOrAdmin } 
          fieldsName={['booking', 'admin']}
          chartTitle='' 
          chartLabels={['Клієнт', 'Адмін']}
          chartsViews = {['stackedbars', 'bars', 'lines']}
        />
      </CustomTabPanel>
    </Box>
  );
};
