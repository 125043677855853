import { useState } from "react";

export const useChangeTab = ({ initialValue }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return [value || 0, handleChange];
}