import { useNavigate } from 'react-router-dom';
import { ModalForm } from './../../../common/FormsComponent';
import { Button } from '@mui/material';
import { BookingHeader } from './../../../pages/Booking/bookingHeader';

export const SingUpSuccess = () => {
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/');
  }

  return(
    <>
      <BookingHeader />
      <ModalForm
      title='Ви зареєстровані'
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Button
        variant="contained"
        onClick={toggleOpened}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
      >
        ok
      </Button>
    </ModalForm>
    </>
  );

}