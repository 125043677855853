import dayjs from 'dayjs';
import axios from '../../helpers/private.axios';
import { path } from './constants';

export const getModelTimeSlots = (modelId, modelType, date, period = 'day') => {
    const params = {
        span: period,
        modelType,
    };

    if (modelId === 'anyHorse') {
        params.any_hors = 1;
    } else {
        params.modelId = modelId;
    }

    if (date) {
        params.date = dayjs(date).format('YYYY-MM-DD');
    }

    return axios.get(`/${path}`, {params});
};
