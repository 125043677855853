export const getStocktakingItemsRowCellsData = (dataItem, __) => {
  const itemDiff = 
    (dataItem?.actual_amount || dataItem?.actual_amount === 0) && 
      (dataItem?.actual_amount - dataItem?.calculated_amount).toFixed(2);

  let diffClass = '';
  const editActualAmountAction = dataItem && dataItem?.editable ? dataItem?.editable.editActualAmountAction : () => console.log('not action');
  const editActualAmountCallback = dataItem && dataItem?.editable ? dataItem?.editable.editActualAmountCallback : () => console.log('not callback');

  if (itemDiff) {
    if (itemDiff < 0) {
      diffClass = 'td--minus';
    } else if (itemDiff > 0) {
      diffClass = 'td--plus';
    }
  }

  return {
    item: {
      th: 'Назва',
      td: dataItem && dataItem.title,
      sort: null,
      // link: dataItem && {
      //   url: `/inventory-items/${dataItem.inventory_item_id}/edit`,
      //   state: { id: dataItem.inventory_item_id }
      // },
    },
    unit: {
      th: 'Од. виміру',
      td: dataItem && dataItem.measure,
      sort: null,
    },
    price: {
      th: 'Ціна',
      td: dataItem && dataItem.cost,
      sort: null,
    },
    calculated_price: {
      th: 'Розрахункова вартість',
      
      // !!! HARDCODE
      td: dataItem && (dataItem.calculated_amount * dataItem.cost).toFixed(2),

      // td: dataItem && dataItem.calculated_price,
      sort: null,
    },
    
    actual_price: {
      th: 'Фактична вартість',

      // !!! HARDCODE
      td: dataItem && (dataItem.actual_amount * dataItem.cost).toFixed(2),

      // td: dataItem && dataItem.actual_price,
      sort: null,
    },
    calculated_amount: {
      th: 'Розрахункова кількість',
      td: dataItem && dataItem.calculated_amount,
      sort: null,
    },
    actual_amount: {
      th: 'Фактична кількість',
      td: dataItem && dataItem.actual_amount,
      sort: null,
      __classTd: !dataItem?.editable?.isApply ? 'td--editable-cell' : '',
      editable: !dataItem?.editable?.isApply && {
        className: 'td--editable',
        inputType: 'text',
        data: dataItem && dataItem,
        action: editActualAmountAction,
        callback: editActualAmountCallback,
      },
    },
    diff_amout: {
      th: 'Фактична різниця',
      td: itemDiff && itemDiff,
      __classTd: diffClass && diffClass,
      sort: null,
    }
  }
}