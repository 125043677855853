import { 
  ruleCorrectData,
  ruleFieldNotEmpty, 
  ruleIsLength, 
} from "./validationRules";

export const validationModel = {
  experience: ruleFieldNotEmpty,
  job_start: ruleCorrectData,
  services: ruleIsLength,
  status: ruleFieldNotEmpty,
  user_id: ruleFieldNotEmpty,
}