import { v4 as uuidv4 } from 'uuid';
import { TableHead, TableRow } from "@mui/material"
import { Cells } from ".";
import { useContext } from "react";
import { TableSimpleContext } from "../tableSimpleContext";

export const TableHeaderSimple = () => {
  const { data, getRowCellsData, __ } = useContext(TableSimpleContext);
  const dataRow = getRowCellsData(null, __);
  return (
    <TableHead>
      <TableRow key={uuidv4()}>
        <Cells cellsData={ dataRow } cellType="th" />
      </TableRow>
    </TableHead>
  )
}