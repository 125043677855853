export const correctAccessibility = (accessibilityData, correctData, paymentData) => {
  correctData.forEach(key => {
    const searchItemIndex = accessibilityData.findIndex(item => item.value === key);
    const isAllowedDays = paymentData[key]?.allowed_day;
    const newLabel = isAllowedDays 
      ? accessibilityData[searchItemIndex].label 
      : accessibilityData[searchItemIndex].label + ' (не доступний на цей день)'
    console.log(isAllowedDays);
    accessibilityData[searchItemIndex].disabled = isAllowedDays ? false : true;
    accessibilityData[searchItemIndex].label = newLabel;
  });

  return accessibilityData;
}