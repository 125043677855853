export * from './resources';

export const ADD_COUNT = 'ADD_COUNT';
export const SUB_COUNT = 'SUB_COUNT';
export const CHANGE_ANIMAL = 'CHANGE_ANIMAL';

// Locales
export const LOCALE = { 
  CHANGE_LOCALE: "CHANGE_LOCALE"
}

// Auth
export const AUTH = {
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_RESPONSE: 'AUTH_RESPONSE',
  AUTH_ERROR: 'AUTH_ERROR',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_SET_USER: 'AUTH_SET_USER'
};

// Calendar
export const CALENDAR = {
  SET_DATE: 'SET_DATE'
};

// Roles
export const ROLES = {
  ADMIN: {
    ROLE: 'ADMIN',
    PATH: '/admin'
  },
  USER: {
    ROLE: 'USER',
    PATH: ''
  }
}

export const PERMISSIONS = {
  ACTIONS: {
    CREATE: 'create',
    READ: 'read',
    UPDATE: 'update',
    DELETE: 'delete',
  },
  ACCESS: {}
}

// Modes
export const SYSTEM_MODES = {
  CHANGE_MODE: 'CHANGE_MODE',
  HORSE: 'horses',
  COACH: 'coaches',
}

// System type
export const SYSTEM_TYPES = {
  CHANGE_SYSTEM_TYPE: 'CHANGE_SYSTEM_TYPE',
  SINGLE: 'SINGLE',
  DUAL: 'DUAL',
}
