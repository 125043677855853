import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useTranslation } from './../../../../helpers/translate';
import { ButtonAction, TableVerticalSimple } from './../../../common/TableComponent';
import { getClientCardVerticalTableData } from './getClientCardVerticalTableData';
import { getVisit } from './../../../../actions/visit/visitRoutes';
import dayjs from 'dayjs';
import { OpenDialog } from './../../../common/Popups';
import { ButtonSend, ToggleActiveStatus, serverErrorHandler } from './../../../common/FormsComponent';
import { useNavigate } from 'react-router-dom';
import { setCancelVisit } from './../../../../actions';
import { getPossibilityVisitCancel } from './../../../library';
import { ToastContainer } from 'react-toastify';

export const VisitRemove = ({ clickedDate, extendedProps }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const [visit, setVisit] = useState();
  const [load, setLoad] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isForceDelete, setIsForceDelete] = useState(false);

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const handelCancelVisit = async () => {
    const res = await setCancelVisit(visit.id);
    const data = res.data;

    if (data) {
      navigate('/');
    }

    serverErrorHandler(res?.response?.data?.message, setErrorMessages);
  }

  const visitId = extendedProps.visit?.id;
  const visitDate = dayjs(clickedDate).add(2, 'hour').format('DD-MM-YYYY hh:mm');
  const possibilityVisitCancel = getPossibilityVisitCancel(clickedDate, 2);

  useEffect(() => {
    if (visitId) {
      getVisit(visitId)
        .then((res) => {
          const data = res?.data?.data;
          const date = dayjs(data?.timeslot.date).$d;

          setLoad(true);

          setVisit({
            ...data,
            phoneCode: '+380 ',
            phone: data?.phone.substring(5),
            editTime: date,
          });
        });
    }
  }, []);

  return (
    <>
      <ToastContainer limit={10}/>
      <OpenDialog
        open={openSuccessDialog}
        setOpen={setOpenSuccessDialog}
        data={{
          title: 'Підтвердження',
          description: 'Підтвердіть, що ви дійсно хочете відмінити візит'
        }}
      >
        <ButtonSend text="Відмовитись" sx={{ mr: 1, width: '50%' }} onClick={ () => setOpenSuccessDialog(false) } />
        <ButtonSend variant="outlined" text="Підтвердити" sx={{ mr: 1, width: '50%' }} onClick={ handelCancelVisit }/>
      </OpenDialog>

      <Typography variant="h4" sx={{ mb: 3, color: 'red' }}>
        Скасування візиту
      </Typography>

      {
        possibilityVisitCancel 
          ? <>
              <TableVerticalSimple
                title="Деталі візиту"
                data={visit}
                getVerticalTableData={ getClientCardVerticalTableData } 
                  __={__}
              />

              <ButtonAction onClick={ () => setOpenSuccessDialog(true) } text="Видалити" sx={{ mt: 3 }} type="error"/>
            </>
          : 
            <>
              <Typography variant="h5" sx={{ mb: 3, color: 'red' }}>{ visitDate }</Typography>
              <Typography sx={{ mb: 3, color: 'red' }}>
                Скасівання візиту можливе не пізніше, ніж за 2 години до візиту !
              </Typography>
              {/* <ToggleActiveStatus title="Примусове видалення" value={isForceDelete} onChange={() => setIsForceDelete(prev => !prev)} />
              {
                isForceDelete &&
                <ButtonAction onClick={ () => setOpenSuccessDialog(true) } text="Видалити примусово" sx={{ mt: 3 }} type="error"/>
              } */}
            </>
      }
      
    </>
  );
};
