export const getServiceRowCellsData = (dataItem, __) => {
  return {
    serviceName: {
      th: 'Послуга',
      td: dataItem && dataItem.service_title,
      sort: null,
    },
    serviceAmount: {
      th: 'Вартість',
      td: dataItem && dataItem.amount,
      sort: null,
    },
  }
}
