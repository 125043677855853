import Box from '@mui/material/Box';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box
          sx={{
            p: '32px 24px',
            m: '28px 0',
            background: '#fff',
            border: '1px solid #ddd',
            borderRadius: '24px',

            '@media screen and (max-width: 920px)': {
              p: '24px 12px'
            }
          }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}
