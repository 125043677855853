import axios from '../../helpers/private.axios';

export const getPaymentsInfo = async (
    sortOption,
    sortDirection,
    currentPage,
    userId,
    searchPhone,
    searchLastName,
    filterStatus,
) => {
    const params = {};

    if (searchPhone) {
        params.phone = searchPhone;
    }

    if (searchLastName) {
        params['last-name'] = searchLastName;
    }

    if (filterStatus === 0) {
        params.status = 'nopaid';
    } else if (filterStatus === 1) {
        params.status = 'paid';
    }

    // if (filterType) {
    //     params.type = filterType;
    // }

    if (userId) {
        params.user = userId;
    }

    params.page = currentPage;
    
    return axios.get(`/payment`, { params });
};