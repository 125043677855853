import { TableCell } from "@mui/material";

export const Cells = ({ cellsData, cellType = 'td' }) => {
  const rowCells = [];

  for (let key in cellsData) {
    const value = cellsData[key][cellType];
    
    rowCells.push(
      <TableCell key={ key }>
        <>{ value }</>
      </TableCell>
    );
  }

  return (
    <>
      { rowCells }
    </>
  )
}