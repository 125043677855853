import axios from '../../helpers/private.axios';

export const createCoach = (person) => {
  return axios.post(`/coaches`,person, 
    { 
      headers: { 'Content-Type': 'mulipart/form-data' },
    },
   )
    .then((res) => res)
    .catch(error => error);
};
