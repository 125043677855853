import axios from '../../helpers/private.axios';

export const getOrderIncomings = async (
    /*  contractorFilter, */
    searchTitle,
    currentPage,
    itemsPerPage,
    showArchive
) => {
    const params = {};

    if (searchTitle) {
        params['order_num'] = searchTitle;
    }

    /* if (contractorFilter) {
        params['contractor'] = contractorFilter;
    } */

    if (showArchive) {
        params.status = 'archive';
    }

    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/order-incomings`, { params });
};

