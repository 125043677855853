import { getValidationLocaleModel } from "./../../../common/FormsComponent/LocaleTabs";
// may be import from current component folder
// import { requiredLocaleValidationFields } from "./../../../common/FormsComponent/LocaleTabs";
import { requiredLocaleValidationFields } from "./requiredLocaleValidationFields";

export const validationModel = {
  ...getValidationLocaleModel(requiredLocaleValidationFields),
  passport: { isValid: true, errorMessage: '' },
  date_of_birth: { isValid: true, errorMessage: '' },
  horsCategories: { isValid: true, errorMessage: '' },
  services: { isValid: true, errorMessage: '' },
  status: { isValid: true, errorMessage: '' }
}