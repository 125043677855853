import { useEffect, useState } from 'react';
import { IconButton, MenuItem, Select } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { SelectFirstName } from './SelectFirstName';
import { SelectLastName } from './SelectLastName';
import { SelectPhone } from './SelectPhone';
import { getCode, getPhone } from './getPhoneInfo';

// const mainStyles = {
//   display: 'flex',
//   flexDirection: 'column'
// };

export const UserSelectComponent = ({
  data,
  dataChangeHandler,
  isLabel = false,
  isValidation = true,
  direction = 'column',
  sx = {},
  disabled = false,
  isFullWidth = true
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const user = data
    ? { ...data.user }
    : {
        id: null,
        user_id: '',
        first_name: '',
        last_name: '',
        phone: ''
      };

  const valueData = {
    user_id: user?.user_id ?? null,
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone: getPhone(user?.phone),
    phoneCode: getCode(user?.phone)
  };

  const [value, setValue] = useState(null);

  // !!! maybe not use
  // const directionStyles = direction === 'row' ? { flexDirection: 'row' } : {};
  // const fieldDirectionStyles = direction === 'row' ? { mt: 0, ml: 4 } : {};
  // const firstNameDirectionStyles = direction === 'row' ? { width: 200 } : {};
  // const lastNameDirectionStyles = direction === 'row' ? { width: 200 } : {};
  // const phoneDirectionStyles = direction === 'row' ? { width: 280 } : {};

  const titleStyle = direction !== 'row' ? { marginBottom: '12px', display: 'block' } : {};

  const clearHandler = () => {
    const clearValue = {
      user_id: null,
      first_name: '',
      last_name: '',
      phone: '',
      phoneCode: getCode(user?.phone)
    };
    setValue(clearValue);
    dataChangeHandler(clearValue);
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    console.log(value);

    dataChangeHandler({ ...data, user: { ...value }, user_id: value?.user_id });
  }, [value]);

  return (
    <>
      <div className="input-wrap">
        {isLabel && <b style={{ ...titleStyle }}>Ім'я клієнта</b>}
        <SelectFirstName
          value={value || valueData}
          setValue={setValue}
          isValidation={isValidation}
          disabled={disabled}
          direction={direction}
          isFullWidth={isFullWidth}
          isFirstRender={isFirstRender}
        />
      </div>
      <div className="input-wrap">
        {isLabel && <b style={{ ...titleStyle }}>Прізвище клієнта</b>}
        <SelectLastName
          value={value || valueData}
          setValue={setValue}
          isValidation={isValidation}
          disabled={disabled}
          direction={direction}
          isFullWidth={isFullWidth}
          isFirstRender={isFirstRender}
        />
      </div>

      <div className="input-wrap  input-wrap--type2">
        {isLabel && <b style={{ ...titleStyle }}>Телефон</b>}
        <div className="input-phone-wrap">
          <Select
            value={value?.phoneCode || valueData?.phoneCode}
            sx={{ height: '56px', marginRight: '12px' }}
            disabled={disabled}>
            <MenuItem value={value?.phoneCode || valueData?.phoneCode}>
              {value?.phoneCode || valueData?.phoneCode}
            </MenuItem>
          </Select>
          <SelectPhone
            value={value || valueData}
            setValue={setValue}
            isValidation={isValidation}
            disabled={disabled}
            isFirstRender={isFirstRender}
          />
        </div>
        {direction === 'row' && (
          <IconButton
            className="button-icon"
            aria-label="clear"
            onClick={clearHandler}
            sx={{ width: '24px' }}>
            <ClearIcon />
          </IconButton>
        )}
      </div>
    </>
  );
};
