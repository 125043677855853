import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { useCheckAvailableCurrentUserPermission } from "src/configData/rolesConfig";

export const ButtonLinkIcon = ({icon, route, state, sx, checkRoles = false, permission, ...props}) => {
  const componentAvailable = useCheckAvailableCurrentUserPermission(checkRoles?.resource, permission);

  return (
    <>
      {
        componentAvailable &&
          <Link to={ route } state={state} >
            <IconButton
              variant="contained"
              type="submit"
              sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1, ...sx }}
              { ...props }
            >
              <>{ icon }</>
            </IconButton>
          </Link>
      }
    </>
    
  );
};

ButtonLinkIcon.defaultProps = {
  sx: {},
  state: {},
}