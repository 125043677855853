import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Switch,
  FormControlLabel
} from '@mui/material';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';

import { getWriteOffs, deleteWriteOffs } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { rolesComponentConfig } from './rolesComponentConfig';
import { ButtonIcon, ButtonLink, ButtonLinkIcon } from './../../common/FormsComponent';

export const WriteOffsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);
  const [searchTitle, setSearchTitle] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleWriteOffsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteWriteOffs(id).then(async () => {
        const { data: response } = await getWriteOffs(
          sortOption,
          sortDirection,
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getWriteOffs(
          sortOption,
          sortDirection,
          searchTitle,
          currentPage,
          itemsPerPage,
          showArchive
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [sortOption, sortDirection, searchTitle, currentPage, itemsPerPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('writeOffs.page.title')}</div>
      <div className="table-action">

        <div className="table-action__block">
          <ButtonLink
            text={ __('create.button.title') }
            route="create"
            checkRoles={ rolesComponentConfig }
            permission={ PERMISSIONS.ACTIONS.CREATE }
          />
        </div>

      </div>
      <div className="table-block">
        <div className="table table--write-offs">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <span className="td-name">
                  <span>{__('writeOffs.table.serial_number')}</span>
                </span>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('writeOffs.table.created_at')}</p>
                </div>
              </div>
              <div className="td ">
                <div className="td-name">
                  <p>{__('writeOffs.table.subdivision')}</p>
                </div>
              </div>
              <div className="td ">
                <div className="td-name">
                  <p>{__('writeOffs.table.person')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('writeOffs.table.reason')}</p>
                </div>
              </div>
              <div className="td">
                <span className="td-name">
                  <span>{__('writeOffs.table.commentary')}</span>
                </span>
              </div>
              <div className="td">
                <div className="td-name">
                  <span className="td-name">
                    <span>{__('writeOffs.table.summary')}</span>
                  </span>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <span className="td-name">
                    <span>{__('writeOffs.table.status')}</span>
                  </span>
                </div>
              </div> */}
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('writeOffs.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((writeOffs) => {
              const isEditable = !writeOffs.turnover_completed;
              return (
              <div className="tr" key={writeOffs.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.serial_number')}</p>
                  </div>
                  <div className="td-content">{writeOffs.order_num}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.created_at')}</p>
                  </div>
                  <div className="td-content">
                    {dayjs(writeOffs.created_at).format('YYYY-MM-DD')}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.person')}</p>
                  </div>
                  <div className="td-content">{writeOffs.subdivision?.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.person')}</p>
                  </div>
                  <div className="td-content">
                    {writeOffs.person.last_name} {writeOffs.person.first_name}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.reason')}</p>
                  </div>
                  <div className="td-content">{writeOffs.reason.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.commentary')}</p>
                  </div>
                  <div className="td-content">{writeOffs.commentary}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.summary')}</p>
                  </div>
                  <div className="td-content">{writeOffs.summary}</div>
                </div>
                {/*   <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.status')}</p>
                  </div>
                  <div className="td-content">
                    {writeOffs.status ? __('general.table.active') : __('general.table.inactive')}
                  </div>
                </div> */}
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('writeOffs.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">

                      <ButtonLinkIcon 
                        icon={ <PageviewIcon /> }
                        route={ `/write-offs/${writeOffs.id}` }
                        checkRoles={ rolesComponentConfig }
                        permission={PERMISSIONS.ACTIONS.READ}
                      />

                      {
                        isEditable && 
                        <>
                          <ButtonLinkIcon 
                            icon={ <EditIcon /> }
                            route={ `/write-offs/${writeOffs.id}/edit` }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.UPDATE}
                          />
                          <ButtonIcon 
                            icon={ <DeleteIcon /> } 
                            onClick={ handleWriteOffsDelete(writeOffs.id) }
                            checkRoles={ rolesComponentConfig }
                            permission={PERMISSIONS.ACTIONS.DELETE}
                          />
                        </>
                      }
                      
                    </div>
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
