const seasonTickets = {
        uk: {
                "seasonTickets.page.title": "Абонементи/Сертифікати (шаблони)",
                // table
                "seasonTickets.table.title": "Назва",
                "seasonTickets.table.description": "Опис",
                "seasonTickets.table.image": "Зображення",
                "seasonTickets.table.type": "Тип",
                "seasonTickets.table.category": "Категорія",
                "seasonTickets.table.service": "Послуга",
                "seasonTickets.table.amount": "Вартість",
                "seasonTickets.table.quantity_hours": "Кількість годин",
                "seasonTickets.table.discount_amount": "Зижка (сума)",
                "seasonTickets.table.discount_percent": "Знижка %",
                "seasonTickets.table.date_start": "Дата початку",
                "seasonTickets.table.date_end": "Дата кінця",
                "seasonTickets.table.allowed_days": "Доступні дні",
                "seasonTickets.table.validity_period": "Термін дії (міс)",
                "seasonTickets.table.is_active": "Активний/Неактивний",
                "seasonTickets.table.actions": "Дії",
                // table end

                //form
                "seasonTickets.form.title-create": "Створити (абонемент/сертифікат)",
                "seasonTickets.form.title-edit": "Редагувати (абонемент/сертифікат)",
                "seasonTickets.form.button": "Зберегти",
                "seasonTickets.form.is_active": "Активний/Неактивний",
                "seasonTickets.input.type": "Тип*",
                "seasonTickets.input.category_id": "Категорії*",
                "seasonTickets.input.service_id": "Сервіси*",
                "seasonTickets.input.amount": "Ціна*",
                "seasonTickets.input.quantity_hours": "Кількість годин",
                "seasonTickets.input.discount_amount": "Зижка (сума)",
                "seasonTickets.input.discount_percent": "Знижка %",
                "seasonTickets.input.validity_period": "Термін дії (міс)*",
                "seasonTickets.input.date_start": "Дата початку дії*",
                "seasonTickets.input.date_end": "Дата кінця дії*",
                "seasonTickets.input.allowed_days": "Доступні дні",
                //form error
                "seasonTickets.form.null.error": "*Поле є обов'язковим",
                "seasonTickets.form.title.error": "*Поле є обов'язковим",
                "seasonTickets.form.description.error": "*Поле є обов'язковим",
                "seasonTickets.form.category_id.error": "*Поле є обов'язковим",
                "seasonTickets.form.service_id.error": "*Поле є обов'язковим",
                "seasonTickets.form.amount.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "seasonTickets.form.discount_percent.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "seasonTickets.form.discount_amount.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
                "seasonTickets.form.quantity_hours.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9",
                "seasonTickets.form.validity_period.error": "*Поле є обов'язковим",
                "seasonTickets.form.date_start.error": "*Поле є обов'язковим, дата не може бути після дати закічення, дати не можуть бути однаковими",
                "seasonTickets.form.date_end.error": "*Поле є обов'язковим, дата не може бути перед датою початку, дати не можуть бути однаковими",
                "seasonTickets.form.one_of_three_error": "*Поле є обов'язковим, в поле можна водити лише - 0-9. Тільки одне поле може з трьох містити значення"

                //form end
        },
        "en": {
                "seasonTickets.page.title": "Season Tickets/Certificates (templates)",
                // table
                "seasonTickets.table.title": "Title",
                "seasonTickets.table.description": "Description",
                "seasonTickets.table.image": "Image",
                "seasonTickets.table.type": "Type",
                "seasonTickets.table.category": "Category",
                "seasonTickets.table.service": "Service",
                "seasonTickets.table.amount": "Price",
                "seasonTickets.table.quantity_hours": "Quantity Hours",
                "seasonTickets.table.discount_amount": "Sale (amount)",
                "seasonTickets.table.discount_percent": "Sale %",
                "seasonTickets.table.date_start": "Start Date",
                "seasonTickets.table.date_end": "End Date",
                "seasonTickets.table.validity_period": "Validity (month)",
                "seasonTickets.table.is_active": "Active/Inactive",
                "seasonTickets.table.actions": "Actions",
                // table end

                //form
                "seasonTickets.form.title-create": "Create (Season Ticket/Certificate)",
                "seasonTickets.form.title-edit": "Edit (Season Ticket/Certificate)",
                "seasonTickets.form.button": "Save",
                "seasonTickets.form.is_active": "Active/Inactive",
                "seasonTickets.input.type": "Type*",
                "seasonTickets.input.category_id": "Categories*",
                "seasonTickets.input.service_id": "Services*",
                "seasonTickets.input.amount": "Price*",
                "seasonTickets.input.quantity_hours": "Quantity Hours",
                "seasonTickets.input.discount_amount": "Discount (amount)",
                "seasonTickets.input.discount_percent": "Discount %",
                "seasonTickets.input.validity_period": "Validity period (month)*",
                "seasonTickets.input.date_start": "Start Date*",
                "seasonTickets.input.date_end": "End Date*",
                //form error
                "seasonTickets.form.null.error": "*This field is required",
                "seasonTickets.form.title.error": "*This field is required",
                "seasonTickets.form.description.error": "*This field is required",
                "seasonTickets.form.category_id.error": "*This field is required",
                "seasonTickets.form.service_id.error": "*This field is required",
                "seasonTickets.form.amount.error": "*This field is required, only 0-9 are allowed.",
                "seasonTickets.form.quantity_hours.error": "*This field is required, only 0-9 are allowed.",
                "seasonTickets.form.discount_percent.error": "*This field is required, only 0-9 are allowed.",
                "seasonTickets.form.discount_amount.error": "*This field is required, only 0-9 are allowed.",
                "seasonTickets.form.validity_period.error": "*This field is required",
                "seasonTickets.form.date_start.error": "*This field is required, date cannot be after the end date, dates cannot be the same",
                "seasonTickets.form.date_end.error": "*This field is required, date cannot be before the start date, dates cannot be the same",
                "seasonTickets.form.one_of_three_error": "*This field is required, only 0-9 are allowed. Only one field out of three can have a value."
                //form end
        },

        "ru": {
                "seasonTickets.page.title": "Абонементы/Сертификаты (шаблоны)",
                // table
                "seasonTickets.table.title": "Название",
                "seasonTickets.table.description": "Описание",
                "seasonTickets.table.image": "Изображение",
                "seasonTickets.table.type": "Тип",
                "seasonTickets.table.category": "Категория",
                "seasonTickets.table.service": "Услуга",
                "seasonTickets.table.amount": "Цена",
                "seasonTickets.table.quantity_hours": "Количество часов",
                "seasonTickets.table.discount_amount": "Скидка (сумма)",
                "seasonTickets.table.discount_percent": "Скидка %",
                "seasonTickets.table.date_start": "Дата начала",
                "seasonTickets.table.date_end": "Дата окончания",
                "seasonTickets.table.validity_period": "Срок действия (мес)",
                "seasonTickets.table.is_active": "Активный/Неактивный",
                "seasonTickets.table.actions": "Действия",
                // table end

                //form
                "seasonTickets.form.title-create": "Создать (абонемент/сертификат)",
                "seasonTickets.form.title-edit": "Редактировать (абонемент/сертификат)",
                "seasonTickets.form.button": "Сохранить",
                "seasonTickets.form.is_active": "Активный/Неактивный",
                "seasonTickets.input.type": "Тип*",
                "seasonTickets.input.category_id": "Категории*",
                "seasonTickets.input.service_id": "Услуги*",
                "seasonTickets.input.amount": "Цена*",
                "seasonTickets.input.quantity_hours": "Количество часов",
                "seasonTickets.input.discount_amount": "Скидка (сумма)",
                "seasonTickets.input.discount_percent": "Скидка %",
                "seasonTickets.input.validity_period": "Срок действия (мес)*",
                "seasonTickets.input.date_start": "Дата начала*",
                "seasonTickets.input.date_end": "Дата окончания*",
                //form error
                "seasonTickets.form.null.error": "*Это поле обязательно для заполнения",
                "seasonTickets.form.title.error": "*Это поле обязательно для заполнения",
                "seasonTickets.form.description.error": "*Это поле обязательно для заполнения",
                "seasonTickets.form.category_id.error": "*Это поле обязательно для заполнения",
                "seasonTickets.form.service_id.error": "*Это поле обязательно для заполнения",
                "seasonTickets.form.amount.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "seasonTickets.form.quantity_hours.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "seasonTickets.form.discount_percent.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "seasonTickets.form.discount_amount.error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9.",
                "seasonTickets.form.validity_period.error": "*Это поле обязательно для заполнения.",
                "seasonTickets.form.date_start.error": "*Это поле обязательно для заполнения, дата не может быть позже даты окончания, даты не могут совпадать",
                "seasonTickets.form.date_end.error": "*Это поле обязательно для заполнения, дата не может быть раньше даты начала, даты не могут совпадать",
                "seasonTickets.form.one_of_three_error": "*Это поле обязательно для заполнения, разрешены только цифры 0-9. Только одно из трех полей может содержать значение."
                //form end
        }


}


export default seasonTickets;