import dayjs from "dayjs"
export const getClientCardVerticalTableData = (dataItem, __) => {

  return [
    {
      th: 'Дата створення',
      td: dataItem && dataItem.created_at,
    },
    {
      th: 'Профіт',
      td: dataItem && dataItem.profit,
    },
    {
      th: 'Кількість візитів',
      td: dataItem && dataItem.visits_count,
    },
    {
      th: 'Віртуальний рахунок',
      td: dataItem && dataItem.virtual_wallet,
    },
    {
      th: 'Знижка',
      td: dataItem && dataItem.discount_percent,
    },
    {
      th: 'Перший візит',
      td: dataItem && dataItem.first_visit,
    },
    {
      th: 'Останній візит',
      td: dataItem && dataItem.last_visit,
    },
    {
      th: 'Статус',
      td: dataItem && dataItem?.status?.title,
    },
    {
      th: 'Група',
      td: dataItem && dataItem?.group?.title,
    },
   
  ]
}