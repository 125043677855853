export const timeSlotStatuses = {
  empty: {
    color: 'seagreen',
    title: 'Вільний слот',
  },
  busy: {
    color: 'blue',
    title: 'Очікується візит',
  },
  break: {
    color: 'red',
    title: 'заблоковоно',
  }
}