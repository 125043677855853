import { Box, Divider, Typography } from '@mui/material';
import { SelectService } from './SelectService';
import { useEffect, useState } from 'react';
import dayjs, { extend } from 'dayjs';
import { phoneNumberWithCodeFormat } from './Utils';
import { TimeAndAmount } from './TimeAndAmount';
import { TimeAndDate } from './TimeAndDate/TimeAndDate';
import { createVisit, editVisit, getVisits } from '../../../../../actions/visit/visitRoutes';
import { getModelTimeslot } from '../../../../../actions';
import { SelectHorse } from './SelectHorse';
import { ButtonLink, ButtonSend, serverErrorHandler } from './../../../FormsComponent';
import { ToastContainer } from 'react-toastify';
import { OpenDialog } from './../../../Popups';
import { useNavigate } from 'react-router-dom';
import { modelSelect, singleModelActions } from '../../../../../configData/modelConfig';
import { useSelector } from 'react-redux';

export const AddServiceForm = ({
  clickedDate,
  value,
  setValue,
  status,
  validVisit,
  setValidVisit,
  extendedProps,
  setUpdate,
  setClickedDate,
  copy
}) => {
  const navigate = useNavigate();

  const modelType = useSelector((state) => state.mode);

  const isServices = value?.visit_services ?? null;
  const services = isServices ? value?.visit_services : [];

  let service = '';
  if (services.length) {
    service = {
      ...value?.visit_services[0],
      id: value?.visit_services[0]?.service_id,
      title: value?.visit_services[0]?.service_title
    }
  } 
    
  const [visitServices, setVisitServices] = useState(service || null);
  const [selectedHorse, setSelectedHorse] = useState(null);
  const [isAnyHorseSelected, setAnyHorseSelected] = useState(false);
  const [visitDate, setVisitDate] = useState(clickedDate);
  const [timeSlot, setTimeSlot] = useState(null);

  const [errorMessages, setErrorMessages] = useState();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openPastDateDialog, setOpenPastDateDialog] = useState(false);
  const [createdVisitId, setCreatedVisitId] = useState(null);

  const { subStatusId, visit } = extendedProps;

  const timeSlotId = timeSlot ? timeSlot.id : value?.timeslot?.id;
  const horse = { id: value?.timeslot?.model_id, name: value?.timeslot?.model_title };
  const visitId = visit?.id;
  const userId = visit?.user_id;
  const originalSlotId = value?.timeslot?.id;
  const horseId = selectedHorse ? selectedHorse.id : value?.timeslot?.model_id;
  const date = visitDate ? dayjs(visitDate).$d : dayjs(clickedDate).$d;

  const createVisitHandler = async () => {
    let newVisitServices = [{ ...visitServices, service_id: visitServices?.id, discount: 0 }];
    const { first_name, last_name, email, phone, another_name, phoneCode } = value;

    const validVisitObj = {
      ...validVisit,
      first_name: !!first_name,
      last_name: !!last_name,
      phone: !!phone?.match(/\d{2}\s\d{3}-\d{2}-\d{2}$/i),
      email: true,
      visit_services: !!Object.keys(newVisitServices).length
    };

    const isValid = Object.values(validVisitObj).every((item) => item);
    setValidVisit(validVisitObj);

    let sendingData = {
      status_id: copy ? 0 : value.created_at ? value.status_id : status,
      first_name,
      last_name,
      email,
      length: 1,
      another_name,
      timeslot_id: timeSlotId,
      model_id: horseId,
      phone: phoneNumberWithCodeFormat(phoneCode + value.phone, '$1 $2 $3-$4-$5'),
      visit_services: newVisitServices,
      notifyBySms: value.notifyBySms
    };

    if (!value.created_at) {
      sendingData = {
        ...sendingData,
        description: 'event description',
        description_status: 'string',
        user_id: null,
        sub_status_id: subStatusId
      };
    } else {
      sendingData = {
        ...sendingData,
        date,
        user_id: userId
      };
    }

    try {
      if (!value.created_at) {
        const res = await createVisit(sendingData);
        const errors = res?.response?.data?.errors;

        if (errors) {
          serverErrorHandler(errors, setErrorMessages);
          return;
        }

        setCreatedVisitId(res?.data?.id);
      } else {
        const res = await editVisit(visitId, sendingData);
        const errors = res?.response?.data?.errors;
        if (errors) {
          serverErrorHandler(errors, setErrorMessages);
          return;
        }
      }

      setUpdate((prev) => !prev);
      setOpenSuccessDialog(true);

      if (!visitId) {
        navigate('/');
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const { horseId, timeSlotId } = extendedProps;
    (async () => {
      const { data: horseData } = await singleModelActions[modelType.mode](horseId);
      const { data: timeSlotData } = await getModelTimeslot(timeSlotId);

      setSelectedHorse(horse.id ? horse : horseData.data);
      setTimeSlot(timeSlotData.data);
    })();

    if (copy) {
      setTimeSlot(null);
    }
  }, []);

  useEffect(() => {
  }, [visitServices, selectedHorse, isAnyHorseSelected, visitDate, timeSlot]);

  return (
    <>
      <ToastContainer />

      <OpenDialog
        open={openPastDateDialog}
        setOpen={setOpenPastDateDialog}
        data={{
          title: 'Підтвердження',
          description: 'Підтвердіть, що ви дійсно хочете створити візит, за минулу дату'
        }}
      >
        <ButtonSend text="Відмовитись" sx={{ mr: 1, width: '50%' }} onClick={ () => setOpenPastDateDialog(false) } />
        <ButtonSend variant="outlined" text="Підтвердити" sx={{ mr: 1, width: '50%' }} onClick={ createVisitHandler }/>
      </OpenDialog>

      <OpenDialog
        open={openSuccessDialog}
        setOpen={setOpenSuccessDialog}
        data={{
          title: 'Успіх',
          description: 'Зміни успішно внесені та зафіксовані'
        }}
      >
        <ButtonSend text="Продовжити" sx={{ mr: 1, width: '50%' }} onClick={ () => setOpenSuccessDialog(false) }/>
        <ButtonLink text="До розкладу" route="/" variant="outlined" sx={{ width: '100%' }} />
      </OpenDialog>

      <Box sx={{ maxWidth: 560 }}>
        <Typography variant="h6">Послуга</Typography>
        <TimeAndAmount timeSlot={timeSlot || value?.timeslot} visitServices={visitServices} />
        <SelectService
          visitServices={visitServices || (value?.visit_services ? value?.visit_services[0] : [])}
          setVisitServices={setVisitServices}
          validVisit={validVisit}
          selectedHorse={selectedHorse}
          setSelectedHorse={ setSelectedHorse }
          visitId={visitId}
          modelType={ modelType.mode }
        />

        <Typography variant="h6">{ modelSelect[modelType.mode] }</Typography>
        <SelectHorse
          title={ modelSelect[modelType.mode] }
          selectedHorse={selectedHorse}
          setSelectedHorse={setSelectedHorse}
          setTimeSlot={setTimeSlot}
          isAnyHorseSelected={isAnyHorseSelected}
          setAnyHorseSelected={setAnyHorseSelected}
          visitServices={visitServices}
          visitId={visitId}
          modelType={ modelType.mode }
        />
      </Box>
      <Divider variant="middle" sx={{ mb: 4, mt: 4 }} />
      <TimeAndDate
        validVisit={validVisit}
        visitDate={visitDate || clickedDate}
        clickedDate={clickedDate}
        setVisitDate={setVisitDate}
        selectedHorse={selectedHorse}
        setTimeSlot={setTimeSlot}
        originalSlotId={!copy ? originalSlotId : null}
        value={value}
        visitId={visitId}
        modelType={ modelType.mode }
      />

      <ButtonSend
        disabled={ !(visitServices && selectedHorse && timeSlot && value?.id) }
        sx={{ mt: 3, minWidth: 200, p: 4 }}
        onClick={() => {
          const isPastDate = dayjs().isAfter(date);
          isPastDate 
            ? setOpenPastDateDialog(true)
            : createVisitHandler();
        }}
        text="Зберегти"
      />
    </>
  );
};
