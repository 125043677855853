import { useEffect, useState } from 'react';
import { AddInventoryItems, ButtonSend } from './../../common/FormsComponent';

import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Box
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';

import dayjs from 'dayjs';

import { getOrderSubdivisions, getOrderStores, updateOrderOutcomings } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { useLocation, useNavigate } from 'react-router-dom';

export const OrderOutcomingsForm = ({
  orderOutcomings,
  handleChange,
  actionUpdate,
  actionApply,
  actionCreate,
  ctaButtonText,
  validationStatus,
  setValidationStatus,
  updateItemСount,
  formType,
  itemsPush,
  isChanged,
}) => {
  const { __ } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // const disabledEdit = pathname.includes('edit') ? true : false;
  const disabledEdit = false;

  const [{ loaded, subdivisions, stores }, setData] = useState({
    loaded: false,
    subdivisions: [],
    stores: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: orderContractorsResponse } = await getOrderSubdivisions();
        const { data: orderStoresResponse } = await getOrderStores();
        setData({
          loaded: true,
          subdivisions: orderContractorsResponse,
          stores: orderStoresResponse
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <TextField
        fullWidth
        value={orderOutcomings?.order_num}
        onChange={handleChange('order_num')}
        name="order_num"
        label={__('orderOutcomings.input.order_num')}
        error={!validationStatus.order_num.isValid}
        helperText={validationStatus.order_num.errorMessage}
      />
      <FormControl className={validationStatus.subdivision_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('orderOutcomings.input.subdivision_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('orderOutcomings.input.subdivision_id')}
          name="subdivision_id"
          value={orderOutcomings?.subdivision_id}
          onChange={handleChange('subdivision_id')}
          error={!validationStatus.subdivision_id.isValid}
          helperText={validationStatus.subdivision_id.errorMessage}>
          {subdivisions.data &&
            subdivisions.data.map((subdivisions) => (
              <MenuItem key={subdivisions.id} value={subdivisions.id}>
                {subdivisions.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.subdivision_id.isValid && (
          <FormHelperText>{validationStatus.subdivision_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateField']}>
          <DateField
            label={__('orderOutcomings.input.date_order')}
            value={dayjs(orderOutcomings.date_order)}
            onChange={handleChange('date_order')}
            format="YYYY-MM-DD"
            sx={{ width: '100%' }}
            error={!validationStatus.date_order.isValid}
            helperText={validationStatus.date_order.errorMessage}
            className={validationStatus.date_order.isValid ? '' : 'input-error'}
          />
        </DemoContainer>
      </LocalizationProvider>
      <FormControl className={validationStatus.store_id.isValid ? '' : 'input-error'}>
        <InputLabel>{__('orderOutcomings.input.store_id')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('orderOutcomings.input.store_id')}
          name="store_id"
          value={orderOutcomings?.store_id}
          onChange={handleChange('store_id')}
          error={!validationStatus.store_id.isValid}
          helperText={validationStatus.store_id.errorMessage}>
          {stores.data &&
            stores.data.map((stores) => (
              <MenuItem key={stores.id} value={stores.id}>
                {stores.title}
              </MenuItem>
            ))}
        </Select>
        {!validationStatus.store_id.isValid && (
          <FormHelperText>{validationStatus.store_id.errorMessage}</FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <InputLabel>{__('orderOutcomings.input.autoorder')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('orderOutcomings.input.autoorder')}
          name="autoorder"
          value={orderOutcomings?.autoorder}
          onChange={handleChange('autoorder')}>
          <MenuItem value={0}>{__('orderOutcomings.input.autoorder.none')}</MenuItem>
          <MenuItem value={1}>{__('orderOutcomings.input.autoorder.day')}</MenuItem>
          <MenuItem value={2}>{__('orderOutcomings.input.autoorder.week')}</MenuItem>
          <MenuItem value={3}>{__('orderOutcomings.input.autoorder.month')}</MenuItem>
        </Select>
      </FormControl>

      <div className={validationStatus.inventoryItems.isValid ? '' : 'error'}>
        <AddInventoryItems disabled={ disabledEdit } type="outcomings" updateItemСount={updateItemСount} itemsPush={itemsPush} />
      </div>

      {
        formType === 'edit' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ !isChanged } onClick={ actionUpdate }text="Зафіксувати зміни" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
      {
        formType === 'create' &&
          <Box sx={{ display: 'flex' }} fullWidth>
            <ButtonSend disabled={ isChanged } onClick={ actionCreate }text="Створити" sx={{ width: '50%', mr: 2 }}/>
            <ButtonSend disabled={ !isChanged } onClick={ actionApply } text={ ctaButtonText } sx={{ width: '50%' }}/>
          </Box>
      }
      {
        formType === 'copy' &&
          <ButtonSend onClick={ actionUpdate } text="копіювати" />
      }
      
    </>
  );
};
