import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { MultyDataChart } from './charts';
import { CustomTabPanel, a11yProps } from './components/CustomTabPanel';
import { useChangeTab } from './hooks/useChangeTab';
import { getChartIncome, getChartLostClients, getChartNewClients, getChartTimeUsedClients } from 'src/actions';

export const ChartIndicatorsFinancial = () => {
  const [tabIndex, handleChangeTab] = useChangeTab(0);
  const tabWidth = '100%';

  return (
    <Box sx={{ width: '100%', height: '200px' }}>
      <div className="title title--mb36">
        Фінансові показники
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="basic tabs example" variant="scrollable" scrollButtons="auto">
          <Tab label="Доходи" {...a11yProps(0)} wrapped />
          <Tab label="Кількість годин послуг, наданих клієнтам" {...a11yProps(1)} wrapped />
          <Tab label="Кількість нових клієнтів" {...a11yProps(2)} wrapped />
          <Tab label="Кількість втрачених клієнтів" {...a11yProps(3)} wrapped />
        </Tabs>
      </Box>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={0}>
        <MultyDataChart 
          action={ getChartIncome } 
          fieldsName={['cost']}
          chartTitle='' 
          chartLabels={['Доходи']}
          chartsViews = {['bars', 'lines']}
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={1}>
        <MultyDataChart 
          action={ getChartTimeUsedClients } 
          fieldsName={['count']}
          chartTitle='' 
          chartLabels={['Кількість годин послуг']}
          chartsViews = {['bars', 'lines']}
        />
      </CustomTabPanel>
      
      <CustomTabPanel width={ tabWidth } value={tabIndex} index={2}>
        <MultyDataChart 
          action={ getChartNewClients } 
          fieldsName={['count']}
          chartTitle='' 
          chartLabels={['Кількість нових клієнтів']}
          chartsViews = {['bars', 'lines']}
        />
      </CustomTabPanel>

      <CustomTabPanel width={ tabWidth } value={tabIndex} index={3}>
        <MultyDataChart 
            action={ getChartLostClients } 
            fieldsName={['lost', 'count']}
            actionType="valueHiger"
            chartTitle='' 
            chartLabels={['Втрачено', 'Всього']}
            chartsViews = {['lines', 'bars']}
        />
      </CustomTabPanel>

    </Box>
  );
};
