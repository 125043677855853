import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import reducers from '../reducers';
import { saveState, loadState } from '../helpers/storeLocalData';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const middlewares = applyMiddleware(thunk);

const prevState = loadState('ride_state');

const getState = (oldState) => {
  if (!oldState) {
    return createStore(reducers, composeEnhancers(middlewares));
  }
  return createStore(reducers, oldState, composeEnhancers(middlewares));
};

const store = getState(prevState);

store.subscribe(() => {
  const data = store.getState();
  if (data.auth.token !== null) {
    saveState('ride_state', {
      auth: {
        token: data.auth.token,
        user: data.auth.user
      },
      localization: {
        locale: data.localization.locale
      }
    });
  }
});

export default store;
