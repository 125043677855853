export const getValidateForm = (data, setValidationStatus, __, translationError) => () => {
  let isValid = true;

  Object.keys(data.locale).forEach((locale) => {
    const title = data.locale[locale].title;
    const isTitleValid = title ? title.trim() !== '' : false;
    const description = data.locale[locale].description;
    const isDescriptionValid = description ? description.trim() !== '' : false;

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [locale]: {
        ...prevValidationStatus[locale],
        title: {
          isValid: isTitleValid,
          errorMessage: isTitleValid ? '' : `${__(translationError)}`
        },
        description: {
          isValid: isDescriptionValid,
          errorMessage: isDescriptionValid ? '' : `${__(translationError)}`
        }
      }
    }));

    if (!isTitleValid) {
      isValid = false;
    }

    if (!isDescriptionValid) {
      isValid = false;
    }
  });

  return isValid;
};