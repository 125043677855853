import { SYSTEM_MODES } from '../../constants';
import config from '../../config'

const initState = {
  mode: config.system_mode_default
};

const modeReducer = (state = initState, action) => {
  switch (action.type) {
    case SYSTEM_MODES.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload
      };

    default:
      return state;
  }
};

export default modeReducer;
