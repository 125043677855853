import { rolesConfig } from "../rolesConfig"

export const getPermissionsByResource = (roles, resource) => {
  let permissions = [];
  if (!roles) {
    return permissions;
  }
  

  for (let role of roles) {
    if (!resource) {
      permissions = [...permissions, ...rolesConfig[role.name].permissions];
      continue;
    }

    const permissionsExceptions = rolesConfig[role.name].permissionsExceptions;
    if (!permissionsExceptions || !Object.keys(permissionsExceptions).length) {
      permissions = [...permissions, ...rolesConfig[role.name].permissions];
      continue;
    }

    if (!permissionsExceptions[resource]) {
      permissions = [...permissions, ...rolesConfig[role.name].permissions];
      continue;
    }

    permissions = [...permissions, ...permissionsExceptions[resource]];
  }

  if (!(Array.isArray(permissions) && permissions.length)) {
    return [];
  }

  return Array.from(new Set(permissions));
}