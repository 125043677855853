import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";

export const SelectMultyCheckbox = ({
  fieldClassNames,
  label,
  dataList,
  dataListFields,
  dataChecked,
  renderValue,
  error,
  errorMessage,
  sx={},
  ...props
}) => {
  return (
    <FormControl className={ fieldClassNames }>
      <InputLabel>{ label }</InputLabel>
      <Select
        sx={{ width: '100%', textAlign: 'left', ...sx }}
        label={ label }
        multiple
        dataChecked={ dataChecked }
        dataList={ dataList }
        renderValue={(selected) => handleRenderMultySelectValue(selected, dataList, dataListFields)}
        error={ error }
        {...props}
      >
        {dataList &&
          dataList.map((dataListItem) => {
            const { key, value, text } = dataListFields;
            return (
              <MenuItem key={dataListItem[key]} value={dataListItem[value]}>
                <Checkbox
                  checked={dataChecked?.some((checkedItemId) => checkedItemId === dataListItem[value])}
                />
                <ListItemText primary={dataListItem[text]} />
              </MenuItem>
            )}
          )}
      </Select>

        { error && <FormHelperText>{ errorMessage }</FormHelperText> }

    </FormControl>
  );
}

function handleRenderMultySelectValue(selected, dataList, dataListFields) {
  const { key, text } = dataListFields;
  if (dataList) {
    return dataList
      .filter((dataListItem) => selected.includes(dataListItem[key]))
      .map((dataListItem) => dataListItem[text])
      .join(',');
  }
}

SelectMultyCheckbox.defaultProps = {
  dataListFields: {
    key: 'id',
    value: 'id',
    text: 'title',
  }
}