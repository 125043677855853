import { Button } from '@mui/material';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';

export const UnitsForm = ({
  unit,
  setUnit,
  handleLocalizationChange,
  action,
  ctaButtonText,
  validationStatus
}) => {

  return (
    <>
      <LocaleTabs 
        data={ unit }
        setData={ setUnit }
        dataName={ 'units' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        nameField="title"
        fillCheckbox
      />
      
      <Button
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
        variant="contained"
        type="submit"
        onClick={action}>
        {ctaButtonText}
      </Button>
    </>
  );
};
