export const localeFieldsModel = [
  { fieldName: 'first_name', required: true, displayControl: true },
  { fieldName: 'last_name', required: true, displayControl: true },
  {
    fieldName: 'description',
    required: false,
    fieldProps: { multiline: true, maxRows: 8 },
    options: {
      value: {
        maxLength: 1024
      }
    },
  },
  {
    fieldName: 'body',
    required: true,
    fieldProps: { multiline: true, maxRows: 8 },
    options: {
      value: {
        maxLength: 1024
      }
    },
  }
];