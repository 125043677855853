import axios from '../../helpers/private.axios';

export const getChartIncome = async (userParams) => {
    const params = {
        ...userParams,
    };

    return axios.get(`/dashboards/income`, { params })
        .then(res => res)
        .catch(error => error);
};

