import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import OneModel from '../SelectModel/OneModel';
import { modelActions, modelTitle } from '../../../configData/modelConfig';
import { useIsReadOnlyResourceByCurrentUser } from 'src/configData/rolesConfig';
import { rolesComponentConfig } from './rolesComponentConfig';

export function SelectModelForSchedule({ modelType }) {
  const resource = rolesComponentConfig.resource;
  const {userId, isReadOnly} = useIsReadOnlyResourceByCurrentUser(resource);
  
  const [models, setModels] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await modelActions[modelType]();
      let data = response.data.data;

      if (isReadOnly) {
        data = data.filter(model => model.user_id === userId);
      }

      setModels(data);
    }

    fetchData();
  }, [modelType]);

  return (
    <div>
      <Box
        sx={{
          mb: 5,
          display: 'flex',
          alignItems: 'center'
        }}>
        <Typography variant="h5">{ modelTitle[modelType].selectModel }</Typography>
      </Box>
      <div className="schedule-models-list">
        {models.length > 0 ? (
          models.map((model) => (
            <OneModel
              key={model.id}
              model={model}
              modelType={ modelType }
              isReadOnly={ isReadOnly }
            />
          ))
        ) : (
          <p>{ modelTitle[modelType].noModel }</p>
        )}
      </div>
    </div>
  );
}
