import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';
import { CALENDAR, ROLES, SYSTEM_TYPES } from '../../../constants';
import { LocaleSwitch } from './LocaleSwitch';

import { logout } from '../../../actions';
import { useEffect, useState } from 'react';
import DateCalendarRow from '../ScheduleModelManage/DateCalendarRow';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import styles from './layout.module.scss';
import ExternalAccount from './Account/ExternalAccount';
import MainAccount from './Account/MainAccount';
import { PayOrdersButton } from './TempComponents/PayOrdersButton';

import { changeLocale } from '../../../actions';
import { RoleInfo } from './RoleInfo/RoleInfo';
import { ModeToggle } from './ModeToggle';

export function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const calendarDate = useSelector((state) => state.calendar.date);

  const { locale } = useSelector((state) => state.localization);

  const { systemType } = useSelector((state) => state.systemType);
  const role = useSelector((state) => state.auth.user?.role);

  const setCalendarDate = (val) => {
    dispatch({ type: CALENDAR.SET_DATE, payload: val });
  };
  const handleUnlogin = () => {
    dispatch(logout());
    localStorage.removeItem('ride_state');
    navigate('/');
  };
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    !locale && dispatch(changeLocale('uk'));
  }, []);

  return (
    <div className={styles['dashbord-wrapper']}>
      <div className={`${styles.sidebar} ${open ? styles.active : ''}`}>
        <button className={`${styles.toggler} ${open ? styles.active : ''}`} onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="" />
          </Link>
        </div>

        { 
          (role === ROLES.ADMIN.ROLE && systemType === SYSTEM_TYPES.DUAL) &&
            <ModeToggle size="large" orientation={ open ? 'vertical' : 'horizontal' } sx={{ mt: 3 }}/> }

        {pathname !== '/horse-schedule' 
          ? 
            (role === ROLES.ADMIN.ROLE &&
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ locale || 'uk' }>
                <DateCalendar
                  // timezone="system"
                  className={styles['date-calendar']}
                  sx={{
                    width: 240,
                    '& .MuiButtonBase-root': { width: 30, height: 30, lineHeight: 1 },
                    '& .MuiPickersSlideTransition-root': { minHeight: 210 },
                    '& .MuiYearCalendar-root': { width: 241 },
                    '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
                      backgroundColor: 'var(--main-color2)'
                    }
                  }}
                  value={calendarDate}
                  onChange={(val) => {
                    navigate('/');
                    setCalendarDate(val);
                  }}
                />
              </LocalizationProvider>) 
          : <DateCalendarRow />
        }

        {role === ROLES.ADMIN.ROLE ? <MainAccount /> : <ExternalAccount />}
      </div>

      <div className={`${styles.dashbord} ${open ? styles.active : ''}`}>
        <header className={styles.header}>
          <div className={styles['header-left']}>
            <button
              className={`${styles.toggler} ${open ? styles.active : ''}`}
              onClick={handleClick}>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className={styles['header-right']}>
            {/* {role !== ROLES.ADMIN.ROLE && <PayOrdersButton />} */}

            <RoleInfo />
            <LocaleSwitch />
            <div>
              <Button
                onClick={handleUnlogin}
                variant="contained"
                sx={{ width: '180', height: 48, lineHeight: 1 }}>
                Вийти
              </Button>
            </div>
          </div>
        </header>
        <div className="dashbord-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
