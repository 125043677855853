import validator from 'validator';

export const validate = ( name, value ) => {
  let rules = [];

  if( name === "number"){
    rules = [ validator.isLength( value, { min: 6, max: 6 }), validator.isNumeric( value ) ];
  }

  if ( name === "phone" ) {
    rules = [ true ]
  }

  if( name === "individual_taxpayer_number"){
    rules = [ validator.isLength( value, { min: 10, max: 10 }), validator.isNumeric( value ) ];
  }
  
  if( name === "code"){
    rules = [ validator.isLength( value, { min: 2, max: 2 })];
  }

  if( name === "email"){
    rules = [ validator.isEmail(value)];
  }

  return rules.every( rule => rule === true );
}