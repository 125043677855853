import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BookingHeader } from './bookingHeader';
import { CircularProgress, TextField, Checkbox, Modal, Box, IconButton } from '@mui/material';
import { getBookingBasket, getPersonalData, setPaymentOrders } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  autoLoginIntoCabinet,
  buildQueryParams,
  openPaymentPlacePage,
  redirectToLoginForm,
  transformDataToItemsFormat
} from './utils';
import { makePaymentQuery } from './utils/makePaymentQuery';
import { UserDataBlock } from './components';
import { dispatchToken } from './utils/dispatchToken';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const BookingBasket = () => {
  const { user } = useSelector((state) => state.auth);

  const [items, setItems] = useState();
  const [role, setRole] = useState('USER');
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState('');

  const { __ } = useTranslation();
  const navigate = useNavigate();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const [{ userData }, setUserData] = useState({
    userData: []
  });
  const [open, setOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);

  const selectedData = JSON.parse(localStorage.getItem('selectedBookingItems')) || {};
  const [queryParams, setQueryParams] = useState(buildQueryParams(selectedData));
  const [selectedBookingItems, setSelectedItems] = useState({});

  const [booking, setBooking] = useState({
    user_id: '',
    last_name: '',
    first_name: '',
    phone: '',
    email: '',
    amount_paid: '',
    items: []
  });

  const [validationStatus, setValidationStatus] = useState({
    last_name: { isValid: true, errorMessage: '' },
    first_name: { isValid: true, errorMessage: '' },
    phone: { isValid: true, errorMessage: '' }
  });

  const updateQueryParams = (newQueryParams) => {
    setQueryParams(newQueryParams);
  };

  const handleItemDelete = async (itemId) => {
    let updatedSelectedData;

    setSelectedItems((prevSelected) => {
      const updatedSelectedItems = { ...prevSelected };

      if (updatedSelectedItems[itemId]) {
        delete updatedSelectedItems[itemId];
      }

      updatedSelectedData = { ...selectedData };
      delete updatedSelectedData[itemId];

      const newQueryParams = buildQueryParams(updatedSelectedData);
      updateQueryParams(newQueryParams);

      return updatedSelectedItems;
    });

    const updatedItems = transformDataToItemsFormat(updatedSelectedData);
    setData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        abonnement: updatedItems.filter((item) => item.abonnement === true),
        certificate: updatedItems.filter((item) => item.certificate === true)
      }
    }));
  };

  const validateForm = () => {
    let isValid = true;

    for (let field in validationStatus) {
      if (field.includes('phone')) {
        const isValidPhoneNumber = /\+\d{3} \d{2} \d{3}-\d{2}-\d{2}/.test(booking[field]);
        if (!isValidPhoneNumber) {
          isValid = false;
          setValidationStatus((prevValidationStatus) => ({
            ...prevValidationStatus,
            [field]: {
              isValid: false,
              errorMessage: `${__('general.form.required_field.error')}`
            }
          }));
        }
        continue;
      }

      if (!booking[field]) {
        isValid = false;
        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [field]: {
            isValid: false,
            errorMessage: `${__('general.form.required_field.error')}`
          }
        }));
      }
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;

    if (name.includes('phone')) {
      value = event.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 $2 $3-$4-$5');
    } else {
      value = event?.target.value;
    }

    setBooking({
      ...booking,
      [name]: value
    });

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || !isNaN(value),
        errorMessage: value.length > 0 || !isNaN(value) ? '' : `${__('booking.form.null.error')}`
      }
    }));
  };

  const handleMoreButtonClick = () => {
    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/booking/season-tickets');
  };

  const handleCabinetButtonClick = () => {
    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/season-tickets');
  };

  const handleCreate = async () => {
    setErrorMessages({});
    const isValid = validateForm();
    booking.items = items;
    booking.amount_paid = totalAmount;

    if (!isValid) {
      return;
    }

    const { token, paymentPlacePageUrl } = await makePaymentQuery(
      booking,
      setPaymentOrders,
      setOpen,
      setErrorMessages
    );
    const userAlreadyRegistered = !user && !token;

    if (userAlreadyRegistered) {
      redirectToLoginForm(booking?.phone, setErrorMessages, navigate);
      return;
    }

    if (paymentPlacePageUrl) {
      openPaymentPlacePage(paymentPlacePageUrl);
    }

    if (!user && token) {
      await dispatchToken(dispatch, role, token, setAuthToken);
      autoLoginIntoCabinet(dispatch, navigate, role, token, '/history');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getPersonalData();
        setUserData({
          userData: response.data
        });
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingBasket(queryParams);
        setData({
          loaded: true,
          data: response.data
        });
        const items = transformDataToItemsFormat(response.data);
        setItems(items);
        setBooking((prevBooking) => ({
          ...prevBooking,
          items: items
        }));
      } catch (error) {
        const errorFields = Object.keys(error.response.data.errors);
        const errorMessage = errorFields
          .map((field) => error.response.data.errors[field][0])
          .join('<br>');

        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, {
          position: 'top-right',
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        setErrorMessages(error.response.data.errors);
      }
    };

    if (
      queryParams &&
      (queryParams.search.abonnement.length > 0 || queryParams.search.certificate.length > 0)
    ) {
      fetchData();
    }
  }, [queryParams]);

  useEffect(() => {
    const savedSelectedItems = localStorage.getItem('selectedBookingItems');
    if (savedSelectedItems) {
      setSelectedItems(JSON.parse(savedSelectedItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedBookingItems', JSON.stringify(selectedBookingItems));
  }, [selectedBookingItems]);

  useEffect(() => {
    if (loaded) {
      let total = 0;

      if (data.abonnement && data.abonnement.length > 0) {
        data.abonnement.forEach((seasonTicket) => {
          total += parseFloat(seasonTicket.amount);
        });
      }

      if (data.certificate && data.certificate.length > 0) {
        data.certificate.forEach((certificate) => {
          total += parseFloat(certificate.amount);
        });
      }

      setTotalAmount(total);
      setBooking((prevData) => ({ ...prevData, amount_paid: total }));
    }
  }, [loaded, data]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setBooking((prevBooking) => ({
        ...prevBooking,
        last_name: userData.last_name,
        first_name: userData.first_name,
        phone: userData.phone,
        amount_paid: totalAmount
      }));
    }
  }, [userData]);

  return (
    <>
      <ToastContainer />
      <BookingHeader />
      <main className="main-booking">
        {Object.keys(selectedData).length === 0 ? (
          <div className="booking-wrap">
            <div className="booking-form-wrap">
              <div className="booking-title">
                <Link className="booking-title__back" to={`/booking/services/`}>
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <p>{__('booking.basket.title.empty')}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="booking-wrap">
            <div className="booking-form-wrap">
              <div className="booking-title">
                <Link className="booking-title__back" to={`/booking/services/`}>
                  <svg
                    width="10"
                    height="18"
                    viewBox="0 0 10 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                      fill="black"
                    />
                  </svg>
                </Link>
                <p>{__('booking.basket.title')}</p>
              </div>
              <div className="booking-basket-wrap">
                {loaded ? (
                  data.abonnement && data.abonnement.length > 0 ? (
                    <div className="booking-basket">
                      <div className="booking-basket__title">
                        <p>{__('booking.basket.title.basket-season-tickets')}</p>
                      </div>
                      <div className="booking-basket__inside">
                        <div className="booking-basket-list">
                          {loaded ? (
                            data?.abonnement?.map((seasonTickets) => (
                              <div className="booking-basket-list__item" key={seasonTickets.id}>
                                <div className="booking-basket-list__item-photo">
                                  <img src={seasonTickets?.image ? seasonTickets?.image : noImage} alt="" />
                                </div>
                                <div className="booking-basket-list__item-info">
                                  <div className="booking-basket-list__item-title">
                                    <p>{seasonTickets.title}</p>
                                  </div>
                                  <div className="booking-basket-list__item-amount">
                                    <p>{seasonTickets.amount}</p>
                                  </div>
                                  <div className="booking-basket-list__actions">
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      variant="contained"
                                      onClick={() => handleItemDelete(seasonTickets.id)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : (
                  <CircularProgress />
                )}
                {loaded ? (
                  data.certificate && data.certificate.length > 0 ? (
                    <div className="booking-basket">
                      <div className="booking-basket__title">
                        <p>{__('booking.basket.title.basket-certificate')}</p>
                      </div>
                      <div className="booking-basket__inside">
                        <div className="booking-basket-list">
                          {loaded ? (
                            data?.certificate?.map((certificates) => (
                              <div className="booking-basket-list__item" key={certificates.id}>
                                <div className="booking-basket-list__item-photo">
                                  <img src={data?.service?.image ? data?.service?.image : noImage} alt="" />
                                </div>
                                <div className="booking-basket-list__item-info">
                                  <div className="booking-basket-list__item-title">
                                    <p>{certificates.title}</p>
                                  </div>
                                  <div className="booking-basket-list__item-amount">
                                    <p>{certificates.amount}</p>
                                  </div>
                                  <div className="booking-basket-list__actions">
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      variant="contained"
                                      onClick={() => handleItemDelete(certificates.id)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : (
                  <CircularProgress />
                )}
                <div className="booking-basket-total-amount">
                  <p>
                    {__('booking.basket.title.total-amount')}
                    <span> {totalAmount.toFixed(2)}</span>
                  </p>
                </div>
              </div>
              <div className="booking-form">
                <div className="booking-form__title">
                  <p>{__('booking.form.title.form')}</p>
                </div>
                <div className="booking-form__inside">
                  {
                    <UserDataBlock
                      user={user}
                      data={booking}
                      setData={setBooking}
                      onChange={handleChange}
                      validationStatus={validationStatus}
                      setValidationStatus={setValidationStatus}
                      isOtherUser={true}
                      __={__}>
                      <TextField
                        fullWidth
                        value={booking.email}
                        name="email"
                        label={__('booking.input.email')}
                        onChange={handleChange('email')}
                      />
                    </UserDataBlock>
                  }
                </div>
              </div>

              <div className="booking-action active">
                <div className="booking-action-inside">
                  <div className="booking-action-checkbox">
                    <label>
                      <Checkbox sx={{ p: 0 }} defaultChecked disabled />
                      <p>
                        {__('booking.сheckbox.text')}
                        <Link to="/"> {__('booking.сheckbox.text2')}</Link>.
                      </p>
                    </label>
                  </div>
                  <button className="button button--width100p" onClick={handleCreate}>
                    <p>{__('booking.basket.button.end')}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      {/* may be comment */}

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <div className="booking-popup">
            <div className="booking-popup-title">
              <p>{__('booking.popup.basket.title')}</p>
            </div>
            <div className="booking-popup-actions">
              <button className="button button--width100p" onClick={handleMoreButtonClick}>
                <p>{__('booking.popup.button.more_buy')}</p>
              </button>
              {user === null ? (
                <>
                  <button className="button button--width100p" onClick={handleCabinetButtonClick}>
                    <p>{__('booking.popup.button.cabinet')}</p>
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};
