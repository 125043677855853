import { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';

import { getTurnovers, getInventoryItems } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import {
  FilterDate,
  FilterSelect,
  SearchItem,
  TableAction,
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
  FilterWithSearchDropdown
} from './../../common/TableComponent';

import { getRowCellsData } from './getRowCellsData';
import dayjs from 'dayjs';

export const Turnovers = () => {
  const { __ } = useTranslation();
  const [{ loaded, data, orderTypes }, setData] = useState({
    loaded: false,
    data: [],
    orderTypes: []
  });

  const [sortOption, setSortOption] = useState('');
  const [sortDirection, setSortDirection] = useState(1);

  const [searchDoc, setSearchDoc] = useState('');
  const [searchInvItemId, setSearchInvItemId] = useState(null);

  const [orderTypeFilter, setOrderTypeFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getTurnovers(
          sortOption,
          sortDirection,
          searchDoc,
          searchInvItemId,
          orderTypeFilter,
          dayjs(dateFilter).format('YYYY-MM-DD'),
          currentPage,
          itemsPerPage
        );
        setData({
          loaded: true,
          data: response.data,
          orderTypes: [
            { value: 'incoming', title: 'Прибуткова' }, 
            { value: 'outcoming', title: 'Розхідна' }
          ]
        });
        setTotalPages(response?.meta.last_page);
      } catch (error) {
        console.warn('Turnovers fetch error: ', error);
      }
    };
    fetchData();
  }, [
    sortOption,
    sortDirection,
    searchDoc,
    searchInvItemId,
    orderTypeFilter,
    dateFilter,
    currentPage,
    itemsPerPage
  ]);

  return (
    <TableMain
      title={'Оборотна відомість'}
      getRowCellsData={getRowCellsData}
      sortOption={sortOption}
      setSortOption={setSortOption}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      __={__}>
      <TableAction className="table-action">
        <div className="table-action__block">
          <SearchItem
            label={'Документ'}
            value={searchDoc}
            handler={(e) => setSearchDoc(e.target.value)}
          />

          <FilterWithSearchDropdown
            id="inventory-items-search"
            label="ТМЦ"
            action={{
              f: getInventoryItems,
              searchArgPos: 3
            }}
            optionItem={{
              key: 'title',
              textKeyArr: ['title', 'cost', 'description']
            }}
            onSelected={(selectedItem) => setSearchInvItemId(selectedItem?.id || null)}
          />

          <FilterSelect
            inputLabel={'Тип операції'}
            selectLabel={'Тип операції'}
            selectName="order-type-select-filter"
            selectValue={orderTypeFilter}
            menuItemDefaultText="Всі"
            selectHandler={(e) => setOrderTypeFilter(e.target.value)}>
            {orderTypes &&
              orderTypes.map((orderType, index) => (
                <MenuItem key={index} value={orderType.value}>
                  {orderType.title}
                </MenuItem>
              ))}
          </FilterSelect>

          <FilterDate date={dateFilter} setDate={setDateFilter} />
        </div>
      </TableAction>

      <TableBlock __class="table--turnovers">
        <TableHeader />
        <TableBody loaded={loaded}>
          {data.map((turnoversItem) => (
            <TrBody key={turnoversItem.id} data={turnoversItem} />
          ))}
        </TableBody>
      </TableBlock>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </TableMain>
  );
};
