import { timeFormat } from "../utils/timeFormat";

export const EmptySlot = ({styles, eventInfo, extendedClassName}) => {
  const { timeText, event: { title } } = eventInfo;
  const { slot, slot_empty, header, content } = styles;
  const extClassName = styles[extendedClassName];

  

  return (
    <div className={ `${slot} ${slot_empty}${extendedClassName ? ' ' + extClassName : ' '}` }>
      <div className={ header }>
        <span>{ timeFormat(timeText) }</span>
      </div>
      <div className={ content }>
        
      </div>
    </div>
  );
};
