import { useEffect, useState } from 'react';
import { getUnitId, updateUnit } from '../../../actions/units';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { UnitsForm } from './UnitsForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';

export const UnitsEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/units');
  };

  const [loaded, setLoaded] = useState(false);

  const [unit, setUnit] = useState({
    is_active: true,
    locale: {
      en: {
        title: '',
        description: ''
      },
      ru: {
        title: '',
        description: ''
      },
      uk: {
        title: '',
        description: ''
      }
    }
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' }
    }
  });

  const validateForm = () => {
    let isValid = true;

    Object.keys(unit.locale).forEach((locale) => {
      const title = unit.locale[locale].title;
      const isTitleValid = title.trim() !== '';

      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        [locale]: {
          ...prevValidationStatus[locale],
          title: {
            isValid: isTitleValid,
            errorMessage: isTitleValid ? '' : `${__('units.form.title.error')}`
          }
        }
      }));

      if (!isTitleValid) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('units.form.title.error'),
    setUnit,
    setValidationStatus
  );

  const handleUpdate = () => {
    const isValid = validateForm();
    const sendingData = {...unit};
    if (isValid) {
      sendingData.locale = JSON.stringify(sendingData.locale);
      updateUnit(params.id, sendingData).then((res) => {
        navigate('/units');
      });
    }
  };

  useEffect(() => {
    getUnitId(params.id).then((res) => {
      setUnit(res.data.data);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('units.form.title-edit')}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      closeBtn
    >
      <UnitsForm
        unit={unit}
        setUnit={setUnit}
        action={handleUpdate}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('units.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
