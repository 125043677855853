import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, Select } from '@mui/material';

import { changeLocale } from '../../../actions';

export const LocaleSwitch = () => {
  const dispatch = useDispatch();
  const { locale } = useSelector((state) => state.localization);

  const changeLocalization = (event) => {
    dispatch(changeLocale(event.target.value));
  };

  return (
    <>
      <Select
        value={locale}
        label="Localization"
        onChange={changeLocalization}
        sx={{ '.MuiSelect-select': { minHeight: 'auto', p: '12.5px 16px' } }}>
        <MenuItem value="en">En</MenuItem>
        <MenuItem value="ru">Ru</MenuItem>
        <MenuItem value="uk">Uk</MenuItem>
      </Select>
    </>
  );
};
