import { UserSelectComponent } from './../../../common';
import { useTranslation } from './../../../../helpers/translate';
import { ButtonSend, InputField, SelectField } from './../../../common/FormsComponent';
import { useEffect, useState } from 'react';

export const ClientForm = ({
  client,
  statuses,
  groups,
  action,
  handleChange,
  ctaButtonText,
  validationStatus,
  formType,
}) => {
  const { __ } = useTranslation();
  const [user, setUser] = useState({});

  useEffect(() => {
    client.user_id = user?.user?.id;
    client.user = user;
  }, [user]);

  return (
    <>
      {
        formType === 'create' &&
          <UserSelectComponent 
            data={ user } 
            dataChangeHandler={ setUser }
          />
      }
      
      <InputField
        type="number"
        value={client?.discount_percent}
        field="discount_percent"
        label="Знижка % *"
        onChange={ handleChange }
        validationStatus={ validationStatus } 
      />

      <SelectField
        field="client_status_id"
        label="Статус"
        data={ statuses }
        value={ client?.client_status_id || '' }
        onChange={ handleChange }
        itemInfo={{value: 'id', key: 'id', content: 'title',}}
        validationStatus={ validationStatus } 
      />

      <SelectField
        field="client_group_id"
        label="Група"
        data={ groups }
        value={ client?.client_group_id || '' }
        onChange={ handleChange }
        itemInfo={{value: 'id', key: 'id', content: 'title',}}
        validationStatus={ validationStatus } 
      />

      <ButtonSend onClick={action} text={ctaButtonText} />
    </>
  );
};