import { useEffect, useState } from 'react';

import {
  getSeasonTicketsCategoriesId,
  updateSeasonTicketsCategories
} from '../../../actions/seasonTicketsCategories';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { SeasonTicketsCategoriesForm } from './SeasonTicketsCategoriesForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange, localeModel } from './../../common/FormsComponent/LocaleTabs';

export const SeasonTicketsCategoriesEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/season-tickets-categories');
  };

  const [loaded, setLoaded] = useState(false);

  const [seasonTicketsCategories, setSeasonTicketsCategories] = useState({
    is_active: true,
    locale: { ...localeModel }
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' },
      description: { isValid: true, errorMessage: '' }
    }
  });

  const validateForm = () => {
    let isValid = true;

    ['uk', 'ru', 'en'].forEach((locale) => {
      const titleValue = seasonTicketsCategories?.locale[locale]?.title || '';
      const descriptionValue = seasonTicketsCategories?.locale[locale]?.description || '';

      if (titleValue.trim().length === 0) {
        isValid = false;

        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            title: {
              isValid: false,
              errorMessage: `${__('seasonTicketsCategories.form.title.error')}`
            }
          }
        }));
      }

      if (descriptionValue.trim().length === 0) {
        isValid = false;

        setValidationStatus((prevValidationStatus) => ({
          ...prevValidationStatus,
          [locale]: {
            ...prevValidationStatus[locale],
            description: {
              isValid: false,
              errorMessage: `${__('seasonTicketsCategories.form.description.error')}`
            }
          }
        }));
      }
    });

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('seasonTicketsCategories.form.title.error'),
    setSeasonTicketsCategories,
    setValidationStatus
  );

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    setSeasonTicketsCategories({
      ...seasonTicketsCategories,
      [name]: value
    });
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      updateSeasonTicketsCategories(params.id, seasonTicketsCategories).then((res) => {
        navigate('/season-tickets-categories');
      });
    }
  };

  useEffect(() => {
    getSeasonTicketsCategoriesId(params.id).then((res) => {
      setSeasonTicketsCategories(res.data.data);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('certificateCategories.form.title-edit')}
      open={true}
      onClose={toggleOpened}
      loaded={loaded}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <SeasonTicketsCategoriesForm
        seasonTicketsCategories={seasonTicketsCategories}
        setSeasonTicketsCategories={setSeasonTicketsCategories}
        action={handleUpdate}
        handleLocalizationChange={handleLocalizationChange}
        handleChange={handleChange}
        ctaButtonText={__('seasonTicketsCategories.form.button')}
        validationStatus={validationStatus}
        setValidationStatus={setValidationStatus}
      />
    </ModalForm>
  );
};
