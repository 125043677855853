const services = {
    uk: {
        "services.page.title": "Послуги",
        // table
        "services.table.title": "Найменування",
        "services.table.description": "Опис",
        "services.table.service_category_id": "Категорія",
        "services.table.model_type": "Напрямок",
        "services.table.unit_id": "Одиниця виміровання",
        "services.table.amount": "Ціна",
        "services.table.available_for_online": "Доступно для запису онлайн",
        "services.table.is_active": "Активний/Неактивний",
        "services.table.actions": "Дії",
        "services.table.title-create": "Створити послугу",
        "services.table.button": "Зберегти",
        // table end

        //filter
        "services.filter.title": "Найменування",
        //filter end

        //form
        "services.form.title-create": "Створення сервіса",
        "services.form.title-edit": "Редагування сервіса",
        "services.form.title-copy": "Копіювання сервіса",
        "services.form.submit-btn": "Зберегти",

        "services.input.service_category_id": "Категорії",
        "services.input.model_type": "Напрямок",
        "services.input.unit_id": "Одиниці виміровання",
        "services.input.amount": "Ціна",
        "services.input.available_for_online": "Доступно для запису онлайн",
        "services.input.is_active": "Активний/Неактивний",

        // form value
        "services.form.active_checkbox": "Активний",
        "services.form.inactive_checkbox": "Неактивний",
        "services.form.online_checkbox_true": "Так",
        "services.form.online_checkbox_false": "Ні",

        //form error
        "services.form.title.error": "*Поле є обов'язковим",
        "services.form.service_category.error": "*Поле є обов'язковим",
        "services.form.unit.error": "*Поле є обов'язковим",
        "services.form.amount.error": "*Поле є обов'язковим, в поле можна водити лише - 0-9.",
        //form end
    },
    ru: {
        "services.page.title": "Услуги",
        "services.table.title": "Наименование",
        "services.table.description": "Описание",
        "services.table.service_category_id": "Категория",
        "services.table.model_type": "Напрямок",
        "services.table.unit_id": "Единица измерения",
        "services.table.amount": "Цена",
        "services.table.available_for_online": "Доступно для записи онлайн",
        "services.table.is_active": "Активный/Неактивный",
        "services.table.actions": "Действия",
        "services.table.title-create": "Создать услугу",
        "services.table.button": "Сохранить",
        "services.filter.title": "Наименование",

        // form
        "services.form.title-create": "Создание сервиса",
        "services.form.title-edit": "Редактирование сервиса",
        "services.form.title-copy": "Копирование сервиса",
        "services.form.submit-btn": "Сохранить",

        "services.input.service_category_id": "Категории",
        "services.input.model_type": "Напрямок",
        "services.input.unit_id": "Единицы измерения",
        "services.input.amount": "Цена",
        "services.input.available_for_online": "Доступно для записи онлайн",
        "services.input.is_active": "Активный/Неактивный",
        "services.form.title.error": "*Поле обязательно для заполнения",
        "services.form.service_category.error": "*Поле обязательно для заполнения",
        "services.form.unit.error": "*Поле обязательно для заполнения",
        "services.form.amount.error": "*Поле обязательно для заполнения, в поле можно вводить только - 0-9.",

        // form value
        "services.form.active_checkbox": "Активний",
        "services.form.inactive_checkbox": "Неактивний",
        "services.form.online_checkbox_true": "Да",
        "services.form.online_checkbox_false": "Нет",
    },
    en: {
        "services.page.title": "Services",
        "services.table.title": "Title",
        "services.table.description": "Description",
        "services.table.service_category_id": "Category",
        "services.table.model_type": "Direction",
        "services.table.unit_id": "Unit of Measurement",
        "services.table.amount": "Price",
        "services.table.available_for_online": "Available for Online Booking",
        "services.table.is_active": "Active/Inactive",
        "services.table.actions": "Actions",
        "services.table.title-create": "Create Service",
        "services.table.button": "Save",
        "services.filter.title": "Title",

        "services.input.service_category_id": "Categories",
        "services.input.model_type": "Direction",
        "services.input.unit_id": "Units of Measurement",
        "services.input.amount": "Price",
        "services.input.available_for_online": "Available for Online Booking",
        "services.input.is_active": "Active/Inactive",

        // form
        "services.form.title-create": "Service create",
        "services.form.title-edit": "Service edit",
        "services.form.title-copy": "Service copy",
        "services.form.submit-btn": "Save",
        "services.form.title.error": "*This field is required",
        "services.form.service_category.error": "*This field is required",
        "services.form.unit.error": "*This field is required",
        "services.form.amount.error": "*This field is required, and only numbers 0-9 are allowed.",


        // form value
        "services.form.active_checkbox": "Active",
        "services.form.inactive_checkbox": "Inactive",
        "services.form.online_checkbox_true": "Yes",
        "services.form.online_checkbox_false": "No",
    }

};

export default services;
