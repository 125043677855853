const persons = {
    uk: {
        "persons.page.title": "Особи",
        "persons.table.name": "Прізвище, Ім'я, по-батькові",
        "persons.table.gender": "Стать",
        "persons.table.phone": "Телефон",
        "persons.table.email": "Email",
        "persons.table.registered_at": "Дата/час реєстрації",
        "persons.table.date_of_birth": "Дата народження",
        "persons.table.passport_data": "Паспорт",
        "persons.table.individual_taxpayer_number": " ІПН",
        "persons.table.address": "Адреса",
        "persons.table.actions": "Дії",

        "persons.filter.phone": "Введіть номер телефону",
    },
    ru: {
        "persons.page.title": "Люди",
        "persons.table.name": "Фамилия, Имя, Отчество",
        "persons.table.gender": "Пол",
        "persons.table.phone": "Телефон",
        "persons.table.email": "Email",
        "persons.table.registered_at": "Дата/время регистрации",
        "persons.table.date_of_birth": "Дата рождения",
        "persons.table.passport_data": "Паспорт",
        "persons.table.individual_taxpayer_number": "ИНН",
        "persons.table.address": "Адрес",
        "persons.table.actions": "Действия",
        "persons.filter.phone": "Введите номер телефона",
    },
    en: {
        "persons.page.title": "Persons",
        "persons.table.name": "Last Name, First Name, Patronymic",
        "persons.table.gender": "Gender",
        "persons.table.phone": "Phone",
        "persons.table.email": "Email",
        "persons.table.registered_at": "Registration Date/Time",
        "persons.table.date_of_birth": "Date of Birth",
        "persons.table.passport_data": "Passport",
        "persons.table.individual_taxpayer_number": "ITN",
        "persons.table.address": "Address",
        "persons.table.actions": "Actions",
        "persons.filter.phone": "Enter phone number",
    },

};

export default persons;
