import { Route, Routes } from 'react-router-dom';

import {
  CertificatesList,
  BookingServices,
  BookingForm,
  BookingSeasonTickets,
  BookingCertificatesTickets,
  BookingBasket,
  BookingHorse,
  BookingHorseTime,
} from './../../../pages';

import { Layout } from './../../../common';
import {
  PersonalData,
  PersonalSeasonTickets,
  PersonalVisits,
  PersonalSertificates,
  PersonalHistory
} from './../../../pages/Cabinet';
import { ExternalSuccessPaid } from './../../Payment';

const ExternalRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<PersonalVisits />} />

        <Route path="payment/success" element={<ExternalSuccessPaid />} />

        {/* history */}
        <Route path="history" element={<PersonalHistory />} />

        {/* booking */}
        <Route path="booking">
          <Route index element={<BookingServices />} />
          <Route path="services" element={<BookingServices />} />
          <Route path="services/:selectedServiceId" element={<BookingHorse />} />
          <Route
            path="services/:selectedServiceId/horse/:selectedHorseId"
            element={<BookingHorseTime />}
          />
          <Route
            path="services/:selectedServiceId/horse/:selectedHorseId/time/:selectedTimeId"
            element={<BookingForm />}
          />
          <Route path="season-tickets" element={<BookingSeasonTickets />} />
          <Route path="certificates" element={<BookingCertificatesTickets />} />
          <Route path="booking-basket" element={<BookingBasket />} />
        </Route>

        {/* personal-data */}
        <Route path="personal-data" element={<PersonalData />} />

        {/* visits */}
        <Route path="visits">
          <Route index element={<PersonalVisits />} />
        </Route>
        <Route path="visits/:visitId/payment" element={<PersonalVisits />} />

        {/* sertificates */}
        <Route path="sertificates">
          <Route index element={<PersonalSertificates />} />
        </Route>

        {/* season-tickets */}
        <Route path="season-tickets">
          <Route index element={<PersonalSeasonTickets />} />
        </Route>
      </Route>

      <Route path="*" element={<Layout />}>
        <Route path="*" element={<PersonalVisits />} />
      </Route>
    </Routes>
  );
};

export default ExternalRoutes;
