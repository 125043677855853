import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { useEffect, useState } from 'react';
import { getHorsesServices, getModelsServices } from './../../../../../../actions';

export const SelectService = ({
  visitServices,
  setVisitServices,
  validVisit,
  selectedHorse,
  setSelectedHorse,
  visitId,
  modelType,
}) => {
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    if (!visitId) {
      (async () => {
        const res = await getModelsServices(modelType);
        const services = res?.data?.data;

        selectedHorse?.services &&
          // setServiceOptions(
          //   selectedHorse?.services.filter(service => {
          //     const presentService = services.find(item => item.id === service.id);
          //     return presentService;
          //   })
          // )
          setServiceOptions(
            services.filter(service => {
              const presentService = selectedHorse?.services.find(item => item.id === service.id);
              return presentService;
            })
          )
      })()
      return;
    }

    (async () => {
      const res = await getModelsServices(modelType);
      const services = res?.data?.data;
      setServiceOptions(services);
    })();
  }, [selectedHorse, visitServices]);

  return (
    <Autocomplete
      disableClearable
      id="services"
      sx={{ width: '100%', mt: 3, mb: 2, mr: 2 }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={visitServices}
      onChange={(_, newValue) => {
        setVisitServices(newValue);
      }}
      getOptionLabel={(item) => {
        return item?.service_title || item?.title} 
      }
      options={serviceOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Виберіть послугу"
          required
          InputLabelProps={{ shrink: true }}
          error={!validVisit.visit_services}
        />
      )}
    />
    
  );
};
