import { localeModel } from "./localeModel";

const validateObj = { isValid: true, errorMessage: '' };

export const getValidationLocaleModel = (requiredFields) => {
  const locales = Object.keys(localeModel);
  const validationLocaleModel = {};

  locales.forEach(locale => {
    const fields = {};
    requiredFields.forEach(field => fields[field] = validateObj);

    validationLocaleModel[locale] = {...fields};
  });

  return validationLocaleModel;
}