import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Button, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';

export const CertificateCategoriesForm = ({
  certificateCategories,
  handleLocalizationChange,
  action,
  ctaButtonText
}) => {
  const { locale } = useSelector((state) => state.localization);
  const [tabLocale, setTabLocale] = useState(locale);

  const handleLocale = (event, new_locale) => setTabLocale(new_locale);

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={tabLocale}
        onChange={handleLocale}
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        <ToggleButton
          value="uk"
          sx={{
            '&.Mui-selected': { background: 'var(--main-color2)', color: '#fff' },
            '&.Mui-selected:hover': { background: 'var(--main-color2)', color: '#fff' },
            '&:hover': { color: 'var(--main-color2)' }
          }}>
          {' '}
          UK{' '}
        </ToggleButton>
        <ToggleButton
          value="ru"
          sx={{
            '&.Mui-selected': { background: 'var(--main-color2)', color: '#fff' },
            '&.Mui-selected:hover': { background: 'var(--main-color2)', color: '#fff' },
            '&:hover': { color: 'var(--main-color2)' }
          }}>
          {' '}
          RU{' '}
        </ToggleButton>
        <ToggleButton
          value="en"
          sx={{
            '&.Mui-selected': { background: 'var(--main-color2)', color: '#fff' },
            '&.Mui-selected:hover': { background: 'var(--main-color2)', color: '#fff' },
            '&:hover': { color: 'var(--main-color2)' }
          }}>
          {' '}
          EN{' '}
        </ToggleButton>
      </ToggleButtonGroup>
      {tabLocale === 'uk' && (
        <>
          <TextField
            fullWidth
            value={certificateCategories?.locale.uk?.title}
            onChange={handleLocalizationChange('uk')}
            name="title"
            label="Назва"
          />
          <TextField
            fullWidth
            value={certificateCategories?.locale.uk?.description}
            onChange={handleLocalizationChange('uk')}
            name="description"
            label="Опис"
          />
        </>
      )}
      {tabLocale === 'ru' && (
        <>
          <TextField
            fullWidth
            value={certificateCategories?.locale.ru?.title}
            onChange={handleLocalizationChange('ru')}
            name="title"
            label="Назва ру"
          />
          <TextField
            fullWidth
            value={certificateCategories?.locale.ru?.description}
            onChange={handleLocalizationChange('ru')}
            name="description"
            label="Опис ру"
          />
        </>
      )}
      {tabLocale === 'en' && (
        <>
          <TextField
            fullWidth
            value={certificateCategories?.locale.en?.title}
            onChange={handleLocalizationChange('en')}
            name="title"
            label="Назва Англ"
          />
          <TextField
            fullWidth
            value={certificateCategories?.locale.en?.description}
            onChange={handleLocalizationChange('en')}
            name="description"
            label="Опис Англ"
          />
        </>
      )}
      {/*
      <label htmlFor="component-simple">
        Статус
        <Checkbox name="is_active" value={certificateCategories?.is_active} onChange={handleChange('is_active')} />
      </label>*/}
      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
