import { getOrderTypes } from "./getOrderTypes";

export const formatDataForHistoryCollaps = (data) => {
  let returnedData = [];
  const isVisit = data && !!data.visit?.length;
  const isSeasonTickets = data && !!data.seasonTickets?.length;

  const types = getOrderTypes(data, 'STR', ',', true);

  if (isVisit) {
    const visitInfo = data.visit[0];
    const services = visitInfo.services;

    services.forEach(service => {
      const data = {
        type: '',
        service: '',
        dateVisit: '',
        dateStart: '',
        dateEnd: '',
        hoursQty: '',
      }

      data.type = types;
      data.service = service.service_title;
      data.dateVisit = visitInfo.date || '';
      data.hoursQty = 1;

      returnedData.push(data);
    });

  } else if (isSeasonTickets) {
    const seasonTickets = data.seasonTickets;

    seasonTickets.forEach(ticket => {
      const service = ticket.service;
      const data = {
        type: '',
        service: '',
        dateVisit: '',
        dateStart: '',
        dateEnd: '',
        hoursQty: '',
      }

      data.type = types;
      data.service = service.title;
      data.dateStart = ticket.date_start;
      data.dateEnd = ticket.date_end;
      data.hoursQty = ticket.quantity_hours;

      returnedData.push(data);
    });
  }

  return returnedData;
}