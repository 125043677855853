import dayjs from "dayjs";

export const getLogVerticalTableData = (dataItem, __) => {

  return getLogData(dataItem);

  // return [
  //   {
  //     th: 'Дата створення',
  //     td: dataItem && [dataItem.created_at, dataItem.created_at],
  //   },
  // ]
}

function getLogData(dataItem) {
  const dateKey = ['created_at', 'updated_at'];
  const data = [];
  const { attributes, old } = dataItem.changes;

  for (let key in attributes) {
    let newValue = attributes[key];
    let oldValue = old ? old[key] : null;

    const isEqual = newValue === oldValue;

    if (dateKey.includes(key)) {
      newValue = dayjs(newValue).format('DD-MM-YYYY hh:mm:ss');
      oldValue = !oldValue ? oldValue : dayjs(oldValue).format('DD-MM-YYYY hh:mm:ss');
    }
    
    const row = {
      th: key,
      td: !old ? newValue : [ oldValue, newValue ]
    }

    if (old && !isEqual) {
      row.__class = 'not-equal';
    }

    data.push(row);
  }

  return data;
}