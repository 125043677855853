import { useEffect, useState } from 'react';

import {
  getServiceCategoriesId,
  updateServiceCategories
} from '../../../actions/serviceCategories';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { ServiceCategoriesForm } from './ServiceCategoriesForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange, localeModel } from './../../common/FormsComponent/LocaleTabs';
import { getHandleChange } from './utils';

export const ServiceCategoriesEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/service-categories');
  };

  const [loaded, setLoaded] = useState(false);

  const [serviceCategories, setServiceCategories] = useState({
    is_active: true,
    locale: { ...localeModel },
    type: '',
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' }
    }
  });

  const validateForm = () => {
    let isValid = true;

    Object.keys(serviceCategories.locale).forEach((locale) => {
      const title = serviceCategories.locale[locale].title;
      const isTitleValid = title.trim() !== '';

      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        [locale]: {
          ...prevValidationStatus[locale],
          title: {
            isValid: isTitleValid,
            errorMessage: isTitleValid ? '' : `${__('serviceCategories.form.title.error')}`
          }
        }
      }));

      if (!isTitleValid) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleLocalizationChange = getHandleLocalizationChange(
    __('serviceCategories.form.title.error'),
    setServiceCategories,
    setValidationStatus
  );

  const handleChange = getHandleChange(setServiceCategories, setValidationStatus, __);

  const handleUpdate = () => {
    const isValid = validateForm();
    const sendingData = {...serviceCategories};
    if (isValid) {
      sendingData.locale = JSON.stringify(sendingData.locale);
      updateServiceCategories(params.id, sendingData).then((res) => {
        navigate('/service-categories');
      });
    }
  };

  useEffect(() => {
    getServiceCategoriesId(params.id).then((res) => {
      setServiceCategories(res.data.data);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={__('serviceCategories.form.title-edit')}
      open={true}
      onClose={toggleOpened}
      loaded={loaded}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <ServiceCategoriesForm
        serviceCategories={serviceCategories}
        setServiceCategories={setServiceCategories}
        handleChange={handleChange}
        action={handleUpdate}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('serviceCategories.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
