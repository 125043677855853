import { getPersonalVisits, getPersonalVisitsStatuses } from "./../../../../../actions/cabinet";

export const fetchData = async (sortOption, sortDirection, visitStatusFilter, currentPage, setData) => {
  try {
    const { data: visits } = await getPersonalVisits(sortOption, sortDirection, visitStatusFilter, currentPage);
    const { data: statuses } = await getPersonalVisitsStatuses();
    const meta = visits.meta;

    setData({
      loaded: true,
      data: {
        visits: visits.data.map(visit => ({visit, statuses: statuses.data})),
        statuses: statuses.data,
        meta,
      }
    });
  }
  catch (error) {
    console.warn('PersonalVisits fetch error: ', error);
  }
}