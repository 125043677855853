export const getOrderServices = (obj, returnType='STR', sep=',') => {
  const visit = obj?.visit?.length ? obj?.visit[0] : null;
  const seasonTickets = obj.seasonTickets || [];
  const isSeasonTickets = seasonTickets?.length;

  let allServices = [];

  if (visit) {
    const visitService = visit.services[0].service_title;
    allServices = [...allServices, visitService];
  }

  if (isSeasonTickets) {
    const seasonTicketServices = seasonTickets.map(seasonTicket => seasonTicket.service.title)
    allServices = [...allServices, ...seasonTicketServices];
  }

  if (returnType === 'STR') {
    return allServices.join(sep);
  } else if (returnType === 'ARRAY') {
    return allServices;
  }
}