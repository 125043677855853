import { useEffect, useState } from 'react';
import { createPage, getPageId, updatePage, editPage } from './../../../actions';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { DataForm } from './DataForm';
import { ModalForm, serverErrorHandler } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';

import { getFormData } from './../../library';

import { getDataCloneForSend, getHandleChange, makeSlug } from './utils';
import { dataModel } from './dataModels';
import { validateForm, validationModel, validationData } from './validation';

export const PagesEdit = ({ type }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/info-pages');
  };

  const [loaded, setLoaded] = useState(false);

  const [pages, setPages] = useState(dataModel);
  const [validationStatus, setValidationStatus] = useState(validationData);
  const [errorMessages, setErrorMessages] = useState([]);

  const formTitle = type === 'create' ? __('pages.form.title-create') : __('pages.form.title-edit')

  const handleLocalizationChange = getHandleLocalizationChange(
    __('pages.form.title.error'),
    setPages,
    setValidationStatus
  );
  const handleChange = getHandleChange(pages, setPages, setValidationStatus);

  const actionHandle = () => {
    const isValid = validateForm(
      pages,
      'pages',
      validationModel,
      setValidationStatus,
      __,
      true,
    );

    if (isValid) {
      const dataClone = getDataCloneForSend(pages);
      let method = 'POST';
      let action = createPage.bind(null, getFormData(dataClone, method, true));

      if (type === 'edit') {
        method = 'PUT';
        action = updatePage.bind(null, params.id, getFormData(dataClone, method, true));
      }

      try {
        action().then((res) => {
          if (res?.data?.success) {
            navigate('/info-pages');
          }

          
          serverErrorHandler(res?.response?.data?.errors, setErrorMessages);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (type === 'create') {
      setLoaded(true);
      return
    }

    editPage(params.id).then((res) => {
      const fetchData = res.data.data;
      setPages(fetchData);
      setLoaded(true);
    });
  }, []);

  return (
    <ModalForm
      title={formTitle}
      open={true}
      loaded={loaded}
      onClose={toggleOpened}
      closeBtn
    >
      <DataForm
        pages={pages}
        setPages={setPages}
        action={actionHandle}
        handleChange={handleChange}
        handleLocalizationChange={handleLocalizationChange}
        ctaButtonText={__('pages.form.button')}
        validationStatus={validationStatus}
      />
    </ModalForm>
  );
};
