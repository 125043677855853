import dayjs from "dayjs";

// common rules
export const ruleDateOfBirth = (data) => dayjs(data.date_of_birth, 'YYYY-MM-DD', true).isValid();
export const ruleStatus = (data) => data.status !== '';

export const ruleClientStatus = (data) => true;
export const ruleClientGroup = (data) => true;
export const ruleDiscountPercent = (data) => 
  data.discount_percent !== '' &&
  Number.isInteger(data.discount_percent) &&  
  data.discount_percent >= 0


// Horses
export const ruleHorsePassport = (horses) => horses.passport.length > 0;
export const ruleHorseCategory = (horses) => horses.horsCategories.length > 0;
export const ruleHorseServices = (horses) => horses.services.length > 0;