import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { ButtonLinkIcon } from '.';
import { useLocation } from 'react-router-dom';

export const ButtonBackward = ({ route, isState }) => {
  const { state } = useLocation();

  let to = isState && state ? state?.backUrl || route : route;

  return (
    <ButtonLinkIcon
      icon={<ArrowCircleLeftIcon fontSize="large" />}
      route={to}
      color="primary"
      sx={{ mb: '8px', ml: -1 }}
    />
  );
};
