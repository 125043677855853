import { getIsReadOnlyResource } from "src/configData/rolesConfig";
import { getSystemMode } from "./getSystemMode";
import { getSystemType } from "./getSystemType";
import { RESOURCES, ROLES, SYSTEM_MODES, SYSTEM_TYPES } from "src/constants";

export const dispatchSytemTypeAndMode = (dispatch, user) => {
  const systemType = getSystemType(user.service_types);
  const systemMode = getSystemMode(user.service_types);

  // check for main role coach
  const isReadOnly = user.role === ROLES.ADMIN.ROLE ? getIsReadOnlyResource(user.roles, RESOURCES.DASHBOARD) : false;

  dispatch({
    type: SYSTEM_TYPES.CHANGE_SYSTEM_TYPE,
    payload: isReadOnly ? SYSTEM_TYPES.SINGLE : systemType
  });

  dispatch({
    type: SYSTEM_MODES.CHANGE_MODE,
    payload: isReadOnly ? SYSTEM_MODES.COACH : systemMode
  });

  console.log('------dispatchSytemTypeAndMode---');
  console.log(user);
  console.log(systemType, systemMode, isReadOnly);
  console.log('=================================');
}