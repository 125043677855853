import React from 'react';

export const CloseModalBtn = ({ onClose }) => {
  return (
    <div className="close-modal" onClick={onClose}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
          fill="black"
        />
      </svg>
    </div>
  );
};