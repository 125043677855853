import { combineReducers } from 'redux';

import stuffReducer from './developer/stuffReducer';
import authReducer from './auth/authReducer';
import calendarReducer from './calendar/calendarReducer';
import localeReducer from './locale/localeReducer';
import modeReducer from './mode/modeReducer';
import modeTypeReducer from './modeType/modeReducer';


const rootReducer = combineReducers({
  localization: localeReducer,
  auth: authReducer,
  stuff: stuffReducer,
  calendar: calendarReducer,
  mode: modeReducer,
  systemType: modeTypeReducer,
});

export default rootReducer;
