import { useState } from 'react';
import { useDispatch } from 'react-redux';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { ToastContainer } from 'react-toastify';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from '../../../helpers/translate';

import styles from './auth.module.scss';
import { login } from '../../../actions/auth';
import { ROLES } from './../../../constants';
import { ModalStyle } from './styles/styles';
import { PhoneInputBlock } from './uiComponents';
import { phoneChangeHandler } from './utils';
import { serverErrorHandler } from './utils/serverErrorHandler';

const serverErrorMessages = {
  login_phone: ['login phone data is invalid'],
  login_pwd: ['login password data is invalid']
};

export function Auth() {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const { pathname: path } = useLocation();
  const [role, setRole] = useState(path === ROLES.ADMIN.PATH ? ROLES.ADMIN.ROLE : ROLES.USER.PATH);

  const dispatch = useDispatch();
  const [loginData, setLogin] = useState({
    phone_number: '+380 67 765-43-55'
  });
  const [passwordData, setPassword] = useState({
    password: 'password'
  });
  const [open, setOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const errorHandlerFromServer = () => {
    serverErrorHandler(serverErrorMessages, setErrorMessages);
  };

  const submitData = () => {
    if (!!loginData.phone_number.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i)) {
      dispatch(login(loginData.phone_number, passwordData.password, role, errorHandlerFromServer));
      navigate('', { replace: true });
    }
  };

  const onChangePhone = (e) => phoneChangeHandler(e, loginData, setLogin, setErrorMessages);

  const onChangePassword = (e) => {
    setPassword({
      ...passwordData,
      password: e.target.value
    });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ToastContainer />
      <div className={styles.authorization}>
        <div className="inside">
          <div className={styles['authorization-form']}>
            <div className={styles['authorization-title']}>
              <p>Вхід</p>
            </div>
            <PhoneInputBlock
              isError={!!Object.keys(errorMessages).length}
              value={loginData.phone_number}
              setValue={onChangePhone}
              errorText={__('general.authorization.phone-error')}
            />
            <TextField
              name="password"
              type="password"
              label="Ваш пароль"
              value={passwordData.password}
              onChange={onChangePassword}
              error={!!Object.keys(errorMessages).length}
              helperText={
                !!Object.keys(errorMessages).length && __('general.authorization.password-error')
              }
            />

            <Button
              variant="contained"
              type="submit"
              onClick={submitData}
              sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
              Увійти
            </Button>

            <div className={styles['authorization-form__action']}>
              <Button
                className="link"
                onClick={handleOpen}
                sx={{ p: 0, textTransform: 'none', fontSize: 16, lineHeight: 1 }}>
                Забули пароль?
              </Button>
              {/* <Link to="/registration" className="link">
                Немає акаунта?
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <div className="close-modal" onClick={handleClose}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={styles['reset-password']}>
            <div className={styles['authorization-title']}>
              <p>Відновлення паролю</p>
            </div>
            <TextField name="E-mail" label="Введіть E-mail" />
            <Button
              variant="contained"
              type="submit"
              sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
              Відновити пароль
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
