export const getRowCellsData = (dataItem, __) => {

  return {
    title: {
      th: __('pages.table.title'),
      td: dataItem && dataItem.title,
      sort: null,
    },
    slug: {
      th: __('pages.table.slug'),
      td: dataItem && dataItem.slug,
      sort: null,
    },
    isActive: {
      th: __('pages.table.is_active'),
      td: dataItem && 
            dataItem.is_active
              ? __('pages.table.status_active')
              : __('pages.table.status_inactive'),
      sort: null,
    },
    actions: {
      th: __('pages.table.actions'),
      td: null,
      sort: null,
      __class: 'td--right',
    }
  }
}