export const formatDataForHistoryCollaps = (data) => {
  let returnedData = [];
  const item = data && data?.items[0]
  const isVisit = item && !!item.hors;

  const isSeasonTickets = item && !!item.type;
  let formatData = {
        type: '',
        service: '',
        horse: '',
        dateVisit: '',
        seasonTicketTitle: '',
        dateStart: '',
        dateEnd: '',
        hoursQty: '',
        validityPeriod: '',
      };

  if (isVisit) {
    const visitInfo = item;
    const services = visitInfo.services;

    services.forEach(service => {
      const data = {...formatData}; 

      data.type = 'visit';
      data.service = service.service_title;
      data.horse = visitInfo.hors;
      data.dateVisit = visitInfo.date || '';
      data.hoursQty = 1;

      returnedData.push(data);
    });

  } else if (isSeasonTickets) {
    const seasonTickets = item;
    const data = {...formatData}; 

    data.type = seasonTickets.type;
    data.seasonTicketTitle = seasonTickets.title;
    data.service = seasonTickets.service_title;
    data.dateStart = seasonTickets.date_start;
    data.dateEnd = seasonTickets.date_end;
    data.hoursQty = seasonTickets.quantity_hours;
    data.validityPeriod = seasonTickets.validity_period;

    returnedData.push(data);
  }

  return returnedData;
}