import dayjs from "dayjs";
import { timeDateHasPassed } from "../../components/AddServiceForm/Utils";

const validateObj = {
  visitDate: checkVisitDate
}

export const validPaidParams = (validateProps) => {
  let errors = [];

  for(let param in validateObj) {
    const checkCallback = validateObj[param];
    const dataCheck = validateProps[param];
    const checkRes = checkCallback(dataCheck);
    if (checkRes) {
      errors.push(checkRes);
    }
  }

  return errors;
}

function checkVisitDate(visitDate) {
  if (!dayjs(visitDate).isValid()) {
    return 'Передані данні не є данними дати';
  }
  return timeDateHasPassed(visitDate)
    ? 'Час візиту вже минув'
    : '';
}