import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { UserSelectComponent } from './../../../UserSelectComponent';
import Statuses from './../Statuses';
import { NotifyBySms } from './../../../FormsComponent';

export default function VisitForm({ value, setValue, status, setStatus, validVisit, copy }) {
  const [checked, setChecked] = useState(false);
  const userData = {
    user: {
      user_id: value?.user_id,
      first_name: value?.first_name,
      last_name: value?.last_name,
      phone: value?.phoneCode + value?.phone
    }
  };

  const notifyHandleChange = (name) => (event) => {
    setValue({
      ...value,
      notifyBySms: event.target.value
    });
  };

  const userDataChangeHandler = (user) => {
    if (user) {
      setValue({
        ...value,
        ...user.user
      });
    }
  };

  useEffect(() => {
    setChecked(!!value.another_name);
  }, [value.another_name]);

  return (
    <>
      {!copy && (
        <Statuses status={status} setStatus={setStatus} value={value} setValue={setValue} />
      )}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '560px', maxWidth: '100%' },
          mt: 3,
          mb: 3,
          display: 'flex',
          alignItems: 'center'
        }}
        noValidate
        autoComplete="off">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 1,
            width: '560px',
            maxWidth: '100%'
          }}>
          <UserSelectComponent
            data={userData}
            dataChangeHandler={userDataChangeHandler}
            isLabel={true}
            isFullWidth={false}
          />

          <Box sx={{ mt: 3 }}>
            <b style={{ marginBottom: '12px', display: 'block' }}>Email</b>
            <TextField
              style={{ marginRight: 0, marginLeft: 0, width: '100%' }}
              value={value.email}
              onChange={({ target }) => setValue({ ...value, email: target.value })}
              label="Введіть email клієнта"
              InputLabelProps={{ shrink: true }}
              error={!validVisit.email}
            />
          </Box>

          <NotifyBySms
            title="Нагадування"
            value={value?.notifyBySm || 3}
            handler={notifyHandleChange}
            field="notifyBySms"
          />
        </Box>
      </Box>
    </>
  );
}
