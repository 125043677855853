import { AccordionSummary, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { ExpandMoreIcon } from "./../icons";

import { s } from './styles';

export const AccordionTooltip = ({ title, icon }) => {
  return (
    <Tooltip title={ title } placement="right" leaveDelay={50}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={ s.accordionSummary }
      >
        <ListItemIcon sx={ s.listItemIcon }>
          { icon }
        </ListItemIcon>
        <Typography>{ title }</Typography>
      </AccordionSummary>
    </Tooltip>
  );
};