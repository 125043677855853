import dayjs from "dayjs";

export const getDateValidOrder = (orderDateEnd) => {
  const now = dayjs();
  const dateEnd = dayjs(orderDateEnd);
  let dateDiff = 0;
  let isValidOrder = false;

  if (!dateEnd) {
    return isValidOrder;
  }

  dateDiff = dateEnd ? dateEnd.diff(now, 'day') : 0;
  isValidOrder = dateDiff >= 0 ? true : false;

  return isValidOrder;
}