import { useEffect, useState } from 'react';

import { Box } from '@mui/material';

import {
  getStocktaking,
  updateStocktaking,
  applyStocktaking,
  getStocktakingItems,
  updateStocktakingItem
} from './../../../actions';
import { useTranslation } from './../../../helpers/translate';
import {
  TableAction,
  TableBody,
  TrBody,
  TableMain,
  TableHeader,
  TableBlock,
  TablePagination,
  ButtonAction,
  ButtonBackward
} from '../../common/TableComponent';

import { getStocktakingItemsRowCellsData, getStocktakingRowCellsData } from './TablesData';
import { useLocation, useNavigate } from 'react-router-dom';

export const StocktakingItems = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { __ } = useTranslation();

  const [dataIsSaved, setDataIsSaved] = useState(false);

  const [{ stockTakingLoaded, stockTakingData }, setStockTakingData] = useState({
    stockTakingLoaded: false,
    stockTakingData: {}
  });
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const applyHandler = () => {
    applyStocktaking({ id: stockTakingData?.id });
    navigate('/stocktaking');
  };
  const saveChangeHandler = () => {
    if (stockTakingData?.id) {
      setDataIsSaved(true);
      updateStocktaking(stockTakingData?.id, {
        serial: stockTakingData?.serial,
        description: stockTakingData?.description
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: stocktakingResponse } = await getStocktaking(
          state.id,
          currentPage,
          itemsPerPage
        );
        setStockTakingData({
          stockTakingLoaded: true,
          stockTakingData: stocktakingResponse.data
        });

        const { data: response } = await getStocktakingItems(state.id, currentPage, itemsPerPage);
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response?.meta.last_page);
      } catch (error) {
        console.warn('Clients fetch error: ', error);
      }
    };
    fetchData();
  }, [dataIsSaved, currentPage, itemsPerPage]);

  return (
    <>
      <ButtonBackward route="/stocktaking" />
      <TableMain title={'Інвентаризація'} getRowCellsData={getStocktakingRowCellsData} __={__}>
        <TableBlock __class="table--stocktacking-edit">
          <TableHeader />
          <TableBody loaded={stockTakingLoaded}>
            {
              <TrBody key={stockTakingData.id} data={stockTakingData}>
                {!stockTakingData?.status && (
                  <Box sx={{ display: 'block' }}>
                    <ButtonAction
                      text="Зафіксувати зміни"
                      onClick={() => saveChangeHandler()}
                      sx={{ m: 1, display: 'block' }}
                    />
                    <ButtonAction
                      text="Коригувати"
                      onClick={() => applyHandler()}
                      sx={{ m: 1, display: 'block' }}
                      disabled={!dataIsSaved}
                    />
                  </Box>
                )}
              </TrBody>
            }
          </TableBody>
        </TableBlock>
      </TableMain>

      <TableMain title={'Данні по ТМЦ'} getRowCellsData={getStocktakingItemsRowCellsData} __={__}>
        <TableAction className="table-action">
          <div className="table-action__block"></div>
        </TableAction>

        <TableBlock __class="table--stocktacking-items">
          <TableHeader />
          <TableBody loaded={loaded}>
            {data.map((item) => {
              item.editable = {
                editActualAmountAction: updateStocktakingItem,
                editActualAmountCallback: setDataIsSaved,
                isApply: !!stockTakingData?.status
              };
              return <TrBody key={item.id} data={item} />;
            })}
          </TableBody>
        </TableBlock>

        <TablePagination
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </TableMain>
    </>
  );
};
