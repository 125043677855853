import { SYSTEM_TYPES } from '../../constants';
import config from '../../config'

const initState = {
  systemType: config.system_type_default
};

const modeTypeReducer = (state = initState, action) => {
  switch (action.type) {
    case SYSTEM_TYPES.CHANGE_SYSTEM_TYPE:
      return {
        ...state,
        systemType: action.payload
      };

    default:
      return state;
  }
};

export default modeTypeReducer;
