import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { timeDateHasPassed } from '../Utils';

export const TimeAndAmount = ({ visitServices, timeSlot }) => {
  const totalAmount = visitServices ? visitServices.amount : '0.00';
  const date = timeSlot?.date;
  const timeStart = date ? dayjs(date).format('HH-mm') : '';
  const timeTo = date ? dayjs(date).add(1, 'hour').format('HH-mm') : '';

  const timeHasPassed = timeDateHasPassed(date);
  const timeAlertStyle = (timeStart && timeHasPassed)
    ? { color: 'red' }
    : {}

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: 2,
        justifyContent: 'space-between'
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img alt="Банкнота" src="images/banknote.png" />
        <Typography ml={1} mr={1}>{totalAmount}</Typography>
        <img alt="Гривня" src="images/grn.png" />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AccessTimeIcon />
        <Typography ml={1} sx={ timeAlertStyle }>{ timeStart } : { timeTo }</Typography>
      </Box>
    </Box>
  );
};
