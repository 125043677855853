import dayjs from "dayjs"
import { getOrderTypes, getOrderServices, formatDataForHistoryCollaps } from "../utils";
import { getPersonalOrder } from "./../../../../actions/cabinet";

export const getRowCellsData = (dataItem, __) => {
  const orderTypes = dataItem && getOrderTypes(dataItem, 'ARRAY', '', true);
  const orderServices = dataItem && getOrderServices(dataItem);

  const isPaydOrData = dataItem && dataItem.paided_date
    ? dayjs(dataItem.paided_date).format('YYYY-MM-DD HH:mm')
    : 'не сплачено'

  return {
    number: {
      th: 'Номер',
      td: dataItem && dataItem.id,
      sort: null,
    },
    date: {
      th: 'Дата замовлення',
      td: dataItem && dayjs(dataItem.created_at).format('YYYY-MM-DD HH:mm'),
      sort: null,
    },
    datePaided: {
      th: 'Дата(статус) сплати ',
      td: dataItem && isPaydOrData,
      sort: null,
    },
    orderTypes: {
      th: 'Тип',
      td: dataItem && orderTypes.join(','),
      sort: null,
    },
    service: {
      th: 'Послуга',
      td: dataItem && orderServices,
      sort: null,
    },
    summ: {
      th: 'Сумма',
      td: dataItem && dataItem.amount_paid,
      sort: null,
    },
    
    action: {
      th: 'Сплатити',
      td: null,
      sort: null,
      __class: 'td--right'
    },

    __GET_COLLAPS_DATA_ACTION: dataItem && getPersonalOrder.bind(null, dataItem.id),
    __FORMAT_COLLAPS_DATA_CALLBACK: dataItem && formatDataForHistoryCollaps
  }
}