import { useState } from 'react';
import { Box, Modal } from '@mui/material';

import { createCertificateCategories } from '../../../actions/certificateCategories';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate } from 'react-router-dom';

import { CertificateCategoriesForm } from './CertificateCategoriesForm';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const CertificateCategoriesCreate = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/certificate-categories');
  };

  const [certificateCategories, setCertificateCategories] = useState({
    is_active: true,
    locale: {
      en: {
        title: '',
        description: ''
      },
      ru: {
        title: '',
        description: ''
      },
      uk: {
        title: '',
        description: ''
      }
    }
  });

  const handleLocalizationChange = (locale) => (event) => {
    const { name, value } = event.target;

    setCertificateCategories({
      ...certificateCategories,
      locale: {
        ...certificateCategories.locale,
        [locale]: {
          ...certificateCategories.locale[locale],
          [name]: value
        }
      }
    });
  };

  const handleCreate = () => {
    certificateCategories.locale = JSON.stringify(certificateCategories.locale);
    createCertificateCategories(certificateCategories).then((res) => {
      navigate('/certificate-categories');
    });
  };

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('certificateCategories.form.title-create')}</h3>
              </div>
              <CertificateCategoriesForm
                certificateCategories={certificateCategories}
                action={handleCreate}
                handleLocalizationChange={handleLocalizationChange}
                ctaButtonText={__('certificateCategories.form.button')}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
