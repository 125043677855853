import { useEffect, useState } from 'react';

import {
  Button,
  TextField,
  CircularProgress,
  IconButton
} from '@mui/material';

import { FilterWithSearchDropdown } from '../TableComponent';

import DeleteIcon from '@mui/icons-material/Delete';
import { getInventoryItems, getInventoryItemsAll } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const AddInventoryItems = ({ updateItemСount, itemsPush, type, disabled = false }) => {
  const { __ } = useTranslation();

  const [{ loaded, inventoryItems }, setData] = useState({
    loaded: false,
    inventoryItems: []
  });

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const inventoryData = inventoryItems.data || [];
  const [itemСount, setItemСount] = useState({});

  useEffect(() => {
    const updateItemCount = () => {
      const itemСount = itemsPush;
      setItemСount(itemСount);
    };

    updateItemCount();
  }, [itemsPush]);

  useEffect(() => {
    const addItemsFromItemsPush = () => {
      Object.entries(itemsPush).forEach(([itemId, itemData]) => {
        if (!selectedItems.some((item) => item.id === Number(itemId))) {
          const selectedItem = inventoryData.find((item) => item.id === Number(itemId));
          if (selectedItem) {
            const updatedSelectedItems = [...selectedItems, selectedItem];
            setSelectedItems(updatedSelectedItems);
          }

          // !!! DIMA UNKNOW FUNCTIONALY
          // updateItemСount({ ...itemСount });
        }
      });
    };
    addItemsFromItemsPush();
  }, [inventoryData, itemsPush, itemСount, selectedItems, updateItemСount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: inventoryItemsResponse } = await getInventoryItemsAll();
        setData({
          loaded: true,
          inventoryItems: inventoryItemsResponse
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleAddButtonClick = () => {
    if (selectedItemId !== null) {
      if (!selectedItems.some((item) => item.id === selectedItemId)) {
        const selectedItem = inventoryData.find((item) => item.id === selectedItemId);
        const updatedSelectedItems = [...selectedItems, selectedItem];

        setSelectedItems(updatedSelectedItems);
        setItemСount((prevСount) => ({
          ...prevСount,
          [selectedItemId]: {
            count: 1,
            ...prevСount[selectedItemId]
          }
        }));

        updateItemСount({
          ...itemСount,
          [selectedItemId]: {
            count: 1,
            ...itemСount[selectedItemId]
          }
        });
      }
    }
  };

  const handleDeleteButtonClick = (itemId) => {
    const selectedItem = selectedItems.find((item) => item.id === itemId);
    if (selectedItem) {
      const updatedSelectedItems = selectedItems.filter((item) => item.id !== itemId);
      setSelectedItems(updatedSelectedItems);
      delete itemСount[itemId];
    }
    updateItemСount({ ...itemСount });
  };

  const handleQuantityChange = (event, selectedItem) => {
    const newValue = parseFloat(event.target.value) || 0;
    const updatedItemСount = {
      ...itemСount,
      [selectedItem.id]: {
        ...itemСount[selectedItem.id],
        count: newValue
      }
    };
    setItemСount(updatedItemСount);
    updateItemСount(updatedItemСount);
  };

  const handleCounterClick = (selectedItem, change) => {
    const currentQuantity = itemСount[selectedItem.id]?.count || 0;
    const newQuantity = Math.max(currentQuantity + change, 0);
    const updatedItemСount = {
      ...itemСount,
      [selectedItem.id]: {
        ...itemСount[selectedItem.id],
        count: newQuantity
      }
    };

    setItemСount(updatedItemСount);
    updateItemСount(updatedItemСount);
  };

  const handlePriceChange = (event, selectedItem) => {
    const newValue = parseFloat(event.target.value) || 0;
    const updatedItemСount = {
      ...itemСount,
      [selectedItem.id]: {
        ...itemСount[selectedItem.id],
        price: newValue
      }
    };
    setItemСount(updatedItemСount);
    updateItemСount(updatedItemСount);
  };

  function calculateTotalPrice() {
    let totalPrice = 0;

    selectedItems.forEach((selectedItem) => {
      let price = type === 'incomings' 
        ? itemСount[selectedItem.id]?.price || 0
        : selectedItem.sale_price || 0;
      
      if (type === 'write-offs') {
        price = selectedItem.cost || 0;
      } else if (type === 'expenditureNorms') {
        price = selectedItem.cost || 0;
      }

      const count = itemСount[selectedItem.id]?.count || 0;
      const salePrice = parseFloat(price * count).toFixed(2);
      totalPrice += parseFloat(salePrice);

    });

    return totalPrice.toFixed(2);
  }

  const getItemElements = (type, selectedItem, itemCount, handlePriceChange) => {
    let element = <></>;

    if (type === 'incomings') {
      element = 
        <TextField
          disabled={ disabled }
          sx={{
            height: '50px',
            width: '120px',
            fontSize: 16,
            '.MuiInputBase-input': {
              height: '100%'
            },
            '. MuiFormLabel-root': {
              lineHeight: 'normal'
            }
          }}
          name="price"
          label="Ціна"
          value={itemCount[selectedItem.id]?.price || ''}
          onChange={(event) => handlePriceChange(event, selectedItem)}
        />
    } else if (type === 'outcomings') {
      element = selectedItem?.sale_price || '';
    } else if (type === 'write-offs') {
      element = selectedItem?.cost || '';
    } else if (type === 'expenditureNorms') {
      element = selectedItem?.cost || '';
    } else {
      element = <></>
    }

    return element;
  };

  const getTotalPrice = (type, selectedItem, itemCount) => {
    let total = 0;

    if (type === 'incomings') {
      total = parseFloat(
        (itemCount[selectedItem.id]?.price || 0) *
          (itemCount[selectedItem.id]?.count || 0)
      ).toFixed(2)
    } else if (type === 'outcomings') {
      total = parseFloat(
        (selectedItem?.sale_price || 0) *
          (itemCount[selectedItem.id]?.count || 0)
      ).toFixed(2)
    } else if (type === 'write-offs') {
      total = parseFloat(
        (selectedItem?.cost || 0) *
          (itemCount[selectedItem.id]?.count || 0)
      ).toFixed(2)
    } else if (type === 'expenditureNorms') {
      total = parseFloat(
        (selectedItem?.cost || 0) *
          (itemCount[selectedItem.id]?.count || 0)
      ).toFixed(2)
    } else {
      total = 0;
    }
    return total;
  }

  if (!loaded) {
    return <CircularProgress />;
  }
  
  return (
    <>
      <div className="orders-inventory-items">
        <div className="orders-inventory-items__error">
          <p>{__('orderIncomings.form.items.error')}</p>
        </div>
        <div className="orders-inventory-items__title">
          <p>{__('orderIncomings.form.items.title')}</p>
        </div>
        <div className="orders-inventory-items__header">
          <FilterWithSearchDropdown
            disabled={ disabled }
            id="inventory-items-search"
            label="ТМЦ"
            action={{
              f: getInventoryItems,
              searchArgPos: 3
            }}
            optionItem={{
              key: 'title',
              textKeyArr: ['title', 'cost', 'description']
            }}
            onSelected={(selectedItem) => setSelectedItemId(selectedItem?.id || null)}
          />
          <Button
            disabled={ disabled }
            variant="contained"
            sx={{ width: '120px', height: '56px', fontSize: 14, lineHeight: 1 }}
            onClick={handleAddButtonClick}>
            Добавити
          </Button>
        </div>

        <div className="orders-inventory-items-table">
          <div className="table table--order-inventory-items">
            <div className="table-header">
              <div className="tr">
                <div className="td">
                  <div className="td-name">
                    <p>№</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.title')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.unit')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.price')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.numbers')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.sale_price')}</p>
                  </div>
                </div>
                <div className="td">
                  <div className="td-name">
                    <p>{__('orderIncomings.table.items.actions')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-body">
              {!loaded && (
                <div className="tr">
                  <div className="td">
                    <CircularProgress />
                  </div>
                </div>
              )}
              {selectedItems.map((selectedItem, index) => (
                <div className="tr" key={selectedItem.id}>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>№</p>
                    </div>
                    <div className="td-content">
                      <p>{index + 1}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.items.title')}</p>
                    </div>
                    <div className="td-content">
                      <p>{selectedItem.title}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.items.unit')}</p>
                    </div>
                    <div className="td-content">
                      <p>{selectedItem.unit?.title}</p>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.items.price')}</p>
                    </div>
                    <div className="td-content">

                      { getItemElements(type, selectedItem, itemСount, handlePriceChange) }
                      
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.items.numbers')}</p>
                    </div>
                    <div className="td-content">
                      <div className="input-counter">
                        <button
                          disabled={ disabled }
                          className="button-add-counter button-add-counter--minus"
                          onClick={() => handleCounterClick(selectedItem, -1)}>
                          <span></span>
                        </button>
                        <TextField
                          disabled={ disabled }
                          sx={{
                            height: '50px',
                            width: '120px',
                            fontSize: 16,
                            '.MuiInputBase-input': {
                              height: '100%'
                            },
                            '. MuiFormLabel-root': {
                              lineHeight: 'normal'
                            }
                          }}
                          name="count"
                          label="Кількість"
                          value={itemСount[selectedItem.id]?.count || ''}
                          onChange={(event) => handleQuantityChange(event, selectedItem)}
                        />
                        <button
                          disabled={ disabled }
                          className="button-add-counter button-add-counter--plus"
                          onClick={() => handleCounterClick(selectedItem, 1)}>
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.items.sale_price')}</p>
                    </div>
                    <div className="td-content">
                      { getTotalPrice(type, selectedItem, itemСount) }
                    </div>
                  </div>
                  <div className="td td--right">
                    <div className="td-hidden-name">
                      <p>{__('orderIncomings.table.actions')}</p>
                    </div>
                    <div className="td-content">
                      <div className="table-buttons">
                        <IconButton
                          disabled={ disabled }
                          aria-label="delete"
                          size="small"
                          variant="contained"
                          onClick={() => handleDeleteButtonClick(selectedItem.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="orders-inventory-items-sum">
          <p>Разом: {calculateTotalPrice()}</p>
        </div>
      </div>
    </>
  );
};
