import { getDateValidOrder } from "./../../../library";
import dayjs from "dayjs"

export const getRowCellsData = (dataItem, __) => {

  let dateDiff = '';
  const now = dayjs();
  const dateStart = dataItem && 
    dataItem.date_start ? dayjs(dataItem.date_start).format('YYYY-MM-DD') : '';
  
  const dateEnd = dataItem && 
  dataItem.date_end ? dayjs(dataItem.date_end).format('YYYY-MM-DD') : '';

  if ( dateStart && dateEnd) {
    dateDiff = dayjs(dateEnd).diff(dayjs(now), 'days');
    if (dateDiff < 0) {
      dateDiff = 0;
    }
  }

  const validOrder = getDateValidOrder(dayjs(dateEnd));
  const validOrderText = (validOrder && dataItem?.visits_left)
    ? __('abonnements.table.valid')
    : __('abonnements.table.notvalid')

  const allowedDays = dataItem?.allowed_days ? dataItem?.allowed_days?.map(day => day.title).join(',') : '';

  return {
    number: {
      th: 'Номер',
      td: dataItem && dataItem.id,
      sort: null,
    },
    name: {
      th: 'Назва',
      td: dataItem && dataItem.season_ticket_title,
      sort: null,
    },
    description: {
      th: 'Опис',
      td: dataItem && dataItem.season_ticket_description,
      sort: null,
    },
    category: {
      th: 'Категорія',
      td: dataItem && dataItem?.season_ticket_category?.title,
      sort: null,
    },
    service: {
      th: 'Послуга',
      td: dataItem && dataItem.season_ticket_service_name,
      sort: null,
    },
    visitsCount: {
      th: 'Кількість годин (всього)',
      td: dataItem && dataItem.visits_count,
      sort: null,
    },
    visitsLeft: {
      th: 'Кількість годин (залишилось)',
      td: dataItem && dataItem.visits_left,
      sort: null,
    },
    dateOfStart: {
      th: 'Дата початку',
      td: dataItem && dateStart,
      sort: null,
    },
    dateOfEnd: {
      th: 'Дата кінця',
      td: dataItem && dateEnd,
      sort: null,
    },
    dayCount: {
      th: 'Термін дії (днів до закінчення)',
      td: dataItem && dateDiff,
      sort: null,
    },
    allowedDays: {
      th: 'Доступні дні',
      td: dataItem && allowedDays,
      sort: null,
    },
    isValidOrder: {
      th: 'Діючий/Недіючий',
      td: dataItem && validOrderText,
      sort: null,
    },

    // price: {
    //   th: 'Вартість',
    //   td: dataItem && dataItem.price,
    //   sort: null,
    // },

    // action: {
    //   th: 'Перезамовити',
    //   td: null,
    //   sort: null,
    //   __class: 'td--right',
    //   link: dataItem && {
    //     url: `/order-${orderType}/${docOrderId}`,
    //     state: null
    //   },
    // },
  }
}