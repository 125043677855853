import validator from 'validator';

export const validate = ( name, value, data = null ) => {
  let rules = [];

  if( name === "first_name"){
    rules = [ validator.isLength( value, { min: 2 })];
  }

  if( name === "last_name"){
    rules = [ validator.isLength( value, { min: 2 })];
  }

  if( name === "email"){
    rules = [true];
  }

  return rules.every( rule => rule === true );
}