import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

import { serverErrorHandler } from 'src/components/common/FormsComponent';
import { ChartReportControls } from '../components';
import { getMultyDataChartOption, getRequestDates, sortDataOfDates } from './utils/functions';
import EmptyChartsInfo from './components/EmptyChartsInfo';
import { chartsAssociatesObj } from './chartsAssociatesObj';
import s from './MultyDataChart.module.scss';

export const MultyDataChart = ({
  fieldsName,
  actionType = 'inTotal', 
  action, 
  chartTitle, 
  chartLabels, 
  srcDateFormat = 'YYYY-MM-DD',
  outputAsPercents = false,
  difference = null,
  chartsViews = ['bars', 'stackedbars', 'lines'],
}) => {
  const [, setErrorMessages] = useState([]);
  const [chartOptions, setChartOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const firstDayOfCurrentYear = dayjs().startOf('year');
  const currentDate = dayjs();

  const [groupValue, setGroupValue] = useState({ top: null, bottom: null });
  const [reportDate, setReportDate] = useState({ start: firstDayOfCurrentYear, end: currentDate });

  const [dataDifference, setDataDifference] = useState(null);

  const [responseData, setResponseData] = useState(null);

  const defaultChartsViewValue = chartsAssociatesObj[chartsViews[0]].value;
  const [ chartView, setChartView ] = useState(defaultChartsViewValue);

  const { chartWrapper, chart } = s;

  useEffect(() => {
    if (reportDate.start && reportDate.end) {

      (async () => {
        const body = getRequestDates(groupValue.top, reportDate);

        if (dataDifference && dataDifference !== 0) {
          body.modelId = dataDifference;
        }

        const res = await action(body);
  
        const data = res.data;
        let dataForCarts = [];
        let error = null;
  
        if (!data) {
          const errorData = res?.response?.data;
          error = errorData.errors ? errorData.errors : errorData.message;
          
          serverErrorHandler(error, setErrorMessages);
          return;
        }

        dataForCarts = sortDataOfDates(data.data);
        
        setChartOptions(getMultyDataChartOption(dataForCarts, groupValue.bottom, fieldsName, actionType, chartTitle, chartLabels, srcDateFormat, outputAsPercents));
        setResponseData(dataForCarts);
        setIsLoading(true);
      })()

    }
  }, [reportDate, groupValue.top, dataDifference]);

  useEffect(() => {
    if (responseData) {
      setChartOptions(getMultyDataChartOption(responseData, groupValue.bottom, fieldsName, actionType, chartTitle, chartLabels, srcDateFormat, outputAsPercents));
    }
  }, [groupValue.bottom]);

  const srcComponent = chartsAssociatesObj[chartView].component
  const selectedChartComponent  = React.cloneElement(
      srcComponent,
      { ...srcComponent.props, chartOptions }
    );

  return (
    <>
      <ToastContainer limit={10}/>
      <ChartReportControls options={{ groupValue, setGroupValue, reportDate, setReportDate, difference, setDataDifference, chartsViews, setChartView }}/>
      
      <div className={ chartWrapper }>
        <div className={ chart }>
          {
            !isLoading
              ? <h3>Завантаження ...</h3>
              : !chartOptions?.data?.labels 
                ? <EmptyChartsInfo />
                : selectedChartComponent
          }
        </div>
      </div>
      

    </>
  );
}