import { PERMISSIONS } from "../../../constants";

export const permissionsIsReadOnly = (permissionsArr) => {
  const permission = permissionsArr[0];

  if (permissionsArr.length === 1 && permission === PERMISSIONS.ACTIONS.READ) {
    return true;
  }

  return false;
}