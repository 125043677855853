import { toast } from "react-toastify";
import { toastStyle } from "./../../../common/FormsComponent/styles/styles";

export const serverErrorHandler = (serverErrorMessages = [], setErrorMessages) => {
  const errorFields = serverErrorMessages ? Object.keys(serverErrorMessages): [];
  const errorMessage = errorFields
    .map((field) => serverErrorMessages[field][0])
    .join('<br>');

  toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, toastStyle);

  setErrorMessages(serverErrorMessages);
}