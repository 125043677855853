import { validateAllLocales } from "./../../../../common/FormsComponent/LocaleTabs";
import { validationField } from "./validationField";

export const validateForm = (
  data, 
  dataValidationModel, 
  setValidationStatus, 
  dataName, 
  __, 
  localeValidation = false,
  requiredLocaleValidationFields = [],
) => {
  let isValid = true;

  if (localeValidation) {
    const isLocalesValid = validateAllLocales(data, dataName, setValidationStatus, requiredLocaleValidationFields, __);
    if (!isLocalesValid) {
      isValid = false;
    }
  }
  
  for (let field in dataValidationModel) {
    if (field === 'locale') {
      continue;
    }

    const ruleCallback = dataValidationModel[field];
    let isFieldValid = validationField(dataName, field, data, setValidationStatus, __, ruleCallback);
    if (!isFieldValid) {
      isValid = false;
    }
  }

  return isValid;
};