import axios from '../../helpers/private.axios';

export const getBookingModelTime = async (modelType, modelId, selectedServiceId, selectedDate) => {
  const params = {
    modelType,
    services: selectedServiceId,
    date: selectedDate,
  };

  if (modelId === 'any') {
    params.any = 1;
  } else {
    params.modelId = modelId;
  }

  return axios.get(`/site/booking/timeslots`, { params });
};
