import axios from '../../helpers/private.axios';

export const getAbonnements = (
    userSearch,
    archive,
    currentPage,
) => {
    const params = {};


    if (userSearch) {
        params.user = userSearch;
    }

    if (archive) {
        params.status = 'archive';
    }

    params.page = currentPage;

    return axios.get(`/abonements/`, { params });
};
