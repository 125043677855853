export const flexBoxCenter = {
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center',
}

export const previewStyles = {
  width: 150, 
  height: 150, 
  border: '1px dashed silver',
  objectFit: 'contain',
  mr: 3,
}