import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from './../../../helpers/translate';

import { TableVerticalSimple } from './../../common/TableComponent';
import { ModalForm } from './../../common/FormsComponent';
import { getLogVerticalTableData } from './tableData';
import { getLogId } from './../../../actions';

export const LogDetails = () => {
  const { logId } = useParams();
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const [{ loaded, log }, setData] = useState({
    loaded: false,
    log: {}
  });

  const toggleOpened = () => {
    navigate('/logs');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getLogId(logId);

        setData({
          loaded: true,
          log: data.data
        });
      } catch (error) {
        console.warn('Logs fetch error: ', error);
      }
    };
    fetchData();
  }, []);

  return (
    <ModalForm
      title="Деталізація логів"
      open={true}
      onClose={toggleOpened}
      loaded={loaded}
      backRoute="/logs"
      className="flexible-form"
      sx={{ width: 'initial' }}>
      <TableVerticalSimple
        className="table-detail-log"
        data={log}
        headerRow={['Поле', 'Старе значення', 'Нове значення']}
        getVerticalTableData={getLogVerticalTableData}
        __={__}
      />
    </ModalForm>
  );
};
