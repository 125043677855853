import { useEffect, useState } from 'react';
import { ModalForm, RadioBtnsGroup } from '../FormsComponent';
import { ButtonAction } from '../TableComponent';
import { correctAccessibility } from './utils';
import { autoLoginIntoCabinet } from './../../pages/Booking/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

export const SelectingPaymentSystem = ({
  open,
  onClose,
  handlePaid,
  handlePaidLater,
  accessibility,
  paymentData={},
  isAdmin = false,
  authToken = null,
  virtualWalletBalance = null,
  payDisabled = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [role, setRole] = useState('USER');

  let radioDataProp = [
    { value: 'online', label: 'Онлайн оплата', title: 'Платіжний термінал FONDY', disabled: false },
    { value: 'abonnement', label: 'Абонемент', title: 'Оплата абонементом', disabled: true },
    { value: 'certificate', label: 'Сертифікат', title: 'Оплата сертифікатом', disabled: true }
  ];

  if (isAdmin) {
    radioDataProp.splice(0, 1, {
      value: 'cash',
      label: 'Оплата в касі',
      title: 'Оплата в касі',
      disabled: false
    });
  }

  if (accessibility) {
    console.log(accessibility);
    radioDataProp = correctAccessibility(radioDataProp, accessibility, paymentData);
  }

  const firstChild = radioDataProp[0].value;

  const [radioValue, setRadioValue] = useState(firstChild);
  const [label, setLabel] = useState('');

  const handleRadio = (e) => {
    const value = e.target.value;
    setRadioValue(value);
  };

  const payLaterHandler = async () => {
    autoLoginIntoCabinet(dispatch, navigate, role, authToken, '/visits');
  };

  useEffect(() => {
    const label = radioDataProp.find((prop) => prop.value === radioValue).title;
    setLabel(label);
  }, [radioValue]);

  return (
    <ModalForm
      title={'Оберіть засіб оплати'}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {
        virtualWalletBalance &&
          <Typography variant="body1" sx={{ textAlign:  'left' }}>Віртуальний рахунок: { virtualWalletBalance }</Typography>
      }

      <RadioBtnsGroup
        name="selecting-payment-system"
        radioDataProp={radioDataProp}
        onChange={(e) => handleRadio(e)}
        value={radioValue}
        label={label}
      />

      <div className="pay-button">
        <ButtonAction
          text="Сплатити"
          fullWidth
          onClick={(e) => handlePaid(radioValue)}
          sx={{ width: '100%' }}
          disabled={ payDisabled }
        />

        {
          isAdmin ? (
            <ButtonAction
              text="Сплатити пізніше"
              onClick={onClose}
              sx={{ width: '100%', whiteSpace: 'nowrap' }}
              variant="outlined"
            />
          ) : (
            <ButtonAction
              text={`${!payDisabled ? 'Сплатити пізніше' : 'Закрити'}`}
              onClick={handlePaidLater || payLaterHandler}
              sx={{ width: '100%', whiteSpace: 'nowrap' }}
              variant="outlined"
            />
          )
        }

      </div>
    </ModalForm>
  );
};
