import { AUTH } from '../../constants';

const initState = {
  token: null,
  user: null,
  loading: false,
  error_message: []
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case AUTH.AUTH_REQUEST:
      return {
        ...state,
        loading: true
      };

    case AUTH.AUTH_RESPONSE:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        token: action.payload.token
      };

    case AUTH.AUTH_LOGOUT:
      return initState;

    case AUTH.AUTH_SET_USER:
      return {
        ...state,
        user: action.payload.user
      };

    default:
      return state;
  }
};

export default authReducer;
