import { TextField, FormControl } from '@mui/material';
import { checkRolesIdPresent } from './utils';

export const ExtendForm = ({ user, handleChange, validationStatus, extendedFormType,}) => {
  console.log(user.roles);
  return (
    <>
    {
      checkRolesIdPresent(user?.roles, [1, 4, 5, 6]) && 
        <>
          <FormControl variant="outlined" fullWidth>
            <TextField fullWidth
              name="password"
              type="password"
              required
              variant="outlined"
              label="Введіть пароль (мін. 6 символів)"
              inputProps={{ minLength:6 }}
              value={user.password}
              onChange={handleChange("password")}
              error={ !validationStatus?.password }
              helperText="Має містити літери"
            />
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <TextField fullWidth
              name="password_confirmation"
              type="password"
              required
              variant="outlined"
              label="Введіть пароль ще раз"
              inputProps={{ minLength:6 }}
              value={user.password_confirmation}
              onChange={handleChange("password_confirmation")}
              error={ !validationStatus?.password_confirmation }
            />
          </FormControl>
        </>
    }
      {
        extendedFormType === "create" &&
        <>
          <TextField 
            fullWidth 
            name="first_name" 
            value={user.first_name} 
            onChange={handleChange("first_name")} 
            label="Введіть ім'я користувача*" 
            error={!validationStatus?.first_name}
          />

          <TextField 
            fullWidth 
            name="last_name" 
            value={user.last_name} 
            onChange={handleChange("last_name")} 
            label="Вкажіть прізвище користувача*"
            error={!validationStatus?.last_name} 
          />
        </>
        
      }
    </>
  );
}

ExtendForm.defaultProps = {
  validationStatus: {},
}