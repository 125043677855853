import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from '../../../../helpers/translate';
import { useEffect, useState } from "react";
import { useCheckAvailableCurrentUserPermission } from "src/configData/rolesConfig";

export const ToggleShowActive = ({ value, onChange, title, statusesTitle, noTitle, checkRoles = false, permission }) => {
  const { __ } = useTranslation();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [ status, setStatus ] = useState(!!value);

  const componentAvailable = useCheckAvailableCurrentUserPermission(checkRoles?.resource, permission);
  
  const changeStatusHandler = () => {
    // console.dir(onChange.arguments);
    onChange.call(null, null);
    // onChange;
  }

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (status !== null) {
      const timeOutHandler = setTimeout(() => {
        changeStatusHandler();
      }, 300);

      return () => {
        clearTimeout(timeOutHandler);
      }
    }
  }, [status]);

  if (!statusesTitle) {
    statusesTitle = {
      active: __('general.form.filter.active_show'),
      inactive: __('general.form.filter.inactive_show')
    }
  }

  return (
    <>
      {
        componentAvailable &&
          <FormControlLabel
            label={
              noTitle 
                ? ''
                : !title 
                    ? status
                        ? statusesTitle.active
                        : statusesTitle.inactive
                    : title
            }
            control={
              <Switch 
                checked={ status } 
                onChange={ () => setStatus(prev => !prev) } 
              />
            }
          />
      }
    </>
    
  );
};