import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = () => {
  const data = {
    labels: [
      'Очікування клієнта', 
      'Клієнт прийшов', 
      'Клієнт не прийшов', 
      'Клієнт підтвердив', 
      'Клієнт оплатив', 
    ],
    datasets: [
      {
        label: 'Кількість записів',
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          'rgba(139, 195, 74, .7)',
          'rgba(255, 235, 59, .7)',
          'rgba(244, 67, 54, .7)',
          'rgba(63, 81, 181, .7)',
          'rgba(156, 39, 176, .7)',
        ],
        borderColor: [
          'rgba(139, 195, 74, 1)',
          'rgba(255, 235, 59, 1)',
          'rgba(244, 67, 54, 1)',
          'rgba(63, 81, 181, 1)',
          'rgba(156, 39, 176, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Pie data={data} />
  )
}



