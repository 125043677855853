import { useTranslation } from '../../../helpers/translate';
import { LocaleTabs } from '../../common/FormsComponent/LocaleTabs';
import { localeFieldsModel } from './localeValidation';
import { getRequiredFields } from './../../common/FormsComponent/LocaleTabs/utils';
import { ButtonSend, InputField, ToggleActiveStatus } from '../../common/FormsComponent';
import { useState } from 'react';
import { getSlug } from './utils';

export const DataForm = ({
  pages,
  setPages,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  validationStatus
}) => {
  const { __ } = useTranslation();
  const requiredLocaleValidationFields = getRequiredFields(localeFieldsModel);

  return (
    <>
      <LocaleTabs 
        data={ pages }
        setData={ setPages }
        dataName={ 'pages' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        fillCheckbox
        fieldsModel={ localeFieldsModel }
      />

      <InputField
        type="text"
        value={pages.slug}
        field="slug"
        label={__('pages.form.slug')}
        onChange={ handleChange }
        validationStatus={ validationStatus } 
      />

      <ToggleActiveStatus 
        name="is_active"
        value={!!pages?.is_active}
        checked={!!pages?.is_active || false}
        onChange={handleChange('is_active')}
      />

      <ButtonSend onClick={action} text={ctaButtonText} />
    </>
  );
};