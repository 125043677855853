export const getHandleChange = (setServiceCategories, setValidationStatus, __) => (name) => (event) => {
  let value = event.target.value;

  console.log(name, value);

  setServiceCategories((prevServices) => {
    let updatedServices = {
      ...prevServices,
      [name]: value
    };
    return updatedServices;
  });
  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [name]: {
      isValid: value,
      errorMessage:
        !value
          ? ''
          : `${__('services.form.amount.error')}`
    }
  }));
};