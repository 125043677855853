import axios from '../../helpers/private.axios';

export const getCoaches = ( searchParams, showArchive ) => {
  const params = { ...searchParams };

  if (showArchive) {
    params.status = 'archive';
  } 

  return axios.get('/coaches/', { params } );
};

export const getCoachesByService = (serviceId) => {
  return axios.get(`/coaches?services=${serviceId}`);
};
