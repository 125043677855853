import axios from '../../helpers/private.axios';

export const getTurnovers = (
    sortOption,
    sortDirection,
    searchDoc,
    searchInvItemId,
    orderTypeFilter,
    dateFilter,
    currentPage,
    itemsPerPage,
) => {
    const params = {};

    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (searchDoc) {
        params['doc'] = searchDoc;
    }

    if (searchInvItemId) {
        params['item'] = searchInvItemId;
    }

    if (orderTypeFilter) {
        params['type'] = orderTypeFilter;
    }

    if (dateFilter !== 'Invalid Date') {
        params['date'] = dateFilter;
    }

    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/turnovers`, { params });
};