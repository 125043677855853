import axios from '../../helpers/private.axios';

export const getContractors = (
    sortOption,
    sortDirection,
    searchTitle,
    currentPage = 1,
    itemsPerPage = 10,
) => {
    const params = {};
    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (searchTitle) {
        params['search-title'] = searchTitle;
    }

    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/contractors/?status=all`, { params });
};