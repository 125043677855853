import { useEffect, useState } from 'react';
import { getServicesOnline } from '../../../actions/services';
import OneService from '../../../components/common/SelectService/OneService';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styles from '../../../components/common/Layout/layout.module.scss';
import { Link, useParams } from 'react-router-dom';

export function SelectService() {
  const [services, setServices] = useState([]);
  const { id } = useParams();
  const [clickedServiceId, setClickedServiceId] = useState(id);
  const [amount, setAmount] = useState(id ? 1 : 0);
  useEffect(() => {
    async function fetchData() {
      const response = await getServicesOnline();
      setServices(response.data.data);
    }

    fetchData();
  }, []);

  return (
    <Box sx={{ maxWidth: 584, m: '0 auto' }}>
      <Box>
        {services.length > 0 ? (
          services.map((service) => (
            <OneService
              service={service}
              setAmount={setAmount}
              amount={amount}
              key={service.id}
              clickedServiceId={clickedServiceId}
              setClickedServiceId={setClickedServiceId}
              serviceId={id}
            />
          ))
        ) : (
          <p>Сервісів поки немає</p>
        )}
      </Box>
      {amount ? (
        <Link
          className={styles['dashbord-nav__item-link']}
          to={'/select-coach/' + clickedServiceId}>
          <Box
            sx={{
              background: 'blue',
              color: 'white',
              borderRadius: 5,
              height: 48,
              display: 'flex',
              pl: '248px',
              alignItems: 'center',
              position: 'fixed',
              top: '865px',
              width: '584px',
              cursor: 'pointer'
            }}>
            <Typography>Продовжити</Typography>
          </Box>
        </Link>
      ) : null}
    </Box>
  );
}
