import { useEffect, useState } from 'react';
import { PERMISSIONS } from 'src/constants';

import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  Pagination,
  FormControlLabel,
  Switch,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { deleteSeasonTickets, getAbonnements } from '../../../../../actions'; 
import { useTranslation } from '../../../../../helpers/translate';
import dayjs from 'dayjs';
import { getDateValidOrder } from './../../../../library';
import { UserSelectComponent } from './../../../../common/UserSelectComponent';
import { rolesComponentConfig } from './rolesComponentConfig';
import { ButtonLink } from './../../../../common/FormsComponent';

export const AbonnementsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);
  const [filterType, setFilterType] = useState('');
  const [userSearch, setUserSearch] = useState(null);

  const userData = {};
  const userChangeHandler = (data) => {
    const userId = data?.user?.id;
    setUserSearch(userId);
  }

  const handleSeasonTicketsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteSeasonTickets(id).then(async () => {
        const { data: response } = await getAbonnements(showArchive, currentPage);

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getAbonnements(userSearch, showArchive, currentPage);
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [userSearch, currentPage, showArchive]);

  return (
    <>
      <div className="title title--mb36">{__('abonnements.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <UserSelectComponent
            data={ userData }
            dataChangeHandler={ userChangeHandler }
            direction="row"
            isValidation={ false }
          />
        </div>
        <div className="table-action__block">
          {/* <FormControlLabel
            label={
              showArchive
                ? `${__('inventoryItem.filter.archiveHide')}`
                : `${__('inventoryItem.filter.archiveShow')}`
            }
            control={
              <Switch checked={showArchive} onChange={(e) => setShowArchive(e.target.checked)} />
            }
          /> */}
          {/* !!! HARD CODE */}
          <ButtonLink text={__('create.button.title')} route="create" checkRoles={ rolesComponentConfig } permission={PERMISSIONS.ACTIONS.CREATE}/>
        </div>
      </div>
      <div className="table-block">
        <div className="table table--abonnements">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.description')}</p>
                </div>
              </div>
              
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.category')}</p>
                </div>
              </div>
              
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.service')}</p>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.amount')}</p>
                </div>
              </div> */}

              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.quantity_hours')}</p>
                </div>
              </div>

              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.quantity_hours_last')}</p>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.discount_amount')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.discount_percent')}</p>
                </div>
              </div> */}
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.date_start')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.date_end')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.day_last')}</p>
                </div>
              </div>
               
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.person')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('seasonTickets.table.allowed_days')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.is_valid')}</p>
                </div>
              </div>
              {/* <div className="td">
                <div className="td-name">
                  <p>{__('abonnements.table.is_active')}</p>
                </div>
              </div> */}
              {/* !!! HARD CODE */}
              {/* <div className="td td--right">
                <div className="td-name">
                  <p>{__('abonnements.table.actions')}</p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((seasonTickets) => {
              const dayDiff = dayjs(seasonTickets.date_end).diff(dayjs(), 'day');
              const lastDay =  dayDiff < 0 ? 0 : dayDiff;
              const validOrder = getDateValidOrder(seasonTickets.date_end) && seasonTickets.quantity_hours_left;
              const validOrderText = validOrder
                ? __('abonnements.table.valid')
                : __('abonnements.table.notvalid')

              const userName = seasonTickets?.user?.first_name + ' ' + seasonTickets?.user?.last_name;
              const allowedDays = seasonTickets?.allowed_days ? seasonTickets?.allowed_days?.map(day => day.title).join(',') : '';

              return (
                <div className="tr" key={seasonTickets.id} style={{ background: !validOrder && '#f2f2f2' }}>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.title')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.title}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.description')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.description}</div>
                  </div>
                  
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.category')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.category.title}</div>
                  </div>

                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.service')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.service.title}</div>
                  </div>
                  {/* <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.amount')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.amount}</div>
                  </div> */}

                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.quantity_hours')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.quantity_hours}</div>
                  </div>

                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.quantity_hours_last')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.quantity_hours_left || 0}</div>
                  </div>
                  {/* <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.discount_amount')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.discount_amount}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.discount_percent')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.discount_percent}</div>
                  </div> */}
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.date_start')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.date_start}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.date_end')}</p>
                    </div>
                    <div className="td-content">{seasonTickets.date_end}</div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.day_last')}</p>
                    </div>
                    <div className="td-content">{ lastDay }
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.person')}</p>
                    </div>
                    <div className="td-content">
                      {
                        seasonTickets?.user?.client_id
                          ? <Link 
                              to={`/clients/${seasonTickets?.user.client_id}`} 
                              state={{id: seasonTickets?.user?.client_id, backUrl: '/clients/loyality/abonnements'}}
                            >
                                { userName }
                            </Link>
                          : userName
                      }
                      
                      
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('seasonTickets.table.allowed_days')}</p>
                    </div>
                    <div className="td-content">{ allowedDays }
                    </div>
                  </div>
                  <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.is_valid')}</p>
                    </div>
                    <div className="td-content">{ validOrderText }</div>
                  </div>
                  {/* <div className="td">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.is_active')}</p>
                    </div>
                    <div className="td-content">
                      {
                        seasonTickets.is_active
                          ? __('general.table.active')
                          : __('general.table.inactive')
                      }
                    </div>
                  </div> */}

                  {/* !!! HARD CODE */}
                  {/* <div className="td td--right">
                    <div className="td-hidden-name">
                      <p>{__('abonnements.table.actions')}</p>
                    </div>
                    <div className="td-content">
                      <div className="table-buttons">
                        <Link to={`/season-tickets/${seasonTickets.id}/edit`} sx={{ m: 0 }}>
                          <IconButton aria-label="edit" size="small" variant="contained">
                            <EditIcon />
                          </IconButton>
                        </Link>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={handleSeasonTicketsDelete(seasonTickets.id)}
                          variant="contained">
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div> */}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
