import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

// itemInfo = {
//   value: 'id',
//   key: 'id',
//   content: 'title',
// }

export const SelectField = ({
  field,
  label,
  data,
  value,
  onChange,
  itemInfo,
  validationStatus,
}) => {
  return (
    <FormControl className={validationStatus[field].isValid ? '' : 'input-error'}>
      <InputLabel>{ label }</InputLabel>
      <Select
        sx={{ width: '100%', textAlign: 'left' }}
        value={value}
        label={ label }
        name={ field }
        onChange={onChange(field)}
        error={!validationStatus[field].isValid}
        helperText={validationStatus[field].errorMessage}
        className={validationStatus[field].isValid ? '' : 'input-error'}>
        {data.map(item => (
          <MenuItem value={item[itemInfo.value]} key={item[itemInfo.key]}>
            { item[itemInfo.content] }
          </MenuItem>
        ))}
      </Select>
      {!validationStatus[field].isValid && (
        <FormHelperText>{validationStatus[field].errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};