import dayjs from "dayjs"

export const getRowCellsData = (dataItem, __) => {

  const orderType = dataItem && getOrderType(dataItem.type);
  const orderRoutePrefix = orderType?.value === 'write-offs' ? '' : 'order-';
  const docOrderNum = dataItem && dataItem.document?.order_num;
  const docOrderId = dataItem && dataItem.document?.id;

  return {
    dateTime: {
      th: 'Дата, час',
      td: dataItem && dayjs(dataItem.date_doc).format('YYYY-MM-DD hh:mm:ss'),
      sort: null,
    },
    invItem: {
      th: 'ТМЦ',
      td: dataItem && dataItem.inventory_item.title,
      sort: null,
      // link: dataItem && {
      //   url: `/inventory-items/${dataItem.inventory_item.id}/edit`,
      //   state: { id: dataItem.inventory_item.id }
      // },
    },
    unit: {
      th: 'Од. вим.',
      td: dataItem && dataItem.unit.title,
      sort: null,
    },
    balanceIn: {
      th: 'Залишок на початок',
      td: dataItem && dataItem.balance_in,
      sort: null,
    },
    balanceInCost: {
      th: 'Сума на початок',
      td: dataItem && dataItem.balance_in_cost,
      sort: null,
    },
    inCount: {
      th: 'Приход',
      td: dataItem && dataItem.in_count,
      sort: null,
    },
    inCost: {
      th: 'Сума приходу',
      td: dataItem && dataItem.in_cost,
      sort: null,
    },
    outCount: {
      th: 'Витрата',
      td: dataItem && dataItem.out_count,
      sort: null,
    },
    outCost: {
      th: 'Сума витрати',
      td: dataItem && dataItem.out_cost,
      sort: null,
    },
    balanceOut: {
      th: 'Залишок на кінець',
      td: dataItem && dataItem.balance_out,
      sort: null,
    },
    balanceOutCost: {
      th: 'Сума на кінець',
      td: dataItem && dataItem.balance_out_cost,
      sort: null,
    },
    type: {
      th: 'Тип операції',
      td: dataItem && orderType.title,
      sort: null,
    },
    document: {
      th: 'Документ',
      td: dataItem && docOrderNum,
      sort: null,
      link: dataItem && {
        url: `/${orderRoutePrefix}${orderType.value}/${docOrderId}`,
        state: '/turnovers'
      },
    },
  }
}

function getOrderType(str) {
  const srtType = str.toLowerCase();
  let type = {};

  if (srtType.includes('outcoming')) {
    type.title = 'Розхідна';
    type.value = 'outcomings';
  } else if (srtType.includes('write')) {
    type.title = 'Розхідна';
    type.value = 'write-offs';
  } else if (srtType.includes('incoming')) {
    type.title = 'Прибуткова';
    type.value = 'incomings';
  } 

  return type;
}