import axios from '../../helpers/private.axios';

export const getHorses = (showArchive) => {
    const params = {
        paginated: false,
    };

    if (showArchive) {
        params.status = 'archive';
    }

    return axios.get(`/horses`, {params});
};

export const getHorsesByService = (serviceId) => {
    const params = { 
        paginated: false,
        services: serviceId,
    };


    return axios.get(`/horses`, {params});
};
