import { getRequiredFields } from "../../../common/FormsComponent/LocaleTabs/utils";
import { getValidationLocaleModel } from "../../../common/FormsComponent/LocaleTabs";
// may be import from current component folder
// import { requiredLocaleValidationFields } from "./../../../common/FormsComponent/LocaleTabs";
import { localeFieldsModel } from "../localeValidation";

const requiredLocaleValidationFields = getRequiredFields(localeFieldsModel);
export const validationData = {
  ...getValidationLocaleModel(requiredLocaleValidationFields),
  slug: { isValid: true, errorMessage: '' },
}