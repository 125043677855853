export   const transformDataToItemsFormat = (data) => {
  const items = [];

  if (data.abonnement && data.abonnement.length > 0) {
    data.abonnement.forEach((abonnement) => {
      const item = {
        id: abonnement.id,
        count: 1,
        amount: parseFloat(abonnement.amount)
      };
      items.push(item);
    });
  }

  if (data.certificate && data.certificate.length > 0) {
    data.certificate.forEach((certificate) => {
      const item = {
        id: certificate.id,
        count: 1,
        amount: parseFloat(certificate.amount)
      };
      items.push(item);
    });
  }

  return items;
};