import { flatingLocaleData } from "./../../../library";
import dayjs from "dayjs";

export const getDataCloneForSend = (data) => {
  let dataClone = { ...data };
  const flatLocaleData = flatingLocaleData(data);

  delete dataClone.locale;
  dataClone = {...dataClone, ...flatLocaleData};

  dataClone.is_active = Number(dataClone.is_active);
  dataClone.user_id = Number(dataClone.user_id);
  dataClone.job_start = dayjs(dataClone.job_start).format('YYYY-MM-DD');

  // !!! HARDCODE ----
  dataClone.categories = [1];

  return dataClone;
}

/*
locale[en][fist_name]


*/