export const getHandleChange = (data, setData, setValidationStatus) => (name) => (event) => {
  let value;

  if (name === 'discount_percent') {
    value = value !== '' ? parseInt(event?.target.value) : '';
  } else if (name === 'user') {
    value = value?.id;
  } else {
    value = event?.target.value;
  }

  setData({
    ...data,
    [name]: value
  });
  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [name]: {
      isValid: true,
      errorMessage: ''
    }
  }));
};

