import axios from '../../helpers/private.axios';

export const getStocktakings = (
  currentPage,
  itemsPerPage,
) => {
  const params = {};
    params.status = 'all';

    params.page = currentPage;
    params.limit = itemsPerPage;

  return axios.get(`/stocktaking`, { params });
};