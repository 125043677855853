import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import ExternalRoutes from './Account/ExternalRoutes';
import MainRoutes from './Account/MainRoutes';
import {
  Auth,
  Registration,
  BookingHome,
  BookingAuth,
  BookingServices,
  BookingCoach,
  BookingCoachTime,
  BookingForm,
  BookingSeasonTickets,
  BookingCertificatesTickets,
  BookingBasket,
  Home,
  BookingHorse,
  BookingHorseTime
} from './../../pages';

import { AuthCode } from './../../pages/Auth/authCode';
import { SingUpExternalUser, SingUpSuccess } from './../../pages/Auth/SingUpExternalUser';
import { ExternalSuccessPaid } from '../Payment';

import { ROLES } from 'src/constants';
import { dispatchSytemTypeAndMode } from './../../../actions/auth/utils';

export function AuthWrapper() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  if (user) {
    dispatchSytemTypeAndMode(dispatch, user);
  } 

  if (user === null) {
    return (
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/sign-in" element={<AuthCode />} />
        <Route path="/sign-up" element={<SingUpExternalUser />} />
        <Route path="/sign-up/success" element={<SingUpSuccess />} />
        <Route path="/admin" element={<Auth />} />

        <Route path="/payment/success" element={<ExternalSuccessPaid />} />


        {/* registration new internal user */}
        {/* <Route path="/registration" element={<Registration />} /> */} 

        <Route path="/booking">
          <Route index element={<BookingHome />} />
          <Route path="/booking/authorization" element={<BookingAuth />} />
          <Route path="/booking/services" element={<BookingServices />} />
          <Route path="/booking/services/:selectedServiceId" element={<BookingHorse />} />
          <Route
            path="services/:selectedServiceId/horse/:selectedHorseId"
            element={<BookingHorseTime />}
          />
          <Route
            path="/booking/services/:selectedServiceId/horse/:selectedHorseId/time/:selectedTimeId"
            element={<BookingForm />}
          />
          <Route path="/booking/season-tickets" element={<BookingSeasonTickets />} />
          <Route path="/booking/certificates" element={<BookingCertificatesTickets />} />
          <Route path="/booking/booking-basket" element={<BookingBasket />} />
        </Route>
      </Routes>
    );
  }

  return user.role === ROLES.ADMIN.ROLE ? <MainRoutes /> : <ExternalRoutes />;
}
