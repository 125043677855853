import { Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect } from 'react';
import { stackBtnsGroupOptions } from './groupsData';

export const ToggleButtonsGroup = ({
  options, 
  groupValue, 
  setGroupValue,
  orientation, 
}) => {
  const { groupKey, relationKey, btns, slave  } = options;

  const relationValue = groupValue[relationKey];
  const relationOptions = getRelationOptions(relationKey, stackBtnsGroupOptions);

  const defaultValue = getDefaultValue(btns);
  groupValue[groupKey] = groupValue[groupKey] || defaultValue;

  const handleChange = (event, newGroupValue) => {
    setGroupValue(prev => {
      const newData = { ...prev, [groupKey]: newGroupValue };

      if (!slave) {
        newData[relationKey] = getDefaultValue(relationOptions.btns);
      } 

      return newData;
    });
  };

  const children = btns.map(btn => {
    const { key, title, disabled, blockingKeys } = btn;
    let isDisabled = false;
    if (blockingKeys) {
      isDisabled = blockingKeys.includes(relationValue);
    }

    return (
      <ToggleButton value={ key } key={ key } disabled={ disabled || isDisabled }>
        <Typography>{ title }</Typography>
      </ToggleButton>
    )
  });

  const control = {
    value: groupValue[groupKey],
    onChange: handleChange,
    exclusive: true,
  };

  useEffect(() => {
    setGroupValue(prev => ({ ...prev, [groupKey]: defaultValue }));
  },[]);

  return (
    <ToggleButtonGroup {...control} size="small" aria-label="Small sizes" orientation={ orientation }>
      {children}
    </ToggleButtonGroup>
  );
}

function getDefaultValue(btns) {
  return btns.find(btn => btn.default)?.key;
}

function getRelationOptions(relationKey, GroupOptions) {
  return GroupOptions.find(options => options.groupKey === relationKey);
}