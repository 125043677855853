export const getClientsRowCellsData = (dataItem, __) => {

  const userName = `${dataItem?.user?.first_name} ${dataItem?.user?.last_name}`;

  return {
    userName: {
      th: 'Кліент',
      td: dataItem && userName,
      sort: null,
      link: dataItem && {
        url: `/clients/${dataItem.id}`,
        state: { id: dataItem.id }
      },
    },
    userPhone: {
      th: 'Телефон',
      td: dataItem && dataItem.user?.login,
      sort: null,
    },
    userEmal: {
      th: 'Email',
      td: dataItem && dataItem.user?.email,
      sort: null,
    },
    status: {
      th: 'Статус',
      td: dataItem && dataItem.status?.title,
      sort: null,
    },
    group: {
      th: 'Група',
      td: dataItem && dataItem.group?.title,
      sort: null,
    },
    profit: {
      th: 'Дохід',
      td: dataItem && dataItem.profit,
      sort: null,
    },
    discount: {
      th: 'Знижка %',
      td: dataItem && dataItem.discount_percent,
      sort: null,
    },
    visitsCount: {
      th: 'Візити',
      td: dataItem && dataItem.visits_count,
      sort: null,
    },
    virtualWallet: {
      th: 'Віртуальний рахунок',
      td: dataItem && dataItem.virtual_wallet,
      sort: null,
    },
    firstVisit: {
      th: 'Перший візит',
      td: dataItem && dataItem.first_visit,
      sort: null,
    },
    lastVisit: {
      th: 'Останній візит',
      td: dataItem && dataItem.last_visit,
      sort: null,
    },
    actions: {
      th: 'Дії',
      td: null,
      sort: null,
      __class: 'td--right',
    }
  }
}