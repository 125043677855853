import { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { createUserCategories } from '../../../actions/userCategories';
import { useTranslation } from '../../../helpers/translate';
import { useNavigate } from 'react-router-dom';
import { UserCategoriesForm } from './UserCategoriesForm';
import { ModalForm } from './../../common/FormsComponent';
import { getHandleLocalizationChange } from './../../common/FormsComponent/LocaleTabs';
import { getValidateForm, localeModel, localeValidationData } from './validation';

export const UserCategoriesCreate = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();

  const toggleOpened = () => {
    navigate('/user-categories');
  };

  const [userCategories, setUserCategories] = useState({
    is_active: true,
    locale: { ...localeModel }
  });

  const [validationStatus, setValidationStatus] = useState({...localeValidationData});

  const validateForm = getValidateForm(userCategories, setValidationStatus, __, 'userCategories.form.title.error');

  const handleLocalizationChange = getHandleLocalizationChange(
    __('userCategories.form.title.error'),
    setUserCategories,
    setValidationStatus
  );

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    setUserCategories({
      ...userCategories,
      [name]: value
    });
  };

  const handleCreate = () => {
    const isValid = validateForm();
    if (isValid) {
      createUserCategories(userCategories).then((res) => {
        navigate('/user-categories');
      });
    }
  };

  return (
    <ModalForm title={__('userCategories.form.title-create')} open={true} onClose={toggleOpened}>
      <div className="close-modal" onClick={toggleOpened}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
            fill="black"
          />
        </svg>
      </div>
      <UserCategoriesForm
        userCategories={userCategories}
        setUserCategories={setUserCategories}
        action={handleCreate}
        handleLocalizationChange={handleLocalizationChange}
        handleChange={handleChange}
        ctaButtonText={__('userCategories.form.button')}
        validationStatus={validationStatus}
        setValidationStatus={setValidationStatus}
      />
    </ModalForm>
  );
};
