export const validationField = (dataName, field, data, setValidationStatus, __, callBackValitadionRule) => {
  const fieldValid = callBackValitadionRule(data);
  
  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [field]: {
      isValid: fieldValid,
      errorMessage: fieldValid ? '' : `${__(`${dataName}.form.${field}.error`)}`
    }
  }));
  return fieldValid;
}