import { useSelector } from 'react-redux';

import { List } from '@mui/material';
import { SidebarAccordion, SidebarLink } from '../MenuItems';

import {
  PeopleIcon,
  PersonIcon,
  GroupsIcon,
  CalendarMonthIcon,
  WarehouseIcon,
  LibraryBooksIcon,
  AttachMoneyIcon,
  CreditCardIcon,
  SquareFootIcon,
  BedroomBabyRoundedIcon,
  CardGiftcardIcon,
  QueryStatsIcon,
  InventoryIcon,
  ManageHistoryIcon,
  HistoryEduIcon,
} from './../icons';
import iconHorse from './../icons/horse.png'
import iconJockey from './../icons/jockey.png'

import styles from './../layout.module.scss';
import { RESOURCES, SYSTEM_MODES, SYSTEM_TYPES } from './../../../../constants';

const MainAccount = () => {
  const { mode } = useSelector(state => state.mode);
  const { systemType } = useSelector(state => state.systemType);
  const isDual = systemType === SYSTEM_TYPES.DUAL;
  const horseMode = mode === SYSTEM_MODES.HORSE;
  const coachMode = mode === SYSTEM_MODES.COACH;

  return (
    <List
      className={styles['dashbord-nav']}
      component="nav"
      aria-labelledby="nested-list-subheader">
      {/* Clients */}
      <SidebarAccordion title="Клієнти" icon={<PeopleIcon />} providing resource={ RESOURCES.CLIENT }>
        <SidebarLink title="Клієнтська база" link="/clients" icon={<PeopleIcon />} resource={ RESOURCES.CLIENT_BASE }/>
        <SidebarLink title="Лояльність" link="/clients/loyality/certificates" icon={<PeopleIcon />} resource={ RESOURCES.CLIENT_LOYALITY }/>
      </SidebarAccordion>

      { // Horses
        (isDual || horseMode) &&
        <SidebarAccordion title="Коні" icon={<img src={ iconHorse } className={ styles.icon } />} providing resource={ RESOURCES.HORSES }>
          <SidebarLink title="Розклад" link="/" icon={<CalendarMonthIcon />} changeModelType={ SYSTEM_MODES.HORSE } resource={ RESOURCES.HORSES_SCHEDULE } />
          <SidebarLink 
            title="Налаштування розкладу" 
            link="/schedule/horses" 
            icon={<ManageHistoryIcon />} 
            changeModelType={ mode } 
            resource={ RESOURCES.HORSES_SCHEDULE_MANAGE } 
          />
        </SidebarAccordion>
      }
      
      { // Coaches
        (isDual || coachMode) &&
        <SidebarAccordion title="Тренери" icon={<img src={ iconJockey } className={ styles.icon } />} providing resource={ RESOURCES.COACHES }>
          <SidebarLink title="Розклад" link="/" icon={<CalendarMonthIcon />} changeModelType={ SYSTEM_MODES.COACH } resource={ RESOURCES.COACHES_SCHEDULE }/>
          <SidebarLink 
            title="Налаштування розкладу" 
            link="/schedule/coaches" 
            icon={<ManageHistoryIcon />} 
            changeModelType={ mode }
            resource={ RESOURCES.COACHES_SCHEDULE_MANAGE } 
          />
        </SidebarAccordion>
      }

      {/* Inventory control */}
      <SidebarAccordion title="Складський облік" icon={<WarehouseIcon />} providing resource={ RESOURCES.TMC_CONTROLS }>
        <SidebarLink title="Наявність ТМЦ" link="/inventory-items-availability" icon={<PersonIcon />} resource={ RESOURCES.TMC_AVAILABILITY } />
        <SidebarLink title="Інвентаризація" link="/stocktaking" icon={<InventoryIcon />} resource={ RESOURCES.STOCKTAKING } />
        <SidebarLink title="Оборотна відомість" link="/turnovers" icon={<PersonIcon />} resource={ RESOURCES.TURNOVERS } />
      </SidebarAccordion>

      {/* Movement TMC */}
      <SidebarAccordion title="Рух ТМЦ" icon={<CreditCardIcon />} providing resource={ RESOURCES.TMC_MOVEMENT }>
        <SidebarLink title="Прибуткові ордери" link="/order-incomings" icon={<PersonIcon />} resource={ RESOURCES.ORDER_INCOMINGS } />
        <SidebarLink title="Розхідні ордери" link="/order-outcomings" icon={<PersonIcon />} resource={ RESOURCES.ORDER_OUTCOMINGS } />
        <SidebarLink title="Акти на списання ТМЦ" link="/write-offs" icon={<PersonIcon />} resource={ RESOURCES.WRITE_OFFS } />
      </SidebarAccordion>

      {/* Reference books */}
      <SidebarAccordion title="Налаштування" icon={<LibraryBooksIcon />} providing resource={ RESOURCES.REFERENCE_BOOKS }>
        <SidebarLink title="ТМЦ" link="/inventory-items" icon={<WarehouseIcon />} />
        <SidebarLink title="Послуги" link="/services" icon={<PersonIcon />} />
        <SidebarLink title="Абонементи/Сертифікати" link="/season-tickets" icon={<CardGiftcardIcon />}/>
        <SidebarLink title="Особи" link="/persons" icon={<PersonIcon />} />
        <SidebarLink title="Користувачі" link="/users" icon={<PersonIcon />} />
        <SidebarLink title="Коні" link="/horses" icon={<img src={ iconHorse } className={ styles.icon } />} />
        <SidebarLink title="Тренери" link="/coaches" icon={<img src={ iconJockey } className={ styles.icon } />} />
        <SidebarLink title="Норми витрат" link="/expenditure-norms" icon={<PersonIcon />} />
        <SidebarLink title="Інформаційні сторінки" link="/info-pages" icon={<SquareFootIcon />} />

        <SidebarAccordion title="Система" icon={<LibraryBooksIcon />} providing resource={ RESOURCES.REFERENCE_BOOKS_ADDITIONALS }>
          <SidebarLink title="Одиниці вимірювання" link="/units" icon={<SquareFootIcon />} />
          <SidebarLink title="Категорії послуг" link="/service-categories" icon={<PersonIcon />} />
          <SidebarLink title="Категорії коней" link="/horses-categories" icon={<BedroomBabyRoundedIcon />} />
          {/* <SidebarLink title="Категорії сертифікати" link="/certificate-categories" icon={<CardGiftcardIcon />} /> */}
          <SidebarLink title="Категорії (Сертифікати/Абонементи)" link="/season-tickets-categories" icon={<CardGiftcardIcon />} />
          <SidebarLink title="Типи ТМЦ" link="/inventory-item-types" icon={<WarehouseIcon />} />
          <SidebarLink title="Категорії користувачів" link="/user-categories" icon={<PersonIcon />} />
        </SidebarAccordion>

      </SidebarAccordion>

      {/* Analitics */}
      <SidebarAccordion title="Аналітика" icon={<QueryStatsIcon />} providing resource={ RESOURCES.ANALITICS }>
        <SidebarLink title="Фінансова аналітика" link="financial-analytics" icon={<AttachMoneyIcon />} resource={ RESOURCES.ANALITICS_FINANCIAL } />
        <SidebarLink title="Фінансові показники" link="/indicators-financial" icon={<CardGiftcardIcon />} resource={ RESOURCES.INDICATORS_FINANCIAL } />
        <SidebarLink title="Показники активності" link="/indicators-activity" icon={<PersonIcon />} resource={ RESOURCES.INDICATORS_ACTIVITY } />
      </SidebarAccordion>

      {/* Logs */}
      <SidebarLink title="Логування" link="logs" icon={<HistoryEduIcon />} resource={ RESOURCES.LOGS } />
    </List>
  );
};

export default MainAccount;
