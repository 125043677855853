import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button
} from '@mui/material';

import { TablePagination } from './../../common/TableComponent';

import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';

import { getPersons, deletePerson } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import moment from 'moment';
import MaskedInput from 'react-text-mask';

export const PersonsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data, meta }, setData] = useState({
    loaded: false,
    data: [],
    meta: []
  });

  const [searchName, setSearchName] = useState('');
  const [searchPhone, setSearchPhone] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);

  const handleSearchTitleChange = (event) => {
    setSearchName(event.target.value);
  };

  const handleSearchPhoneChange = (event) => {
    const searchStr = event.target.value.replace(/[+\s-_]/g, '');
    setSearchPhone(searchStr);
  };

  const handlePersonDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deletePerson(id).then(async () => {
        const { data: response } = await getPersons(
          searchName,
          searchPhone,
          currentPage,
          itemsPerPage
        );
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getPersons(
          searchName,
          searchPhone,
          currentPage,
          itemsPerPage
        );

        setData({
          loaded: true,
          data: response.data,
          meta: response.meta
        });

        setTotalPages(response.meta.last_page);
        setCurrentPage(response.meta.current_page);
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, [searchName, searchPhone, currentPage, itemsPerPage]);

  return (
    <>
      <div className="title title--mb36">{__('persons.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{ width: '100%', '.MuiInputBase-input': { pr: '40px' } }}
              label={__('general.form.filter.last_name')}
              value={searchName}
              onChange={handleSearchTitleChange}
            />
            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
          <div className="input-wrap">
            <MaskedInput
              sx={{ width: '100%' }}
              placeholder={__('persons.filter.phone')}
              mask={[
                /[1-9]/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/
              ]}
              value={searchPhone}
              onChange={handleSearchPhoneChange}
              render={(innerRef, props) => <TextField {...props} inputRef={innerRef} />}
            />
          </div>
        </div>
        <div className="table-action__block">
          <Link to="/persons/create">
            <Button
              variant="outlined"
              type="submit"
              sx={{ mb: 2, height: 40, fontSize: 14, lineHeight: 1 }}>
              Додати
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-block">
        <div className="table table--persons">
          <div className="table-header">
            <div className="tr">
              <div className="td">ID</div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.name')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.gender')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.phone')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.email')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.registered_at')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.date_of_birth')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.passport_data')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.individual_taxpayer_number')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.address')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('persons.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((persons) => (
              <div className="tr" key={persons.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>ID</p>
                  </div>
                  <div className="td-content">{persons.id}.</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.name')}</p>
                  </div>
                  <div className="td-content">
                    {persons?.first_name} {persons?.middle_name} {persons?.last_name}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.gender')}</p>
                  </div>
                  <div className="td-content">{persons.gender}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.phone')}</p>
                  </div>
                  <div className="td-content">{persons.phone}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.email')}</p>
                  </div>
                  <div className="td-content">{persons.email}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.registered_at')}</p>
                  </div>
                  <div className="td-content">
                    {persons?.user && (
                      <>{moment(persons?.user?.registered_at * 1000).format('DD.MM.YYYY')}</>
                    )}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.date_of_birth')}</p>
                  </div>
                  <div className="td-content">
                    {moment(persons?.date_of_birth).format('DD.MM.YYYY')}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.passport_data')}</p>
                  </div>
                  <div className="td-content">
                    {persons.passport_data && (
                      <>{persons?.passport_data.code + ' / ' + persons?.passport_data.number}</>
                    )}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.individual_taxpayer_number')}</p>
                  </div>
                  <div className="td-content">{persons?.individual_taxpayer_number}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.address')}</p>
                  </div>
                  <div className="td-content">{persons?.address}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('persons.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/persons/${persons.id}/edit`}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handlePersonDelete(persons.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};
