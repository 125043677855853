import { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';

import { applyWriteOffs, getWriteOffsId, updateWriteOffs } from '../../../actions/writeOffs';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { WriteOffsForm } from './writeOffsForm';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 960,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const WriteOffsEdit = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const toggleOpened = () => {
    navigate('/write-offs');
  };

  const [loaded, setLoaded] = useState(false);

  const [writeOffs, setWriteOffs] = useState({
    person_id: '',
    reason: '',
    commentary: '',
    subdivision_id: '',
    items: []
  });

  const [items, setItems] = useState([]);
  const [itemsPush, setItemsPush] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  const updateItemСount = (newItems) => {
    setItems(newItems);

    const itemsArray = Object.entries(newItems).map(([inventory_item_id, itemData]) => ({
      item_id: parseInt(inventory_item_id),
      quantity: itemData.count
    }));

    setWriteOffs((prevWriteOffs) => ({
      ...prevWriteOffs,
      items: itemsArray
    }));

    setIsChanged(true);
  };

  const [validationStatus, setValidationStatus] = useState({
    subdivision_id: { isValid: true, errorMessage: '' },
    commentary: { isValid: true, errorMessage: '' },
    inventoryItems: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    if (!writeOffs.subdivision_id) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        subdivision_id: {
          isValid: false,
          errorMessage: `${__('writeOffs.form.subdivision_id.error')}`
        }
      }));
    }

    if (!writeOffs.commentary) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        commentary: {
          isValid: false,
          errorMessage: `${__('writeOffs.form.commentary.error')}`
        }
      }));
    }

    if (!writeOffs.items.length > 0) {
      isValid = false;
      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        inventoryItems: {
          isValid: false
        }
      }));
    }

    return isValid;
  };

  const handleChange = (name) => (event) => {
    let value;

    if (name === 'is_active') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }

    setWriteOffs({
      ...writeOffs,
      [name]: value
    });

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('writeOffs.form.numbers.error')}`
      }
    }));

    setIsChanged(true);
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      updateWriteOffs(params.id, writeOffs).then((res) => {
        setIsChanged(false);
      });
    }
  };

  const handleApply = () => {
    const isValid = validateForm();
    if (isValid) {
      const sendData = new FormData();
      sendData.append('id', params.id);
      applyWriteOffs(sendData).then((res) => {
        navigate('/write-offs');
      });
    }
  };

  const convertItemsToItemCounts = (items) => {
    const itemCounts = {};

    items.forEach((item) => {
      const inventoryItemId = item.id;

      if (!itemCounts[inventoryItemId]) {
        itemCounts[inventoryItemId] = {
          count: parseFloat(item.quantity),
          id: parseFloat(item.id)
        };
      } else {
        itemCounts[inventoryItemId].count += parseFloat(item.quantity);
        itemCounts[inventoryItemId].id += parseFloat(item.id);
      }
    });

    return itemCounts;
  };

  useEffect(() => {
    getWriteOffsId(params.id).then((res) => {
      const { ...rest } = res.data.data;
      const savedUserData = JSON.parse(localStorage.getItem('ride_state'));
      setWriteOffs({
        ...rest,
        person_id: savedUserData?.auth?.user?.id || '',
        reason: rest.reason.id || 0,
        subdivision_id: rest.subdivision.id || 0
      });

      setItemsPush(convertItemsToItemCounts(rest.items));
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <Modal
        open={true}
        onClose={toggleOpened}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('writeOffs.form.title-edit')}</h3>
              </div>
              <WriteOffsForm
                writeOffs={writeOffs}
                setWriteOffs={setWriteOffs}
                actionUpdate={handleUpdate}
                actionApply={handleApply}
                handleChange={handleChange}
                ctaButtonText={__('writeOffs.form.button')}
                validationStatus={validationStatus}
                setValidationStatus={setValidationStatus}
                updateItemСount={updateItemСount}
                itemsPush={itemsPush}
                isChanged={!!isChanged}
                formType="edit"
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
