import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { requiredLocaleValidationFields } from './validation/requiredLocaleValidationFields';
import { LocaleTabs } from './../../common/FormsComponent/LocaleTabs';
import { useEffect, useState } from 'react';
import { getModelTypes } from 'src/actions';
import { useTranslation } from 'src/helpers/translate';
import config from 'src/config';

export const ServiceCategoriesForm = ({
  serviceCategories,
  setServiceCategories,
  handleLocalizationChange,
  handleChange,
  action,
  ctaButtonText,
  validationStatus
}) => {
  const { __ } = useTranslation();
  const [{ loaded, modelTypes }, setData] = useState({
    loaded: false,
    modelTypes: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: modelTypes } = await getModelTypes();

        setData({
          loaded: true,
          modelTypes: Object.entries(modelTypes),
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);
  
  return (
    <>
      <LocaleTabs 
        data={ serviceCategories }
        setData={ setServiceCategories }
        dataName={ 'serviceCategories' }
        validationStatus={ validationStatus }
        handleLocalizationChange={ handleLocalizationChange }
        requiredFields={ requiredLocaleValidationFields }
        nameField="title"
        fillCheckbox
      />

      <FormControl>
        <InputLabel>{__('services.input.model_type')}</InputLabel>
        <Select
          sx={{ width: '100%', textAlign: 'left' }}
          label={__('services.input.model_type')}
          name="type"
          value={serviceCategories?.type || config.system_mode_default}
          onChange={handleChange('type')}>
          {
            modelTypes &&
              modelTypes.map(typeData => {
                const [key, type] = typeData;
                console.log(key);
                return (
                  <MenuItem key={key} value={key}>
                    { type }
                  </MenuItem>
                )
            })
          }
        </Select>
      </FormControl>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}>
        {ctaButtonText}
      </Button>
    </>
  );
};
