const users = {
    uk: {
        "users.page.title": "Особи",
        "users.table.name": "Довідник особи",
        "users.table.roles": "Роль",
        "users.table.login": "Логін",
        "users.table.registered_at": "Дата і час реєстрації",
        "users.table.categories": "Категорія",
        "users.table.is_active": "Активність/ Неактивний",
        "users.table.actions": "Дії",
    },
    ru: {
    },
    en: {
    },

};

export default users;
