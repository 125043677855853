import { Box, CircularProgress, Modal } from '@mui/material';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ModalStyle } from './styles/styles';
import { ButtonBackward } from '.';
import { CloseModalBtn } from './UI';

export const ModalForm = ({children, loaded, title, backRoute, closeBtn, sx = {}, ...props}) => {
  const { onClose } = props;

  if (!loaded) {
    return (
      <Modal
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
        { ...props }
      >
        <Box sx={{ ...ModalStyle, ...sx }}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <>
      <ToastContainer limit={10}/>
      <Modal { ...props }>
        <Box sx={{ ...ModalStyle, ...sx }}>

          <div className="inside">
            <div className="create-edit-form">
              
              <div className="create-edit-form__action">
                { backRoute && <ButtonBackward route={ backRoute } /> }
              </div>

              <div className="title">
                <h3>{ title }</h3>
              </div>

              { closeBtn && <CloseModalBtn onClose={ onClose }/> }
              
              {
                children
              }

            </div>
          </div>
          
        </Box>
      </Modal>
    </>
  );
};

ModalForm.defaultProps = {
  children: <h1>Empty form</h1>,
  loaded: true,
}