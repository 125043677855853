import dayjs from "dayjs";

// GET DATES OPTIONS FUNCS
// MULTY DATA
export function getMultyDataChartOption(datesInfoList, reportDetail, fieldsName, actionType, chartTitle, chartLabels, srcDateFormat, outputAsPercents) {
  let infoOptions;

  switch (reportDetail) {
    case 'day':
      infoOptions = getMultyDataDatesOptionsDay(datesInfoList, fieldsName, srcDateFormat);
      break;
    case 'week':
      infoOptions = getMultyDataDatesOptionsWeek(datesInfoList, fieldsName, actionType, srcDateFormat);
      break;
    case 'month':
      infoOptions = getMultyDataDatesOptionsMonth(datesInfoList, fieldsName, actionType, srcDateFormat);
      break;
    case 'quarter':
      infoOptions = getMultyDataDatesOptionsQuarter(datesInfoList, fieldsName, actionType, srcDateFormat);
      break;
    default:
      infoOptions = getMultyDataDatesOptionsDay(datesInfoList, fieldsName, srcDateFormat);
  }

  if (!infoOptions) {
    return null;
  }

  const options = {
    type: 'line',
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: chartTitle || false,
        text: chartTitle,
      },
      filler: {
        propagate: false,
      },
    },
    interaction: {
      intersect: false,
    },
    // scales: {
    //   y: {
    //       beginAtZero: true,
    //   }
    // },
  };

  
  
  const barColors = ['#19b495', '#ffad79', '#8479ff', '#79ff84', '#ba79ff'];
  const borderColors = ['#19b495', '#ffad79', '#8479ff', '#79ff84', '#ba79ff'];

  if (outputAsPercents) {
    infoOptions.values = [getValuesAsPercents(infoOptions.values)];
  }

  const datasets = infoOptions.values.map((values, index, dataList) => {
    return (
      {
        label: chartLabels[index],
        data: values,
        backgroundColor: barColors[index],
        borderColor: borderColors[index],
        maxBarThickness: 30,
        minBarLength: 10,
      }
    )
  });

  const data = {
    labels: infoOptions.dates,
    datasets,
  };

  return { options, data }
}
export function getMultyDataDatesOptionsDay(datesInfoList, fieldsName, srcDateFormat) {
  if (!datesInfoList || !datesInfoList.length) {
    return null;
  }

  const datesOfDay = {
    dates: [],
    values: [],
  };

  fieldsName.forEach(fieldName => {
    const values = [];

    datesInfoList.forEach(dateInfo => {
      const { date, [fieldName]:value } = dateInfo;
      const { year, month, day } = getDiffDate(date, srcDateFormat);
  
      // dates of day
      const key = `${ day }-${ month }-${year}`;
  
      if (!datesOfDay.dates.includes(key)) {
        datesOfDay.dates.push(key)
      };
      values.push(Number(value));
      
    });

    datesOfDay.values.push(values);
  });

  return datesOfDay;
}
export function getMultyDataDatesOptionsWeek(datesInfoList, fieldsName, actionType, srcDateFormat) {
  if (!datesInfoList || !datesInfoList.length) {
    return null;
  }

  const datesList = {
    dates: [],
    values: [],
  };

  fieldsName.forEach(fieldName => {
    const groupValues = [];
    const weeks = {};

    datesInfoList.forEach(dateInfo => {
      const { date, [fieldName]:value } = dateInfo;
      const currentWeek = dayjs(date, srcDateFormat).week();
  
      if (!weeks[currentWeek]) {
        weeks[currentWeek] = {
          date,
          values: [],
        }
      }
      
      weeks[currentWeek].values.push(Number(value));
    });
  
    for (let weekNum in weeks) {
      const { date, values } = weeks[weekNum];
      const weekSumm = getValuesResults(actionType, values);
      const dateWithWeekNum = getWeekName(weekNum, date, srcDateFormat);

      if (!datesList.dates.includes(dateWithWeekNum)) {
        datesList.dates.push(dateWithWeekNum);
      }
      groupValues.push(weekSumm);
    }

    datesList.values.push(groupValues);
  });

  return datesList;
}
export function getMultyDataDatesOptionsMonth(datesInfoList, fieldsName, actionType, srcDateFormat) {
  if (!datesInfoList || !datesInfoList.length) {
    return null;
  }

  const datesList = {
    dates: [],
    values: [],
  };

  fieldsName.forEach(fieldName => {
    const groupValues = [];
    const month = {};

    datesInfoList.forEach(dateInfo => {
      const { date, [fieldName]:value } = dateInfo;
      const currentMonth = dayjs(date, srcDateFormat).month();
  
      if (!month[currentMonth]) {
        month[currentMonth] = {
          date,
          values: [],
        }
      }
      
      month[currentMonth].values.push(Number(value));
    });
  
    for (let monthNum in month) {
      const { date, values } = month[monthNum];
      const monthSumm = getValuesResults(actionType, values);
      const monthName = getMonthName(monthNum, date, srcDateFormat);

      if (!datesList.dates.includes(monthName)) {
        datesList.dates.push(monthName);
      }
      groupValues.push(monthSumm);
    }

    datesList.values.push(groupValues);
  });

  return datesList;
}
export function getMultyDataDatesOptionsQuarter(datesInfoList, fieldsName, actionType, srcDateFormat) {
  if (!datesInfoList || !datesInfoList.length) {
    return null;
  }

  const datesList = {
    dates: [],
    values: [],
  };

  fieldsName.forEach(fieldName => {
    const groupValues = [];
    const quarter = {};

    datesInfoList.forEach(dateInfo => {
      const { date, [fieldName]:value } = dateInfo;
      const currentQuarter = getQuarterNum(date, srcDateFormat);
  
      if (!quarter[currentQuarter.str]) {
        quarter[currentQuarter.str] = {
          date,
          values: [],
        }
      }
      
      quarter[currentQuarter.str].values.push(Number(value));
    });
  
    for (let quarterNum in quarter) {
      const { date, values } = quarter[quarterNum];
   
      const quarterSumm = getValuesResults(actionType, values);
      const quarterName = getQuarterName(quarterNum, date, srcDateFormat);

      if (!datesList.dates.includes(quarterName)) {
        datesList.dates.push(quarterName);
      }
      groupValues.push(quarterSumm);
    }

    datesList.values.push(groupValues);
  });


  return datesList;
}
// -----------------------

export function getMonthName(monthNum, date, srcDateFormat) {
  const { year } = getDiffDate(date, srcDateFormat);
  const dateMonthNum = dayjs().month(monthNum).format('MM');
  const monthName = `${ dateMonthNum }-${ year }`

  return monthName;
}
export function getWeekName(weekNum, date, srcDateFormat) {
  const { year, month, day } = getDiffDate(date, srcDateFormat);
  const weekName = `${ day }-${ month }-${ year }(${ weekNum })`

  return weekName;
}
export function getQuarterName(quarterNum, date, srcDateFormat) {
  const { year } = getDiffDate(date, srcDateFormat);
  const quarterName = `${ year }(${ quarterNum })`

  return quarterName;
}

// GET DATES REPORT OPTIONS FUNCS
export function getRequestDates(reportType, reportDate) {
  if (!reportType && !reportDate) {
    return getRequestDatesRange(reportDate, reportType);
  }

  reportDate = {
    start: dayjs(reportDate.start).format('YYYY-MM-DD'),
    end: dayjs(reportDate.end).format('YYYY-MM-DD'),
  }

  switch (reportType) {
    case 'range':
      return getRequestDatesRange(reportDate, reportType);
    case 'yearly':
      return getRequestDatesYearly(reportDate, reportType);
    case 'quarterly':
      return getRequestDatesQuarterly(reportDate, reportType);
    case 'monthly':
      return getRequestDatesMonthly(reportDate, reportType);
    case 'weekly':
      return getRequestDatesWeekly(reportDate, reportType);
    default:
      return getRequestDatesRange(reportDate, reportType)
  }
}
export function getRequestDatesRange(reportDate, reportType) {
  const requestDates = {
    'date-start': dayjs().format('YYYY-MM-DD'),
    'date-end': dayjs().format('YYYY-MM-DD'),
  };
  if (!reportType && !reportDate) {
    return requestDates;
  }

  requestDates['date-start'] = reportDate.start;
  requestDates['date-end'] = reportDate.end;

  return requestDates;
}
export function getRequestDatesYearly(reportDate, reportType) {
  const requestDates = {
    'date-start': dayjs().format('YYYY-MM-DD'),
    'date-end': dayjs().format('YYYY-MM-DD'),
  };
  if (!reportType && !reportDate) {
    return requestDates;
  }

  const year = dayjs(reportDate.start).year();
  const firstDayOfYear = dayjs(reportDate.start).startOf('year').format('YYYY-MM-DD');
  const lastDayOfYear = dayjs(`${year}-12-31`).format('YYYY-MM-DD');
  const lastDayOfCurrentYear = dayjs().format('YYYY-MM-DD');

  const isYearPassed = dayjs().isAfter(dayjs(lastDayOfYear), 'day');

  requestDates['date-start'] = firstDayOfYear;
  requestDates['date-end'] = lastDayOfYear;

  if (!isYearPassed) {
    requestDates['date-end'] = lastDayOfCurrentYear;
  }

  return requestDates;
}
export function getRequestDatesQuarterly(reportDate, reportType) {
  const requestDates = {
    'date-start': dayjs().format('YYYY-MM-DD'),
    'date-end': dayjs().format('YYYY-MM-DD'),
  };
  if (!reportType && !reportDate) {
    return requestDates;
  }

  const quarterInfo = getQuarterInfo(reportDate.start);

  requestDates['date-start'] = quarterInfo.dates.start;
  requestDates['date-end'] = quarterInfo.dates.end;


  return requestDates;
}
export function getRequestDatesMonthly(reportDate, reportType) {
  const requestDates = {
    'date-start': dayjs().format('YYYY-MM-DD'),
    'date-end': dayjs().format('YYYY-MM-DD'),
  };
  if (!reportType && !reportDate) {
    return requestDates;
  }

  const monthInfo = getMonthInfo(reportDate.start);

  requestDates['date-start'] = monthInfo.dates.start;
  requestDates['date-end'] = monthInfo.dates.end;

  return requestDates;
}
export function getRequestDatesWeekly(reportDate, reportType) {
  const requestDates = {
    'date-start': dayjs().format('YYYY-MM-DD'),
    'date-end': dayjs().format('YYYY-MM-DD'),
  };
  if (!reportType && !reportDate) {
    return requestDates;
  }

  const weekInfo = getWeekInfo(reportDate.start);
  console.log(weekInfo);

  requestDates['date-start'] = weekInfo.dates.start;
  requestDates['date-end'] = weekInfo.dates.end;

  return requestDates;
}
// -------------------------

// OTHER FUNCS
export function getQuarterNum(date, srcDateFormat = 'YYYY-MM-DD') {
  const month = dayjs(date, srcDateFormat).month();
  let quarterNum = {};
  
  if (month >= 0 && month <= 2) {
    quarterNum = { num: 1, str: 'I'};
  } else if (month >= 3 && month <= 5) {
    quarterNum = { num: 2, str: 'II'};
  } else if (month >= 6 && month <= 8) {
    quarterNum = { num: 3, str: 'III'}
  } else {
    quarterNum = { num: 4, str: 'IV'};
  }

  return quarterNum;
}
export function getQuarterInfo(date, srcDateFormat = 'YYYY-MM-DD') {
  const quarterInfo = {
    name: {},
    dates: {},
    passed: true,
  };
  if (!date) {
    return quarterInfo;
  }

  quarterInfo.name = getQuarterNum(date, srcDateFormat);

  const quarterNum = getQuarterNum(date, srcDateFormat);
  const firstMonthOfQuarter = (quarterNum.num - 1) * 3 + 1;
  const lastMonthOfQuarter = (quarterNum.num - 1) * 3 + 3;

  const firstDayOfQuarter = dayjs(date, srcDateFormat)
    .set('month', firstMonthOfQuarter - 1)
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastDayOfQuarter = dayjs(date, srcDateFormat)
    .set('month', lastMonthOfQuarter - 1)
    .endOf('month')
    .format('YYYY-MM-DD');

  const currentDate = dayjs().format('YYYY-MM-DD');

  const isQuarterPassed = dayjs().isAfter(dayjs(lastDayOfQuarter), 'day');

  quarterInfo.dates.start = firstDayOfQuarter;
  quarterInfo.dates.end = lastDayOfQuarter;
  quarterInfo.passed = true;

  if (!isQuarterPassed) {
    quarterInfo.dates.end = currentDate;
    quarterInfo.passed = false;
  }

  return quarterInfo;
}
export function getMonthInfo(date) {
  const monthInfo = {
    name: {},
    dates: {},
    passed: true,
  };

  if (!date) {
    return monthInfo;
  }

  monthInfo.name.num = dayjs(date).month();

  const firstDayOfMonth = dayjs(date)
    .startOf('month')
    .format('YYYY-MM-DD');
  const lastDayOfMonth = dayjs(date)
    .endOf('month')
    .format('YYYY-MM-DD');

  const currentDate = dayjs().format('YYYY-MM-DD');
  const isMonthPassed = dayjs().isAfter(dayjs(lastDayOfMonth), 'day');

  monthInfo.dates.start = firstDayOfMonth;
  monthInfo.dates.end = lastDayOfMonth;

  if (!isMonthPassed) {
    monthInfo.dates.end = currentDate;
    monthInfo.passed = false;
  }

  return monthInfo;
}
export function getWeekInfo(date) {
  const weekInfo = {
    name: {},
    dates: {},
    passed: true,
  };

  if (!date) {
    return weekInfo;
  }

  weekInfo.name.num = dayjs(date).week();

  const firstDayOfWeek = dayjs(date)
    .startOf('week')
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  const lastDayOfWeek = dayjs(date)
    .endOf('week')
    .subtract(1, 'day')
    .format('YYYY-MM-DD');

  const currentDate = dayjs().format('YYYY-MM-DD');
  const isWeekPassed = dayjs().isAfter(dayjs(lastDayOfWeek), 'day');

  weekInfo.dates.start = firstDayOfWeek;
  weekInfo.dates.end = lastDayOfWeek;

  if (!isWeekPassed) {
    weekInfo.dates.end = currentDate;
    weekInfo.passed = false;
  }

  return weekInfo;
}
export function getDiffDate(date, srcDateFormat = 'YYYY-MM-DD') {
  const year = dayjs(date, srcDateFormat).format('YY');
  const month = dayjs(date, srcDateFormat).format('MM');
  const day = dayjs(date, srcDateFormat).format('DD');

  return { year, month, day };
}

export function sortDataOfDates(data, reverse = false, srcDateFormat = 'YYYY-MM-DD') {
  if (!data) {
    return [];
  }

  const sortedDates = data.sort((a, b) => {
    const dateA = dayjs(a.date, srcDateFormat);
    const dateB = dayjs(b.date, srcDateFormat);

    const diffDate = !reverse ? dateA.diff(dateB, 'day') :  dateB.diff(dateA, 'day');
    return diffDate;
  });

  return sortedDates;
}


// calculation functions
function getValuesResults(actionType, values) {
  if (!values || !values.length) {
    return 0;
  }

  const valuesActionsTypes = {
    inTotal(values) {
      return values.reduce((acc, currValue) => acc + currValue);
    },
    valueHiger(values) {
      return Math.max(...values);
    },
    average() {
      const summ = values.reduce((acc, currValue) => acc + currValue);
      return (summ / values.length).toFixed(2);
    },
  }

  
  let appliedAction = valuesActionsTypes[actionType];
  if (!appliedAction) {
    return valuesActionsTypes['inTotal'](values);
  }

  return appliedAction(values);
}

function getValuesAsPercents(valuesList) {
  const results = [];
  if (!valuesList || !valuesList.length) {
    return results;
  }

  for(let i = 0; i < valuesList[0].length; i++) {
    const total = valuesList[0][i];
    const usage = valuesList[1][i];
    let res = 0;

    if (total !== 0) {
      res = (usage / total * 100).toFixed(2);
    }

    results.push(res);
  }

  return results;
}

function getOrder(data) {
  console.log(data);
}