import { createPaymentCertificate, getSeasonTickectsCertificates } from '../../../../../actions/certificates';
import { LoaylityCreate } from '../components';

export const CertificatesCreate = () => {
  return (
    <LoaylityCreate 
      title="Створити сертифікат" 
      action={ createPaymentCertificate }
      getSeasonTicketsAction={ getSeasonTickectsCertificates } 
      baseRoute="certificates"
      type="Сертифікат" />
    )
};
