import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export function OpenImgDialog({ open, setOpen, data }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 10,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Card sx={{ minWidth: 300, overflowY: 'auto' }} fullWidth>
          <CardActionArea>
            <CardMedia
              component="img"
              width="100%"
              image={ data.image }
              // alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                { data.name || data.title }
              </Typography>
              <Typography variant="body2">
                { data.description }
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </BootstrapDialog>
    </div>
  );
}