import React, { useState, useEffect } from 'react';
import { SelectModelForSchedule } from '../../common/ScheduleModelManage';
import { Grid, TextField, Button, Box, Typography } from '@mui/material';
import './horseSchedule.sass';
import { createModelsTimeslotWeek, getModelDateTimeslots } from '../../../actions';
import { createHorseTimeslotWeek } from '../../../actions/horsesTimeslots/index';
import DateCalendarNewRow from '../../common/ScheduleModelManage/DateCalendarNewRow';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { useLocation, useParams } from 'react-router-dom';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ButtonSend } from '../../common/FormsComponent';
import { ScheduleGrid } from './compoments/ScheduleGrid';
import { OpenDialog } from '../../common/Popups';

dayjs.extend(isBetweenPlugin);
dayjs.extend(isoWeek);
// Enums for statuses:
const statuses = {
  disabled: 3,
  free: 0,
  selected: 1,
  booked: 2
};
const generateWeekState = (selected_day, coach_schedule) => {
  const dayJsSchedule = coach_schedule.map((schedule) => {
    schedule.date = dayjs(schedule.date);
    return schedule;
  });
  const week = [];

  for (let day_index = 1; day_index <= 7; day_index++) {
    const date = selected_day.isoWeekday(day_index);
    const hours = [];

    for (let index = 0; index <= 23; index++) {
      const selectedSlot = dayJsSchedule.find((item) => {
        return (
          item.date.format('DD-MM-YYYY') === date.format('DD-MM-YYYY') &&
          item.date.isoWeekday() === day_index &&
          index === item.date.hour()
        );
      });

      if (selectedSlot) {
        if (selectedSlot.sub_status.id > 0) {
          hours.push({ status: statuses.disabled });
        } else if (selectedSlot.visit_id !== null) {
          hours.push({ status: statuses.booked });
        } else {
          hours.push({ status: statuses.selected });
        }
      } else hours.push({ status: statuses.free });
    }

    const day = {
      date: date,
      hours: hours
    };

    week.push(day);
  }
  return week;
};

export const SingleModel = ({ modelType }) => {
  const { modelId } = useParams();
  const { state: { isReadOnly } } = useLocation(); 

  const [value, setValue] = useState(dayjs(new Date()));
  const [repeat, setRepeat] = useState(0);
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [weekDays, setDays] = useState(generateWeekState(value, data));
  const [updatedDays, setUpdatedDays] = useState({});
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getModelDateTimeslots(modelId, modelType, value.format('YYYY-MM-DD'));
        setData({
          loaded: true,
          data: response.data
        });

        setDays(generateWeekState(value, response.data));
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, [modelId, value.isoWeek(), updatedDays]);

  const handleTimeChange = (new_day, new_time, date) => (event) => {
    if (isReadOnly) {
      return;
    }

    const checked = event.currentTarget?.checked;

    const resultDays = weekDays.map((day, day_index) => {
      day.hours = day.hours.map((hour, hour_index) => {
        if (day_index === new_day && hour_index === new_time) {
          hour.status = Number(checked);
        }
        return hour;
      });
      return day;
    });
    // Set info for visual render
    setDays(resultDays);
  };

  function getfillAllDays(fillValue) {
    if (isReadOnly) {
      return;
    }

    const daysData = {};
    for (let time = 0; time < 24; time++) {
      daysData[time] = fillValue;
    }
    return daysData;
  }

  const handleDaysFill = (e, dayIndex) => {
    if (isReadOnly) {
      return;
    }

    const checked = true;

    const resultDays = weekDays.map((day, day_index) => {
      day.hours = day.hours.map((hour, hour_index) => {
        if (day_index === dayIndex) {
          hour.status = Number(checked);
        }
        return hour;
      });
      return day;
    });
    // Set info for visual render
    setDays(resultDays);
  };

  const handleTimeFill = (e, timeIndex) => {
    if (isReadOnly) {
      return;
    }
    
    const checked = true;

    const resultDays = weekDays.map((day, day_index) => {
      day.hours = day.hours.map((hour, hour_index) => {
        if (hour_index === timeIndex) {
          hour.status = Number(checked);
        }
        return hour;
      });
      return day;
    });

    // Set info for visual render
    setDays(resultDays);
  };

  const submitChange = async () => {
    const schedule_list = {};
    const hoursList = {};
    let scheduleIsEmpty = true;

    // check for non empty this day
    weekDays.forEach(({ hours }, index) => {
      const isEmpty = !hours.some((item) => item.status !== 0);
      if (!isEmpty) {
        hoursList[index] = hours;
      }
    });

    // get filled time for current day
    for (let hour in hoursList) {
      const hours = hoursList[hour];
      const fillHours = {};
      hours.forEach((hour, index) => {
        if (hour.status) {
          fillHours[index] = 1;
        }
      });
      schedule_list[hour] = fillHours;
    }

    if (Object.keys(schedule_list).length) {
      scheduleIsEmpty = false;
    }

    const scheduleList = {
      hors_id: modelId,
      date_start: value.format('YYYY-MM-DD'),
      schedule_list: schedule_list,
      repeat: parseInt(repeat)
    };

    if (scheduleIsEmpty) {
      scheduleList.clear = 1;
    }

    await createModelsTimeslotWeek(scheduleList, modelType);
    setUpdatedDays(schedule_list);
  };

  const clearWeekSchedule = async () => {
    const scheduleList = {
      hors_id: modelId,
      date_start: value.format('YYYY-MM-DD'),
      repeat: 0,
      schedule_list: {},
      clear: 1
    };

    const res = await createModelsTimeslotWeek(scheduleList, modelType);
    setUpdatedDays({});
    setOpenSuccessDialog(false);
  };

  return (
    <>
      <OpenDialog
        open={openSuccessDialog}
        setOpen={setOpenSuccessDialog}
        data={{
          title: 'Підтвердження',
          description: 'Підтвердіть, що ви дійсно хочете очистити розклад на тиждень'
        }}>
        <ButtonSend
          text="Скасувати"
          sx={{ mr: 1, width: '50%' }}
          onClick={() => setOpenSuccessDialog(false)}
        />
        <ButtonSend
          variant="outlined"
          text="Підтвердити"
          sx={{ mr: 1, width: '50%' }}
          onClick={clearWeekSchedule}
        />
      </OpenDialog>

      <div className="title title--mb36">Налаштування розкладу</div>
      <div className="schedule-horses-block-wrap">
        <div>
          <SelectModelForSchedule modelType={ modelType }/>
        </div>
        <div>
          <div className="schedule-horses-calendar">
            <DateCalendarNewRow
              sx={{ m: 0 }}
              value={value}
              changeValue={(newValue) => setValue(dayjs(newValue))}
            />
          </div>
          <div className="schedule-horses-date-select">
            <p>
              {' '}
              Вибрана дата - {value.format('DD.MM.YYYY')}
              <br />
              Вибраний період - {value.isoWeekday(1).format('DD.MM.YYYY')} -{' '}
              {value.isoWeekday(7).format('DD.MM.YYYY')}
            </p>
          </div>
          <Grid item xs={12}>
            <ScheduleGrid
              weekDays={weekDays}
              handleTimeChange={handleTimeChange}
              handleTimeFill={handleTimeFill}
              handleDaysFill={handleDaysFill}
            />

            <div className="schedule-duplicator">
              Скопіювати графік на
              <TextField
                id="outlined-number"
                type="number"
                InputProps={{
                  inputProps: { max: 100, min: 1 }
                }}
                onChange={(event) => setRepeat(event.target.value)}
                value={repeat}
              />
              тижнів вперед
            </div>

            <Box>
              <Box sx={{ mb: 1 }}>
                <Typography variant="p">
                  Видалення або зміна, можливі лише після поточного часу і дати
                </Typography>
              </Box>
              <Box>
                <ButtonSend onClick={submitChange} text="Зберегти" sx={{ mr: 2 }} disabled={ isReadOnly }/>
                <ButtonSend
                  onClick={() => setOpenSuccessDialog(true)}
                  text="Очистити"
                  variant="outlined"
                  disabled={ isReadOnly }
                />
              </Box>
            </Box>
          </Grid>
        </div>
      </div>
    </>
  );
};
