import { Td, TdEditable } from '.';

import { useContext, useState } from 'react';
import { TableContext, TableSimple } from '..';
import { CollapseToggleBtn, CollapsibleContent } from './Collapsible';

export const TrBody = ({ children, data, ...props }) => {
  const { getRowCellsData, getCollapseRowCellsData, __, collapsible, collapsibleTableTitle } =
    useContext(TableContext);
  const rowCellsData = getRowCellsData(data, __);

  const [open, setOpen] = useState(false);
  const COLLAPS_DATA_ACTION = rowCellsData && rowCellsData.__GET_COLLAPS_DATA_ACTION;
  const FORMAT_COLLAPS_DATA_CALLBACK = rowCellsData && rowCellsData.__FORMAT_COLLAPS_DATA_CALLBACK;

  return (
    <>
      <div className="tr" {...props}>
        <CollapseToggleBtn collapsible={collapsible} open={open} setOpen={setOpen} />

        <Cells cellsData={rowCellsData} />

        {children && (
          <Td __class="td--right">
            <div className="table-buttons">{children}</div>
          </Td>
        )}
      </div>

      <CollapsibleContent collapsible={collapsible} open={open}>
        <TableSimple
          title={
            collapsibleTableTitle && collapsibleTableTitle?.length ? collapsibleTableTitle : []
          }
          // data={ collapsData }
          getRowCellsData={getCollapseRowCellsData}
          __={__}
          DATA_ACTION={COLLAPS_DATA_ACTION}
          FORMAT_DATA_CALLBACK={FORMAT_COLLAPS_DATA_CALLBACK}
        />
      </CollapsibleContent>
    </>
  );
};

export default TrBody;

const Cells = ({ cellsData }) => {
  const rowCells = [];

  for (let key in cellsData) {
    if (key.startsWith('__')) {
      continue;
    }

    if (key.toLowerCase().startsWith('action')) {
      continue;
    }

    const __class = cellsData[key].__class;
    const __classTd = cellsData[key].__classTd;
    const value = cellsData[key].td;
    const valueTh = cellsData[key].th;
    const link = cellsData[key].link;
    const editable = cellsData[key].editable;

    if (editable) {
      rowCells.push(
        <TdEditable
          key={key}
          value={value}
          __class={__class}
          __classTd={__classTd}
          link={link}
          editable={editable}
        />
      );
      continue;
    }

    rowCells.push(
      <Td
        key={key}
        value={value}
        __class={__class}
        __classTd={__classTd}
        link={link}
        th={valueTh}
      />
    );
  }

  return <>{rowCells}</>;
};
