import { Box } from "@mui/material";
import { DifferenceBlock, SelectDateBlock, StackToggleBtnsGroup } from ".";
import { useMediaQuery } from "react-responsive";
import ChartToggleViewBlock from "./ChartToggleViewBlock";

export const ChartReportControls = ({ options }) => {
  const { groupValue, setGroupValue, reportDate, setReportDate, difference, setDataDifference, chartsViews, setChartView } = options;

  let is860 = useMediaQuery({query: '(max-width: 1224px)'});
  let orientation = is860 ? 'vertical' : 'horizontal';

  return (
    <Box>

      {
        difference &&
          <DifferenceBlock type={ difference } setDataDifference={ setDataDifference }/>
      }

      <Box 
        sx={
          { 
            display: 'flex', 
            alignItems: orientation === '' ? 'flex-end' : 'flex-start', 
            gap: '40px', 
            flexWrap: 'wrap', 
            mb: 3 
          }}
      >
        <SelectDateBlock reportDate={ reportDate } setReportDate={ setReportDate } groupValue={ groupValue } orientation= { orientation }/>
        <StackToggleBtnsGroup groupValue={ groupValue } setGroupValue={ setGroupValue } orientation= { orientation }/>
      </Box>

      <ChartToggleViewBlock chartsViews={ chartsViews } setChartView={ setChartView }/>

    </Box>
  );
};