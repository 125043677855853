import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton
} from '@mui/material';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getServiceCategories, deleteServiceCategories } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

export const ServiceCategoriesList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const handleDeleteServiceCategories = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteServiceCategories(id).then(async () => {
        const { data: response } = await getServiceCategories();
        setData({
          loaded: true,
          data: response.data
        });
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getServiceCategories();
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {
        // console.error(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="title title--mb36">{__('serviceCategories.page.title')}</div>
      <div className="table-action">
        <Link to="/service-categories/create">
          <Button
            variant="contained"
            type="submit"
            sx={{ mb: 0, height: 40, fontSize: 14, lineHeight: 1 }}>
            {__('create.button.title')}
          </Button>
        </Link>
      </div>
      <div className="table-block">
        <div className="table table--service-categories">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('serviceCategories.table.title')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('serviceCategories.table.description')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('services.table.model_type')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('serviceCategories.table.is_active')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('serviceCategories.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((serviceCategories) => (
              <div className="tr" key={serviceCategories.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('serviceCategories.table.title')}</p>
                  </div>
                  <div className="td-content">{serviceCategories.title}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('serviceCategories.table.description')}</p>
                  </div>
                  <div className="td-content">{serviceCategories.description}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('services.table.model_type')}</p>
                  </div>
                  <div className="td-content">
                  { serviceCategories.type_title }
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('serviceCategories.table.is_active')}</p>
                  </div>
                  <div className="td-content">
                    {serviceCategories.status
                      ? __('general.table.inactive')
                      : __('general.table.active')}
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('serviceCategories.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">
                      <Link to={`/service-categories/${serviceCategories.id}/edit`} sx={{ m: 0 }}>
                        <IconButton aria-label="edit" size="small" variant="contained">
                          <EditIcon />
                        </IconButton>
                      </Link>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleDeleteServiceCategories(serviceCategories.id)}
                        variant="contained">
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
