import { useSelector } from 'react-redux';
import { useState } from 'react';

//import { getRoles } from '../../../actions/roles/getRoles';

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Typography
} from '@mui/material';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField, DatePicker } from '@mui/x-date-pickers/';
import moment from 'moment';
import dayjs from 'dayjs';
import styles from './persons.scss';
import { PhoneInputBlock } from './../../common/FormsComponent';

export const PersonFormEdit = ({
  person,
  handlePassportChange,
  handleChange,
  //handleRoleChange,
  action,
  ctaButtonText,
  validationStatus
}) => {
  const isValid = checkValidData(validationStatus);

  return (
    <>
      <TextField
        fullWidth
        name="first_name"
        value={person.first_name}
        onChange={handleChange('first_name')}
        label="Введіть ім'я особи*"
        error={!validationStatus['first_name']}
      />

      <TextField
        fullWidth
        name="middle_name"
        value={person.middle_name}
        onChange={handleChange('middle_name')}
        label="Введіть по-батькові особи*"
        error={!validationStatus['middle_name']}
      />

      <TextField
        fullWidth
        name="last_name"
        value={person.last_name}
        onChange={handleChange('last_name')}
        label="Введіть прізвище особи*"
        error={!validationStatus['last_name']}
      />

      <FormControl fullWidth>
        <InputLabel id="gender-select-label">Стать</InputLabel>
        <Select
          labelId="gender-select-label"
          id="gender-select"
          value={person.gender ? person.gender : ''}
          name="gender"
          label="Стать"
          onChange={handleChange('gender')}
          error={!validationStatus['gender']}>
          <MenuItem selected disabled value={''}>
            {' '}
            Оберіть стать{' '}
          </MenuItem>
          <MenuItem value="male">Чоловік</MenuItem>
          <MenuItem value="female">Жінка</MenuItem>
        </Select>
      </FormControl>

      <div className="phone-input-block">
        {/* <Typography
          id="number"
          sx={{
            top: '25px',
            fontSize: '1rem',
            color: !validationStatus['phone'] ? 'red' : '',
            textAlign: 'left'
          }}>
          Контактний номер (+XXX XX XXX-XX-XX)
        </Typography> */}
        <PhoneInput
          className="phone-input"
          placeholder={'Телефон'}
          name="phone"
          id="number"
          type="number"
          country={'ua'}
          preferredCountries={['ua', 'ru', 'us']}
          masks={{ ua: '(..) ...-..-..' }}
          value={person.phone}
          onChange={handleChange('phone')}
          isValid={(value, country) => validationStatus['phone']}
        />
      </div>

      <TextField
        fullWidth
        name="email"
        value={person.email}
        onChange={handleChange('email')}
        label="Введіть емейл особи*"
        error={!validationStatus['email']}
      />

      <TextField
        fullWidth
        name="individual_taxpayer_number"
        value={person.individual_taxpayer_number || ''}
        onChange={handleChange('individual_taxpayer_number')}
        label="Введіть ІПН особи*"
        error={!validationStatus['individual_taxpayer_number']}
        helperText="це поле має бути унікальним"
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Дата народження"
            name="date"
            value={dayjs(person.date_of_birth)}
            onChange={handleChange('date_of_birth')}
            format="YYYY-MM-DD"
          />
        </DemoContainer>
      </LocalizationProvider>

      <TextField
        fullWidth
        name="code"
        value={person?.passport_data?.code}
        onChange={handleChange('code')}
        label="Введіть  код паспорт користувача, ##*"
        error={!validationStatus['code']}
      />

      <TextField
        type="number"
        fullWidth
        name="number"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]', minLength: 6 }}
        value={person?.passport_data?.number}
        onChange={handleChange('number')}
        label="Введіть числовий номер паспорту, ######*"
        error={!validationStatus['number']}
      />

      <TextField
        fullWidth
        name="organ"
        value={person?.passport_data?.organ}
        onChange={handleChange('organ')}
        label="Вкажіть орган видачі паспорта*"
        error={!validationStatus['organ']}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Дата видачі паспроту ####-##-##"
            name="date"
            value={dayjs(person.passport_data.date)}
            onChange={handleChange('date')}
            format="YYYY-MM-DD"
            error={!validationStatus['date']}
          />
        </DemoContainer>
      </LocalizationProvider>

      <TextField
        fullWidth
        name="address"
        value={person.address}
        onChange={handleChange('address')}
        label="Введіть адресу особи*"
        error={!validationStatus['address']}
      />

      <div className={styles['authorization-form__action']}></div>

      <Button
        variant="contained"
        type="submit"
        onClick={action}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
        disabled={!isValid}>
        {ctaButtonText}
      </Button>
    </>
  );
};

function checkValidData(data) {
  return Object.values(data).every((item) => item);
}
