import { useEffect, useState } from 'react';
import { Box, Modal, CircularProgress } from '@mui/material';

import { getServicesId, copyServices } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';

import { useNavigate, useParams } from 'react-router-dom';

import { ServicesForm } from './ServicesForm';

const ModalStyle = {
  maxHeight: '90vh',
  maxWidth: 'calc(100% - 20px)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
  overflowY: 'auto'
};

export const ServicesCopy = ({ opened }) => {
  const { __ } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [loaded, setLoaded] = useState(false);

  const toggleOpened = () => {
    navigate('/services');
  };

  const [services, setServices] = useState({
    is_active: true,
    available_for_online: true,
    service_category_id: '',
    unit_id: '',
    amount: '',
    locale: {
      uk: {
        title: '',
        description: ''
      },
      ru: {
        title: '',
        description: ''
      },
      en: {
        title: '',
        description: ''
      }
    }
  });

  const [validationStatus, setValidationStatus] = useState({
    uk: {
      title: { isValid: true, errorMessage: '' }
    },
    ru: {
      title: { isValid: true, errorMessage: '' }
    },
    en: {
      title: { isValid: true, errorMessage: '' }
    },
    service_category_id: { isValid: true, errorMessage: '' },
    unit_id: { isValid: true, errorMessage: '' },
    amount: { isValid: true, errorMessage: '' }
  });

  const validateForm = () => {
    let isValid = true;

    Object.keys(services.locale).forEach((locale) => {
      const title = services.locale[locale].title;
      const isTitleValid = title.trim() !== '';

      setValidationStatus((prevValidationStatus) => ({
        ...prevValidationStatus,
        [locale]: {
          ...prevValidationStatus[locale],
          title: {
            isValid: isTitleValid,
            errorMessage: isTitleValid ? '' : `${__('services.form.title.error')}`
          }
        }
      }));

      if (!isTitleValid) {
        isValid = false;
      }
    });

    const isServiceCategoryValid = services.service_category_id !== '';
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      service_category_id: {
        isValid: isServiceCategoryValid,
        errorMessage: isServiceCategoryValid ? '' : `${__('services.form.service_category.error')}`
      }
    }));
    if (!isServiceCategoryValid) {
      isValid = false;
    }

    const isUnitValid = services.unit_id !== '';
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      unit_id: {
        isValid: isUnitValid,
        errorMessage: isUnitValid ? '' : `${__('services.form.unit.error')}`
      }
    }));
    if (!isUnitValid) {
      isValid = false;
    }

    const isAmountValid = services.amount.length > 0;
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      amount: {
        isValid: isAmountValid,
        errorMessage: isAmountValid ? '' : `${__('services.form.amount.error')}`
      }
    }));
    if (!isAmountValid) {
      isValid = false;
    }

    return isValid;
  };

  const handleLocalizationChange = (locale) => (event) => {
    const { name, value } = event.target;

    setServices((prevHorses) => ({
      ...prevHorses,
      locale: {
        ...prevHorses.locale,
        [locale]: {
          ...prevHorses.locale[locale],
          [name]: value
        }
      }
    }));

    const isTitleValid = value.trim() !== '';

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [locale]: {
        ...prevValidationStatus[locale],
        title: {
          isValid: isTitleValid,
          errorMessage: isTitleValid ? '' : `${__('services.form.title.error')}`
        }
      }
    }));
  };

  const handleChange = (name) => (event) => {
    let value;
    if (name === 'is_active' || name === 'available_for_online') {
      value = event?.target.checked;
    } else {
      value = name === 'amount' ? event.target.value.replace(/[^0-9.]/g, '') : event.target.value;
    }

    setServices((prevServices) => {
      let updatedServices = {
        ...prevServices,
        [name]: value
      };

      if (name === 'service_category_id') {
        updatedServices.service_category_id = value;
      } else {
        if (name === 'unit_id') {
          updatedServices.unit_id = value;
        }
      }

      return updatedServices;
    });
    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [name]: {
        isValid: value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0),
        errorMessage:
          value.length > 0 || (!isNaN(value) && parseFloat(value) >= 0)
            ? ''
            : `${__('services.form.amount.error')}`
      }
    }));
  };

  const handleUpdate = () => {
    const isValid = validateForm();
    if (isValid) {
      const amountWithTwoDecimalPlaces = parseFloat(services.amount).toFixed(2);
      const servicesClone = { ...services, amount: amountWithTwoDecimalPlaces };
      servicesClone.locale = JSON.stringify(servicesClone.locale);
      servicesClone.serviceCategories = servicesClone.service_category_id;
      servicesClone.units = servicesClone.unit_id;
      copyServices(servicesClone).then((res) => {
        navigate('/services');
      });
    }
  };

  useEffect(() => {
    getServicesId(params.id).then((res) => {
      const servicesData = res.data.data;
      servicesData.serviceCategories = res.data.data.service_category_id;
      servicesData.units = res.data.data.unit_id;
      setServices(servicesData);
      setLoaded(true);
    });
  }, []);

  if (!loaded) {
    return (
      <Modal
        open={true}
        onClose={toggleOpened}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={ModalStyle}>
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={ModalStyle}>
        <div className="close-modal" onClick={toggleOpened}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.56406 0L0.0499168 1.51793L8.48586 9.97498L0 18.4987L1.4975 20L10 11.4929L18.4859 20L20 18.4821L11.5141 9.97498L19.9501 1.51793L18.4359 0L10 8.45705L1.56406 0Z"
              fill="black"
            />
          </svg>
        </div>
        <div>
          <div className="inside">
            <div className="create-edit-form">
              <div className="title">
                <h3>{__('services.form.title-copy')}</h3>
              </div>
              <ServicesForm
                services={services}
                action={handleUpdate}
                handleChange={handleChange}
                handleLocalizationChange={handleLocalizationChange}
                ctaButtonText={__('services.form.submit-btn')}
                validationStatus={validationStatus}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
