import { ToggleActiveStatus } from "./../../../common/FormsComponent";
import { UserDataForm } from ".";
import { useEffect, useState } from "react";  

const userDataField = {
  first_name: 'external_first_name',
  last_name: 'external_last_name',
  phone: 'external_phone'
}

export const OtherUserForm = ({ data, setData, onChange, validationStatus, setValidationStatus, __ }) => {
  const [active, setActive] = useState(false);
  
  useEffect(() => {
    if (active) {
      setValidationFieldsForExternalUser(validationStatus, setValidationStatus, userDataField);
      setExternalUserDataToSendData(data, setData, userDataField);
    } else {
      removeValidationFieldsForExternalUser(validationStatus, setValidationStatus, userDataField);
      removeExternalUserDataToSendData(data, setData, userDataField);
    }
  }, [active]);

  return (
    <>
      <ToggleActiveStatus
        name="toggle_active_status"
        value={ active }
        checked={ active || false }
        onChange={ () => setActive(prev => !prev) }
        title="Замовити для іншого"
      />
      {
        active &&
          <UserDataForm 
            data={ data }
            onChange={ onChange }
            validationStatus={ validationStatus }
            userDataField={ userDataField }
            __={ __ }
          /> 
      }
      
    </>
  );
};

function setValidationFieldsForExternalUser(validationStatus, setValidationStatus, validationFields = {}) {
  const externalUserValidationFields = {}
  Object.values(validationFields).forEach(field => {
    externalUserValidationFields[field] = { isValid: true, errorMessage: '' }
  });

  setValidationStatus({...validationStatus, ...externalUserValidationFields})
}

function setExternalUserDataToSendData(data, setData, fields = {}) {
  const externalUserDataFields = {}
  Object.values(fields).forEach(field => {
    externalUserDataFields[field] = '';
  });

  setData({...data, ...externalUserDataFields})
}

function removeValidationFieldsForExternalUser(validationStatus, setValidationStatus, validationFields = {}) {
  Object.values(validationFields).forEach(field => {
    delete validationStatus[field];
  });

  setValidationStatus(validationStatus)
}

function removeExternalUserDataToSendData(data, setData, fields = {}) {
  Object.values(fields).forEach(field => {
    delete data[field];
  });

  setData(data)
}