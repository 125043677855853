import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import s from './styles/formComponent.module.scss';
import { Typography } from '@mui/material';
 
export const PhoneInputBlock = ({ isError, value, setValue, errorText, noMarginBottom, noValidate=false, ...props }) => {
  return (
    <div className={!noMarginBottom ? s['phone-input-block'] : ''}>
      <PhoneInput
        className={`phone-input ${isError ? 'input-error' : ''}`}
        isValid={!noValidate ? !!value.match(/\+\d{3}\s\d{2}\s\d{3}-\d{2}-\d{2}$/i) : true}
        fullWidth
        placeholder={'Телефон'}
        name="phone_number"
        id="number"
        type="number"
        country={'ua'}
        preferredCountries={['ua', 'ru', 'us']}
        masks={{ ua: '(..) ...-..-..' }}
        value={value}
        onChange={setValue}
        { ...props }
      />
      {
        (isError && !noValidate ) &&
          <Typography sx={{ 
            mb: 1,
            pl: 1, 
            position: 'absolute', 
            left: 0, 
            bottom: '-30px', 
            color: 'red', 
            fontSize: '0.7rem' 
          }}>
            {errorText}
          </Typography>
      }
    </div>
  );
};
