import { Route, Routes } from 'react-router-dom';

import {
  CertificateCategoriesCreate,
  CertificateCategoriesEdit,
  CertificateCategoriesList,
  CreatePerson,
  CreateCoach,
  CreateUser,
  CoachesList,
  Dashboard,
  EditPerson,
  EditCoach,
  EditUser,
  HorsesCategoriesCreate,
  HorsesCategoriesEdit,
  HorsesCategoriesList,
  HorsesCreate,
  HorsesEdit,
  HorsesList,
  InventoryItemsCreate,
  InventoryItemsEdit,
  InventoryItemsList,
  InventoryItemTypesCreate,
  InventoryItemTypesEdit,
  InventoryItemTypesList,
  InventoryItemsAvailability,
  PersonsList,
  ServicesList,
  ServicesCreate,
  ServicesCopy,
  ServicesEdit,
  ServiceCategoriesCreate,
  ServiceCategoriesEdit,
  ServiceCategoriesList,
  PagesEdit,
  PagesList,
  UnitsCreate,
  UnitsEdit,
  UnitsList,
  UsersList,
  SeasonTicketsList,
  SeasonTicketsCreate,
  SeasonTicketsEdit,
  SeasonTicketsCategoriesList,
  SeasonTicketsCategoriesCreate,
  SeasonTicketsCategoriesEdit,
  WriteOffsList,
  WriteOffsCreate,
  WriteOffsView,
  WriteOffsEdit,
  OrderIncomingsList,
  OrderIncomingsCreate,
  OrderIncomingsView,
  OrderIncomingsEdit,
  OrderOutcomingsList,
  OrderOutcomingsCopy,
  ChartIndicatorsFinancial,
  ChartIndicatorsActivity,
  OrderOutcomingsCreate,
  OrderOutcomingsView,
  OrderOutcomingsEdit,
  ExpenditureNormsList,
  ExpenditureNormsCreate,
  ExpenditureNormsView,
  ExpenditureNormsEdit,
  Turnovers,
  Loyality,
  ClientsList,
  ClientEdit,
  ClientCardLayout,
  ClientCard,
  ClientVisitsHistory,
  ClientLoyality,
  Stocktaking,
  StocktakingItems,
  UserCategoriesList,
  UserCategoriesCreate,
  UserCategoriesEdit,
  Logs,
  LogDetails,
} from './../../../pages';

import { Layout, EventDialog } from './../../../common';
import { SelectService } from './../../../pages/SelectService';
import { SelectTime } from './../../../../components/pages/SelectTime';
import { CreateRecord } from './../../../../components/pages/CreateRecord';
import { ExternalPage } from './../../../../components/pages/ExternalPage';
import { Schedule, SingleModel } from '../../../pages/Schedule';
import {
  CertificatesList,
  AbonnementsList,
  CertificatesCreate,
  CertificatesEdit,
  AbonnementsCreate
} from './../../../pages/Clients/Loyality';
import { FinancialAnalytics } from './../../../pages/Clients/Analytics';
import { SYSTEM_MODES } from './../../../../constants';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Dashboard />} />

        <Route path="visit" element={<EventDialog />} />

        <Route path="external-page" element={<ExternalPage />} />
        <Route path="select-service/:id?" element={<SelectService />} />
        <Route path="select-time/:id?" element={<SelectTime />} />
        <Route path="create-record/:id?" element={<CreateRecord />} />

        <Route path="schedule/horses">
          <Route index element={<Schedule modelType={SYSTEM_MODES.HORSE} />} />
          <Route path=":modelId" element={<SingleModel modelType={SYSTEM_MODES.HORSE} />} />
        </Route>

        <Route path="schedule/coaches">
          <Route index element={<Schedule modelType={SYSTEM_MODES.COACH} />} />
          <Route path=":modelId" element={<SingleModel modelType={SYSTEM_MODES.COACH} />} />
        </Route>

        {/* Clients */}
        <Route path="clients/loyality" element={<Loyality />}>
          <Route index element={<CertificatesList />} />

          <Route path="certificates" element={<CertificatesList />} />
          <Route path="certificates/create" element={<CertificatesCreate />} />
          <Route path="certificates/:id/edit" element={<CertificatesEdit />} />

          <Route path="abonnements" element={<AbonnementsList />} />
          <Route path="abonnements/create" element={<AbonnementsCreate />} />
        </Route>

        <Route path="clients">
          <Route index element={<ClientsList />} />
          <Route path="create" element={<ClientEdit />} />
        </Route>

        <Route path="clients/:id/edit" element={<ClientEdit />} />

        <Route path="clients/:id/*" element={<ClientCardLayout />}>
          <Route index element={<ClientCard />} />
          <Route path="card" element={<ClientCard />} />
          <Route path="visits-history" element={<ClientVisitsHistory />} />
          <Route path="loyality" element={<ClientLoyality />} />
          <Route path="financial-analytics" element={<FinancialAnalytics />} />
        </Route>

        {/* Finacial Analitics */}
        <Route path="financial-analytics" element={<FinancialAnalytics />} />

        {/* Inventory control */}
        {/* InventoryItemsAvailability */}
        <Route path="inventory-items-availability" element={<InventoryItemsAvailability />} />
        {/* Turnovers */}
        <Route path="turnovers" element={<Turnovers />} />

        {/* Stocktaking */}
        <Route path="/Stocktaking">
          <Route index element={<Stocktaking />} />
          <Route path="items" element={<StocktakingItems />} />
        </Route>

        <Route path="/persons">
          <Route index element={<PersonsList />} />
          <Route path="/persons/create" element={<CreatePerson />} />
          <Route path="/persons/:id/edit" element={<EditPerson />} />
        </Route>

        <Route path="/users">
          <Route index element={<UsersList />} />
          <Route path="/users/create" element={<CreateUser />} />
          <Route path="/users/create/:id" element={<CreateUser />} />

          <Route path="/users/:id/edit" element={<EditUser />} />
        </Route>

        <Route path="/coaches">
          <Route index element={<CoachesList />} />
          <Route path="/coaches/create" element={<EditCoach type="create" />} />
          <Route path="/coaches/:id/edit" element={<EditCoach type="edit"/>} />
        </Route>

        <Route path="/info-pages">
          <Route index element={<PagesList />} />
          <Route path="/info-pages/create" element={<PagesEdit type="create"/>} />
          <Route path="/info-pages/:id/edit" element={<PagesEdit type="edit"/>} />
        </Route>
        
        <Route path="/units">
          <Route index element={<UnitsList />} />
          <Route path="/units/create" element={<UnitsCreate />} />
          <Route path="/units/:id/edit" element={<UnitsEdit />} />
        </Route>
        <Route path="/services">
          <Route index element={<ServicesList />} />
          <Route path="create" element={<ServicesCreate />} />
          <Route path=":id/edit" element={<ServicesEdit />} />
          <Route path=":id/copy" element={<ServicesCopy />} />
        </Route>
        <Route path="/service-categories">
          <Route index element={<ServiceCategoriesList />} />
          <Route path="/service-categories/create" element={<ServiceCategoriesCreate />} />
          <Route path="/service-categories/:id/edit" element={<ServiceCategoriesEdit />} />
        </Route>
        <Route path="/horses">
          <Route index element={<HorsesList />} />
          <Route path="/horses/create" element={<HorsesCreate />} />
          <Route path="/horses/:id/edit" element={<HorsesEdit />} />
        </Route>
        <Route path="/horses-categories">
          <Route index element={<HorsesCategoriesList />} />
          <Route path="/horses-categories/create" element={<HorsesCategoriesCreate />} />
          <Route path="/horses-categories/:id/edit" element={<HorsesCategoriesEdit />} />
        </Route>
        <Route path="/certificates">
          <Route index element={<CertificatesList />} />
          <Route path="/certificates/create" element={<CertificatesCreate />} />
          <Route path="/certificates/:id/edit" element={<CertificatesEdit />} />
        </Route>
        <Route path="/certificate-categories">
          <Route index element={<CertificateCategoriesList />} />
          <Route path="/certificate-categories/create" element={<CertificateCategoriesCreate />} />
          <Route path="/certificate-categories/:id/edit" element={<CertificateCategoriesEdit />} />
        </Route>
        <Route path="/inventory-items">
          <Route index element={<InventoryItemsList />} />
          <Route path="/inventory-items/create" element={<InventoryItemsCreate />} />
          <Route path="/inventory-items/:id/edit" element={<InventoryItemsEdit />} />
        </Route>
        <Route path="/inventory-item-types">
          <Route index element={<InventoryItemTypesList />} />
          <Route path="/inventory-item-types/create" element={<InventoryItemTypesCreate />} />
          <Route path="/inventory-item-types/:id/edit" element={<InventoryItemTypesEdit />} />
        </Route>
        <Route path="/season-tickets">
          <Route index element={<SeasonTicketsList />} />
          <Route path="/season-tickets/create" element={<SeasonTicketsCreate />} />
          <Route path="/season-tickets/:id/edit" element={<SeasonTicketsEdit />} />
        </Route>
        <Route path="/season-tickets-categories">
          <Route index element={<SeasonTicketsCategoriesList />} />
          <Route
            path="/season-tickets-categories/create"
            element={<SeasonTicketsCategoriesCreate />}
          />
          <Route
            path="/season-tickets-categories/:id/edit"
            element={<SeasonTicketsCategoriesEdit />}
          />
        </Route>
        <Route path="/write-offs">
          <Route index element={<WriteOffsList />} />
          <Route path="/write-offs/create" element={<WriteOffsCreate />} />
          <Route path="/write-offs/:id" element={<WriteOffsView />} />
          <Route path="/write-offs/:id/edit" element={<WriteOffsEdit />} />
        </Route>
        <Route path="/order-incomings">
          <Route index element={<OrderIncomingsList />} />
          <Route path="/order-incomings/create" element={<OrderIncomingsCreate />} />
          <Route path="/order-incomings/:id" element={<OrderIncomingsView />} />
          <Route path="/order-incomings/:id/edit" element={<OrderIncomingsEdit />} />
        </Route>
        <Route path="/order-outcomings">
          <Route index element={<OrderOutcomingsList />} />
          <Route path="/order-outcomings/create" element={<OrderOutcomingsCreate />} />
          <Route path="/order-outcomings/:id" element={<OrderOutcomingsView />} />
          <Route path="/order-outcomings/:id/edit" element={<OrderOutcomingsEdit />} />
          <Route path=":id/copy" element={<OrderOutcomingsCopy />} />
        </Route>
        <Route path="/expenditure-norms">
          <Route index element={<ExpenditureNormsList />} />
          <Route path="/expenditure-norms/create" element={<ExpenditureNormsCreate />} />
          <Route path="/expenditure-norms/:id" element={<ExpenditureNormsView />} />
          <Route path="/expenditure-norms/:id/edit" element={<ExpenditureNormsEdit />} />
        </Route>
        <Route path="/turnovers">
          <Route index element={<Turnovers />} />
        </Route>

        {/* CHARTS */}
        <Route path="/indicators-financial" element={<ChartIndicatorsFinancial />} />
        <Route path="/indicators-activity" element={<ChartIndicatorsActivity />} />

        <Route path="/user-categories">
          <Route index element={<UserCategoriesList />} />
          <Route path="/user-categories/create" element={<UserCategoriesCreate />} />
          <Route path="/user-categories/:id/edit" element={<UserCategoriesEdit />} />
        </Route>

        <Route path="logs">
          <Route index element={<Logs />} />
          <Route path=":logId" element={<LogDetails />} />
        </Route>

      </Route>

      <Route path="*" element={<Layout />}>
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
