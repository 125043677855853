import { useNavigate } from 'react-router-dom';
import { ModalForm } from './../FormsComponent';
import { Button } from '@mui/material';
import { BookingHeader } from './../../pages/Booking/bookingHeader';
import { useSelector } from 'react-redux';

export const ExternalSuccessPaid = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const toggleOpened = () => {
    if (user) {
      navigate('/history');
      return;
    } 

    navigate('/sign-in');
  }

  return(
    <>
      <BookingHeader />
      <ModalForm
      title='Оплата успішна'
      open={true}
      onClose={toggleOpened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Button
        variant="contained"
        onClick={toggleOpened}
        sx={{ height: 40, fontSize: 14, lineHeight: 1 }}
      >
        ok
      </Button>
    </ModalForm>
    </>
  );

}