import { useEffect, useState } from 'react';
import { PERMISSIONS } from './../../../constants';
import { Link } from 'react-router-dom';
import {
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Switch,
  FormControlLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import PageviewIcon from '@mui/icons-material/Pageview';

import { getExpenditureNorms, deleteExpenditureNorms } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import { ButtonIcon, ButtonLink, ButtonLinkIcon, ToggleShowActive } from './../../common/FormsComponent';
import { rolesComponentConfig } from './rolesComponentConfig';

export const ExpenditureNormsList = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTitle, setSearchTitle] = useState('');
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showArchive, setShowArchive] = useState(false);

  const handleExpenditureNormsDelete = (id) => () => {
    if (window.confirm('Are you sure?')) {
      deleteExpenditureNorms(id).then(async () => {
        const { data: response } = await getExpenditureNorms(
          currentPage,
          itemsPerPage,
          showArchive,
          searchTitle
        );

        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getExpenditureNorms(
          currentPage,
          itemsPerPage,
          showArchive,
          searchTitle
        );
        setData({
          loaded: true,
          data: response.data
        });
        setTotalPages(response.meta.last_page);
      } catch (error) {
        // Handle error state or display an error message to the user
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, showArchive, searchTitle]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  const periodLabels = {
    0: __('expenditureNorms.input.period.day'),
    1: __('expenditureNorms.input.period.week'),
    2: __('expenditureNorms.input.period.month'),
    3: __('expenditureNorms.input.period.quarter'),
    4: __('expenditureNorms.input.period.year')
  };

  return (
    <>
      <div className="title title--mb36">{__('expenditureNorms.page.title')}</div>
      <div className="table-action">
        <div className="table-action__block">
          <div className="input-wrap">
            <TextField
              sx={{
                width: '100%',
                display: 'block',
                '.MuiInputBase-input': { pr: '40px' },
                '.MuiOutlinedInput-root': { width: '100%' }
              }}
              label={__('inventoryItem.filter.title')}
              name="inventory-item-title-filter"
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />

            <SearchIcon
              sx={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%);',
                color: 'var(--icon-color)'
              }}
            />
          </div>
        </div>
        <div className="table-action__block">
          <ToggleShowActive value={ showArchive } onChange={ () => setShowArchive(prev => !prev)} />
          <ButtonLink
            text={__('create.button.title')}
            route="create"
            checkRoles={ rolesComponentConfig }
            permission={PERMISSIONS.ACTIONS.CREATE}
          />
        </div>
      </div>
      <div className="table-block">
        <div className="table table--expenditure-norms">
          <div className="table-header">
            <div className="tr">
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.name')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.created_at')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.total')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.horse')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.period')}</p>
                </div>
              </div>
              <div className="td">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.status')}</p>
                </div>
              </div>
              <div className="td td--right">
                <div className="td-name">
                  <p>{__('expenditureNorms.table.actions')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {!loaded && (
              <div className="tr">
                <div className="td">
                  <CircularProgress />
                </div>
              </div>
            )}
            {data.map((expenditureNorms) => (
              <div className="tr" key={expenditureNorms.id}>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.name')}</p>
                  </div>
                  <div className="td-content">{expenditureNorms.name}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.created_at')}</p>
                  </div>
                  <div className="td-content">
                    {expenditureNorms.created_at ? formatDate(expenditureNorms.created_at) : ''}
                  </div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.total')}</p>
                  </div>
                  <div className="td-content">{expenditureNorms.total}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.horse')}</p>
                  </div>
                  <div className="td-content">{expenditureNorms.horse.name}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.period')}</p>
                  </div>
                  <div className="td-content">{periodLabels[expenditureNorms.period]}</div>
                </div>
                <div className="td">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.status')}</p>
                  </div>
                  <div className="td-content">
                    {expenditureNorms.status
                      ? __('general.table.active')
                      : __('general.table.inactive')}
                  </div>
                </div>
                <div className="td td--right">
                  <div className="td-hidden-name">
                    <p>{__('expenditureNorms.table.actions')}</p>
                  </div>
                  <div className="td-content">
                    <div className="table-buttons">

                      <ButtonLinkIcon 
                        icon={ <PageviewIcon /> }
                        route={ `/expenditure-norms/${expenditureNorms.id}` }
                        checkRoles={ rolesComponentConfig }
                        permission={ PERMISSIONS.ACTIONS.READ }
                      />
                      <ButtonLinkIcon 
                        icon={ <EditIcon /> }
                        route={ `/expenditure-norms/${expenditureNorms.id}/edit` }
                        checkRoles={ rolesComponentConfig }
                        permission={ PERMISSIONS.ACTIONS.UPDATE }
                      />
                      <ButtonIcon 
                        icon={ <DeleteIcon /> } 
                        onClick={ handleExpenditureNormsDelete(expenditureNorms.id) }
                        checkRoles={ rolesComponentConfig }
                        permission={ PERMISSIONS.ACTIONS.DELETE }
                      />

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="table-pagination">
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage}
          onChange={(event, page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};
