import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { DialogActions } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export function OpenDialog({ children, open, setOpen, data, redirect, type = 'normal' }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    if (redirect) {
      navigate(redirect);
    }
  };

  const typeStyles = {
    normal: {},
    error: {
      background: 'red',
      color: 'white',
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle sx={{ m: 0, p: 2, pr: 7, ...typeStyles[type] }} id="customized-dialog-title">
          { data.name || data.title }
        </DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom sx={{ workBreak: 'break-all' }}>
            { data.description }
          </Typography>
        </DialogContent>

        {
          children &&
            <DialogActions style={{ padding: '15px' }}>
              { children }
            </DialogActions>
        }
        
        
      </BootstrapDialog>
    </div>
  );
}