import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { getClient } from './../../../../actions';
import { CircularProgress } from '@mui/material';

import { useTranslation } from './../../../../helpers/translate';
import {
  TableBody,
  TrBody,
  TableMain,
  TableBlock,
  ButtonBackward
} from './../../../common/TableComponent';

import { getLayoutRowCellsData } from '../TablesData';
import { SidebarMenu } from '.';

import s from './clientCard.module.scss';

export const ClientCardLayout = () => {
  const { __ } = useTranslation();
  const {
    state: { id }
  } = useLocation();
  const [{ loaded, data, user }, setData] = useState({
    loaded: false,
    data: {},
    user: {}
  });

  const { header, mainBlock, mainBlock__sidebar, mainBlock__content } = s;

  useEffect(() => {
    (async () => {
      try {
        const response = await getClient(id);
        const data = response?.data?.data;
        setData({
          loaded: true,
          data: data,
          user: data?.user
        });
      } catch (error) {
        console.warn(`Get client fetch error: ${error}`);
      }
    })();
  }, []);

  if (!loaded) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className={header}>
        <ButtonBackward route="/clients" isState />
        <TableMain getRowCellsData={getLayoutRowCellsData} __={__}>
          <TableBlock __class="table--client-card">
            <TableBody loaded={loaded}>
              <TrBody data={data || []} />
            </TableBody>
          </TableBlock>
        </TableMain>
      </div>

      <div>
        <div>
          <SidebarMenu id={id} user={user || {}} />
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
