import axios from '../../helpers/private.axios';

export const getUserCategoriesDirectory = async (
    currentPage,
    itemsPerPage,
    showArchive,
) => {
    const params = {};


    if (showArchive) {
        params.status = 'archive';
    }


    params.page = currentPage;
    params.limit = itemsPerPage;

    return axios.get(`/user-categories`, { params });
};

