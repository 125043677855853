export const validationField = (dataName, field, data, setValidationStatus, __, callBackValidationRule) => {
  const fieldValid = callBackValidationRule(data, field);
  
  setValidationStatus((prevValidationStatus) => ({
    ...prevValidationStatus,
    [field]: {
      isValid: fieldValid,
      errorMessage: fieldValid ? '' : `${__(`${dataName}.form.${field}.error`)}`
    }
  }));
  return fieldValid;
}