import { Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import VisitForm from './../components/VisitForm';
import { AddServiceForm } from './../components/AddServiceForm';
import { getVisit } from '../../../../actions/visit/visitRoutes';
import dayjs from 'dayjs';

export default function VisitCopy({ clickedDate, extendedProps }) {
  const [value, setValue] = useState({
    id: null,
    first_name: '',
    last_name: '',
    phone: '',
    phoneCode: '+380 ',
    email: '',
    another_name: '',
    length: 1,
    visit_services: [],
    count: 1,
    status_id: 0,
    time: ''
  });

  const [validVisit, setValidVisit] = useState({
    first_name: true,
    last_name: true,
    email: true,
    phone: true,
    visit_services: true,
    time: true
  });

  const [status, setStatus] = useState(0);
  const visitId = extendedProps.visit?.id;
  const [update, setUpdate] = useState(false);
  const [load, setLoad] = useState(!visitId ? true : false);

  useEffect(() => {
    if (visitId) {
      getVisit(visitId).then((res) => {
        const data = res?.data?.data;
        const date = dayjs(data?.timeslot.date).$d;

        setLoad(true);

        data.created_at = '';
        data.timeslot.date = '';
        data.timeslot.id = '';
        data.user_id = '';

        setValue({
          ...data,
          phoneCode: '+380 ',
          phone: data?.phone.substring(5),
          editTime: date
        });
      });
    }
  }, [update]);

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          mb: 3,
          '@media screen and (max-width: 920px)': {
            fontSize: '28px'
          }
        }}>
        Копіювати візит
      </Typography>
      <VisitForm
        value={value}
        setValue={setValue}
        status={status}
        setStatus={setStatus}
        validVisit={validVisit}
        copy
      />

      <Divider variant="middle" sx={{ mb: 4 }} />

      {load && (
        <AddServiceForm
          clickedDate={clickedDate}
          setValue={setValue}
          value={value}
          status={status}
          validVisit={validVisit}
          setValidVisit={setValidVisit}
          extendedProps={extendedProps}
          setUpdate={setUpdate}
          copy
        />
      )}
    </>
  );
}
