const infoPages = {
    uk: {
        "pages.page.title": "Інформаційні сторінки",
        "pages.table.title": "Назва",
        "pages.table.name": "Назва",
        "pages.table.slug": "Слаг",
        "pages.table.body": "Контент",
        "pages.table.is_active": "Активний/Неактивний",
        "pages.table.actions": "Дії",
        "pages.table.status_active": "Активний",
        "pages.table.status_inactive": "Неактивний",
        "pages.form.title": "Заголовок",
        "pages.form.slug": "Слаг",
        "pages.form.body": "Контент",
        "pages.form.title-create": "Створити сторінку",
        "pages.form.title-edit": "Редагувати сторінку",
        "pages.form.button": "Зберегти",
        "pages.form.title.error": "*Поле є обов'язковим",
        "pages.form.body.error": "*Поле є обов'язковим",
        "pages.form.slug.error": "*Поле є обов'язковим",
    },
    en: {
        "pages.page.title": "Info pages",
        "pages.table.title": "Name",
        "pages.table.name": "Name",
        "pages.table.slug": "Slug",
        "pages.table.body": "Body",
        "pages.table.is_active": "Active/Inactive",
        "pages.table.actions": "Actions",
        "pages.table.status_active": "Active",
        "pages.table.status_inactive": "Inactive",
        "pages.form.title": "Title",
        "pages.form.slug": "Slug",
        "pages.form.body": "Body",
        "pages.form.title-create": "Create a page",
        "pages.form.title-edit": "Edit page",
        "pages.form.button": "Save",
        "pages.form.title.error": "*The field is mandatory",
        "pages.form.body.error": "*The field is mandatory",
        "pages.form.slug.error": "*The field is mandatory",
    },
    ru: {
        "pages.page.title": "Информационные страницы",
        "pages.table.title": "Название",
        "pages.table.name": "Название",
        "pages.table.slug": "Слаг",
        "pages.table.body": "Контент",
        "pages.table.is_active": "Активный/Неактивний",
        "pages.table.actions": "Действия",
        "pages.table.status_active": "Активный",
        "pages.table.status_inactive": "Неактивний",
        "pages.form.title": "Заголовок",
        "pages.form.slug": "Слаг",
        "pages.form.body": "Контент",
        "pages.form.title-create": "Создать страницу",
        "pages.form.title-edit": "Редактировать страницу",
        "pages.form.button": "Сохранить",
        "pages.form.title.error": "*Поле является обязательным",
        "pages.form.body.error": "*Поле является обязательным",
        "pages.form.slug.error": "*Поле является обязательным",

    }
}


export default infoPages;