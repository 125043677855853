import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgress, Button, TextField } from '@mui/material';
import { BookingHeader } from './bookingHeader';

import { getBookingSeasonTickets } from '../../../actions';
import { useTranslation } from '../../../helpers/translate';
import noImage from './../../library/ImageUpload/assets/img/no-image.png';

export const BookingSeasonTickets = () => {
  const { __ } = useTranslation();
  const [{ loaded, data }, setData] = useState({
    loaded: false,
    data: []
  });
  const [selectedBookingItems, setSelectedItems] = useState({});
  //const [itemQuantities, setItemQuantities] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await getBookingSeasonTickets();
        setData({
          loaded: true,
          data: response.data
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    const savedSelectedItems = localStorage.getItem('selectedBookingItems');
    if (savedSelectedItems) {
      setSelectedItems(JSON.parse(savedSelectedItems));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedBookingItems', JSON.stringify(selectedBookingItems));
  }, [selectedBookingItems]);

  const isItemSelected = (itemId) =>
    selectedBookingItems[itemId] && selectedBookingItems[itemId].selected;

  const handleItemClick = (itemId) => {
    setSelectedItems((prevSelected) => {
      const updatedSelectedItems = { ...prevSelected };

      if (updatedSelectedItems[itemId]) {
        delete updatedSelectedItems[itemId]; // Видалити, якщо вже вибрано
      } else {
        updatedSelectedItems[itemId] = { selected: true, abonnement: true }; // Додати з відміткою, що вибрано і це абонемент
      }

      return updatedSelectedItems;
    });
  };

  const isItemsActive = Object.values(selectedBookingItems).some((item) => item.selected === true);

  const getButtonStyles = (isSelected) => ({
    height: 50,
    fontSize: 16,
    lineHeight: 1,
    color: isSelected ? 'var(--main-color2)' : '',
    backgroundColor: isSelected ? 'transparent' : '',
    border: '2px solid var(--main-color2)',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: isSelected ? 'transparent' : ''
    }
  });

  return (
    <>
      <BookingHeader />
      <main className="main-booking">
        <div className="booking-wrap">
          <div className="booking-tabs">
            <Link className="button button--type2 button--width100p" to="/booking/services">
              <p>{__('booking.services.tabs.services')}</p>
            </Link>
            <Link className="button button--width100p" to="/booking/season-tickets">
              <p>{__('booking.services.tabs.seasonTickets')}</p>
            </Link>
            <Link className="button button--type2  button--width100p" to="/booking/certificates">
              <p>{__('booking.services.tabs.certificate')}</p>
            </Link>
          </div>
          <div className="booking-services">
            <div className="booking-title">
              <Link className="booking-title__back" to="/booking/">
                <svg
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.7071 0.29289C10.0976 0.68342 10.0976 1.31658 9.7071 1.70711L2.41421 9L9.7071 16.2929C10.0976 16.6834 10.0976 17.3166 9.7071 17.7071C9.3166 18.0976 8.68342 18.0976 8.29289 17.7071L0.29289 9.7071C-0.0976312 9.3166 -0.0976312 8.6834 0.29289 8.2929L8.29289 0.29289C8.68342 -0.09763 9.3166 -0.09763 9.7071 0.29289Z"
                    fill="black"
                  />
                </svg>
              </Link>
              <p>{__('booking.seasonTickets.title')}</p>
            </div>
            <div className="booking-season-tickets-list">
              {loaded ? (
                data.map((seasonTickets) => (
                  <div
                    className={`booking-season-tickets-list__item ${
                      selectedBookingItems[seasonTickets.id] ? 'active' : ''
                    }`}
                    key={seasonTickets.id}>
                    <div className="booking-season-tickets-list__item-photo">
                      <img src={seasonTickets?.image ? seasonTickets?.image : noImage} alt="" />
                    </div>
                    <div className="booking-season-tickets-list__item-info">
                      <div className="booking-season-tickets-list__item-title">
                        <p>{seasonTickets.title}</p>
                      </div>
                      <div className="booking-season-tickets-list__item-text">
                        <p>{seasonTickets.service.title}</p>
                      </div>
                      <div className="booking-season-tickets-list__item-text">
                        <p>{seasonTickets.description}</p>
                      </div>
                      <div className="booking-season-tickets-list__item-amount">
                        <p>{seasonTickets.amount}</p>
                      </div>
                      <div className="booking-season-tickets-list__actions">
                        <Button
                          variant="contained"
                          sx={getButtonStyles(isItemSelected(seasonTickets.id))}
                          onClick={() => handleItemClick(seasonTickets.id)}>
                          {isItemSelected(seasonTickets.id) ? 'Видалити' : 'Вибрати'}
                        </Button>
                        {/*
                        <div className="booking-season-tickets-list__actions-counter">
                          <button
                            className="button-add-counter button-add-counter--minus"
                            onClick={() => {
                              const currentQuantity = itemQuantities[seasonTickets.id] || 0;
                              if (currentQuantity > 0) {
                                handleQuantityChange(seasonTickets.id, currentQuantity - 1);
                              }
                            }}>
                            <span></span>
                          </button>
                          <TextField
                            name="actions-counter"
                            label="Кількість"
                            value={itemQuantities[seasonTickets.id] || ''}
                            onChange={(e) => {
                              const value = parseInt(e.target.value);
                              if (!isNaN(value) && value >= 0) {
                                handleQuantityChange(seasonTickets.id, value);
                              }
                            }}
                            sx={{
                              height: '50px',
                              width: '120px',
                              fontSize: 16,
                              '.MuiInputBase-input': {
                                height: '100%'
                              },
                              '. MuiFormLabel-root': {
                                lineHeight: 'normal'
                              }
                            }}
                          />
                          <button
                            className="button-add-counter button-add-counter--plus"
                            onClick={() =>
                              handleQuantityChange(
                                seasonTickets.id,
                                (itemQuantities[seasonTickets.id] || 0) + 1
                              )
                            }>
                            <span></span>
                            <span></span>
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <CircularProgress />
              )}
            </div>
            <div className={`booking-action ${isItemsActive ? 'active' : ''}`}>
              <div className="booking-action-inside">
                <Link className="button button--width100p" to={`/booking/booking-basket`}>
                  <p>{__('booking.button.next')}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
