import axios from '../../helpers/private.axios';

export const getPersonalSeasonTickets = (
  sortOption,
  sortDirection,
  statusFilter,
  currentPage,
) => {
  const params = {};

    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (statusFilter || statusFilter === 0) {
        params['status_id'] = statusFilter;
    }

    params.page = currentPage;
    params.sort = '-id';

  return axios.get(`/site/cabinet/abonements`, { params });
};
