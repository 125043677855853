import axios from '../../helpers/private.axios';

export const getPersonalSertificates = (
  sortOption,
  sortDirection,
  statusFilter,
  currentPage,
  itemsPerPage,
) => {
  const params = {};

    if (sortOption && sortDirection !== 0) {
        params.sort = sortDirection === 1 ? sortOption : `-${sortOption}`;
    }

    if (statusFilter || statusFilter === 0) {
        params['status_id'] = statusFilter;
    }

    params.page = currentPage;
    // params.limit = itemsPerPage;

  return axios.get(`/site/cabinet/certificates`, { params });
};
