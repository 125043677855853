export const validateLocale = (locale, localeData, data, dataName, setValidationStatus, __) => {
  let isValid = true;
  for (let field in localeData) {
    const value = data.locale[locale] && data.locale[locale][field];
    const isTitleValid = !!(value && value.trim() !== '');

    setValidationStatus((prevValidationStatus) => ({
      ...prevValidationStatus,
      [locale]: {
        ...prevValidationStatus[locale],
        [field]: {
          isValid: isTitleValid,
          errorMessage: isTitleValid ? '' : `${__(`${dataName}.form.${field}.error`)}`
        }
      }
    }));

    if (!isTitleValid) {
      isValid = false;
    }
  }

  return isValid;
}